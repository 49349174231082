import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import React, { useEffect, useState } from 'react'
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
    InputGroup,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap'
import { useNavigate } from "react-router-dom";
import DataTable from '../../../components/Common/DataTable';
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Select from 'react-select';
import { areaSearchApiCall } from '../../../Utils/searchApiCall';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';

function SubAdmins() {

    const [isSubAdminModelOpen, setSubAdminModelOpen] = useState(false);
    const [countryMenu, setCountryMenu] = useState(false);
    const [stateMenu, setStateMenu] = useState(false);
    const [cityMenu, setCityMenu] = useState(false);
    const [areaMenu, setAreaMenu] = useState(false);
    const [PremiseNameMenu, setPremiseNameMenu] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openActiveModal, setOpenActiveModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [subAdminList, setSubAdminList] = useState([]);
    const [dataTableId, setDataTableId] = useState(null);
    const [areaData, setAreaData] = useState<any[]>([]);
    const [isEditable, setIsEditable] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [isOpenAssignModal, setOpenAssignModal] = useState<boolean>(false);
    const [accessData, setAccessData] = useState<any[]>([]);
    const [removeAccessId, setRemoveAccessId] = useState<any>(null);
    const [isOpenRemoveAccess, setOpenRemoveAccess] = useState<boolean>(false);
    const [grantorUserId, setGrantorUserId] = useState<any>(null);

    console.log("accessData", accessData);

    const navigate = useNavigate();

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
    };

    const accessPremisesListApiCall = (id: string) => {
        setLoading(true);
        const reqObj = {
            userId: id
        }
        Request.post(url.ACCESS_PREMISES_LIST_URL, reqObj).
            then((res) => {
                setAccessData(res.data.data);
                // console.log("Service person List Response", res);
                // const modifiedData = res.data.data.map((row: any, index: number) => {
                //     return { index: index + 1, ...row }; // Adding an 'index' property to represent the row index
                // });
                // setServicePersonList(modifiedData);
                setLoading(false)
            }).
            catch((err) => {
                //   console.log("service person List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const toggleSubAdminModel = () => {
        setIsEditable(false);
        setSubAdminModelOpen(!isSubAdminModelOpen);
        addSubAdminFormReset()
    }

    const toggleAssignPrimiseList = () => {
        setOpenAssignModal(!isOpenAssignModal);
        // assignPremisesFormReset()
    }

    const handleAccessModal = (data: any) => {
        console.log("call access modal", data);
        accessPremisesListApiCall(data.id)
        setOpenAssignModal(true);
        setGrantorUserId(data?.id);
    }

    const addSubAdminFormReset = () => {
        validation.resetForm({
            values: {
                adminId: "",
                firstname: "",
                lastname: "",
                email: "",
                mobile: "",
                // areaId: "",
                addSubAdminError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const validation = useFormik({
        initialValues: {
            adminId: "",
            firstname: "",
            lastname: "",
            email: "",
            mobile: "",
            // areaId: "",
            addSubAdminError: ""
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string()
                .required("Please provide firstname "),
            lastname: Yup.string()
                .required("Please provide lastname "),
            email: Yup.string().trim()
                .email("Invalid email address")
                .required("Please provide email address "),
            mobile: Yup.string()
                .required("Please provide mobile ").min(10, "Mobile number not valid"),
            // areaId: Yup.string()
            //     .required("Please provide area "),
        }),
        onSubmit: (values) => {
            //console.log("values", values);
            // addSubAdminApiCall(values);
            isEditable ? editSubAdminApiCall(values) : addSubAdminApiCall(values)
        }
    })

    const subAdminListApiCall = () => {
        setLoading(true);
        const reqObj = {
            "draw": 1,
            "search": {
                "value": "",
            }
        }
        Request.post(url.GET_SUB_ADMIN_URL, reqObj).
            then((res) => {
                //  console.log("sub admin List Response", res);
                // setAreaList(res.data.data);
                setSubAdminList(res.data.data);
                const modifiedData = res.data.data.map((row: any, index: number) => {
                    return { index: index + 1, ...row }; // Adding an 'index' property to represent the row index
                });
                //  console.log("modifiedData", modifiedData);
                setSubAdminList(modifiedData);
                setLoading(false)
            }).
            catch((err) => {
                //   console.log("sub admin List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }


    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const addSubAdminApiCall = (values: any) => {
        //  console.log("add sub Admin values", values);
        setLoading(true);
        Request.post(url.ADD_SUB_ADMIN_URL, values).
            then((res) => {
                //  console.log("Add Sub Admin Response", res);
                toggleSubAdminModel();
                subAdminListApiCall();
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                //   console.log("add Sub Admin Error", err);
                validation.setErrors({ addSubAdminError: err?.response?.data?.errors[0]?.msg })
                setLoading(false)
            })
    }

    const editSubAdminApiCall = (values: any) => {
        console.log("add sub Admin values", values);
        const reqObj = {
            subAdminId: values.adminId,
            email: values.email,
            mobile: values.mobile,
            firstname: values.firstname,
            lastname: values.lastname,
            // areaId: values.areaId,
        }
        setLoading(true);
        Request.post(url.EDIT_SUB_ADMIN_URL, reqObj).
            then((res) => {
                //  console.log("Add Sub Admin Response", res);
                toggleSubAdminModel();
                subAdminListApiCall();
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                //   console.log("add Sub Admin Error", err);
                validation.setErrors({ addSubAdminError: err?.response?.data?.errors[0]?.msg })
                setLoading(false)
            })
    }

    const deleteSubAdminApiCall = () => {
        setLoading(true);
        const reqObj = {
            subAdminId: deleteData.id
        }
        Request.post(url.DELETE_SUB_ADMIN_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                validation.resetForm({
                    values: {
                        adminId: "",
                        firstname: "",
                        lastname: "",
                        email: "",
                        mobile: "",
                        // areaId: "",
                        addSubAdminError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg)
                reloadDataTable();
                setOpenDeleteModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const removeAssignApiCall = () => {
        setLoading(true);
        const reqObj = {
            grantorUserId: removeAccessId,
            recipientUserId: grantorUserId
        }
        Request.post(url.REMOVE_ACCESS_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                toast.success(res.data.msg)
                reloadDataTable();
                accessPremisesListApiCall(grantorUserId)
                setOpenRemoveAccess(false)
                setRemoveAccessId(null)
                setLoading(false)

            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        //console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const areaSearch = (e: any) => {
        areaSearchApiCall(e, setAreaData);
    }

    const columns = [
        // { data: 'index', title: 'Id' },
        {
            title: 'Name',
            render: function (data: any, type: any, row: any) {
                return `${row?.firstname} ${row?.lastname}`; // Combining firstname and lastname for the 'Name' column
            }
        },
        { data: 'email', title: 'Email' },
        {
            title: 'Mobile Number',
            render: function (data: any, type: any, row: any) {
                return `+91 ${row?.mobile}`; // Combining firstname and lastname for the 'Name' column
            }
        },
        {
            data: null,
            title: 'No. Of Access',
            render: function (data: any, type: any, row: any) {
                const underlineStyle = 'text-decoration: underline; cursor: pointer;';
                return `<span style="${underlineStyle}">${row.noOfAccess}</span>`
            },
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isActive ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Active</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Inactive</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;">
                                    <box-icon type='solid' name='edit' size="20px"></box-icon>
                                </button>
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        areaSearch("a")
        // setAreaData([{ id: rowData.id, name: rowData.name }])
        // setAreaData([{ id: rowData.areaId, name: rowData.areaId.name }])
        // setAreaDetail(rowData)
        setIsEditable(true)
        setSubAdminModelOpen(true);
        validation.setValues({
            adminId: rowData.id,
            firstname: rowData.firstname,
            lastname: rowData.lastname,
            email: rowData.email,
            mobile: rowData.mobile,
            // areaId: rowData.areaId,
            addSubAdminError: ""
        })
    }

    const handleDeleteClick = (rowData: any) => {
        setOpenDeleteModal(!openDeleteModal);
        setDeleteData(rowData);
    }

    const handleRemoveAccessClick = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData);
        setOpenRemoveAccess(!isOpenRemoveAccess);
        setRemoveAccessId(rowData.id);
        // Add your logic here
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <div>
                <Modal
                    isOpen={isSubAdminModelOpen}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleSubAdminModel}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Sub-Admin" : "Add Sub-Admin"}</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                // console.log("call on submit", e);
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.addSubAdminError ? (
                                    <Alert color="danger">{validation.errors.addSubAdminError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">Firstname</Label>
                                    <Input
                                        name="firstname"
                                        type={"text"}
                                        placeholder="Enter Firstname"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.firstname || ""}
                                        invalid={
                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                        }
                                    />
                                    {validation.touched.firstname && validation.errors.firstname ? (
                                        <span className="error">{validation.errors.firstname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Lastname</Label>
                                    <Input
                                        name="lastname"
                                        type={"text"}
                                        placeholder="Enter Lastname"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.lastname || ""}
                                        invalid={
                                            validation.touched.lastname && validation.errors.lastname ? true : false
                                        }
                                    />
                                    {validation.touched.lastname && validation.errors.lastname ? (
                                        <span className="error">{validation.errors.lastname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        type={"text"}
                                        placeholder="Enter Email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <span className="error">{validation.errors.email}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Mobile Number</Label>
                                    <Input
                                        name="mobile"
                                        type={"text"}
                                        placeholder="Enter Mobile"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.mobile || ""}
                                        maxLength={10}
                                        invalid={
                                            validation.touched.mobile && validation.errors.mobile ? true : false
                                        }
                                    />
                                    {validation.touched.mobile && validation.errors.mobile ? (
                                        <span className="error">{validation.errors.mobile}</span>
                                    ) : null}
                                </div>
                                {/* <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Area(S)</Label>
                                    <Select
                                        name="areaId"
                                        value={areaData.find((option: any) => option.value === validation.values.areaId)}
                                        options={areaData}
                                        onChange={(selectedOption: any) => validation.setFieldValue('areaId', selectedOption.value)}
                                        onBlur={() => validation.setFieldTouched('areaId', true)}
                                        // invalid={
                                        //     validation.touched.areaId && validation.errors.areaId ? true : false
                                        // }
                                        onFocus={(e) => areaSearch("a")}
                                        onInputChange={(e) => areaSearch(e)}
                                        styles={customStyles}
                                    />
                                    {validation.touched.areaId && validation.errors.areaId ? (
                                        <span className="error">{validation.errors.areaId}</span>
                                    ) : null}
                                </div> */}

                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                    {/* {loading ? "Saving..." : "Save"} */}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {/* ASSIGN PREMISES LIST */}
                <Modal
                    isOpen={isOpenAssignModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    size='lg'
                    // tabIndex="-1"
                    toggle={toggleAssignPrimiseList}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Access Of Users</ModalHeader>
                        <ModalBody>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>
                                            First Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Mobile Number
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accessData.length === 0 && !loading ?
                                        <tr aria-colspan={3}>
                                            <td
                                                colSpan={3} style={{ textAlign: "center" }}>
                                                No data available in table
                                            </td>
                                        </tr>
                                        :
                                        accessData.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td scope="row">
                                                    {`${item.firstname} ${item.lastname}`}
                                                </td>
                                                <td scope="row">
                                                    {item.email}
                                                </td>
                                                <td scope="row">
                                                    {item.mobile}
                                                    {/* {`+ 91 ${item?.mobile}`} */}
                                                </td>
                                                <td scope="row">
                                                    <button
                                                        onClick={() => handleRemoveAccessClick(item)}
                                                        className="edit-btn" style={{ border: "none", outline: "none", background: "none" }}>
                                                        <span
                                                            className="badge bg-danger"
                                                            style={{
                                                                height: 25,
                                                                // width: 60,
                                                                fontSize: 12,
                                                                padding: 6
                                                            }}
                                                        >
                                                            Remove Access
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </ModalBody>
                    </div>
                </Modal>
                {openDeleteModal &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Delete Sub-Admin"
                        text={`Are You Sure you want to delete Sub-Admin ${deleteData.firstname} ${deleteData.lastname}? Please note that all Premise assigned to them will become unassigned.`}
                        buttonText="Delete"
                        onClick={() => { deleteSubAdminApiCall() }}
                        cancleBtnClick={() => setOpenDeleteModal(false)}
                    />
                }
                {isOpenRemoveAccess &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Remove Access"
                        text={`Are You Sure you want to remove access?`}
                        buttonText="Remove"
                        onClick={() => { removeAssignApiCall() }}
                        cancleBtnClick={() => setOpenRemoveAccess(false)}
                    />
                }
                {openActiveModal &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Active Sub-Admin"
                        text="Are you sure you want to activate this sub-admin? Once activated, the sub-admin will have access to the system."
                        buttonText="Confirm"
                        onClick={() => { }}
                        cancleBtnClick={() => setOpenActiveModal(false)}
                    />
                }
                {/* {loading &&
                    <Spinners />} */}
                <DataTable
                    // data={subAdminList}
                    apiURL={`${url.BASE_URL}${url.GET_SUB_ADMIN_URL}`}
                    columns={columns}
                    isAddBtnShow={true}
                    isBtnShow={false}
                    btnName="Add Sub Admin"
                    btnStyle="btn btn-dark"
                    secondBtnText="Addd"
                    secondBtnStyle="btn btn-outline-secondary"
                    onClickBtn={toggleSubAdminModel}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                    isClicked={true}
                    handleColumnClicked={handleAccessModal}
                    // editApiCall={() => { }}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"SubAdmin"}
                />
            </div>
        </React.Fragment >
    )
}

export default SubAdmins