import React, { useEffect, useState } from 'react'
import { Alert, Card, CardBody, CardText, Col, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap'
// import Offcanvas from 'react-bootstrap/Offcanvas';
import { customStyles } from './style'
import { Images } from '../../../constants/images'
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../../assets/Lottie_json/fan.json";
import Switch from "react-switch";
import Slider from 'react-rangeslider';
import getStorageData from '../../../Utils/getStorageData';
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper';
import { any } from 'prop-types';
import SwitchData from './SwitchData';
import { io } from 'socket.io-client';
import { GET_CHIP_STATUS, MY_CHIP_DATA } from '../../../helpers/socketEvent';
import { useSocket } from '../../../Utils/socket';
import useWindowDimensions from '../../../Utils/useWindowDimensions';
import { useSelector } from 'react-redux';
import ServiceSwitch from './ServiceSwitch';
import Scene from './Scene';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-rangeslider/lib/index.css';

export interface RoomControlProps {
    roomSwitchDetail: any;
    showRoomControl: boolean,
    toggleRoomControl?: () => void;
}


const RoomControl = ({
    roomSwitchDetail,
    showRoomControl,
    toggleRoomControl
}: any) => {
    const { height, width } = useWindowDimensions();
    const data = useSelector((state: any) => state.APIResponse.chipData)
    console.log("data", data);

    // const chipStatus = useSelector((state: any) => state.APIResponse.changeChipStatus)

    console.log("roomSwitchDetail", roomSwitchDetail);
    const [value, setValue] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [switchList, setSwitchList] = useState<any>([]);
    const [getSwitchList, setGetSwitchList] = useState<any[]>([]);
    const [sceneList, setSceneList] = useState<any[]>([]);
    const [slaveData, setSlaveData] = useState<number[]>([]);
    const [scene, setScene] = useState<any[]>([]);
    const [isDisabled, setDisabled] = useState<any>(false);

    console.log("switchList", switchList);

    const sceneData = [
        {
            switchItem: {
                chipSwitchId: {
                    button: "03",
                    type: 6,
                    icon: "",

                },
                modelType: "4HIN",
                isMenually: true,
                id: ""
            },
            isClickable: false,
            isOnOff: "0",

        },
        {
            switchItem: {
                chipSwitchId: {
                    button: "04",
                    type: 6,
                    icon: "",

                },
                modelType: "4HIN",
                isMenually: true,
                id: ""
            },
            isClickable: false,
            isOnOff: "0",

        },
        {
            switchItem: {
                chipSwitchId: {
                    button: "05",
                    type: 6,
                    icon: "",
                },
                modelType: "4HIN",
                isMenually: true,
                id: ""
            },
            isClickable: false,
            isOnOff: "0",

        },
        {
            switchItem: {
                chipSwitchId: {
                    button: "06",
                    type: 6,
                    icon: "",
                },

                id: "",
                modelType: "4HIN",
                isMenually: true
            },
            isClickable: false,
            isOnOff: "0",

        },
    ]
    console.log("switchList", switchList);

    const socket: any = useSocket();

    const switchListApiCall = () => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID
        }
        Request.get(`${url.GET_SWITCH_LIST_URL}?premiseId=${getStorageData().selectedPremiseID}&roomId=${roomSwitchDetail.id}`).
            then((res) => {
                // console.log("socket.on", socket.on);

                var slave: any[] = [];
                // console.log("switch List Response", res);
                const data = res.data.switches
                // console.log("data: ", data);

                for (let i = 0; i < data.length; i++) {
                    const switchElement = data[i].chipSwitchId;
                    // console.log("switchElement", switchElement.slave);
                    slave.push(switchElement.slave);
                }
                var switches: any = [];
                for (let i = 0; i < data.length; i++) {
                    const switchElement = data[i]
                    const modelType = switchElement.modelType;
                    const button = switchElement.chipSwitchId.button;
                    const scene = [];
                    // else {
                    console.log("switchElement", switchElement);
                    switches.push({
                        switchItem: {
                            ...switchElement,
                            identifier: `${switchElement.chipSwitchId.slave}${switchElement.chipSwitchId.button}`
                        },
                        switchDetail: {
                            id: "",
                            val: 0,
                            dval: "XX",
                            isNotificationSubscribed: false,
                            displayName: "",
                            roomName: "",
                        },
                        isClickable: false,
                        isOnOff: "0",
                        isMenually: false
                    })
                }
                // }
                {
                    switches.length === 0 ? setSwitchList(sceneData) :
                        setSwitchList(switches);
                }
                setSlaveData(slave);
                removeDuplicateSlave(slave, data);
                setGetSwitchList(data);
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    const removeDuplicateSlave = (slave: number[], switches: any[]) => {
        let unique: any = [];
        slave.forEach((element: any) => {
            if (!unique.includes(element)) {
                unique.push(element);
            }
        });
        setSlaveData(unique);
    }

    useEffect(() => {
        getChipStatus()
    }, [slaveData])
    console.log("slaveData", JSON.stringify(slaveData));

    const getChipStatus = async () => {
        socket.emit(GET_CHIP_STATUS, JSON.stringify(slaveData));
    }

    var updateSwitches: any = [];
    var allSwitches: any = [];
    const update = (array: any, id: any, newValue: any) => {
        setSwitchList((prevArray: any) => {
            return prevArray.map((obj: any) => {
                if (obj.id === id) {
                    return { ...obj, name: newValue }; // Update the value
                } else {
                    return obj;
                }
            });
        });
    }
    // socket.on(MY_CHIP_DATA, (data: any) => {
    // useEffect(() => {
    //     console.log("get chip data", data);
    //     if (!loading && data.length > 0) {
    //         const result: any = {};
    //         data.forEach((dataItem: any) => {


    //             const slave = dataItem.slave;
    //             const switches = dataItem.detail.switches;
    //             const keys = Object.keys(switches);
    //             const combinedKeys = keys.map(key => slave + key);
    //             const findIndex = switchList.findIndex((item: any) => item?.switchItem?.chipSwitchId?.slave === slave)
    //             console.log("findIndex", findIndex);
    //             const uniqSlave = switchList.filter((item: any) => item?.switchItem?.chipSwitchId?.slave !== slave)

    //             if (data.length === 1) {
    //                 updateSwitches = uniqSlave
    //             }

    //             console.log("uniqObj", uniqSlave);

    //             const switchObj = switchList.filter((item: any) => combinedKeys.includes(item?.switchItem?.identifier));

    //             if (switchObj) {
    //                 result[slave] = { data: dataItem, switch: switchObj };
    //                 const dataDetailSwitches = result[slave].data.detail.switches;
    //                 const switchArray = result[slave].switch;
    //                 console.log("switchArray", switchArray);
    //                 // const matchedObjects = [];
    //                 for (const key in dataDetailSwitches) {
    //                     console.log("dataItem", dataItem);

    //                     const button = key;
    //                     const switchItem = switchArray.find((item: any) => item.switchItem.chipSwitchId.button === button);

    //                     if (switchItem) {
    //                         console.log("switchItem", switchItem);
    //                         switchItem.switchItem.modelType === "4HISC" || switchItem.switchItem.modelType === "4HIN" ?
    //                             updateSwitches.push({
    //                                 ...switchItem,
    //                                 switchDetail: dataDetailSwitches[key],
    //                                 isClickable: dataItem.slave && dataItem.detail.isOnline === true ? true : false,
    //                                 isOnOff: dataDetailSwitches[key].val ? dataDetailSwitches[key].val : "0"
    //                             }) :
    //                             allSwitches.push({
    //                                 ...switchItem,
    //                                 switchDetail: dataDetailSwitches[key],
    //                                 isClickable: dataItem.slave && dataItem.detail.isOnline === true ? true : false,
    //                                 isOnOff: dataDetailSwitches[key].val ? dataDetailSwitches[key].val : "0"
    //                             })
    //                     }
    //                 }
    //             }
    //         })

    //         const modelType = updateSwitches.filter((switches: any) => switches?.switchItem?.modelType === "4HISC" || switches?.switchItem?.modelType === "4HIN")
    //         const filterUpdatedSwitch = modelType.filter((switches: any) =>
    //             switches.switchItem.chipSwitchId.button === "03" ||
    //             switches.switchItem.chipSwitchId.button === "04" ||
    //             switches.switchItem.chipSwitchId.button === "05" ||
    //             switches.switchItem.chipSwitchId.button === "06"
    //         )
    //         console.log("filterUpdatedSwitch", filterUpdatedSwitch);

    //         const filteredArray1 = sceneData.filter((obj1: any) =>
    //             !filterUpdatedSwitch.some((obj2: any) =>
    //                 // console.log("updateSwitches======>", obj1, obj2)
    //                 obj1.switchItem.chipSwitchId.button === obj2.switchItem.chipSwitchId.button

    //                 // obj1.id === obj2.id && obj1.name === obj2.name
    //             )
    //         );
    //         console.log("filteredArray1", filteredArray1);
    //         console.log("combineArray", allSwitches.concat(filteredArray1, updateSwitches));
    //         const combineArray = allSwitches.concat(filteredArray1, updateSwitches)
    //         console.log("combineArray", combineArray);
    //         setSwitchList(combineArray);
    //     }

    // }, [data, loading])
    //     // console.log("updateSwitches", switchList);
    // })

    useEffect(() => {
        console.log("switch data==============>", data);

        if (!loading && data.length > 0) {
            const updatedSwitchList = switchList.map((switchItem: any) => {
                const match = data.find((item: any) => item.detail.id === switchItem.switchItem.chipId);
                console.log("dataa", match);

                if (match) {
                    return {
                        ...switchItem,
                        isOnOff: match.detail.switches[switchItem.switchItem.chipSwitchId.button].val ? match.detail.switches[switchItem.switchItem.chipSwitchId.button].val : "0",
                        isClickable: match.slave && match.detail.isOnline ? true : false,
                        switchDetail: {
                            ...switchItem.switchDetail,
                            val: match.detail.switches[switchItem.switchItem.chipSwitchId.button].val,
                            dval: match.detail.switches[switchItem.switchItem.chipSwitchId.button].dval,
                            isNotificationSubscribed: match.detail.switches[switchItem.switchItem.chipSwitchId.button].isNotificationSubscribed,
                            displayName: match.detail.switches[switchItem.switchItem.chipSwitchId.button].displayName,
                            roomName: match.detail.switches[switchItem.switchItem.chipSwitchId.button].roomName
                        }
                    };
                } else {
                    return switchItem;
                }
            })
            const filteredSceneData = sceneData.filter(sceneItem => {
                return !updatedSwitchList.some((switchItem: any) =>
                    switchItem.switchItem.chipSwitchId.button === sceneItem.switchItem.chipSwitchId.button &&
                    switchItem.switchItem.modelType === "4HISC" ||
                    switchItem.switchItem.chipSwitchId.button === sceneItem.switchItem.chipSwitchId.button &&
                    switchItem.switchItem.modelType === "4HIN"
                );
            });
            console.log("filteredSceneData", filteredSceneData);

            const combineArray = [...updatedSwitchList, ...filteredSceneData]
            console.log("updatedSwitchList", combineArray)

            setSwitchList(combineArray);
        }
    }, [data, loading])

    useEffect(() => {
        switchListApiCall();
    }, [])
    // useEffect(() => {
    //     // getChipStatus()
    //     // console.log("getSwitchList", getSwitchList);
    //     if (showRoomControl) {
    //         // console.log("getSwitchList", getSwitchList);

    //         const intervalId: any = getSwitchList.length > 1 &&
    //             setInterval(() => {
    //                 getChipStatus()
    //             }, 3000)
    //         return () => clearInterval(intervalId);
    //     }
    // }, [loading, showRoomControl])
   
    return (
        <>
            <Offcanvas
                isOpen={showRoomControl}
                direction="end"
                toggle={() => toggleRoomControl()}
                style={{ width: width < 1000 ? "90%" : "50%", overflowY: "auto", }}
            >
                <div style={{
                    pointerEvents: !roomSwitchDetail?.canControl ? "none" : "auto",
                    cursor: !roomSwitchDetail?.canControl ? "not-allowed" : "auto"
                }}
                >
                    <OffcanvasHeader>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", width: '100% !important' }}>
                            <p style={{ cursor: "pointer" }} onClick={() => toggleRoomControl()} ><span style={{ width: "40% !important" }}> <i className="bx bx-chevron-left" style={{ fontSize: 40 }}></i></span></p>
                            <span style={{ width: "40% !important" }}><p style={{ fontSize: 24 }}> Room No. {roomSwitchDetail.name}</p></span>
                            {/* <span style={{ width: "40% !important" }}>{<i className="bx bx-lock" style={{ fontSize: 30 }}></i>}</span> */}
                            <span style={{ width: "40% !important" }}>{roomSwitchDetail?.canControl ? <></> : <i className="bx bx-lock" style={{ fontSize: 30 }}></i>}</span>
                        </div>

                    </OffcanvasHeader>
                    <OffcanvasBody style={{ marginRight: 20, marginLeft: 20 }}>
                        {/* {!roomSwitchDetail?.canControl && <Alert color="danger">
                            You don't have a control access
                        </Alert>} */}
                        <div style={customStyles.offCanvasHeader()}>
                            {switchList.map((item: any, index: number) => {
                                console.log(
                                    "switchItem", item
                                );
                                if (item?.switchItem?.modelType === "4HISC" || item?.switchItem?.modelType === "4HIN") {
                                    return (
                                        <div key={index}>
                                            <ServiceSwitch
                                                switchData={item}
                                                getChipStatus={() => getChipStatus()}
                                            />
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index}></div>
                                    )
                                }
                            })}
                        </div>
                        <Row>

                            {switchList.map((item: any, index: any) => {
                                return (
                                    <React.Fragment key={index}>
                                        <SwitchData
                                            switchData={item}
                                            getChipStatus={() => getChipStatus()}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </Row>
                        {switchList.filter((item: any) => item.switchItem?.chipSwitchId?.type === 7).length > 1 &&
                            <h3 style={{ fontSize: 20 }}>Scenes</h3>}
                        <Row>
                            {switchList.map((item: any, index: any) => {
                                if (item?.switchItem?.chipSwitchId?.type === 7) {
                                    return (
                                        <React.Fragment key={index}>
                                            <Scene
                                                switchData={item}
                                                getChipStatus={() => getChipStatus()}
                                            />
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </Row>

                        {/* <h3>Scenes</h3>
                    <Row>
                        <Col xl="6">
                            <Card style={customStyles.handleRoom()}>
                                <CardBody>
                                    <CardText className="float-end">

                                    </CardText>
                                    <CardText style={{ width: "50%", alignItems: "center", display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 10 }}>
                                            <img src={Images.SunRise} />
                                        </div>
                                        <h6 style={{ marginTop: 10 }}>Morning</h6>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card style={customStyles.handleRoom()}>
                                <CardBody>
                                    <CardText className="float-end">
                                    </CardText>
                                    <CardText style={{ width: "50%", alignItems: "center", display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 10 }}>
                                            <img src={Images.Movie} />
                                        </div>
                                        <h6 style={{ marginTop: 10 }}>Movie</h6>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h3>Automations</h3>
                    <Row>
                        <Col xl="6">
                            <Card style={customStyles.handleRoom()}>
                                <CardBody>
                                    <CardText className="float-end">
                                        <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                        <h4 style={{ fontSize: 16, color: "#5D6679" }}>Check in</h4>
                                    </CardText>
                                    <CardText>
                                        <img src={Images.TunableLigntOn} />
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="6">
                            <Card style={customStyles.handleRoom()}>
                                <CardBody>
                                    <CardText className="float-end">
                                        <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                        <h4 style={{ fontSize: 16, color: "#5D6679" }}>Check in</h4>
                                    </CardText>
                                    <CardText>
                                        <img src={Images.TunableLigntOff} />
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="6">
                            <Card style={customStyles.handleRoom()}>
                                <CardBody>
                                    <CardText className="float-end">
                                        <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                        <h4 style={{ fontSize: 16, color: "#5D6679" }}>Check in</h4>
                                    </CardText>
                                    <CardText>
                                        <img src={Images.TunableLigntOff} />
                                    </CardText>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    </OffcanvasBody>
                </div>
            </Offcanvas >
        </>
    )

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    // return (
    //     <Offcanvas show={showRoomControl} onHide={toggleRoomControl} placement='end'>
    //         <Offcanvas.Header closeButton>
    //             <Offcanvas.Title>Offcanvas</Offcanvas.Title>
    //         </Offcanvas.Header>
    //         <Offcanvas.Body>
    //             Some text as placeholder. In real life you can have the elements you
    //             have chosen. Like, text, images, lists, etc.
    //         </Offcanvas.Body>
    //     </Offcanvas>
    // )
}

export default RoomControl