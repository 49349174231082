import React, { useState } from 'react';
// import FirmwareDataTable from './FirmwareDataTable';
import * as url from "../../../helpers/url_helper";
import { Images } from '../../../constants/images';
import { convertLocalDate } from '../../../Utils/configController';
import FirmwareDataTable from '../Firmware/FirmwareDataTable';
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../components/Common/Breadcrumb';

const TestingDetailsOfDetails = () => {
    const navigate = useNavigate();

    const [dataTableId, setDataTableId] = useState(null);
    const [isOpenAddTestingModel, setOpenAddTestingModel] = useState(false);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Test Case',
            render: function (data: any, type: any, row: any) {
                return row?.userLable ? `${row?.userLable}` : "-";
            }
        },
        {
            title: 'Detail',
            render: function (data: any, type: any, row: any) {
                return row?.hardwareId?.hardwareVersion ? `${row?.hardwareId?.hardwareVersion}` : "-";
            }
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                return row?.firmwareId?.firmwareVersion ? `${row?.firmwareId?.firmwareVersion}` : "-";
            }
        },
        {
            title: 'Send Command',
            render: function (data: any, type: any, row: any) {
                return row?.scheduledDateTime ? convertLocalDate(row?.scheduledDateTime) : "-";
            }
        },
        {
            title: 'Response Command',
            render: function (data: any, type: any, row: any) {
                return row?.scheduledDateTime ? convertLocalDate(row?.scheduledDateTime) : "-";
            }
        },
    ]

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const toggleAddTestingModel = () => {
        setOpenAddTestingModel(!isOpenAddTestingModel)
    }

    const validation = useFormik({
        initialValues: {
            slave: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            slave: Yup.string()
                .required("Please provide your slave "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            // addSlaveApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const handleNavigateDetails = (rowData: any) => {
        console.log("handleNavigateDetails", rowData);
        navigate("/details-of-testing")
    }

    return (
        <div className="page-content" style={{ backgroundColor: '#fff' }}>
            <Breadcrumb
                title="Testing"
                breadcrumbItem={`fdg`}
                secondPath={true}
                secondTitle={"Details of Testing"}
                titleClick={"/testing"}
                secondTitleClick={-1}
                state={{ isReload: true }}
            />
            <Modal
                isOpen={isOpenAddTestingModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleAddTestingModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>
                        Add Testing
                        {/* {isEditable ? "Edit Area" : "Add Area"} */}
                    </ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Slave</Label>
                                <Input
                                    value={validation.values.slave || ""}
                                    name="slave"
                                    type={"text"}
                                    placeholder="Slave"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.slave && validation.errors.slave ? true : false
                                    }
                                />
                                {validation.touched.slave && validation.errors.slave ? (
                                    <span className="error">{validation.errors.slave}</span>
                                ) : null}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"} */}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            <FirmwareDataTable
                apiURL={`${url.BASE_URL}${url.PUSH_FIRMWARE_LIST}`}
                columns={columns}
                title={"Testing List"}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"TestingDetailsList"}
                // btnName='Add Testing'
                // btnStyle="btn btn-dark"
                btnClick={() => toggleAddTestingModel()}
                handleEditClick={handleNavigateDetails}
            />
        </div>
    )
}

export default TestingDetailsOfDetails