// import React, { useEffect, useRef, useState } from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import accessibility from 'highcharts/modules/accessibility';

// const DonutChart = () => {
//     const [chartData, setChartData] = useState<any>([]);

//     const innerSeriesColors = ['#FF453A', '#32D74B'];
//     const outerSeriesColors = ['#0E42D2', '#165DFF', '#4080FF', '#6AA1FF', '#9EC2FF'];

//     const chartRef = useRef(null);
//     const initialData = [
//         { name: 'Outer Series', data: [{ name: "dsffg", data: Math.random() * 100 }, Math.random() * 100, Math.random() * 100, Math.random() * 100, Math.random() * 100] },
//         { name: 'Inner Series', data: [Math.random() * 100, Math.random() * 100] }
//     ];

//     useEffect(() => {
//         const newData = initialData.map(series => ({
//             name: series.name,
//             data: series.data.map(() => Math.random() * 100)
//         }));
//         setChartData(newData);
//     }, []);

//     accessibility(Highcharts);

//     const options = {
//         chart: {
//             type: 'pie',
//             spacing: [0, 0, 0, 0],
//         },
//         title: {
//             text: ''
//         },
//         plotOptions: {
//             pie: {
//                 allowPointSelect: true,
//                 cursor: 'pointer',
//                 dataLabels: {
//                     enabled: false,
//                     style: {
//                         textOutline: '0px',
//                     }
//                 },
//                 borderRadius: 5,
//             }
//         },
//         legend: {
//             enabled: true, // Show legend
//             align: 'right', // Adjust alignment as needed
//             verticalAlign: 'middle', // Adjust vertical alignment as needed
//             layout: 'vertical' // Adjust layout as needed
//         },
//         series: [
//             {
//                 // outerWidth: "70%",
//                 name: 'Outer Series',
//                 size: "60%",
//                 innerSize: '85%', // Adjust the inner size for Outer Series
//                 data: chartData[0]?.data.map((value: any, index: number) => ({
//                     name: `Point ${index}`,
//                     y: value,
//                     color: outerSeriesColors[index % outerSeriesColors.length]
//                 })),
//             },
//             {
//                 name: 'Inner Series',
//                 size: "70%",
//                 innerSize: '87%', // Adjust the inner size for Inner Series
//                 data: chartData[1]?.data.map((value: any, index: number) => ({
//                     name: `Point ${index}`,
//                     y: value,
//                     color: innerSeriesColors[index % innerSeriesColors.length]
//                 })),
//             }
//         ],
//         accessibility: {
//             enabled: true
//         },
//         credits: {
//             enabled: false
//         }
//     };

//     return (
//         <div>
//             <HighchartsReact
//                 highcharts={Highcharts}
//                 options={options}
//                 ref={chartRef}
//             />
//         </div>
//     );
// };

// export default DonutChart;



import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';
import HighchartsMore from 'highcharts/highcharts-more';

if (typeof Highcharts === 'object') {
    HighchartsMore(Highcharts);
}

const DonutChart = ({ hardwareData }: any) => {
    const [chartData, setChartData] = useState<any>({
        colors: ['#32D74B', '#FF453A'],
        chart: {
            polar: true,
            inverted: true,
            type: 'column'
        },
        title: {
            text: ''
        },
        pane: {
            size: '85%',
            innerSize: '20%',
            endAngle: 270
        },
        xAxis: {
            tickInterval: 1,
            labels: {
                align: 'right',
                useHTML: true,
                allowOverlap: true,
                step: 1,
                y: 3,
                style: {
                    fontSize: '13px'
                }
            },
            lineWidth: 0,
            gridLineWidth: 0,
            categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
        },
        yAxis: {
            lineWidth: 0,
            tickInterval: 25,
            reversedStacks: false,
            endOnTick: true,
            showLastLabel: true,
            gridLineWidth: 0
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                borderWidth: 0,
                pointPadding: 0,
                groupPadding: 0.15,
                pointWidth: 20
                // borderRadius: '50%'
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Online Chip',
            data: [148, 113, 104, 71, 77]
        }, {
            name: 'Offline Chip',
            data: [113, 122, 98, 88, 72]
        },]
    });

    const chartRef = useRef(null);
    const initialData = [
        { name: 'Outer Series', data: [{ name: "dsffg", data: Math.random() * 100 }, Math.random() * 100, Math.random() * 100, Math.random() * 100, Math.random() * 100] },
        { name: 'Inner Series', data: [Math.random() * 100, Math.random() * 100] }
    ];

    // useEffect(() => {
    //     const newData = initialData.map(series => ({
    //         name: series.name,
    //         data: series.data.map(() => Math.random() * 100)
    //     }));
    //     setChartData(newData);
    // }, []);

    accessibility(Highcharts);

    // const options = {
    //     chart: {
    //         polar: true,
    //         inverted: true,
    //         type: 'column'
    //     },
    //     title: {
    //         text: ''
    //     },
    //     pane: {
    //         size: '85%',
    //         innerSize: '20%',
    //         endAngle: 270
    //     },
    //     xAxis: {
    //         tickInterval: 1,
    //         labels: {
    //             align: 'right',
    //             useHTML: true,
    //             allowOverlap: true,
    //             step: 1,
    //             y: 3,
    //             style: {
    //                 fontSize: '13px'
    //             }
    //         },
    //         lineWidth: 0,
    //         gridLineWidth: 0,
    //         categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
    //     },
    //     yAxis: {
    //         lineWidth: 0,
    //         tickInterval: 25,
    //         reversedStacks: false,
    //         endOnTick: true,
    //         showLastLabel: true,
    //         gridLineWidth: 0
    //     },
    //     plotOptions: {
    //         column: {
    //             stacking: 'normal',
    //             borderWidth: 0,
    //             pointPadding: 0,
    //             groupPadding: 0.15,
    //             borderRadius: '50%'
    //         }
    //     },
    //     series: [{
    //         name: 'Online Chip',
    //         data: [148, 113, 104, 71, 77]
    //     }, {
    //         name: 'Offline Chip',
    //         data: [113, 122, 98, 88, 72]
    //     },]
    // };

    useEffect(() => {
        const categories = hardwareData?.map((item: any, index: number) => {
            return item.hwVersion ? item.hwVersion : "Other"
        })
        const onLineChips = hardwareData?.map((item: any, index: number) => {
            return item.onlineChip
        })
        const offLineChips = hardwareData?.map((item: any, index: number) => {
            return item.offlineChip
        })
        setChartData((options: any) => {
            return {
                ...options,
                xAxis: {
                    ...options.xAxis,
                    categories: categories
                },
                series: [
                    {
                        name: "Online Chip",
                        data: onLineChips
                    },
                    {
                        name: "Offline Chip",
                        data: offLineChips
                    }
                ]
            }
        })
    }, [hardwareData])

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartData}
                ref={chartRef}
            />
        </div>
    );
};

export default DonutChart;
