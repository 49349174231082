import Admin from "../pages/Admin/Area";
import Users from "../pages/Admin/Users/Users";
import Service from "../pages/Premises/Service";
import Settings from "../pages/Premises/Settings";
import SubAdmins from "../pages/Premises/SubAdmins";
import Permissions from "../pages/Premises/SubAdmins/Permissions";
import React from "react";
import Rooms from "../pages/SubAdmin/Rooms";
import Roles from "../pages/SubAdmin/Roles/Roles";
import Tag from "../pages/SubAdmin/TagManagement/Tag";
import TagAreaRooms from "../pages/SubAdmin/TagManagement/TagAreaRooms";
import ControlPanel from "../pages/SubAdmin/ControlPanel";
import Dashboard from "../pages/SubAdmin/Dashboard/Dashboard";
import getStorageData from "../Utils/getStorageData";
import HotelDashboard from "../pages/HotelUser/HotelDashboard";

const permissions = getStorageData().menuPermissions ? getStorageData().menuPermissions : [];

const hotelRoute = [
    { path: "/dashboard", component: <Dashboard />, menuName: "DASHBOARD" },
    { path: "/control-panel", component: <ControlPanel />, menuName: "CONTROL-PANEL" },
    { path: "/rooms", component: <Rooms />, menuName: "ROOMS" },
    { path: "/settings", component: <Settings />, menuName: "SETTING" },
];

const hotelUserRoutes = permissions?.length === 0 ?
    [{ path: "/dashboard", component: <Dashboard />, menuName: "DASHBOARD" }] :
    // hotelRoute.filter((item) => permissions?.includes(item.menuName))
    //     .concat([{ path: "/dashboard", component: <Dashboard />, menuName: "DASHBOARD" }]);
    // (() => {
    //     const filteredRoutes = hotelRoute.filter((item) => permissions.includes(item.menuName));
    //     console.log("filteredRoutes", filteredRoutes);

    //     const hasDashboard = filteredRoutes.some(route => route.menuName === "DASHBOARD");
    //     if (!hasDashboard) {
    //         filteredRoutes.push({ path: "/dashboard", component: <Dashboard />, menuName: "DASHBOARD" });
    //     }
    //     return filteredRoutes;
    // })();
    (() => {
        const filteredRoutes = hotelRoute.filter((item) => permissions.includes(item.menuName));
        const dashboardRoute: any = hotelRoute.find(route => route.menuName === "DASHBOARD");
        if (!filteredRoutes.some(route => route.menuName === "DASHBOARD")) {
            filteredRoutes.unshift(dashboardRoute);
        }
        return filteredRoutes;
    })();

export { hotelUserRoutes };