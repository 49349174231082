// WITH JQUIRY

import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import 'datatables.net';
// import 'datatables.net-bs4';
import $ from 'jquery'
import { GET_AREA_LIST_URL, GET_SEARCH_CITY_URL } from '../../helpers/url_helper';
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from '../../components/Common/DataTable';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import * as url from '../../helpers/url_helper'
import Request from '../../helpers/request';
import { ToastContainer, toast } from 'react-toastify';
import { citySearchApiCall } from '../../Utils/searchApiCall';

const Area = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [openAreaModal, setOpenAreaModal] = useState(false);
    const [cityData, setCityData] = useState<any>([]);
    const [areaList, setAreaList] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [dataTableId, setDataTableId] = useState(null);
    console.log("cityData", cityData);

    const validation = useFormik({
        initialValues: {
            name: "",
            cityName: "",
            areaId: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Please provide your area name "),
            cityName: Yup.string()
                .required("Please select city name "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })
    console.log("valuesss====>", validation.values, cityData.find((option: any) => option.value === validation.values.cityName), cityData);

    const addAreaApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            name: values.name,
            cityId: values.cityName
        }
        Request.post(url.ADD_AREA_URL, reqObj).
            then((res) => {
                //    console.log("Add Area Response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        name: "",
                        cityName: "",
                        areaId: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenAreaModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const editAreaApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            name: values.name,
            cityId: values.cityName,
            areaId: values.areaId
        }
        Request.post(url.EDIT_AREA_URL, reqObj).
            then((res) => {
                // console.log("edit Area Response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        name: "",
                        cityName: "",
                        areaId: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenAreaModal(false);
                setLoading(false);
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const deleteAreaApiCall = () => {
        setLoading(true);
        const reqObj = {
            name: deleteData.name,
            cityId: deleteData.cityId.id,
            areaId: deleteData.id
        }
        Request.post(url.DELETE_AREA_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                validation.resetForm({
                    values: {
                        name: "",
                        cityName: "",
                        areaId: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                toast.error(err?.response?.data?.errors[0]?.msg)
                setDeleteModalOpen(false)
            })
    }

    const areaListApiCall = () => {
        setLoading(true);
        const reqObj = {
            "draw": 1,
            "search": {
                "value": "",
            }
        }
        Request.post(url.GET_AREA_LIST_URL, reqObj).
            then((res) => {
                //  console.log("Area List Response", res);
                setAreaList(res.data.data);
                setLoading(false)
            }).
            catch((err) => {
                //  console.log("area List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const columns = [
        { data: 'name', title: 'Name' },
        { data: 'cityId.name', title: 'City Name' },
        { data: 'stateId.name', title: 'State Name' },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;">
                                   <box-icon type='solid' name='edit' size="20px"></box-icon>
                                </button>
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                    <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    const handleEditClick = (rowData: any) => {
        searchCity(rowData.cityId.name)
        console.log('Edit button clicked for row data:', rowData);
        // setCityData([{ id: rowData.cityId.id, name: rowData.cityId.name }])
        // setAreaDetail(rowData)
        setIsEditable(true)
        setOpenAreaModal(true);
        validation.setValues({
            name: rowData.name,
            cityName: rowData.cityId.id,
            areaId: rowData.id,
            formError: ""
        })
        // Add your logic here
    };

    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    useEffect(() => {
        // areaListApiCall();
    }, [])

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    // const searchApiCall = (e) => {
    //     console.log("search Api Call", e);
    //     const value = !e ? "a" : e
    //     $.ajax({
    //         url: `${GET_SEARCH_CITY_URL}?keyword=${value}`,
    //         method: 'GET',
    //         headers: {
    //             "Authorization": `Bearer ${token}`
    //         },
    //         success: function (data) {
    //             // Handle the successful response here
    //             console.log('Received data:', data.data);
    //             var city = [];
    //             for (var i = 0; i < data?.data?.length; i++) {
    //                 var ele = data.data[i]
    //                 // console.log("ele", ele);
    //                 city.push({ label: ele.name, value: ele.id });
    //                 // setCityData(data);
    //             }
    //             console.log("datadatadata", data);
    //             setCityData(city);
    //         },
    //         error: function (error) {
    //             // Handle any errors
    //             console.error('Error fetching data:', error);
    //         }
    //     });
    // }

    const searchCity = (e: any) => {
        citySearchApiCall(e, setCityData);
    }
    const toggleArea = () => {
        setOpenAreaModal(!openAreaModal);
        setIsEditable(false);
        validation.resetForm({
            values: {
                name: "",
                cityName: "",
                areaId: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
    };
    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={openAreaModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleArea}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Area" : "Add Area"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                    value={validation.values.name || ""}
                                    name="name"
                                    type={"text"}
                                    placeholder="Enter Area Name"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.name && validation.errors.name ? true : false
                                    }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <span className="error">{validation.errors.name}</span>
                                ) : null}
                            </div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>City Name</Label>
                                <Select
                                    name="cityName"
                                    value={cityData.find((option: any) => option.value === validation.values.cityName)}
                                    options={cityData}
                                    onChange={(selectedOption: any) => validation.setFieldValue('cityName', selectedOption.value)}
                                    onBlur={() => validation.setFieldTouched('cityName', true)}
                                    // invalid={
                                    //     validation.touched.cityName && validation.errors.cityName ? true : false
                                    // }
                                    onFocus={() => searchCity("a")}
                                    onInputChange={(e) => searchCity(e)}
                                    styles={customStyles}
                                />
                                {validation.touched.cityName && validation.errors.cityName ? (
                                    <span className="error">{validation.errors.cityName}</span>
                                ) : null}
                            </div>

                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Delete Area?"
                    text="Are you sure you want to delete this Area ? This action cannot be undone."
                    buttonText="Delete"
                    onClick={() => { deleteAreaApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={loading}
                />
            }
            {/* {loading &&
                <Spinners />} */}
            <DataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${GET_AREA_LIST_URL}`}
                columns={columns}
                isAddBtnShow={true}
                isBtnShow={false}
                btnName="Add Area"
                btnStyle="btn btn-dark"
                onClickBtn={toggleArea}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Area"}
            />
        </div>
    );
};

export default Area;