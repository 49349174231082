import DataTable from '../../../components/Common/DataTable';
import React, { useState } from 'react'
import { Alert, Card, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BASE_URL, GET_ROOM_LIST_URL, GUEST_LIST_URL } from '../../../helpers/url_helper';
import getStorageData from '../../../Utils/getStorageData';
import { convertLocalDate } from '../../../Utils/configController';
import AllocateRoom from '../Dashboard/AllocateRoom';
import $ from 'jquery'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper'
import { ToastContainer, toast } from 'react-toastify';

const GuestHistory = () => {
    const [loading, setLoading] = useState(false);
    const [isOpenAddRoomModel, setOpenAddRoomModel] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);

    const toggleRoomModal = () => {
        setIsEditable(!isEditable);
        setOpenAddRoomModel(!isOpenAddRoomModel);
    }

    const columns = [
        // { data: 'name', title: 'Room No.' },
        {
            title: 'Room No.',
            render: function (data: any, type: any, row: any) {
                return `
                <div>${row?.roomId?.name ? row.roomId.name : "-"} </div>`
            },
        },
        {
            title: 'Guest Name',
            render: function (data: any, type: any, row: any) {
                const joinArray = [row?.guestId?.email, row?.guestId?.mobile].filter(Boolean)
                console.log("joinArray", joinArray);

                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.guestId?.name ? row.guestId.name : "-"} </div>
                <div>${joinArray.join("|")} </div>
                </div>`
            },
        },
        {
            title: 'Check-in',
            render: function (data: any, type: any, row: any) {
                const convertLocal: any = row?.guestCheckIn ? convertLocalDate(row?.guestCheckIn) : ""
                return `<span>${row?.guestCheckIn ? convertLocal : "-"} </span>`
            }
        },
        {
            title: 'Check-out',
            render: function (data: any, type: any, row: any) {
                const convertLocal: any = row?.guestCheckOut ? convertLocalDate(row?.guestCheckOut) : ""
                return `<span>${row?.guestCheckOut ? convertLocal : "-"} </span>`
            }
        },
        // {
        //     title: 'Actions',
        //     render: function (data: any, type: any, row: any) {
        //         const editable = row?.guestStatus === 1 ? (
        //             `<div class="d-flex gap-2">
        //             <button class="edit-btn" style="border: none; outline: none; background: none;">
        //                 <i class="bx bxs-edit" style="font-size: 20px;"></i>
        //             </button>
        //         </div>`
        //         ) : ""
        //         return editable;
        //     }
        // }
    ];

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        setIsEditable(true);
        // setAllocateRoomData(rowData);
        // setOpenAllocateRoomModal(true)
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    // const toggleGuest = () => {
    //     setOpenAddRoomModel(!isOpenAddRoomModel);
    //     setIsEditable(false);
    //     validation.resetForm({
    //         values: {
    //             guestName: "",
    //             email_or_mobile: "",
    //             formError: ""
    //         },
    //         touched: {} // Reset touched state for all fields
    //     });
    // }

    return (
        <div>
            <ToastContainer />
            {/* {
                isOpenAllocateRoomModal &&
                <AllocateRoom
                    isOpenAllocateRoomModal={isOpenAllocateRoomModal}
                    toggleRoomModal={() => toggleRoomModal()}
                    allocateRoomData={allocateRoomData}
                    isEditable={isEditable}
                    reloadData={reloadDataTable}
                />
            } */}
            <DataTable
                apiURL={`${url.BASE_URL}${url.GUEST_HISTORY_LIST_URL}`}
                paramsId={{ premiseId: getStorageData().selectedPremiseID }}
                columns={columns}
                isAddBtnShow={false}
                btnName="Add Guest"
                btnStyle="btn btn-dark"
                // onClickBtn={toggleGuest}
                handleEditClick={handleEditClick}
                handleDeleteClick={() => { }}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Rooms"}
            />
        </div>
    )
}

export default GuestHistory