import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardText,
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table
} from 'reactstrap'
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import getStorageData from '../../../Utils/getStorageData';
import Spinners from '../../../components/Common/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { tagSearchApiCall } from '../../../Utils/searchApiCall';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';

const Tag = () => {
    const navigate = useNavigate();
    const [customActiveTab, setcustomActiveTab] = useState<string>("1");
    const [tagList, setTagList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [isTagModelOpen, setTagModelOpen] = useState<boolean>(false);
    const [isAssignAreaModelOpen, setAssignAreaModel] = useState<boolean>(false);
    const [unAssignedList, setUnAssignedList] = useState<any[]>([]);
    const [selectedAssignRooms, setSelectedAssignRooms] = useState<any[]>([]);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [tagAreaData, setTagAreaData] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [isDeleteTagModalOpen, setDeleteTagModalOpen] = useState<boolean>(false);
    const [deleteData, setDeleteData] = useState<any>();

    console.log("tagAreaData", tagAreaData);
    console.log("unAssignedList", unAssignedList);
    console.log("selectedAssignRooms", selectedAssignRooms);
    const validation = useFormik({
        initialValues: {
            tagId: "",
            name: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Please provide area tag name "),
        }),
        onSubmit: (values) => {
            isEditable ?
                editTagApiCall(values) :
                addTagApiCall(values)
        }
    })
    const tagValidation = useFormik({
        initialValues: {
            tagName: "",
            assignTagError: ""
        },
        validationSchema: Yup.object().shape({
            tagName: Yup.string()
                .required("Please provide area name "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            assignTagRoomApiCall(values)
        }
    })
    console.log("tagValidation", tagValidation.values.tagName);
    const addTagApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            name: values.name
        }
        Request.post(url.ADD_TAG_URL, reqObj).
            then((res) => {
                console.log("Add Role Response", res);
                toggleTag()
                tagListApiCall()
                // reloadDataT  able();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const editTagApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            tagId: values.tagId,
            premiseId: getStorageData().selectedPremiseID,
            name: values.name
        }
        Request.post(url.UPDATE_TAG_URL, reqObj).
            then((res) => {
                console.log("Add Role Response", res);
                setIsEditable(false);
                toggleTag()
                tagListApiCall()
                // reloadDataT  able();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const toggleTag = () => {
        setTagModelOpen(!isTagModelOpen);
        setIsEditable(!isEditable);
        tagFormReset();
    }

    const toggleAssignArea = () => {
        setAssignAreaModel(!isAssignAreaModelOpen);
        // setIsEditable(!isEditable);
        assignTagFormReset();
    }

    const assignTagFormReset = () => {
        tagValidation.resetForm({
            values: {
                tagName: "",
                assignTagError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const tagFormReset = () => {
        setIsEditable(false);
        validation.resetForm({
            values: {
                tagId: "",
                name: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const tagListApiCall = () => {
        setLoading(true);
        const reqObj = {
            length: 100,
            premiseId: getStorageData().selectedPremiseID,
            search: { value: search, regex: false }
        }
        Request.post(url.GET_TAG_LIST_URL, reqObj).
            then((res) => {
                setTagList(res.data.data);
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    const unAssignedListApiCall = () => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
        }
        Request.post(url.UNASSIGN_LIST_API_URL, reqObj).
            then((res) => {
                setUnAssignedList(res.data.data);
                setLoading(false)

            }).
            catch((err) => {
                setLoading(false)
            })
    }


    useEffect(() => {
        tagListApiCall();
        unAssignedListApiCall();
    }, [search])

    const groupedRooms: any = unAssignedList.reduce((acc: any, room: any, index: any) => {
        const groupIndex: any = Math.floor(index / 10);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(room);
        return acc;
    }, []);

    const unSelectArea = (item: any) => {
        console.log("itemitem", item);
        // var list = [];
        var selectedList = [];
        if (selectedAssignRooms.includes(item.id)) {
            const filterData = selectedAssignRooms.filter((e) => e != item.id)
            console.log("filterDatafilterData", filterData);
            setSelectedAssignRooms(filterData)
        } else {
            selectedList.push(...selectedAssignRooms, item.id)
            setSelectedAssignRooms([...selectedAssignRooms, item.id])
        }
        console.log("selectedList", selectedList);
    }

    const assignTagRoomApiCall = (tagId: any) => {
        setSaveLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            tagId: tagId.tagName,
            roomIds: selectedAssignRooms,
            isAdd: 1
        }
        Request.post(url.ASSIGN_AREA_ROOMS_URL, reqObj).
            then((res) => {
                toast.success(res.data.msg);
                toggleAssignArea();
                setSaveLoading(false);
                unAssignedListApiCall();
                tagListApiCall();
            }).
            catch((err) => {
                //  console.log("premises List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setSaveLoading(false)
                // setLoading(false)
                // toast.error(err.response.data.errors[0]?.msg)
                tagValidation.setErrors({ assignTagError: err?.response?.data?.errors[0]?.msg })
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const deleteAreaTagApiCall = () => {
        setLoading(true);
        const reqObj = {
            tagId: deleteData.id
        }
        Request.post(url.DELETE_TAG_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                tagFormReset();
                toast.success(res.data.msg)
                setDeleteTagModalOpen(false)
                setLoading(false)
                tagListApiCall();
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const tagSearch = (e: any) => {
        tagSearchApiCall(e, setTagAreaData)
    }

    useEffect(() => {
        tagSearch("a")
    }, [])

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
    };

    const handleEditClick = (rowData: any) => {
        setIsEditable(true);
        setTagModelOpen(true)
        validation.setValues({
            tagId: rowData.id,
            name: rowData.name,
            formError: ""
        })
    }

    const handleDeleteClick = (rowData: any) => {
        setDeleteTagModalOpen(true);
        setDeleteData(rowData);
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{ backgroundColor: '#fff' }}>
                <ToastContainer />

                <Modal
                    isOpen={isAssignAreaModelOpen}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleAssignArea}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Assign Area</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                //  console.log("add premise e", e);
                                e.preventDefault();
                                tagValidation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {tagValidation.errors && tagValidation.errors.assignTagError ? (
                                    <Alert color="danger">{tagValidation.errors.assignTagError}</Alert>
                                ) : null}
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Select Area Name</Label>
                                    <Select
                                        name="tagName"
                                        value={tagAreaData.find((option) => option.value === tagValidation.values.tagName)}
                                        options={tagAreaData}
                                        onChange={(selectedOption) => tagValidation.setFieldValue('tagName', selectedOption.value)}
                                        onBlur={() => tagValidation.setFieldTouched('tagName', true)}
                                        // invalid={
                                        //     tagValidation.touched.tagName && tagValidation.errors.tagName ? true : false
                                        // }
                                        onFocus={() => tagSearch("a")}
                                        onInputChange={(e) => tagSearch(e)}
                                        styles={customStyles}
                                    />
                                    {tagValidation.touched.tagName && tagValidation.errors.tagName ? (
                                        <span className="error">{tagValidation.errors.tagName}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={saveLoading}
                                >
                                    {saveLoading ? "Assigning..." : "Assign"}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    isOpen={isTagModelOpen}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleTag}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Area Tag" : "Add Area Tag"}</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                // console.log("call on submit", e);
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.formError ? (
                                    <Alert color="danger">{validation.errors.formError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">Area Tag Name</Label>
                                    <Input
                                        value={validation.values.name || ""}
                                        name="name"
                                        type={"text"}
                                        placeholder="Enter Area Tag Name"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <span className="error">{validation.errors.name}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {/* Add */}
                                    {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                    {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {isDeleteTagModalOpen &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Delete Area Tag"
                        text="Are you sure you want to delete this area tag? Deleting it will permanently remove the area and all its data. This action cannot be undone. Proceed with deletion?"
                        buttonText="Delete"
                        onClick={() => { deleteAreaTagApiCall() }}
                        cancleBtnClick={() => setDeleteTagModalOpen(false)}
                    />
                }

                <Card>
                    <CardBody>
                        <div className="mb-4 h4 card-title d-flex justify-content-between flex-row" >
                            <div className="app-search d-lg-block" style={{
                                borderStyle: "solid"
                            }}>
                                <div className="position-relative">
                                    <span className="bx bx-search-alt" />
                                    <Input
                                        type="search"
                                        placeholder="Search"
                                        style={{ background: '#F7F9FC', color: "#858D9D" }}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button className="btn btn-dark"
                                onClick={() => toggleTag()}
                            >
                                Add Area Tag
                            </button>
                        </div>

                        {/* <div style={{ marginTop: 20 }}>
                            <Breadcrumbs title="Sub Admins" breadcrumbItem="Permissions" />
                        </div> */}
                        <Nav tabs className="nav-tabs-custom" style={{ marginTop: 20 }}>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer", color: customActiveTab === "1" ? "#121212" : "#7D8398" }}
                                    className={classNames({
                                        active: customActiveTab === "1",
                                        'custom-active-border': customActiveTab === '1',
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Assigned Areas Tag</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer", color: customActiveTab === "2" ? "#121212" : "#7D8398" }}
                                    className={classNames({
                                        active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Unassigned Area Listing</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                                {loading ? <div style={{ height: "60vh" }}><Spinners /></div> :
                                    tagList.length === 0 && !search ?
                                        <div style={{ height: "60vh", textAlign: "center" }}>
                                            <h4 style={{ color: "#667085" }}>No Assign Area Tag Available</h4>
                                        </div> :
                                        <Table className="table-bordered " style={{ marginTop: 20 }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: 14, }}>Area Tags</th>
                                                    <th style={{ fontSize: 14 }}>Area Count</th>
                                                    <th style={{ fontSize: 14 }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {search && tagList.length === 0 ?
                                                    <tr>
                                                        <td
                                                            colSpan={3} style={{ textAlign: "center" }}>
                                                            No matching records found
                                                        </td>
                                                    </tr> :
                                                    tagList.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th style={{ fontSize: 14, cursor: "pointer", textDecorationLine: "underline" }} onClick={() => { navigate(`/tag-management/rooms/${item.id}`) }}>{item.name}</th>
                                                                <td style={{ fontSize: 14 }}>{item.noOfRooms}</td>
                                                                <td style={{ fontSize: 14 }}>
                                                                    <div className='d-flex gap-2'>
                                                                        <Button color='white' size="sm" style={{ border: 'none', outline: "none" }} onClick={() => handleEditClick(item)}>
                                                                            <i className='bx bxs-edit' style={{ fontSize: '20px', marginRight: 20 }} />
                                                                        </Button>
                                                                        <Button color='white' size="sm" style={{ border: 'none', outline: "none" }} onClick={() => handleDeleteClick(item)}>
                                                                            <i className='mdi mdi-delete' style={{ fontSize: '20px' }} />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>}
                            </TabPane>

                            <TabPane tabId="2">
                                {loading ? <div style={{ height: "60vh" }}><Spinners /></div> :
                                    groupedRooms.length === 0 && !search ?
                                        <div style={{ height: "60vh", textAlign: "center" }}>
                                            <h4 style={{ color: "#667085" }}>No Unassign Area Available</h4>
                                        </div> :
                                        <div>
                                            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                                                {groupedRooms.map((group: any, groupIndex: number) => (
                                                    <Table key={groupIndex} style={{ width: '300px', display: 'inline-block', marginRight: '20px' }} className="table-bordered ">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '300px', textAlign: "center" }}>Areas</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {search && group.length === 0 ?
                                                                <tr>
                                                                    <td
                                                                        // colSpan={3}
                                                                        style={{ display: "flex" }}>
                                                                        No matching records found
                                                                    </td>
                                                                </tr> :
                                                                group.map((row: any, rowIndex: number) => (
                                                                    < tr key={rowIndex} onClick={() => unSelectArea(row)} style={{ cursor: "pointer" }}>
                                                                        <td style={{ fontSize: 14 }}>{
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                checked={selectedAssignRooms.includes(row.id)}
                                                                                onChange={() => { }}
                                                                                // onClick={() => unSelectArea(row)}
                                                                                style={{ fontSize: 15 }}
                                                                            />}     {row.name}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                ))}
                                            </div>
                                            {groupedRooms.length > 0 && <div style={{ justifyContent: "center", display: "flex", marginTop: 20 }}>
                                                <Button
                                                    className='btn btn-dark'
                                                    onClick={() => toggleAssignArea()}
                                                    disabled={selectedAssignRooms.length === 0}
                                                >
                                                    Assign Area
                                                    {/* {saveLoading ? "Saving..." : "Save"} */}
                                                </Button>
                                            </div>}
                                        </div>}
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    )
}

export default Tag