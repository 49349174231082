import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "../Common/withRouter";
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";
import data from '../../assets/images/Icon-premises.png'

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
// import route from "constants/routesData";
import useRoutes from "../../constants/routesData";

const Layout = props => {
  // console.log("routeee", route);
  const dispatch = useDispatch();
  const route = useRoutes();
  console.log("routerouteroute", route);
  const selectLayoutState = (state) => state.Layout;
  // const route = [
  //   {
  //     img: require("../../assets/images/Icon-premises.png"),
  //     activeImg: require("../../assets/images/Icons_active_premises.png"),
  //     path: "/admin",
  //     name: "Admin",
  //     isShow: 1
  //   },
  //   {
  //     img: require("../../assets/images/Icon-premises.png"),
  //     activeImg: require("../../assets/images/Icons_active_premises.png"),
  //     path: "/premises",
  //     name: "Premises",
  //     isShow: 1
  //   },
  //   {
  //     img: require("../../assets/images/icon-sub-admin.png"),
  //     activeImg: require("../../assets/images/Icons_active_subAdmin.png"),
  //     path: "/subadmins",
  //     name: "Sub Admins",
  //     isShow: 1
  //   },
  //   {
  //     img: require("../../assets/images/icon-sub-admin.png"),
  //     activeImg: require("../../assets/images/Icons_active_subAdmin.png"),
  //     path: "/subadmins/permissions",
  //     name: "Sub Admin Permissions",
  //     isShow: 0
  //   },
  //   {
  //     img: require("../../assets/images/Icon-service.png"),
  //     activeImg: require("../../assets/images/Icons_active_service.png"),
  //     path: "/servicepersonal",
  //     name: "Service Personnel",
  //     isShow: 1
  //   },
  //   {
  //     img: require("../../assets/images/Icons-settings.png"),
  //     activeImg: require("../../assets/images/Icons_active_settings.png"),
  //     path: "/settings",
  //     name: "Settings",
  //     isShow: 1
  //   }
  // ]

  // const route = {route}

  // console.log("props.children", props.children);
  // const selectLayoutProperties = createSelector(
  //   selectLayoutState,
  //   (layout) => ({
  //     isPreloader: layout.isPreloader,
  //     layoutModeType: layout.layoutModeType,
  //     leftSideBarThemeImage: layout.leftSideBarThemeImage,
  //     leftSideBarType: layout.leftSideBarType,
  //     layoutWidth: layout.layoutWidth,
  //     topbarTheme: layout.topbarTheme,
  //     showRightSidebar: layout.showRightSidebar,
  //     leftSideBarTheme: layout.leftSideBarTheme,
  //   }));

  // const {
  //   isPreloader,
  //   leftSideBarThemeImage,
  //   layoutWidth,
  //   leftSideBarType,
  //   topbarTheme,
  //   showRightSidebar,
  //   leftSideBarTheme,
  //   layoutModeType
  // } = useSelector(selectLayoutProperties);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  // const toggleMenuCallback = () => {
  //   if (leftSideBarType === "default") {
  //     dispatch(changeSidebarType("condensed", isMobile));
  //   } else if (leftSideBarType === "condensed") {
  //     dispatch(changeSidebarType("default", isMobile));
  //   }
  // };

  //hides right sidebar on body click
  // const hideRightbar = (event) => {
  //   var rightbar = document.getElementById("right-bar");
  //   //if clicked in inside right bar, then do nothing
  //   if (rightbar && rightbar.contains(event.target)) {
  //     return;
  //   } else {
  //     //if clicked in outside of rightbar then fire action for hide rightbar
  //     dispatch(showRightSidebarAction(false));
  //   }
  // };

  /*
  layout  settings
  */

  // useEffect(() => {
  //   //init body click event fot toggle rightbar
  //   document.body.addEventListener("click", hideRightbar, true);


  // }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // useEffect(() => {
  //   dispatch(changeLayout("vertical"));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (leftSideBarTheme) {
  //     dispatch(changeSidebarTheme(leftSideBarTheme));
  //   }
  // }, [leftSideBarTheme, dispatch]);

  // useEffect(() => {
  //   if (layoutModeType) {
  //     dispatch(changeLayoutMode(layoutModeType));
  //   }
  // }, [layoutModeType, dispatch]);

  // useEffect(() => {
  //   if (leftSideBarThemeImage) {
  //     dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
  //   }
  // }, [leftSideBarThemeImage, dispatch]);

  // useEffect(() => {
  //   if (layoutWidth) {
  //     dispatch(changeLayoutWidth(layoutWidth));
  //   }
  // }, [layoutWidth, dispatch]);

  // useEffect(() => {
  //   if (leftSideBarType) {
  //     dispatch(changeSidebarType(leftSideBarType));
  //   }
  // }, [leftSideBarType, dispatch]);

  // useEffect(() => {
  //   if (topbarTheme) {
  //     dispatch(changeTopbarTheme(topbarTheme));
  //   }
  // }, [topbarTheme, dispatch]);
  var activeRoute = ""
  // const routeIndex = route.findIndex((e) => e.path === window.location.pathname)
  const routeIndex = route.findIndex((e) => window.location.pathname.includes(e.path))
  const activeRouteIndex = route.findIndex((e) => e.path === window.location.pathname)
  // console.log("find name", route.findIndex((e) => window.location.pathname.includes(e.path)));
  return (
    <React.Fragment>

      <div id="layout-wrapper">
        <Header
          // toggleMenuCallback={toggleMenuCallback}
          activeRoute={route[activeRouteIndex]?.name}
        />
        {/* <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
          route={route}
          activeRoute={route[routeIndex]?.name}
        /> */}
        <Sidebar
          theme="light"
          type="light"
          isMobile={isMobile}
          route={route}
          activeRoute={route[routeIndex]?.name}
        />
        <div className="main-content">{props.children}</div>
        {/* <Footer /> */}
      </div>
      <RightSidebar />
      {/* {showRightSidebar ? <RightSidebar /> : null} */}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
