import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import Line from './MQTTChart'
import RealTimeChart from './RealTimeChart'
import { getMQTTData, getOfflineChipsData, getOnlineChipsData, getRealTimeData } from '../../../services/APIServices'
import OnlineOfflineChart from './OnlineOfflineChart'
import MQTTChart from './MQTTChart'

const AdminDashboard = () => {
    const [CPUData, setCPUData] = useState();
    const [memoryData, setMemoryData] = useState();
    const [onlineChips, setOnlineChips] = useState({});
    const [offlineChips, setOfflineChips] = useState({});
    const [MQTTSmartNodeData, setMQTTSmartNodeData] = useState([])
    const [MQTTWhitelionData, setMQTTWhitelionData] = useState([])
    const [MOTTAlexaData, setMQTTAlexaData] = useState([])
    const [MQTTGoogleData, setMQTTGoogleData] = useState([])

    console.log("onlineChips", onlineChips);

    const getChartData = () => {
        getRealTimeData().then((res: any) => {
            console.log("realtime chart", res);
            setCPUData(res.cpu);
            setMemoryData(res.memory);
        }).catch((err: any) => {
            console.log("realtime chart error", err);
        })
    }
    const getOnlineChipData = () => {
        getOnlineChipsData().then((res: any) => {
            console.log("online chips chart", res);
            setOnlineChips({
                "All": res.onlineChips,
                "WL11": res.onlineChipsWL1_1,
                "WL12": res.onlineChipsWL1_2,
                "WL13": res.onlineChipsWL1_3,
                "data101": res.onlineChips1_0_1,
                "data201": res.onlineChips2_0_1,
                "data202": res.onlineChips2_0_2,
                "Other": res.onlineChipsOther,
            })
        }).catch((err: any) => {
            console.log("online chart error", err);
        })
    }

    const getOfflineChipData = () => {
        getOfflineChipsData().then((res: any) => {
            console.log("offline chips chart", res);
            setOfflineChips({
                "All": res.offlineChips,
                "WL11": res.offlineChipsWL1_1,
                "WL12": res.offlineChipsWL1_2,
                "WL13": res.offlineChipsWL1_3,
                "data101": res.offlineChips1_0_1,
                "data201": res.offlineChips2_0_1,
                "data202": res.offlineChips2_0_2,
                "Other": res.offlineChipsOther,
            })
            // setCPUData(res.cpu);
            // setMemoryData(res.memory);
        }).catch((err: any) => {
            console.log("offline chart error", err);
        })
    }

    const getMQTTDataCall = () => {
        getMQTTData().then((res: any) => {
            console.log("MQTT chips chart", res);
            setMQTTSmartNodeData(res?.SMARTNODE)
            setMQTTWhitelionData(res?.WHITELION)
            setMQTTAlexaData(res?.ALEXA)
            setMQTTGoogleData(res?.GOOGLE)
            // setCPUData(res.cpu);
            // setMemoryData(res.memory);
        }).catch((err: any) => {
            console.log("offline chart error", err);
        })
    }

    useEffect(() => {
        getChartData();
        getOnlineChipData();
        getOfflineChipData();
        getMQTTDataCall();
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            getChartData();
            getOnlineChipData();
            getOfflineChipData();
            // getMQTTData();
        }, 3000)

        return () => clearInterval(intervalId);
    }, [])
    return (
        <>
            <div className="page-content">
                <Row>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>CPU Usage</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <RealTimeChart data={CPUData ? CPUData : 0} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>Memory Usage</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <RealTimeChart data={memoryData ? memoryData : 0} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>Online Devices</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <OnlineOfflineChart data={onlineChips} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>Offline Devices</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <OnlineOfflineChart data={offlineChips} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>Alexa - Request Count</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <RealTimeChart data={memoryData} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>Google Home - Request Count</CardTitle>
                                <div id="line-chart" className="e-chart">
                                    <RealTimeChart data={memoryData} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>MQTT Commands per day</CardTitle>
                                <MQTTChart
                                    MQTTSmartNodeData={MQTTSmartNodeData}
                                    MQTTWhitelionData={MQTTWhitelionData}
                                    MQTTSmartNodeTitle={"SMARTNODE"}
                                    MQTTWhitelionTitle={"WHITELION"}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle style={{ display: "flex", justifyContent: "center" }}>MQTT Commands per day</CardTitle>
                                <MQTTChart
                                    MQTTSmartNodeData={MOTTAlexaData}
                                    MQTTWhitelionData={MQTTGoogleData}
                                    MQTTSmartNodeTitle={"ALEXA"}
                                    MQTTWhitelionTitle={"GOOGLE"}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default AdminDashboard