import DataTable from '../../../components/Common/DataTable';
import React, { useState } from 'react'
import { Alert, Card, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BASE_URL, GET_ROOM_LIST_URL, GUEST_LIST_URL } from '../../../helpers/url_helper';
import getStorageData from '../../../Utils/getStorageData';
import { convertLocalDate } from '../../../Utils/configController';
import AllocateRoom from '../Dashboard/AllocateRoom';
import $ from 'jquery'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper'
import { ToastContainer, toast } from 'react-toastify';

const Guest = () => {
    const [loading, setLoading] = useState(false);
    const [isOpenAddRoomModel, setOpenAddRoomModel] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);

    const toggleRoomModal = () => {
        setIsEditable(!isEditable);
        setOpenAddRoomModel(!isOpenAddRoomModel);
    }

    const validation = useFormik({
        initialValues: {
            guestName: "",
            email_or_mobile: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            guestName: Yup.string()
                .required("Please provide your guest name "),
            email_or_mobile: Yup.string()
                .test(
                    'emailOrMobile',
                    'Invalid email or mobile',
                    (value: any) => {
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        const mobileRegex = /^[0-9]{10}$/;
                        // Trim whitespace before validation
                        const trimmedValue = value.trim();
                        return (
                            !trimmedValue ||  // allow empty value
                            trimmedValue === '' ||  // allow whitespace
                            emailRegex.test(trimmedValue) ||  // check if it's an email
                            (mobileRegex.test(trimmedValue) && trimmedValue.length === 10)  // check if it's a mobile number with length 10
                        );
                    }
                )
                .required('Email or mobile is required')
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            addGuestApiCall(values);
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })


    const addGuestApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            guestName: values.guestName,
            email_or_mobile: values.email_or_mobile
        }
        Request.post(url.ADD_GUEST_URL, reqObj).
            then((res) => {
                //    console.log("Add Area Response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        guestName: "",
                        email_or_mobile: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenAddRoomModel(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const columns = [
        // { data: 'name', title: 'Room No.' },
        {
            title: 'Guest Name',
            render: function (data: any, type: any, row: any) {
                return `
                <div>${row?.name ? row.name : "-"} </div>`
            },
        },
        {
            title: 'Email',
            render: function (data: any, type: any, row: any) {
                return `<span>${row?.email ? row?.email : "-"} </span>`
            }
        },
        {
            title: 'Mobile No.',
            render: function (data: any, type: any, row: any) {
                const convertLocal: any = row?.guestCheckOut ? convertLocalDate(row?.guestCheckOut) : ""
                return `<span>${row?.mobile ? `+91 ${row?.mobile}` : "-"} </span>`
            }
        },
        // {
        //     title: 'Actions',
        //     render: function (data: any, type: any, row: any) {
        //         const editable = row?.guestStatus === 1 ? (
        //             `<div class="d-flex gap-2">
        //             <button class="edit-btn" style="border: none; outline: none; background: none;">
        //                 <i class="bx bxs-edit" style="font-size: 20px;"></i>
        //             </button>
        //         </div>`
        //         ) : ""
        //         return editable;
        //     }
        // }
    ];

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        setIsEditable(true);
        // setAllocateRoomData(rowData);
        // setOpenAllocateRoomModal(true)
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const toggleGuest = () => {
        setOpenAddRoomModel(!isOpenAddRoomModel);
        setIsEditable(false);
        validation.resetForm({
            values: {
                guestName: "",
                email_or_mobile: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={isOpenAddRoomModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleGuest}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Area" : "Add Guest"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Guest Name</Label>
                                <Input
                                    value={validation.values.guestName || ""}
                                    name="guestName"
                                    type={"text"}
                                    placeholder="Enter Guest Name"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.guestName && validation.errors.guestName ? true : false
                                    }
                                />
                                {validation.touched.guestName && validation.errors.guestName ? (
                                    <span className="error">{validation.errors.guestName}</span>
                                ) : null}
                            </div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Email or Mobile</Label>
                                <Input
                                    value={validation.values.email_or_mobile || ""}
                                    name="email_or_mobile"
                                    type={"text"}
                                    placeholder="Enter Email or Mobile Number"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.email_or_mobile && validation.errors.email_or_mobile ? true : false
                                    }
                                    maxLength={(validation.values.email_or_mobile && validation.values.email_or_mobile.match(/^\d+$/) && validation.values.email_or_mobile.length === 10) ? 10 : undefined}
                                />
                                {validation.touched.email_or_mobile && validation.errors.email_or_mobile ? (
                                    <span className="error">{validation.errors.email_or_mobile}</span>
                                ) : null}
                            </div>

                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            {/* {
                isOpenAllocateRoomModal &&
                <AllocateRoom
                    isOpenAllocateRoomModal={isOpenAllocateRoomModal}
                    toggleRoomModal={() => toggleRoomModal()}
                    allocateRoomData={allocateRoomData}
                    isEditable={isEditable}
                    reloadData={reloadDataTable}
                />
            } */}
            <DataTable
                apiURL={`${BASE_URL}${GUEST_LIST_URL}`}
                paramsId={{ premiseId: getStorageData().selectedPremiseID }}
                columns={columns}
                isAddBtnShow={true}
                btnName="Add Guest"
                btnStyle="btn btn-dark"
                onClickBtn={toggleGuest}
                handleEditClick={handleEditClick}
                handleDeleteClick={() => { }}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Rooms"}
            />
        </div>
    )
}

export default Guest