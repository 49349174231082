import React, { useState } from 'react'
import Request from '../../../../../helpers/request';
import * as url from '../../../../../helpers/url_helper';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery'
import ProfileListDataTable from '../ProfileDataTable';
import Breadcrumb from '../../../../../components/Common/Breadcrumb';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Images } from '../../../../../constants/images';
import { convertLocalDate, convertsecondsToHours } from '../../../../../Utils/configController';

const ChipsLogs = () => {
    const params = useParams();
    const chipData = useSelector((state: any) => state.APIResponse.chipsLogs);
    console.log("chipData", chipData);

    const { slaveId } = params;
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);

    const columns = [
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.slave ? row.slave : "-"} </div>
                </div>`
            }
        },
        {
            title: "Status",
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isOnline ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'Created Time',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.createdAt ? convertLocalDate(row.createdAt) : "-"} </div>
                </div>`
            }
        },
        {
            title: 'Reason',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.reason ? row.reason : "-"} </div>
                </div>`
            }
        },
    ]


    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div style={{ marginTop: 20 }}>
                    <Breadcrumb
                        title="Users"
                        breadcrumbItem={`${"Device List"}(${slaveId})`}
                        secondPath={true}
                        secondTitle={"Profile List"}
                        titleClick={"/users"}
                        secondTitleClick={-1}
                        state={{ isReload: true }}
                    />
                </div>
                {/* <Card body> */}
                <Row>
                    <Col body md={6} >
                        <Card style={{ border: "1px solid #EAECF0", height: '80%' }} >
                            <CardBody>
                                <img src={Images.OnlineIcon} className="float-end" style={{ height: 26.94 }} />
                                <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 24 }}>
                                    {convertsecondsToHours(chipData.totalOnlineTime)}
                                </h1>
                                <p style={{ fontSize: 18, color: "#858D9D" }}>Online Time</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col body md={6} >
                        <Card style={{ border: "1px solid #EAECF0", height: '80%' }} >
                            <CardBody>
                                <img src={Images.OfflineIcon} className="float-end" style={{ height: 26.94 }} />
                                <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 24 }}>
                                    {convertsecondsToHours(chipData.totalOfflineTime)}
                                </h1>
                                <p style={{ fontSize: 18, color: "#858D9D" }}>Offline Time</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* </Card> */}
                <ToastContainer />
                <ProfileListDataTable
                    // data={areaList}
                    apiURL={`${url.BASE_URL}${url.GET_CHIP_LOG_LIST}`}
                    columns={columns}
                    paramsId={{ slave: slaveId }}
                    title="Hardware Log"
                    isAddBtnShow={false}
                    isBtnShow={false}
                    btnName="OTA"
                    btnStyle="btn btn-primary"
                    secondBtnText="Delete"
                    secondBtnStyle="btn btn-dark"
                    // onClickBtn={toggleArea}
                    // handleEditClick={handleEditClick}
                    // handleDeleteClick={handleDeleteClick}
                    // editApiCall={editAreaApiCall}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"Chiplog"}
                />
            </div>
        </React.Fragment>
    )
}

export default ChipsLogs