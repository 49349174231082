import React, { useEffect, useState } from 'react'
import { userSearchApiCall, webUserSearchApiCall } from '../../Utils/searchApiCall';
import { convertLocalDate } from '../../Utils/configController';
import { Alert, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from '../../components/Common/DataTable';
import * as url from '../../helpers/url_helper';
import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery'
import Request from '../../helpers/request';
import { toast, ToastContainer } from 'react-toastify';

const Backup = () => {
    const [webUsersList, setWebUserList] = useState<any>([]);
    const [dataTableId, setDataTableId] = useState<any>(null);
    const [isOpneRequestModel, setOpenRequestModel] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<any>({});
    const [isOpenResponseModel, setOpenResponseModel] = useState<boolean>(false);
    const [importData, setImportData] = useState<any>(null);
    const [expanded, setExpanded] = useState<any>({});
    const [searchWebValue, setSearchWebValue] = useState<string>("all")
    const [isLoading, setLoading] = useState<boolean>(false);
    console.log("webUsersList", webUsersList);

    const columns = [
        {
            title: '#ID',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.id
                        ? `#${row.id}` : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Email',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.email
                        ? row.email : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Mobile No.',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.mobile
                        ? `+91 ${row.mobile}` : "-"} </div>
             </div>`
            }
        },
        {
            title: 'File Size',
            render: function (data: any, type: any, row: any) {
                const size = (row.fileSize / 1024).toFixed(2)
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.fileSize
                        ? `${size} KB` : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Created',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdAt ? convertLocalDate(row.createdAt) : "-"} </div>
             </div>`
            }
        },
        {
            title: '',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
               <button class="delete-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">View</span></button>
               </div>
             </div>`
            }
        },
        {
            title: '',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
               <button class="edit-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Import</span></button>
               </div>
             </div>`
            }
        },
    ]

    const validation = useFormik({
        initialValues: {
            id: "",
            userId: ""
        },
        validationSchema: Yup.object().shape({
            userId: Yup.string()
                .required("please select user")
        }),
        onSubmit: (values) => {
            importBackup(values);
            // console.log("values", values);
            // addServicePerson(values)
            // reloadDataTable();
            // isEditable ? editServicePerson(values) : addServicePerson(values)
        }
    })

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const handleViewRequest = (rowData: any) => {
        console.log("request view data", rowData);
        setOpenRequestModel(true);
        var jsonURL
        if (rowData?.file?.includes("public/")) {
            jsonURL = rowData?.file?.replace("public/", "")
        }
        console.log("jsonURL", jsonURL);

        // const beautifiedJSON = JSON.stringify(rowData.request, null, 2);
        fetch(`${url.BASE_URL}/${jsonURL}.json`) // Adjust the path to your JSON file
            .then(response => response.json())
            .then(data => setRequestData(data))
            .catch(error => console.error('Error fetching JSON:', error));
        // setRequestData(rowData.file)
    }

    const handleViewResponse = (rowData: any) => {
        console.log("request view data", rowData);
        setOpenResponseModel(true);
        // const beautifiedJSON = JSON.stringify(rowData.response, null, 2);
        setImportData(rowData)
    }

    const toggleRequestModel = () => {
        setOpenRequestModel(!isOpneRequestModel)
    }

    const toggleResponsetModel = () => {
        setOpenResponseModel(!isOpenResponseModel)
    }

    const toggleExpand = (key: any) => {
        setExpanded((prevExpanded: any) => ({
            ...prevExpanded,
            [key]: !prevExpanded[key]
        }));
    };

    const renderValue = (value: any, key: any) => {
        const type = typeof value;

        if (type === 'object' && value !== null) {
            if (Array.isArray(value)) {
                return (
                    <div className="json__array">
                        {value.map((item, index) => (
                            <div key={index} className="json__item json__item--array">
                                {renderValue(item, index)}
                            </div>
                        ))}
                    </div>
                );
            } else {
                const isExpanded = expanded[key];
                const arrow = isExpanded ? '▼' : '▶';
                return (
                    <div className="json__object">
                        <div className="json__key" onClick={() => toggleExpand(key)}>{arrow} {key}:</div>
                        {isExpanded && (
                            <div className="json__value">
                                {Object.keys(value).map((nestedKey) => (
                                    <div key={nestedKey} className="json__nested">
                                        {renderValue(value[nestedKey], nestedKey)}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            }
        } else {
            return (
                <div className="json__primitive">
                    <span className="json__key">{key}:</span>
                    <span className={`json__value json__value--${type}`}>{JSON.stringify(value)}</span>
                </div>
            );
        }
    };

    const searchWebUsers = (e: any) => {
        userSearchApiCall(e, setWebUserList, 3, true);
    }

    useEffect(() => {
        searchWebUsers("all")
    }, [])

    const setFilterValue = (e: any) => {
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e.value);
        setSearchWebValue(e.value);
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }

    const customStyles: CustomStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
        // control: (base: any) => ({
        //   ...base,
        //   borderColor: invalid ? 'red' : base.borderColor,
        // }),
    };

    const importBackup = (values: any) => {
        setLoading(true);
        const reqBody = {
            backupId: importData?.id,
            userId: values.userId
        }
        console.log("reqBody", reqBody);

        Request.post(url.GET_BACKUP_RESTORE_URL, reqBody).then((res) => {
            toast.success(res.data.msg);
            setImportData({})
            setOpenResponseModel(false);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        })
    }

    return (
        <div>
            <ToastContainer />

            <Modal
                isOpen={isOpneRequestModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleRequestModel}
            >
                <div className="modal-content">
                    {/* <ModalHeader style={{ justifyContent: 'center' }}></ModalHeader> */}
                    <ModalBody>
                        {/* {requestData} */}
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(requestData, null, 2)}
                        </pre>
                        {/* {Object.keys(requestData).map((key) => (
                            <div key={key} className="json__item">
                                {renderValue(requestData[key], key)}
                            </div>
                        ))} */}
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'center' }}>
                        <button
                            className="btn btn-dark"
                            type="submit"
                            onClick={() => setOpenRequestModel(false)}
                        >
                            Ok
                        </button>
                    </ModalFooter>
                </div>
            </Modal>

            {/* VIEW RESPONSE MODEL */}
            <Modal
                isOpen={isOpenResponseModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleResponsetModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{`#${importData?.id}`}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Users</Label>
                                <Select
                                    name="userId"
                                    value={webUsersList.find((option: any) => option.value === validation.values.userId)}
                                    options={webUsersList}
                                    onChange={(selectedOption: any) => validation.setFieldValue('userId', selectedOption.value)}
                                    onBlur={() => validation.setFieldTouched('userId', true)}
                                    // invalid={
                                    //   assignPremiseValidation.touched.grantorUserId && assignPremiseValidation.errors.grantorUserId ? true : false
                                    // }
                                    onFocus={() => searchWebUsers("a")}
                                    onInputChange={(e) => searchWebUsers(e)}
                                    styles={customStyles}
                                />
                                {validation.touched.userId && validation.errors.userId ? (
                                    <span className="error">{validation.errors.userId}</span>
                                ) : null}
                                {isLoading && <div style={{ marginTop: 10 }}>
                                    <Alert color='warning'><strong>Warning!</strong>  Please wait until the process is complete. Do not close or quit the application during this time.</Alert></div>}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={isLoading}
                            // onClick={() => importBackup()}
                            >
                                {isLoading ? "Importing..." : "Import"}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            <DataTable
                apiURL={`${url.BASE_URL}${url.GET_BACKUP_LIST_URL}`}
                columns={columns}
                paramsId={{ userId: searchWebValue }}
                // title="Logs"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                // handleEditClick={handleEditClick}
                handleDeleteClick={handleViewRequest}
                handleEditClick={handleViewResponse}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"BackupList"}
            // optionGroup={webUsersList}
            />
        </div>
    )
}

export default Backup;