import classNames from 'classnames';
import React, { useState } from 'react'
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import Area from './Area';

const Master = () => {
    const [customActiveTab, setcustomActiveTab] = useState<string>("1");

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <div className="page-content " style={{ backgroundColor: '#fff' }}>
            <Nav tabs className="nav-tabs-custom" style={{ marginTop: 20 }}>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", color: customActiveTab === "1" ? "#121212" : "#7D8398" }}
                        className={classNames({
                            active: customActiveTab === "1",
                            'custom-active-border': customActiveTab === '1',
                        })}
                        onClick={() => {
                            toggleCustom("1");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Area Management</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", color: customActiveTab === "2" ? "#121212" : "#7D8398" }}
                        className={classNames({
                            active: customActiveTab === "2",
                        })}
                        onClick={() => {
                            toggleCustom("2");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block" style={{ fontSize: 15 }}>City Management</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", color: customActiveTab === "3" ? "#121212" : "#7D8398" }}
                        className={classNames({
                            active: customActiveTab === "3",
                        })}
                        onClick={() => {
                            toggleCustom("3");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block" style={{ fontSize: 15 }}>State Management</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", color: customActiveTab === "4" ? "#121212" : "#7D8398" }}
                        className={classNames({
                            active: customActiveTab === "4",
                        })}
                        onClick={() => {
                            toggleCustom("4");
                        }}
                    >
                        <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Country Management</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
            >
                <TabPane tabId="1">
                    <div style={{ marginTop: -100, marginRight: -50, marginLeft: -50 }}>
                        <Area />
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h4>Coming Soon...</h4>
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h4>Coming Soon...</h4>
                    </div>
                </TabPane>
                <TabPane tabId="4">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <h4>Coming Soon...</h4>
                    </div>
                </TabPane>
            </TabContent>

        </div>
    )
}

export default Master