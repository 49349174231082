import React, { useState } from 'react';
import FirmwareDataTable from './FirmwareDataTable';
import * as url from "../../../helpers/url_helper";
import { Images } from '../../../constants/images';
import { convertLocalDate } from '../../../Utils/configController';

const PushFirmwareList = ({ setDataTableId }: any) => {
    // const [dataTableId, setDataTableId] = useState(null);
    const columns = [
        {
            title: 'User',
            render: function (data: any, type: any, row: any) {
                return row?.userLable ? `${row?.userLable}` : "-";
            }
        },
        {
            title: 'Hardware Version',
            render: function (data: any, type: any, row: any) {
                return row?.hardwareId?.hardwareVersion ? `${row?.hardwareId?.hardwareVersion}` : "-";
            }
        },
        {
            title: 'Firmware Version',
            render: function (data: any, type: any, row: any) {
                return row?.firmwareId?.firmwareVersion ? `${row?.firmwareId?.firmwareVersion}` : "-";
            }
        },
        {
            title: 'Is Stable?',
            render: function (data: any, type: any, row: any) {
                return row?.isStable ? '1' : "0";
            }
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge =
                    row?.status === 0 ? (
                        '<span class="font-size-11 badge-soft-danger badge bg-secondary">Pending</span>'
                    ) : row?.status === 1 ? (
                        '<span class="font-size-11 badge-soft-info badge bg-secondary">In Progress</span>'
                    ) : row?.status === 2 && (
                        '<span class="font-size-11  badge-soft-success badge bg-secondary">Completed</span>'
                    );

                return statusBadge;
            }
        },
        {
            title: 'Scheduled Date & Time',
            render: function (data: any, type: any, row: any) {
                return row?.scheduledDateTime ? convertLocalDate(row?.scheduledDateTime) : "-";
            }
        },
        {
            title: 'Date & Time',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? convertLocalDate(row?.createdAt) : "-";
            }
        },
    ]

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <FirmwareDataTable
            apiURL={`${url.BASE_URL}${url.PUSH_FIRMWARE_LIST}`}
            columns={columns}
            title={"Push Firmware List"}
            onDataTableRefresh={handleDataTableIdChange}
            idName={"PushFirmwareList"}
        />
    )
}

export default PushFirmwareList