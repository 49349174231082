import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from "yup";
import $ from 'jquery';
import * as url from '../../helpers/url_helper';
import DataTable from '../../components/Common/DataTable';
import Request from '../../helpers/request';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from '../../components/Common/ConfirmationModal';

const StaticOtp = () => {
    const [loading, setLoading] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [dataTableId, setDataTableId] = useState(null);
    const [openOTPModal, setOpenOTPModal] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const validation = useFormik({
        initialValues: {
            email_or_mobile: "",
            otp: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            email_or_mobile: Yup.string()
                .test(
                    'email_or_mobile',
                    'Invalid email or mobile',
                    (value: any) => {
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        const mobileRegex = /^[0-9]{10}$/;
                        // Trim whitespace before validation
                        const trimmedValue = value.trim();
                        return (
                            !trimmedValue ||  // allow empty value
                            trimmedValue === '' ||  // allow whitespace
                            emailRegex.test(trimmedValue) ||  // check if it's an email
                            (mobileRegex.test(trimmedValue) && trimmedValue.length === 10)  // check if it's a mobile number with length 10
                        );
                    }
                )
                .required('Email or mobile is required'),
            otp: Yup.string()
                .required("Please provide otp").min(4, "OTP not valid"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
            addOTPApiCall(values);
            // addAreaApiCall(values)
        }
    })

    const addOTPApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            email_or_mobile: values.email_or_mobile,
            otp: values.otp
        }
        Request.post(url.ADD_STATIC_OTP, reqObj).
            then((res) => {
                console.log("Add statc Response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        email_or_mobile: "",
                        otp: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenOTPModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const columns = [
        { data: 'email', title: 'email' },
        {
            title: 'Mobile Number',
            render: function (data: any, type: any, row: any) {
                return row?.mobile ? `+91 ${row?.mobile}` : "-";
            }
        },
        { data: 'otp', title: 'OTP' },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                    <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const deleteAreaApiCall = () => {
        setLoading(true);
        const reqObj = {
            otpId: deleteData.id
        }
        Request.post(url.DELETE_STATIC_OTP, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                validation.resetForm({
                    values: {
                        email_or_mobile: "",
                        otp: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const toggleArea = () => {
        setOpenOTPModal(!openOTPModal);
        validation.resetForm({
            values: {
                email_or_mobile: "",
                otp: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={openOTPModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleArea}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Add Static OTP"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">User</Label>
                                <Input
                                    value={validation.values.email_or_mobile || ""}
                                    name="email_or_mobile"
                                    type={"text"}
                                    placeholder="Enter Email or Mobile Number"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.email_or_mobile && validation.errors.email_or_mobile ? true : false
                                    }
                                    maxLength={(validation.values.email_or_mobile && validation.values.email_or_mobile.match(/^\d+$/) && validation.values.email_or_mobile.length === 10) ? 10 : undefined}
                                />
                                {validation.touched.email_or_mobile && validation.errors.email_or_mobile ? (
                                    <span className="error">{validation.errors.email_or_mobile}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">OTP</Label>
                                <Input
                                    value={validation.values.otp || ""}
                                    name="otp"
                                    type={"text"}
                                    placeholder="Enter Static OTP"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.otp && validation.errors.otp ? true : false
                                    }
                                    maxLength={4}
                                />
                                {validation.touched.otp && validation.errors.otp ? (
                                    <span className="error">{validation.errors.otp}</span>
                                ) : null}
                            </div>


                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Delete Area?"
                    text="Are you sure you want to delete this OTP ? This action cannot be undone."
                    buttonText="Delete"
                    onClick={() => { deleteAreaApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={loading}
                />
            }
            <DataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.GET_STATIC_OTP_LIST}`}
                columns={columns}
                isAddBtnShow={true}
                isBtnShow={false}
                btnName="Add Static OTP"
                btnStyle="btn btn-dark"
                onClickBtn={toggleArea}
                handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"StaticOtp"}
            />
        </div>
    )
}

export default StaticOtp