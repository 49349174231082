import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Row, Table } from 'reactstrap'
import { Images } from '../../../constants/images'
import AllocateRoom from './AllocateRoom';
import moment from 'moment';
import { convertUTCDate } from '../../../Utils/configController';
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper'
import getStorageData from '../../../Utils/getStorageData';
import { getMenuPermissions } from '../../../services/APIServices';
import { useDispatch } from 'react-redux';
// import { getDashoboardPermissions } from '../../../Utils/searchApiCall';

const Dashboard = () => {
    const dispatch = useDispatch();
    const user = getStorageData().user;
    const [isOpenAllocateRoomModal, setOpenAllocateRoomModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dashboardData, setDashboardData] = useState<any>();
    const [cleanRoomList, setCleanRoomList] = useState<any[]>([]);
    const [laundryRoomList, setLaundryRoomList] = useState<any[]>([]);
    const [DNDRoomList, setDNDRoomList] = useState<any[]>([]);

    const toggleRoomModal = () => {
        setOpenAllocateRoomModal(!isOpenAllocateRoomModal);
    }


    useEffect(() => {

        if (getStorageData().userType === 4) {
            getMenuPermissions("", dispatch)
        }
        if ((getStorageData().userType === 4 && getStorageData()?.menuPermissions?.includes("DASHBOARD")) || (getStorageData().userType !== 4)) {
            getDashboardApiCall();
            const intervalId: any = setInterval(() => {
                getDashboardApiCall()
            }, 3000)
            return () => clearInterval(intervalId);
        }
    }, [])

    const getDashboardApiCall = () => {
        const start = moment();
        const end = moment();
        const localStartTimeString: any = start.startOf('day');
        const localEndTimeString: any = end.endOf('day');

        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            todayStartDate: localStartTimeString,
            todayEndDate: localEndTimeString
        }
        console.log("reqObj", reqObj);

        Request.post(url.GET_DASHBOARD, reqObj).
            then((res) => {
                console.log("get dashboard Response", res);
                setDashboardData(res.data.data);

                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    return (
        <Card style={{ marginTop: 100, marginLeft: 30, marginRight: 30, marginBottom: 0 }}>
            {(getStorageData().userType === 4 && getStorageData()?.menuPermissions?.includes("DASHBOARD")) || (getStorageData().userType !== 4) ?
                <CardBody>
                    <div className="mb-4 h4 card-title d-flex justify-content-end flex-row" >
                        {/* <div className="app-search d-lg-block" >
                        <div className="position-relative">
                            <span className="bx bx-search-alt" />
                            <input
                                type="search"
                                className="form-control"
                                style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                placeholder={"Search..."}
                            // onChange={(e) => handleCustomSearch(e.target.value)}
                            />
                        </div>
                    </div> */}
                        <div className="justify-content-end  d-flex" style={{ gap: 20 }}>
                            <button className="btn btn-dark"
                                onClick={() => toggleRoomModal()}
                            >
                                Allocate Room
                            </button>
                        </div>
                    </div>
                    <Card style={{ border: "1px solid #EAECF0" }}>
                        <CardBody>
                            <h1 style={{ fontSize: 20 }}>Overview</h1>
                            <Row>
                                <Col style={{ width: "20" }}>
                                    <Card style={{ height: "80% " }}>
                                        <CardBody>
                                            {/* <CardTitle className="mt-0">Special title treatment</CardTitle> */}
                                            <h1 className="float-end" style={{ fontSize: 26 }}>
                                                {dashboardData?.todayCheckIn < 10 ? `0${dashboardData?.todayCheckIn}` : dashboardData?.todayCheckIn}
                                            </h1>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                            <h4 style={{ fontSize: 16, color: "#5D6679" }}>Check-in</h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ width: "20" }}>
                                    <Card style={{ height: "80% " }}>
                                        <CardBody>
                                            {/* <CardTitle className="mt-0">Special title treatment</CardTitle> */}
                                            <h1 className="float-end" style={{ fontSize: 26 }}>
                                                {dashboardData?.todayCheckOut < 10 ? `0${dashboardData?.todayCheckOut}` : dashboardData?.todayCheckOut}
                                            </h1>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                            <h4 style={{ fontSize: 16, color: "#5D6679" }}>Check-out</h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ width: "20" }}>
                                    <Card style={{ height: "80% " }}>
                                        <CardBody>
                                            {/* <CardTitle className="mt-0">Special title treatment</CardTitle> */}
                                            <h1 className="float-end" style={{ fontSize: 26 }}>
                                                {dashboardData?.inHotel < 10 ? `0${dashboardData?.inHotel}` : dashboardData?.inHotel}
                                            </h1>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                            <h4 style={{ fontSize: 16, color: "#5D6679" }}>In hotel</h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ width: "20" }}>
                                    <Card style={{ height: "80% " }}>
                                        <CardBody>
                                            {/* <CardTitle className="mt-0">Special title treatment</CardTitle> */}
                                            <h1 className="float-end" style={{ fontSize: 26, color: "green" }}>
                                                {dashboardData?.availableRooms < 10 ? `0${dashboardData?.availableRooms}` : dashboardData?.availableRooms}
                                            </h1>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                            <h4 style={{ fontSize: 16, color: "green" }}>Available room</h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ width: "20" }}>
                                    <Card style={{ height: "80% " }}>
                                        <CardBody>
                                            {/* <CardTitle className="mt-0">Special title treatment</CardTitle> */}
                                            <h1 className="float-end" style={{ fontSize: 26, color: "red" }}>
                                                {dashboardData?.occupiedRooms < 10 ? `0${dashboardData?.occupiedRooms}` : dashboardData?.occupiedRooms}</h1>
                                            <p style={{ color: "#858D9D", fontSize: 14 }}>Today's</p>
                                            <h4 style={{ fontSize: 16, color: "red" }}>Occupied room</h4>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row >

                            <h1 style={{ fontSize: 20 }}>Service Request</h1>
                            <Row>
                                <Col md={4} >
                                    <Card body style={{ border: "1px solid #EAECF0", height: '80%' }} >
                                        <CardBody>
                                            <img src={Images.cleaner} className="float-end" style={{ height: 26.94 }} />
                                            <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 30 }}>
                                                {dashboardData?.clearRoomRequest < 10 ? `0${dashboardData?.clearRoomRequest}` : dashboardData?.clearRoomRequest}</h1>
                                            <p style={{ fontSize: 18, color: "#858D9D" }}>Clean Room Request</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card body style={{ border: "1px solid #EAECF0", height: '80%' }} >
                                        <CardBody>
                                            <img src={Images.loundry} className="float-end" style={{ height: 26.94 }} />
                                            <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 30 }}>
                                                {dashboardData?.laundryRequest < 10 ? `0${dashboardData?.laundryRequest}` : dashboardData?.laundryRequest}
                                            </h1>
                                            <p style={{ fontSize: 18, color: "#858D9D" }}>Laundry Request</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card body style={{ border: "1px solid #EAECF0", height: '80%' }} >
                                        <CardBody>
                                            <img src={Images.block} className="float-end" style={{ height: 26.94 }} />
                                            <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 30 }}>
                                                {dashboardData?.DNDRequest < 10 ? `0${dashboardData?.DNDRequest}` : dashboardData?.DNDRequest}
                                            </h1>
                                            <p style={{ fontSize: 18, color: "#858D9D" }}>DND Request</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Table className="table-bordered ">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>Clean Room Request</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData?.roomListclear?.map((row: any, rowIndex: number) => (
                                                <tr key={rowIndex}>
                                                    <td style={{ fontSize: 14 }}>{row.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={4}>
                                    <Table className="table-bordered ">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>Laundry Request</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData?.roomListlaundry?.map((row: any, rowIndex: number) => (
                                                < tr key={rowIndex}>
                                                    <td style={{ fontSize: 14 }}>{row.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={4}>
                                    <Table className="table-bordered ">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>DND Request</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData?.roomListDND?.map((row: any, rowIndex: number) => (
                                                <tr key={rowIndex}>
                                                    <td style={{ fontSize: 14 }}>{row.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {
                        isOpenAllocateRoomModal &&
                        <AllocateRoom
                            isOpenAllocateRoomModal={isOpenAllocateRoomModal}
                            toggleRoomModal={() => toggleRoomModal()}
                        />
                    }
                </CardBody >
                :
                <CardBody>
                    <div className="mb-4 h4 card-title d-flex justify-content-end flex-row" ></div>
                    <Card className="overflow-hidden" style={{ width: "40%" }}>
                        <div className="bg-dark-subtle">
                            <Row>
                                <Col xs="7">
                                    <div className="text-primary p-3">
                                        <h5 className="text-dark">Welcome!</h5>
                                        <p className="text-dark">Dashboard</p>
                                    </div>
                                </Col>
                                <Col xs="5" className="align-self-end">
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                            <Row>
                                <Col sm="4">
                                    <div className="avatar-md profile-user-wid mb-4">
                                        <img
                                            src={`${url.IMAGE_URL}${user?.avatar}`}
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                        />
                                    </div>
                                    <h5 className="font-size-15 text-truncate">{`${user.firstname} ${user.lastname}`}</h5>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </CardBody>
            }
        </Card >
    )
}

export default Dashboard