import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    Input,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table
} from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Request from '../../../../helpers/request';
import * as url from "../../../../helpers/url_helper";
import { useNavigate, useParams } from 'react-router-dom';
import classNames from "classnames";
import Spinners from '../../../../components/Common/Spinner';
import Breadcrumb from '../../../../components/Common/Breadcrumb';
import getStorageData from '../../../../Utils/getStorageData';
import { ToastContainer, toast } from 'react-toastify';

function Permissions() {
    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [customActiveTab, setcustomActiveTab] = useState<string>("1");
    const [viewTagIds, setViewTagIds] = useState<any>([]);
    const [accessTagIds, setAccessTagIds] = useState<any[]>([]);
    const [assignTagIds, setAssignTagIds] = useState<any[]>([]);
    const [permissionTagId, setPermissionTagId] = useState<any[]>([]);
    const [tagList, setTagList] = useState<any[]>([]);
    const [menuPermissionsList, setMenuPermissionsList] = useState<any>([
        {
            "name": "DASHBOARD",
            "isChecked": false
        },
        {
            "name": "ROOMS",
            "isChecked": false
        },
        {
            "name": "SERVICES",
            "isChecked": false
        },
        {
            "name": "CONTROL-PANEL",
            "isChecked": false
        },
        {
            "name": "ANALYTICS",
            "isChecked": false
        },
        {
            "name": "SETTING",
            "isChecked": false
        },
    ]);
    console.log(
        "assignTagIds", assignTagIds
    );
    console.log("menuPermissionsList", menuPermissionsList);

    const rolePermissionListApiCall = () => {
        setLoading(true);
        Request.get(`${url.GET_ROLE_PERMISSION_LIST}?roleId=${params.id}`).
            then((res) => {
                console.log("get role permisson List Response", res.data.data.menuPermission);
                const menu = res.data.data.menuPermission;
                const updatedPermissions = menuPermissionsList.map((permission: any) => ({
                    ...permission,
                    isChecked: menu.includes(permission.name)
                }))
                console.log("updatedPermissions", updatedPermissions);
                setMenuPermissionsList(updatedPermissions)
                setViewTagIds(res.data.data.viewTagIds);
                setAccessTagIds(res.data.data.accessTagIds);
                setAssignTagIds(res.data.data.assignTagIds);
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    const rolePermissionApiCall = () => {
        setLoading(true);
        const reqBody = {
            roleId: params.id,
            viewTagIds: viewTagIds,
            assignTagIds: assignTagIds,
            accessTagIds: accessTagIds
        }
        Request.post(`${url.TAG_PERMISSION_LIST}`, reqBody).
            then((res: any) => {
                console.log("add role permission Response", res);
                toast.success(res.data.msg);
                rolePermissionListApiCall();
                tagListApiCall();
                // const menu = res.data.data.menuPermission;
                // const updatedPermissions = menuPermissionsList.map(permission => ({
                //     ...permission,
                //     isChecked: menu.includes(permission.name)
                // }))
                // console.log("updatedPermissions", updatedPermissions);
                // setMenuPermissionsList(updatedPermissions)
                // setViewTagIds(res.data.data.viewTagIds);
                // setAccessTagIds(res.data.data.accessTagIds);
                // setAssignTagIds(res.data.data.assignTagIds);
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
                // toast.error(res.data.data.errors[0].msg)
            })
    }

    const menuPermissionApiCall = () => {
        setLoading(true);
        const reqBody = {
            roleId: params.id,
            menuPermission: menuPermissionsList.filter((item: any) => item.isChecked).map((item: any) => item.name),
        }
        console.log("reqBody", reqBody);

        Request.post(`${url.MENU_PERMISSION_LIST}`, reqBody).
            then((res: any) => {
                console.log("add role permission Response", res);
                toast.success(res.data.msg);
                rolePermissionListApiCall();
                tagListApiCall();
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
                // toast.error(res.data.data.errors[0].msg)
            })
    }

    const tagListApiCall = () => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            length: 100
        }
        Request.post(url.GET_TAG_LIST_URL, reqObj).
            then((res) => {
                setTagList(res.data.data);
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        rolePermissionListApiCall();
        tagListApiCall();
    }, [])

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const handleMenuPermission = (item: any) => {
        console.log(
            "item", item
        );

        var menuList = [];
        for (let i = 0; i < menuPermissionsList.length; i++) {
            const menuElement = menuPermissionsList[i];

            if (menuElement.name === item.name) {
                menuList.push({ name: item.name, isChecked: !item.isChecked })
                // setMenuPermissionsList([...menuPermissionsList, { name: item.name, isChecked: !menuElement.isChecked }])
            } else {
                menuList.push(menuElement)
            }
        }
        setMenuPermissionsList(menuList)
    }

    const handleCheckBox = (item: any, type: any) => {
        var selectedViewList = [];
        var selectedAssignList = [];
        var seletedAccessList = [];
        if (type === 0) {
            if (viewTagIds.includes(item.id)) {
                const filterData = viewTagIds.filter((e: any) => e != item.id)
                console.log("filterDatafilterData", filterData);
                setViewTagIds(filterData)
            } else {
                selectedViewList.push(...viewTagIds, item.id)
                setViewTagIds([...viewTagIds, item.id])
            }
        }
        else if (type === 1) {
            if (assignTagIds.includes(item.id)) {
                const filterData = assignTagIds.filter((e: any) => e != item.id)
                console.log("filterDatafilterData", filterData);
                setAssignTagIds(filterData)
            } else {
                selectedAssignList.push(...assignTagIds, item.id)
                setAssignTagIds([...assignTagIds, item.id])
            }
        } else if (type === 2) {
            if (accessTagIds.includes(item.id)) {
                const filterData = accessTagIds.filter((e) => e != item.id)
                console.log("filterDatafilterData", filterData);
                setAccessTagIds(filterData)
            } else {
                seletedAccessList.push(...accessTagIds, item.id)
                setAccessTagIds([...accessTagIds, item.id])
            }
        }
        console.log("selectedViewList", selectedViewList);
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{ backgroundColor: '#fff' }}>
                <ToastContainer />
                <Card >
                    <CardBody>
                        <div className="app-search d-lg-block" style={{
                            borderStyle: "solid"
                        }}>
                            <div className="position-relative">
                                <span className="bx bx-search-alt" />
                                <Input
                                    type="search"
                                    placeholder="Search"
                                    style={{ background: '#F7F9FC', color: "#858D9D" }}
                                // className="form-control"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Breadcrumb title="Roles" breadcrumbItem={params.name} titleClick={"/role-management"} />
                        </div>
                        <Nav tabs className="nav-tabs-custom" style={{ marginTop: 20 }}>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer", color: customActiveTab === "1" ? "#121212" : "#7D8398" }}
                                    className={classNames({
                                        active: customActiveTab === "1",
                                        'custom-active-border': customActiveTab === '1',
                                    })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Area Permissions</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer", color: customActiveTab === "2" ? "#121212" : "#7D8398" }}
                                    className={classNames({
                                        active: customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Dashboard Menu Permissions</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                                {loading ? <div style={{ height: "60vh" }}><Spinners /></div> :
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th rowSpan={2} style={{ textAlign: 'center', fontSize: 14 }}>Area Tags</th>
                                                <th style={{ textAlign: 'center', fontSize: 14 }}>Permissions</th>
                                            </tr>
                                            <tr>
                                                <th style={{ fontSize: 14 }}>View</th>
                                                <th style={{ fontSize: 14 }}>Assign</th>
                                                <th style={{ fontSize: 14 }}>Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tagList.map((item, index) => (
                                                <tr key={index}>
                                                    <th style={{ fontSize: 14 }}>{item.name}</th>
                                                    <td style={{ fontSize: 14 }}>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={viewTagIds.includes(item.id)}
                                                            onChange={() => handleCheckBox(item, 0)}
                                                            style={{ fontSize: 15 }}
                                                        />
                                                    </td>
                                                    <td style={{ fontSize: 14 }}><input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={assignTagIds.includes(item.id)}
                                                        onChange={() => handleCheckBox(item, 1)}
                                                        style={{ fontSize: 15 }}
                                                    />
                                                    </td>
                                                    <td style={{ fontSize: 14 }}><input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={accessTagIds.includes(item.id)}
                                                        onChange={() => handleCheckBox(item, 2)}
                                                        style={{ fontSize: 15 }}
                                                    /></td>
                                                </tr>
                                            ))}
                                            {/* <tr>
                                                <th style={{ fontSize: 14 }}>Rooms</th>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" checked /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" checked /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                            </tr> */}
                                            {/* <tr>
                                                <th style={{ fontSize: 14 }}>Corridor</th>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" checked /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                            </tr>
                                            <tr>
                                                <th style={{ fontSize: 14 }}>Garden</th>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                                <td style={{ fontSize: 14 }}><input type="checkbox" className="form-check-input" /></td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                }
                                {!loading && <div style={{ justifyContent: "center", display: "flex", marginTop: 40 }}>
                                    <Button
                                        className='btn btn-dark'
                                        onClick={() => rolePermissionApiCall()}
                                    // disabled={saveLoading}
                                    >
                                        {loading ? "Saving..." : "Save"}
                                    </Button>
                                </div>}
                            </TabPane>

                            <TabPane tabId="2">
                                <Table className="table mb-0" style={{ marginTop: 20 }}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{ verticalAlign: 'top', textAlign: 'center', fontSize: 14, width: '20%' }}>Menu List</th>
                                            <th colSpan={2} style={{ textAlign: 'center', fontSize: 14 }}>Permissions</th>
                                        </tr>
                                        <tr>
                                            <th style={{ fontSize: 14 }}>Yes</th>
                                            <th style={{ fontSize: 14 }}>No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menuPermissionsList.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <th style={{ fontSize: 14 }}>{item.name}</th>
                                                <td style={{ fontSize: 14 }}>
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        checked={item.isChecked}
                                                        // checked={permissionTagId.includes(item.id)}
                                                        onChange={() => handleMenuPermission(item)}
                                                    />
                                                </td>
                                                <td style={{ fontSize: 14 }}>
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        checked={!item.isChecked}
                                                        // checked={!permissionTagId.includes(item.id)}
                                                        onChange={() => handleMenuPermission(item)}
                                                    />
                                                </td>
                                                {/* <td style={{ fontSize: 14 }}><input type="radio" className="form-check-input" /></td> */}
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                                {!loading && <div style={{ justifyContent: "center", display: "flex", marginTop: 40 }}>
                                    <Button
                                        className='btn btn-dark'
                                        onClick={() => menuPermissionApiCall()}
                                    // disabled={saveLoading}
                                    >
                                        {loading ? "Saving..." : "Save"}
                                    </Button>
                                </div>}
                            </TabPane>
                        </TabContent>

                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default Permissions