import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

export interface breadcrumbTypes {
  title: string,
  breadcrumbItem?: string,
  titleClick: string,
  secondPath?: boolean,
  secondTitle?: string,
  secondTitleClick?: any
  state?: any
}
const Breadcrumb = ({
  title,
  breadcrumbItem,
  titleClick,
  secondPath,
  secondTitle,
  secondTitleClick,
  state
}: breadcrumbTypes) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {/* <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4> */}
          <div className="page-title-right">
            <ol className="breadcrumb">
              <BreadcrumbItem>
                <Link to={titleClick} state={state ? state : ""} style={{ fontSize: 15 }}>{title}</Link>
              </BreadcrumbItem>
              {secondPath && <BreadcrumbItem>
                <Link to={secondTitleClick} style={{ fontSize: 15 }}>{secondTitle}</Link>
              </BreadcrumbItem>}
              <BreadcrumbItem active >
                <Link to="#" style={{ color: 'green', fontSize: 15 }}>{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
