import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import images from "../../assets/images";

const Sidebar = props => {
  // console.log("route", props.route);
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/premises" className="logo logo-dark">
                <img src={images.logo} style={{ height: 56, width: 55.45, borderRadius: 4 }} />
                {/* <span>{props.t("Dashboard")}</span> */}
              </Link>
            </li>
          </ul>
          {/* <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="45" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="45" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="19" />
            </span>
          </Link> */}
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ?
            <SidebarContent sidebarRoute={props.route} activeRoute={props.activeRoute} /> :
            <SidebarContent sidebarRoute={props.route} />
          }
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
