import getStorageData from "../Utils/getStorageData";
import Dashboard from "../pages/SubAdmin/Dashboard/Dashboard";
import Premises from "../pages/Premises/Dashboard";
import Service from "../pages/Premises/Service";
import Settings from "../pages/Premises/Settings";
import SubAdmins from "../pages/Premises/SubAdmins";
import Permissions from "../pages/Premises/SubAdmins/Permissions";
import React from "react";
import Rooms from "../pages/SubAdmin/Rooms";
import Roles from "../pages/SubAdmin/Roles/Roles";
import Users from "../pages/SubAdmin/HotelUsers/HotelUser";
import Tag from "../pages/SubAdmin/TagManagement/Tag";
import TagAreaRooms from "../pages/SubAdmin/TagManagement/TagAreaRooms";
import ControlPanel from "../pages/SubAdmin/ControlPanel";
import Guest from "../pages/SubAdmin/Guest/Index";
import GuestHistory from "../pages/SubAdmin/GuestHistory/Index";

const selectedPremiseID = getStorageData().selectedPremiseID;
console.log("selectedPremiseID data", selectedPremiseID);
var subAdminRoutes: any = [];
selectedPremiseID ?
    subAdminRoutes =
    [
        { path: "/dashboard", component: <Dashboard /> },
        { path: "/control-panel", component: <ControlPanel /> },
        { path: "/rooms", component: <Rooms /> },
        { path: "/guest-management", component: <Guest /> },
        { path: "/guest-History", component: <GuestHistory /> },
        { path: "/role-management", component: <Roles /> },
        { path: "/role-management/permissions/:name/:id", component: <Permissions /> },
        { path: "/users", component: <Users /> },
        { path: "/tag-management", component: <Tag /> },
        { path: "/tag-management/rooms/:id", component: <TagAreaRooms /> },
        { path: "/premises", component: <Premises /> },
        { path: "/settings", component: <Settings /> },
    ]
    :
    subAdminRoutes =
    [
        { path: "/dashboard", component: <Dashboard /> },
        { path: "/rooms", component: <Rooms /> },
        { path: "/guest-management", component: <Guest /> },
        { path: "/role-management", component: <Roles /> },
        { path: "/users", component: <Users /> },
        { path: "/tag-management", component: <Tag /> },
        { path: "/tag-management/rooms/:id", component: <TagAreaRooms /> },
        { path: "/premises", component: <Premises /> },
        { path: "/settings", component: <Settings /> },
    ]

export { subAdminRoutes };