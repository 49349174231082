import React, { useEffect, useState } from 'react';
// import FirmwareDataTable from './FirmwareDataTable';
import * as url from "../../../helpers/url_helper";
import { Images } from '../../../constants/images';
import { convertLocalDate } from '../../../Utils/configController';
import FirmwareDataTable from '../Firmware/FirmwareDataTable';
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";

const TestingDevicesList = () => {
    const [dataTableId, setDataTableId] = useState(null);
    const [isOpenAddTestingModel, setOpenAddTestingModel] = useState(false);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return row?.slave ? `${row?.slave}` : "-";
            }
        },
        {
            title: 'Hardware Version',
            render: function (data: any, type: any, row: any) {
                return row?.hardwareId?.hardwareVersion ? `${row?.hardwareId?.hardwareVersion}` : "-";
            }
        },
        {
            title: 'Model Type',
            render: function (data: any, type: any, row: any) {
                return row?.firmwareId?.firmwareVersion ? `${row?.firmwareId?.firmwareVersion}` : "-";
            }
        },
    ]

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const validation = useFormik({
        initialValues: {
            slave: "",
            HW: "",
            modelType: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            slave: Yup.string()
                .required("Please provide your slave "),
            HW: Yup.string()
                .required("Please provide your hardware version "),
            modelType: Yup.string()
                .required("Please provide your model type "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            // addSlaveApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const toggleAddTestingModel = () => {
        setOpenAddTestingModel(!isOpenAddTestingModel)
        validation.resetForm({
            values: {
                slave: "",
                HW: "",
                modelType: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    return (
        <>
            <Modal
                isOpen={isOpenAddTestingModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleAddTestingModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>
                        Add Testing Device
                        {/* {isEditable ? "Edit Area" : "Add Area"} */}
                    </ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Slave</Label>
                                <Input
                                    value={validation.values.slave || ""}
                                    name="slave"
                                    type={"text"}
                                    placeholder="Slave"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.slave && validation.errors.slave ? true : false
                                    }
                                />
                                {validation.touched.slave && validation.errors.slave ? (
                                    <span className="error">{validation.errors.slave}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Hardware Version</Label>
                                <Input
                                    value={validation.values.HW || ""}
                                    name="HW"
                                    type={"text"}
                                    placeholder="HW"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.HW && validation.errors.HW ? true : false
                                    }
                                />
                                {validation.touched.HW && validation.errors.HW ? (
                                    <span className="error">{validation.errors.HW}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Model Type</Label>
                                <Input
                                    value={validation.values.modelType || ""}
                                    name="modelType"
                                    type={"text"}
                                    placeholder="modelType"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.modelType && validation.errors.modelType ? true : false
                                    }
                                />
                                {validation.touched.modelType && validation.errors.modelType ? (
                                    <span className="error">{validation.errors.modelType}</span>
                                ) : null}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"} */}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            <FirmwareDataTable
                apiURL={`${url.BASE_URL}${url.PUSH_FIRMWARE_LIST}`}
                columns={columns}
                title={"Testing Devices List"}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"TestingDevicesList"}
                btnName='Add Testing Device'
                btnStyle="btn btn-dark"
                btnClick={() => toggleAddTestingModel()}
            />
        </>
    )
}

export default TestingDevicesList;