import React, { useEffect, useState } from 'react'
import DataTable from '../../components/Common/DataTable';
import * as url from '../../helpers/url_helper';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { convertLocalDate } from '../../Utils/configController';
import Request from "../../helpers/request";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const Feedback = () => {
    const [dataTableId, setDataTableId] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpenFeedbackModel, setOpenFeedbackModel] = useState<boolean>(false);
    const [feedbackData, setFeedbackData] = useState<boolean>(false);

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        // console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const changeStatusApiCall = (values: any, id: string) => {
        // console.log("assign premise values", values);
        setLoading(true);
        const reqObj = {
            feedbackId: id,
            status: values,
        }
        Request.post(url.CHANGE_STATUS_URL, reqObj).
            then((res) => {
                console.log("Assign Premise Response", res);
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("Assign Premise Error", err);
                toast.error(err?.response?.data?.errors[0]?.msg)
                setLoading(false)
            })
    }

    const columns = [
        {
            title: '#ID',
            render: function (data: any, type: any, row: any) {
                const joinArray = [row?.userId?.mobile, row.userId.email].filter(Boolean)
                return `
                <div>
                  <div>${joinArray.join(" | ")}</div>
                </div>`;
            }
        },
        {
            title: 'Feedback',
            render: function (data: any, type: any, row: any) {
                return `<div class="d-flex gap-2"><span style="width:200px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${row?.feedback ? row.feedback : "-"} </span> <button class="edit-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">View</span></button></div>`
            },
        },
        {
            title: 'Created At',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? convertLocalDate(row?.createdAt) : "-"
            },

        },
        {
            title: 'Updated At',
            render: function (data: any, type: any, row: any) {
                return row?.updatedAt ? convertLocalDate(row?.updatedAt) : "-"
            },

        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                return `
                    <select class="dropdown-btn" style="padding: 5px; border-radius: 5px; border: 1px solid #ccc;" >
                        <option value=0 ${row.status === 0 ? 'selected' : ''}>feedback received</option>
                        <option value=1 ${row.status === 1 ? 'selected' : ''}>in progress</option>
                        <option value=2 ${row.status === 2 ? 'selected' : ''}>complete</option>
                    </select>
                `;
            },
        },
    ]

    const handleStatus = (rowData: any, selectedValue: any) => {
        console.log("feedback rowData", rowData);
        changeStatusApiCall(selectedValue, rowData.id)
    }

    const handleViewFeedback = (rowData: any) => {
        setOpenFeedbackModel(true);
        // const beautifiedJSON = JSON.stringify(rowData.response, null, 2);
        setFeedbackData(rowData.feedback)
    }

    const toggleFeedbackModel = () => {
        setOpenFeedbackModel(!isOpenFeedbackModel)
    }

    return (
        <div>
            <ToastContainer />

            <Modal
                isOpen={isOpenFeedbackModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleFeedbackModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Feedback"}</ModalHeader>
                    <ModalBody>
                        {/* {responseData} */}
                        <p>
                            {feedbackData}
                        </p>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'center' }}>
                        <button
                            className="btn btn-dark"
                            type="submit"
                            onClick={() => setOpenFeedbackModel(false)}
                        >
                            Ok
                        </button>
                    </ModalFooter>
                </div>
            </Modal>

            <DataTable
                apiURL={`${url.BASE_URL}${url.GUEST_FEEDBACK_URL}`}
                columns={columns}
                // responseType={{ recordsTotal: 0, recordsFiltered: 0, data: [] }}
                isAddBtnShow={false}
                isBtnShow={false}
                handleDeleteClick={() => { }}
                // handleEditbtn={() => { }}
                isShowSearch={true}
                onDataTableRefresh={handleDataTableIdChange}
                handleDropdownClick={handleStatus}
                handleEditClick={handleViewFeedback}
                idName={"Feedback"}
            />
        </div>
    )
}

export default Feedback