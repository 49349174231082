import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Input,
    Row,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Label,
    FormGroup,
    Alert,
} from 'reactstrap';
import * as url from '../../../helpers/url_helper';
import Request from '../../../helpers/request';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { values } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import getStorageData from '../../../Utils/getStorageData';
import { getDashoboardPermissions } from '../../../Utils/searchApiCall';
import { getMenuPermissions } from '../../../services/APIServices';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { permission } from '../../../store/slices/APIResponseSlice';

function Settings() {
    const dispatch = useDispatch();
    const menuPermissions = useSelector((state) => state.APIResponse.menuPermissions)
    console.log("menuPermissions", menuPermissions);
    const [isLoading, setLoading] = useState(false);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [changeTheme, setChangeTheme] = useState(false);

    const toggleUserModal = () => setUserModalOpen(!userModalOpen);
    const togglePasswordModal = () => setPasswordModalOpen(!passwordModalOpen);

    const getProfileApiCall = () => {
        setLoading(true);
        Request.get(url.GET_PROFILE_URL).
            then((res) => {
                //  console.log("Area List Response", res);
                setLoading(false);
                validation.setValues({
                    firstname: res.data.data.firstname,
                    lastname: res.data.data.lastname,
                    email: res.data.data.email,
                    mobile: res.data.data.mobile
                })
            }).
            catch((err) => {
                setLoading(false)
            })
    }
    const updateProfileApiCall = (values) => {
        setLoading(true);
        Request.post(url.UPDATE_PROFILE_URL, values).
            then((res) => {
                console.log("Update profile Response", res);
                setLoading(false);
                toast.success(res.data.msg)
                setUserModalOpen(false);
                // validation.setValues({
                //     firstname: res.data.data.firstname,
                //     lastname: res.data.data.lastname,
                //     email: res.data.data.email,
                //     mobile: res.data.data.mobile
                // })
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    const validation = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            mobile: ""
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string()
                .required("Please provide your firstname "),
            lastname: Yup.string()
                .required("Please provide your lastname "),
            email: Yup.string()
                .required("Please provide your email address "),
            lastname: Yup.string()
                .required("Please provide your mobile number "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            updateProfileApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    useEffect(() => {
        if (getStorageData().userType === 4) {
            // getDashoboardPermissions()
            if (getStorageData().userType === 4) {
                // getDashoboardPermissions();
                getMenuPermissions("", dispatch)
                // .then((res) => {
                //     console.log("dataaaaaaa", res.menuPermission);

                //     if (res) {
                //         dispatch(permission(res.menuPermission))

                //         if (JSON.stringify(menuPermissions) !== JSON.stringify(res.menuPermission && menuPermissions.length > 0)) {
                //             console.log("not equal");

                //             // window.location.reload();
                //         }
                //         console.log("JSON.stringify(menuPermissions) !== JSON.stringify(res.menuPermission", menuPermissions, res.menuPermission);
                //     }
                // })
                // .then((res) => {
                //     if(res) {
                //         useDispatch
                //     }
                // })
            }
        }
    }, [])

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content " style={{ backgroundColor: '#fff' }}>
                <Modal
                    isOpen={userModalOpen}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={toggleUserModal}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>User Profile</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.formError ? (
                                    <Alert color="danger">{validation.errors.formError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">FirstName</Label>
                                    <Input
                                        value={validation.values.firstname || ""}
                                        name="firstname"
                                        type={"text"}
                                        placeholder="Enter Your Firstname"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                        }
                                    />
                                    {validation.touched.firstname && validation.errors.firstname ? (
                                        <span className="error">{validation.errors.firstname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">LastName</Label>
                                    <Input
                                        value={validation.values.lastname || ""}
                                        name="lastname"
                                        type={"text"}
                                        placeholder="Enter Your Lastname"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.lastname && validation.errors.lastname ? true : false
                                        }
                                    />
                                    {validation.touched.lastname && validation.errors.lastname ? (
                                        <span className="error">{validation.errors.lastname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        value={validation.values.email || ""}
                                        name="email"
                                        type={"text"}
                                        placeholder="Enter Your Email Address"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                        disabled
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <span className="error">{validation.errors.email}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Mobile Number</Label>
                                    <Input
                                        value={validation.values.mobile || ""}
                                        name="mobile"
                                        type={"text"}
                                        placeholder="Enter Your Mobile Number"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.mobile && validation.errors.mobile ? true : false
                                        }
                                        disabled
                                    />
                                    {validation.touched.mobile && validation.errors.mobile ? (
                                        <span className="error">{validation.errors.mobile}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Saving..." : "Save"}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {/* CHANGE PASSWORD MODAL */}
                <Modal
                    isOpen={passwordModalOpen}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={togglePasswordModal}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Change Password</ModalHeader>
                        <ModalBody>
                            <Form className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    // validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="mb-3">
                                    <Label className="form-label">Current Password</Label>
                                    <Input
                                        name="password"
                                        type={"text"}
                                        placeholder="Current Password"
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Enter New Password</Label>
                                    <Input
                                        name="password"
                                        type={"text"}
                                        placeholder="Enter New Password"
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Confirm New Password</Label>
                                    <Input
                                        name="password"
                                        type={"text"}
                                        placeholder="Confirm Password"
                                    />
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <Button type="button" color="dark" onClick={togglePasswordModal}>
                                Save
                            </Button>
                        </ModalFooter>
                    </div>
                </Modal>

                <Card>
                    <CardBody>
                        <div className="mb-4 h4 card-title d-flex justify-content-between flex-row">
                            <div className="app-search  d-lg-block" style={{
                                borderStyle: "solid"
                            }}>
                                <div className="position-relative">
                                    <span className="bx bx-search-alt" />
                                    <Input
                                        type="search"
                                        placeholder="Search"
                                        style={{ background: '#F7F9FC', color: "#858D9D" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Container fluid className="p-0">
                                <Row className="g-2">
                                    <Col xl={6}>
                                        <div
                                            className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center"
                                            onClick={() => {
                                                setUserModalOpen(true);
                                                getProfileApiCall();
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='bx bx-user' style={{ fontSize: 20 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>User Profile</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div
                                            className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center"
                                            onClick={() => setPasswordModalOpen(true)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='mdi mdi-lock-outline' style={{ fontSize: 20 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Change Password</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="g-2">
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='bx bx-toggle-left' style={{ fontSize: 25 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Privacy Settings</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='far fa-moon' style={{ fontSize: 21 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Dark Mode</h6>
                                            </div>
                                            <div className="form-check form-switch mb-3" >
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitch2"
                                                    checked={changeTheme}
                                                    // color='#000'
                                                    onChange={() => {
                                                        setChangeTheme(!changeTheme);
                                                    }}
                                                />
                                                {/* <Label check>Checked switch checkbox input</Label> */}
                                            </div>
                                            {/* <Input
                                                type="switch"
                                                checked={true}
                                            // onClick={() => {
                                            //     setState(!state);
                                            // }}
                                            /> */}
                                            {/* <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} /> */}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="g-2">
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='bx bx-id-card' style={{ fontSize: 25 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Contact Us</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='dripicons-information' style={{ fontSize: 20 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>About Us</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="g-2">
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='bx bx-bell' style={{ fontSize: 25 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Notification Settings</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                    <Col xl={6}>
                                        <div className="border p-3 rounded mt-4 d-flex justify-content-between flex-row align-items-center">
                                            <div className='d-flex justify-content-between flex-row align-items-center'>
                                                <h6 style={{ marginTop: 7 }}><i className='far fa-list-alt' style={{ fontSize: 24 }} /></h6>
                                                <h6 style={{ marginTop: 7, marginLeft: 10, fontSize: 16 }}>Master Audit Trails</h6>
                                            </div>
                                            <i className='mdi mdi-chevron-right' style={{ fontSize: 25 }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </CardBody>
                </Card>
            </div >
        </React.Fragment >
    )
}

export default Settings