import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'
import { Images } from '../../../constants/images'
import ColumnChart from './ColumnChart'
import { CardHeader } from 'react-bootstrap'
import BarChart from './BarChart'
import DonutChart from './DonutChart'
import * as url from '../../../helpers/url_helper';
import Request from '../../../helpers/request';
import Select from 'react-select';

const UserDeviceDashboard = () => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<any[]>([

    ])
    const [modelWiseCount, setModelWiseCount] = useState<any[]>([]);
    const [firmwareWiseCount, setFirmWareWiseCount] = useState<any[]>([]);
    const [modelWiseCountDropdown, setModelWiseCountDropDown] = useState<any[]>([]);
    const [firmwareWiseCountDropdown, setFirmwareWiseCountDropDown] = useState<any[]>([]);
    const [hardwareWiseCount, setHardwareWiseCount] = useState<any[]>([]);
    const [selectedModelOption, setSelectedModelOption] = useState<any>("");
    const [selectedFirmwareOption, setSelectedFirmwareOption] = useState<any>("");
    const [locationMap, setlocationMap] = useState([]);
    const [applicationCount, setApplicationCount] = useState<any>([]);

    console.log("selectedModelOption", selectedModelOption, modelWiseCount);

    const getUserDeviceDataApiCall = () => {
        setLoading(true);

        Request.get(url.GET_USER_DEVICE_DATA).
            then((res: any) => {
                console.log("get requested dashboard Response", res);
                setlocationMap(res?.data?.city)
                // setDashboardData(res.data.data);
                setUserData([
                    {
                        title: "Users",
                        image: Images.TotalUsers,
                        count: res?.data?.totalUsers
                    },
                    {
                        title: "User's Devices",
                        image: Images.TotalModels,
                        count: res?.data?.totalDevice
                    },
                    {
                        title: "Chips",
                        image: Images.TotalDashboardDevices,
                        count: res?.data?.totalChips
                    },

                    {
                        title: "Blasters",
                        image: Images.TotalModels,
                        count: res?.data?.totalBlaster
                    },
                ])
                setModelWiseCount(res?.data?.model)
                setFirmWareWiseCount(res?.data?.firmware)
                setSelectedModelOption({
                    label: res?.data?.model[0].hwVersion ? res?.data?.model[0].hwVersion : "Other",
                    value: res?.data?.model[0].hwVersion ? res?.data?.model[0].hwVersion : "Other"
                })
                setSelectedFirmwareOption({
                    label: res?.data?.model[0].hwVersion ? res?.data?.model[0].hwVersion : "Other",
                    value: res?.data?.model[0].hwVersion ? res?.data?.model[0].hwVersion : "Other"
                })
                setModelWiseCountDropDown(res?.data?.model?.map((item: any, index: number) => {
                    return ({
                        label: item.hwVersion ? item.hwVersion : "Other",
                        value: item.hwVersion
                    })
                }))
                setFirmwareWiseCountDropDown(res?.data?.model?.map((item: any, index: number) => {
                    return ({
                        label: item.hwVersion ? item.hwVersion : "Other",
                        value: item.hwVersion
                    })
                }))
                setHardwareWiseCount(res?.data?.hardware)
                setApplicationCount([
                    {
                        title: "Users",
                        total: res?.data?.totalUsers,
                        color: "#2CAFFE"
                    }, {
                        title: "Online",
                        total: res?.data?.totalOnlineUsers,
                        color: "#32D74B"
                    }, {
                        title: "User's Devices",
                        total: res?.data?.totalDevice,
                        color: "#2CAFFE"
                    }, {
                        title: "Online User's Device",
                        total: res?.data?.totalOnlineDevice,
                        color: "#32D74B"
                    }, {
                        title: "Notification User's Device",
                        total: res?.data?.totalNotificationDevice,
                        color: "#2CAFFE"
                    }, {
                        title: "Chips",
                        total: res?.data?.totalChips,
                        color: "#2CAFFE"
                    }, {
                        title: "Online Chips",
                        total: res?.data?.totalOnlineChips,
                        color: "#32D74B"
                    }
                ])
                setLoading(false)
            }).
            catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getUserDeviceDataApiCall()
    }, [])

    return (
        <div className="page-content">
            <Row>
                {
                    userData.map((userItem: any, index: number) => {
                        return (
                            <Col lg={3} key={index}>
                                <Card style={{ border: "1px solid #EAECF0" }} >
                                    <CardBody>
                                        <img src={userItem.image} className="float-start" style={{ height: 36, width: 36, marginRight: 10 }} />
                                        <h1 style={{ color: "#5D6679", fontWeight: 500, fontSize: 18 }}>
                                            {userItem.title}
                                        </h1>
                                        <p style={{ fontSize: 24, color: "#858D9D", marginLeft: 35 }}>{userItem.count}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row>
                <Col lg="8">
                    <Card>
                        <CardBody>
                            <div className='d-flex justify-content-between'>
                                <h1 style={{ fontSize: 16, fontWeight: 500 }}>By Model</h1>
                                <div style={{ width: '20%' }}>
                                    <Select
                                        // className='w-30'
                                        isSearchable={false}
                                        options={modelWiseCountDropdown}
                                        onChange={(e) => setSelectedModelOption(e)}
                                        value={selectedModelOption}
                                    />
                                </div>
                            </div>
                            <ColumnChart
                                modelData={modelWiseCount.filter((model) => model.hwVersion === selectedModelOption.value)}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4">
                    <Card>
                        <CardBody>
                            <h1 style={{ fontSize: 16, fontWeight: 500 }}>By Hardware version</h1>
                            <DonutChart
                                hardwareData={hardwareWiseCount}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <Card>
                        <CardBody>
                            <div className='d-flex justify-content-between'>
                                <h1 style={{ fontSize: 16, fontWeight: 500 }}>By Firmware</h1>
                                <div style={{ width: '20%' }}>
                                    <Select
                                        // className='w-30'
                                        isSearchable={false}
                                        options={firmwareWiseCountDropdown}
                                        onChange={(e) => setSelectedFirmwareOption(e)}
                                        value={selectedFirmwareOption}
                                    />
                                </div>
                            </div>
                            <ColumnChart
                                modelData={firmwareWiseCount.filter((model) => model.hwVersion === selectedFirmwareOption.value)}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4">
                    <Card>
                        <CardBody>
                            <h1 style={{ fontSize: 16, fontWeight: 500 }}>Overview</h1>
                            <BarChart
                                applicationCount={applicationCount}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                {/* <Col lg="6">
                    <Card>
                        <CardBody>
                            <h1 style={{ fontSize: 16, fontWeight: 500 }}>Application User Count</h1>
                            <BarChart
                                applicationCount={applicationCount}
                            />
                        </CardBody>
                    </Card>
                </Col> */}
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <CardTitle>Location Heat Map</CardTitle>
                            {/* <CardText> */}
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>City</th>
                                            <th>User Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {locationMap.map((item: any, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item?.city?.name ? item?.city?.name : "Other"}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* </CardText> */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div >
    )
}

export default UserDeviceDashboard