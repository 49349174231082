import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = () => {

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 1000)
    // }, [setLoading]);
    return (
        <React.Fragment>
            <Spinner color="dark" className='position-absolute top-50 start-50' />
            {/* <div
                id="overlay"
                style={{
                    display: 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9998, // Ensure the overlay is behind the loader
                }}
            ></div>
            <div
                id="customLoader"
                style={{
                    display: 'none',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                }}
            >
                <div
                    className="spinner"
                    style={{
                        border: '4px solid rgba(0, 0, 0, 0.1)',
                        borderTop: '4px solid #3498db',
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        animation: 'spin 1s linear infinite',
                        margin: '0 auto',
                    }}
                ></div>
                <p>Loading...</p>
                {/* <p>Loading...</p> */}
            {/* </div> */}
        </React.Fragment>
    )
}

export default Spinners;