// import React, { useEffect, useState } from 'react';
// import ReactECharts from 'echarts-for-react';
// import { retry } from 'redux-saga/effects';
// // import accessibility from 'highcharts/modules/accessibility';

// const ColumnChart = ({ modelData }: any) => {
//     // Sample data for the chart
//     console.log("modelData", modelData);

//     const [chartData, setChartData] = useState({
//         title: {
//             text: ''
//         },
//         tooltip: {},
//         xAxis: {
//             data: []
//         },
//         // yAxis: {
//         //     max: maxDataValue
//         // },
//         series: [{
//             name: 'Quantity',
//             type: 'bar',
//             data: [],
//             barWidth: '15%',
//             itemStyle: {
//                 // Specify the style for each column
//                 opacity: 0.7,     // Column opacity
//                 borderWidth: 2,   // Border width
//                 outerWidth: 2,
//                 // barBorderRadius: 50
//             }
//         }],
//     })

//     // const [data, setData] = useState({
//     //     categories: [],
//     //     data: []
//     // })
//     const data = {
//         categories: ['2M', '3M', '4M', '5M', '6M', '7M', '8M', '9M', '10M', '11M'],
//         data: [30, 50, 40, 60, 60, 50, 40, 70, 22, 30, 60]
//     };

//     // useEffect(() => {
//     //     if (modelData !== undefined) {
//     //         const categories = modelData.model.map((item: any, index: number) => {
//     //             return (
//     //                 item.modelType
//     //             )
//     //         })
//     //         const count = modelData.model.map((item: any, index: number) => {
//     //             return (
//     //                 item.total
//     //             )
//     //         })
//     //         console.log("categories", categories);

//     //         setChartData((options: any) => {
//     //             return {
//     //                 ...options,
//     //                 xAxis: {
//     //                     ...options.xAxis,
//     //                     data: categories
//     //                 },
//     //                 series: [
//     //                     {
//     //                         name: 'Quantity',
//     //                         type: 'bar',
//     //                         data: count,
//     //                         barWidth: '15%',
//     //                         itemStyle: {
//     //                             // Specify the style for each column
//     //                             opacity: 0.7,     // Column opacity
//     //                             borderWidth: 2,   // Border width
//     //                             outerWidth: 2,
//     //                             // barBorderRadius: 50
//     //                         }
//     //                     }
//     //                 ]
//     //             }
//     //         })
//     //         // setData(modelData?.model?.map((item: any, index: any) => {
//     //         //     return ({
//     //         //         categories: [...data.categories, item.modelType],
//     //         //         data: [...data.data, item?.total]
//     //         //     })
//     //         // }))
//     //     }
//     // }, [modelData])

//     // Chart options
//     // accessibility(Highcharts);

//     const maxDataValue = Math.max(...data.data);
//     const options = {
//         title: {
//             text: ''
//         },
//         tooltip: {},
//         xAxis: {
//             data: data.categories
//         },
//         yAxis: {
//             max: maxDataValue
//         },
//         series: [{
//             name: 'Quantity',
//             type: 'bar',
//             data: data.data,
//             barWidth: '15%',
//             itemStyle: {
//                 // Specify the style for each column
//                 opacity: 0.7,     // Column opacity
//                 borderWidth: 2,   // Border width
//                 outerWidth: 2,
//                 // barBorderRadius: 50
//             }
//         }],
//         // accessibility: {
//         //     enabled: true
//         // }
//     };

//     return (
//         <ReactECharts
//             option={options}
//             style={{ height: '400px', width: '100%' }}
//         />
//     );
// };

// export default ColumnChart;


import React, { useEffect, useState } from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ColumnChart = ({ modelData }: any) => {
    // Sample data
    console.log("modelDatamodelData", modelData);

    const [data, setData] = useState([
        ['',],
    ]);

    useEffect(() => {
        if (modelData.length > 0) {
            setData(modelData[0]?.model ? modelData[0]?.model?.map((item: any, index: number) => {
                return (
                    [item.modelType, Number(item.total), Number(item.onlineCount)]
                )
            }) : modelData[0]?.wifiVersion?.map((item: any, index: number) => {
                return (
                    [item.wifiVersion, Number(item.total), Number(item.onlineCount)]
                )
            })
            )
        }
    }, [modelData])
    // Highcharts configuration options
    const options = {
        color: ["#2CAFFE", "#32D74B"],
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: data.map(item => item[0]),
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high'
            },
            labels: {
                // overflow: 'justify'
            }
        },
        // tooltip: {
        //     valueSuffix: ' fruits'
        // },
        plotOptions: {
            column: {
                size: 10,
                dataLabels: {
                    enabled: true
                },
                pointWidth: 10,
                borderRadious: 10,
                groupPadding: 0.2,
                pointPadding: 0,
                borderWidth: 0,
                maxPointWidth: 50, // Max width of each column
                borderColor: '#0000FF' // Border color for the columns
            }
        },
        // legend: {
        //     layout: 'vertical',
        //     align: 'right',
        //     verticalAlign: 'bottom',
        //     x: 40,
        //     y: -80,
        //     floating: true,
        //     borderWidth: 1,
        //     backgroundColor: '#FFFFFF',
        //     shadow: true
        // },
        credits: {
            enabled: false
        },
        series: [
            {
                // showInLegend: false,
                name: "Total",
                data: data.map(item => item[1]),
                color: "#2CAFFE"
            },
            {
                // showInLegend: false,
                name: "Online",
                data: data.map(item => item[2]),
                color: "#32D74B"
            },
        ]
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};

export default ColumnChart;

