import React, { useState } from 'react';
// import FirmwareDataTable from './FirmwareDataTable';
import * as url from "../../../helpers/url_helper";
import { Images } from '../../../constants/images';
import { convertLocalDate } from '../../../Utils/configController';
import FirmwareDataTable from '../Firmware/FirmwareDataTable';
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

const TestingList = () => {
    const navigate = useNavigate();

    const [dataTableId, setDataTableId] = useState(null);
    const [isOpenAddTestingModel, setOpenAddTestingModel] = useState(false);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Testing',
            render: function (data: any, type: any, row: any) {
                return row?.userLable ? `${row?.userLable}` : "-";
            }
        },
        {
            title: 'Hardware Version',
            render: function (data: any, type: any, row: any) {
                return row?.hardwareId?.hardwareVersion ? `${row?.hardwareId?.hardwareVersion}` : "-";
            }
        },
        {
            title: 'Software Version',
            render: function (data: any, type: any, row: any) {
                return row?.firmwareId?.firmwareVersion ? `${row?.firmwareId?.firmwareVersion}` : "-";
            }
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge =
                    row?.status === 0 ? (
                        '<span class="font-size-11 badge-soft-danger badge bg-secondary">Pending</span>'
                    ) : row?.status === 1 ? (
                        '<span class="font-size-11 badge-soft-info badge bg-secondary">In Progress</span>'
                    ) : row?.status === 2 && (
                        '<span class="font-size-11  badge-soft-success badge bg-secondary">Completed</span>'
                    );

                return statusBadge;
            }
        },
        {
            title: 'Passed',
            render: function (data: any, type: any, row: any) {
                return row?.scheduledDateTime ? convertLocalDate(row?.scheduledDateTime) : "-";
            }
        },
        {
            title: 'Failed',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? convertLocalDate(row?.createdAt) : "-";
            }
        },
        {
            title: 'Detail',
            render: function (data: any, type: any, row: any) {
                return `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Details</span></button>
                            </div>`
            }
        },
    ]

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const toggleAddTestingModel = () => {
        setOpenAddTestingModel(!isOpenAddTestingModel)
        validation.resetForm({
            values: {
                testing: "",
                HW: "",
                SF: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const validation = useFormik({
        initialValues: {
            testing: "",
            HW: "",
            SF: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            testing: Yup.string()
                .required("Please provide your testing "),
            HW: Yup.string()
                .required("Please provide your hardware version "),
            SF: Yup.string()
                .required("Please provide your software version "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            // addSlaveApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const handleNavigateDetails = (rowData: any) => {
        console.log("handleNavigateDetails", rowData);
        navigate("/testing/details-of-testing/asfds")
    }

    return (
        <>
            <Modal
                isOpen={isOpenAddTestingModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleAddTestingModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>
                        Add Testing
                        {/* {isEditable ? "Edit Area" : "Add Area"} */}
                    </ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Slave</Label>
                                <Input
                                    value={validation.values.testing || ""}
                                    name="testing"
                                    type={"text"}
                                    placeholder="Testing"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.testing && validation.errors.testing ? true : false
                                    }
                                />
                                {validation.touched.testing && validation.errors.testing ? (
                                    <span className="error">{validation.errors.testing}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Hardware Version</Label>
                                <Input
                                    value={validation.values.HW || ""}
                                    name="HW"
                                    type={"text"}
                                    placeholder="Hardware Version"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.HW && validation.errors.HW ? true : false
                                    }
                                />
                                {validation.touched.HW && validation.errors.HW ? (
                                    <span className="error">{validation.errors.HW}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Software Version</Label>
                                <Input
                                    value={validation.values.SF || ""}
                                    name="SF"
                                    type={"text"}
                                    placeholder="Software Version"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.SF && validation.errors.SF ? true : false
                                    }
                                />
                                {validation.touched.SF && validation.errors.SF ? (
                                    <span className="error">{validation.errors.SF}</span>
                                ) : null}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"} */}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            <FirmwareDataTable
                apiURL={`${url.BASE_URL}${url.PUSH_FIRMWARE_LIST}`}
                columns={columns}
                title={"Testing List"}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"TestingList"}
                btnName='Add Testing'
                btnStyle="btn btn-dark"
                btnClick={() => toggleAddTestingModel()}
                handleEditClick={handleNavigateDetails}
            />
        </>
    )
}

export default TestingList