import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import DataTable from '../../components/Common/DataTable';
import $ from 'jquery';
import * as url from '../../helpers/url_helper';
import { convertLocalDate } from '../../Utils/configController';

const SchedulesLogs = () => {
    const [dataTableId, setDataTableId] = useState(null);
    const [loading, setLoading] = useState(false);

    const optionGroup = [{
        options: [
            { label: "First Name / Last Name", value: 0 },
            { label: "Mobile", value: 1 },
            { label: "Email", value: 2 },
            { label: "Slave ID", value: 3 },
        ]
    }]

    const columns = [
        {
            title: 'User Details',
            render: function (data: any, type: any, row: any) {
                const joinArray = [row?.userId?.mobile, row.userId.email].filter(Boolean)
                return `
                <div>
                  <div style="color: black; fontweight: bold">${row.userId.
                        firstname} ${row.userId.lastname}</div>
                  <div>${joinArray.join(" | ")}</div>
                </div>`;
            }
        },
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return row?.slave
                    ? `${row?.slave}` : "-"
            }
        },
        {
            title: 'Button',
            render: function (data: any, type: any, row: any) {
                return row?.button ? row?.button : "-"
            }
        },
        {
            title: 'Button Value',
            render: function (data: any, type: any, row: any) {
                return row?.executeValue ? row?.executeValue : "-"
            }
        },
        {
            title: 'Date & Time',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? convertLocalDate(row?.createdAt) : "-"
            }
        },
        {
            title: 'Response',
            render: function (data: any, type: any, row: any) {
                const response = row?.chipResponse ? row?.chipResponse : "-"
                return `<div class="responseColumn">${response}</div>`
            },
            width: 50
        },
    ]

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <div>
            <ToastContainer />
            <DataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.SCHEDULES_LIST}`}
                columns={columns}
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="Send Notification"
                btnStyle="btn btn-dark"
                // onClickBtn={toggleNotification}
                // handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"SendNotification"}
                showFilterOpetions={true}
                optionGroup={optionGroup}
            />
        </div>
    )
}

export default SchedulesLogs