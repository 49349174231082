
export const BASE_URL = "https://data.whitelion.in";
export const IMAGE_URL = "https://data.whitelion.in/s/avatar/"
const apiVersion = 1;

//LOGIN
export const SEND_OTP = `/admin-api/v${apiVersion}/user/send-otp`;
export const VERIFY_OTP = `/admin-api/v${apiVersion}/user/verify-otp`;

// LOGOUT
export const LOGOUT = `/admin-api/v${apiVersion}/user/logout`;

// AREA
export const GET_AREA_LIST_URL = `/admin-api/v${apiVersion}/city/list-area`;
export const GET_SEARCH_CITY_URL = `/admin-api/v${apiVersion}/city/search`;
export const ADD_AREA_URL = `/admin-api/v${apiVersion}/city/add-area`;
export const EDIT_AREA_URL = `/admin-api/v${apiVersion}/city/edit-area`;
export const DELETE_AREA_URL = `/admin-api/v${apiVersion}/city/delete-area`;

// CONTROL_PANEL
export const GET_ROOM_LIST_URL = `/admin-api/v${apiVersion}/room/list`;
export const GET_CONTROL_ROOM_LIST_URL = `/admin-api/v${apiVersion}/room/control-list`;
export const GET_SWITCH_LIST_URL = `/admin-api/v${apiVersion}/room/switches`;
export const REMOVE_GUEST_URL = `/admin-api/v${apiVersion}/room/remove-allow-room`;

// SUB_ADMIN
export const GET_SUB_ADMIN_URL = `/admin-api/v${apiVersion}/user/list-sub-admin`;
export const ADD_SUB_ADMIN_URL = `/admin-api/v${apiVersion}/user/add-sub-admin`;
export const EDIT_SUB_ADMIN_URL = `/admin-api/v${apiVersion}/user/update-sub-admin`;
export const DELETE_SUB_ADMIN_URL = `/admin-api/v${apiVersion}/user/delete-sub-admin`;
export const REMOVE_ACCESS_URL = `/admin-api/v${apiVersion}/user/remove-user-access`;

// ROLE_MANAGEMENT
export const GET_ROLE_LIST_URL = `/admin-api/v${apiVersion}/role/list`;
export const ADD_ROLE_URL = `/admin-api/v${apiVersion}/role/add`;
export const EDIT_ROLE_URL = `/admin-api/v${apiVersion}/role/update`;
export const DELETE_ROLE_URL = `/admin-api/v${apiVersion}/role/delete`;
export const GET_ROLE_PERMISSION_LIST = `/admin-api/v${apiVersion}/role/get`;
export const TAG_PERMISSION_LIST = `/admin-api/v${apiVersion}/role/tag-permission`;
export const MENU_PERMISSION_LIST = `/admin-api/v${apiVersion}/role/menu-permission`;

// TAG_MANAGEMENT
export const GET_TAG_LIST_URL = `/admin-api/v${apiVersion}/tag/list`;
export const ADD_TAG_URL = `/admin-api/v${apiVersion}/tag/add`;
export const UNASSIGN_LIST_API_URL = `/admin-api/v${apiVersion}/room/unassigned`;
export const GET_AREA_ROOMS_URL = `/admin-api/v${apiVersion}/room/assigned`
export const ASSIGN_AREA_ROOMS_URL = `/admin-api/v${apiVersion}/room/assign-tag`
export const GET_SEARCH_TAG_URL = `/admin-api/v${apiVersion}/tag/search`
export const UPDATE_TAG_URL = `/admin-api/v${apiVersion}/tag/update`
export const DELETE_TAG_URL = `/admin-api/v${apiVersion}/tag/delete`

// SERVICE PERSON
export const GET_SERVICE_PERSON_URL = `/admin-api/v${apiVersion}/user/list-service-person`;
export const ADD_SERVICE_PERSON_URL = `/admin-api/v${apiVersion}/user/add-service-person`;
export const ALL_PREMISES_SEARCH_URL = `/admin-api/v${apiVersion}/premise/search-all`;
export const UPDATE_SERVICE_PERSON_URL = `/admin-api/v${apiVersion}/user/update-service-person`;
export const DELETE_SERVICE_PERSON_URL = `/admin-api/v${apiVersion}/user/delete-service-person`;
export const ACCESS_PREMISES_LIST_URL = `/admin-api/v${apiVersion}/user/list-user-access`;

// PREMISES
export const GET_PREMISE_LIST_URL = `/admin-api/v${apiVersion}/premise/list`;
export const ADD_PREMISE_URL = `/admin-api/v${apiVersion}/premise/add`;
export const ASSIGN_PREMISE_URL = `/admin-api/v${apiVersion}/user/assign-premise`;
export const GET_SEARCH_USER_URL = `/admin-api/v${apiVersion}/user/search`;
export const GET_SEARCH_AREA_URL = `/admin-api/v${apiVersion}/city/search-area`;
export const GET_SEARCH_PREMISE_URL = `/admin-api/v${apiVersion}/premise/search`;
export const GET_SEARCH_STATE_URL = `/admin-api/v${apiVersion}/city/search-state`;
export const GET_SEARCH_CITY_FROM_STATE_URL = `/admin-api/v${apiVersion}/city/search-city-from-state`;
export const SELECT_PREMISE_URL = `/admin-api/v${apiVersion}/premise/select-premise`;

// USERS
export const GET_USERS_DETAIL_URL = `/admin-api/v${apiVersion}/user/list-users`;
export const DELETE_USER = `/admin-api/v${apiVersion}/user/delete-user`;

// PROFILE_LIST
export const GET_PROFILE_LIST_DETAILS = `/admin-api/v${apiVersion}/user/detail`;
export const GET_CHIPS_LIST = `/admin-api/v${apiVersion}/user/chips`;
export const DELETE_CHIP = `/admin-api/v${apiVersion}/user/delete-user-chip`;
export const GET_BLASTER_LIST = `/admin-api/v${apiVersion}/user/blasters`;
export const DELETE_USER_BLASTER = `/admin-api/v${apiVersion}/user/delete-user-blaster`;
export const GET_SCHEDULE_LIST = `/admin-api/v${apiVersion}/user/schedules`;
export const GET_DEVICE_LIST = `/admin-api/v${apiVersion}/user/devices`;
export const GET_CHIP_LOG_LIST = `/admin-api/v${apiVersion}/user/chip-logs`;
export const GET_USER_ACTION_LIST = `/admin-api/v${apiVersion}/user/user-action`;

// HOTEL_USERS
export const GET_HOTEL_USERS_LIST_URL = `/admin-api/v${apiVersion}/user/list-hotel-user`;
export const ADD_HOTEL_USER_URL = `/admin-api/v${apiVersion}/user/add-hotel-user`;
export const UPDATE_HOTEL_USER_URL = `/admin-api/v${apiVersion}/user/update-hotel-user`;
export const REMOVE_HOTEL_USER_ROLE_PERMISSION = `/admin-api/v${apiVersion}/user/remove-hotel-user-role-access`;
export const DELETE_HOTEL_USER_URL = `/admin-api/v${apiVersion}/user/delete-hotel-user`;

// SETTINGS
export const GET_PROFILE_URL = `/admin-api/v${apiVersion}/user/profile`;
export const UPDATE_PROFILE_URL = `/admin-api/v${apiVersion}/user/update-profile`;

// DASHBOARD
export const GET_DASHBOARD = `/admin-api/v${apiVersion}/room/get-dashboard`;
export const ALLOW_ROOM = `/admin-api/v${apiVersion}/room/allow-room`;
export const UPDATE_ROOM = `/admin-api/v${apiVersion}/room/update-allow-room`;
export const SEARCH_GUEST_NAME_URL = `/admin-api/v${apiVersion}/room/search-guest`;
export const GET_ALLOW_ROOM_LIST_URL = `/admin-api/v${apiVersion}/room/list-for-allow`;

// ADMIN-DASHBOARD
export const GET_REAL_TIME_DATA = `/admin-api/v${apiVersion}/user/real-time-data`
export const GET_ONLINE_CHIP_DATA = `/admin-api/v${apiVersion}/user/online-chips`
export const GET_OFFLINE_CHIP_DATA = `/admin-api/v${apiVersion}/user/offline-chips`
export const GET_MQTT_DATA = `/admin-api/v${apiVersion}/user/mqtt-count`

// REQUESTED-DASHBOARD
export const GET_USER_DEVICE_DATA = `/admin-api/v${apiVersion}/user/user-device-data`

// LOGS
export const SEARCH_WEB_USER_URL = `/admin-api/v${apiVersion}/user/search-web-user`

// HOTEL USER APP
export const GET_HOTEL_USER_PERMISSIONS = `/admin-api/v${apiVersion}/user/get-permission`;

// STATIC-OTP
export const GET_STATIC_OTP_LIST = `/admin-api/v${apiVersion}/otp/list`;
export const ADD_STATIC_OTP = `/admin-api/v${apiVersion}/otp/add`;
export const DELETE_STATIC_OTP = `/admin-api/v${apiVersion}/otp/delete`;

// SEND-NOTIFICATION
export const SENT_NOTIFICATION_LIST = `/admin-api/v${apiVersion}/notification/list`;
export const SENT_NOTIFICATION = `/admin-api/v${apiVersion}/notification/send`;

// FIRMWARE
export const FIRMWARE_LIST = `/admin-api/v${apiVersion}/hardware/firmware-list`;
export const GET_HARDWARE_LIST = `/admin-api/v${apiVersion}/hardware/list`;
export const UPLOAD_BIN_FILE = `/admin-api/v${apiVersion}/hardware/update-bin`;
export const SAVE_FIRMWARE = `/admin-api/v${apiVersion}/hardware/save-firmware`;
export const PUSH_FIRMWARE_LIST = `/admin-api/v${apiVersion}/hardware/release-firmware-list`;
export const SEARCH_FIRMWARE_LIST = `/admin-api/v${apiVersion}/hardware/firmware-search`;
export const PUSH_FIRMWARE = `/admin-api/v${apiVersion}/hardware/release-firmware`;

// SCHEDUlEL-LOGS
export const SCHEDULES_LIST = `/admin-api/v${apiVersion}/user/schedules-logs`;

// DEBUG-DEVICE
export const GET_DEBUG_LIST = `/admin-api/v${apiVersion}/debug/list`;
export const ADD_DEBUG_CHIP = `/admin-api/v${apiVersion}/debug/add`;
export const DELETE_DEBUG_CHIP = `/admin-api/v${apiVersion}/debug/delete`;
export const DEBUG_DETAIL_LIST = `/admin-api/v${apiVersion}/debug/detail`;

// GUEST MANAGEMENT
export const GUEST_LIST_URL = `/admin-api/v${apiVersion}/room/guest-list`;
export const ADD_GUEST_URL = `/admin-api/v${apiVersion}/room/add-guest`;

// GUEST HISTORY
export const GUEST_HISTORY_LIST_URL = `/admin-api/v${apiVersion}/room/guest-history`;

// FEEDBACK
export const GUEST_FEEDBACK_URL = `/admin-api/v${apiVersion}/feedback/list`;
export const CHANGE_STATUS_URL = `/admin-api/v${apiVersion}/feedback/change-status`;

// BACKUP
export const GET_BACKUP_LIST_URL = `/admin-api/v${apiVersion}/backup/list`;
export const GET_BACKUP_RESTORE_URL = `/admin-api/v${apiVersion}/backup/restore`;
