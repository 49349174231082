import Request from '../helpers/request';
import * as url from '../helpers/url_helper';
import $ from 'jquery'
import getStorageData from './getStorageData';
import { useDispatch } from 'react-redux';

const token = localStorage.getItem('Authorization');
//console.log("search api call token", token);
const areaSearchApiCall = (e, setAreaData) => {
    //console.log("search Api Call", e);
    const value = !e ? "a" : e
    Request.get(`${url.GET_SEARCH_AREA_URL}?keyword=${value}`).
        then((res) => {
            //  console.log("search area Response", res);
            var area = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                area.push({ label: ele.name, value: ele.id });
                // setCityData(data);
            }
            //console.log("datadatadata", area);
            setAreaData(area);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const userSearchApiCall = (e, setUserData, type, isRemoveAllUsers) => {
    // console.log("search Api Call", type);
    const value = !e ? "a" : e
    Request.get(`${url.GET_SEARCH_USER_URL}?keyword=${value}&type=${type}`).
        then((res) => {
            //  console.log("search user Response", res);
            var user = [];
            if (type === 3 && !isRemoveAllUsers) {
                user.push({ label: "All User", value: "all" })
            } else {
                user = []
            }
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);

                user.push({
                    label: `
                    ${ele.firstname} ${ele.lastname} | 
                    ${ele.email ? `${ele.email} ${ele?.mobile ? "|" : ""} ` : ""}  
                    ${ele?.mobile ? `${ele?.mobile}` : ""}`,
                    value: ele.id
                });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setUserData(user);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const premiseSearchApiCall = (e, setPremisesNameList) => {
    // console.log("search Api Call", e);
    // const value = !e ? "a" : e
    const value = e ? `?keyword=${e}` : `?keyword=`;
    Request.get(`${url.GET_SEARCH_PREMISE_URL}${value}`).
        then((res) => {
            // console.log("search premise Response", res);
            var premises = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                premises.push({ label: `${ele.firstname} ${ele.lastname} | ${ele.email} `, value: ele.id });
                // setCityData(data);
            }
            //console.log("datadatadata", premises);
            setPremisesNameList(premises);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const citySearchApiCall = (e, setCityData) => {
    // console.log("search Api Call", e);
    const value = !e ? "a" : e
    Request.get(`${url.GET_SEARCH_CITY_URL}?keyword=${value}`).
        then((res) => {
            console.log("search city Response", res);
            var city = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                console.log("ele", ele);
                city.push({ label: ele.name, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", city);
            setCityData(city);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const tagSearchApiCall = (e, setTagAreaData) => {
    // console.log("search Api Call", e);
    const value = !e ? "a" : e
    Request.get(`${url.GET_SEARCH_TAG_URL}?premiseId=${getStorageData().selectedPremiseID}&keyword=${value}`).
        then((res) => {
            console.log("search tag Response", res);
            var tag = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                tag.push({ label: ele.name, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setTagAreaData(tag);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const allPremisesSearchApiCall = (e, setPremisesNameList) => {
    // console.log("search Api Call", e);
    const value = !e ? "a" : e
    Request.get(`${url.ALL_PREMISES_SEARCH_URL}?keyword=${value}`).
        then((res) => {
            console.log("search tag Response", res);
            var allPremisesName = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                allPremisesName.push({ label: `${ele.firstname} ${ele.lastname} ${ele.email ? `| ${ele.email}` : ""}`, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setPremisesNameList(allPremisesName);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const allRoomSearchApiCall = (e, setRoomNumberList) => {
    const reqObj = {
        length: 10,
        premiseId: getStorageData().selectedPremiseID,
        start: 0,
        search: { value: e, regex: false }
    }

    Request.post(`${url.GET_ROOM_LIST_URL}`, reqObj).
        then((res) => {
            console.log("search room Response", res);
            var allRoomName = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                allRoomName.push({ label: `${ele.name}(${ele.guestStatus == 0 ? "Available" : "Occupied"})`, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setRoomNumberList(allRoomName);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const allowRoomSearchApiCall = (e, setRoomNumberList) => {
    const reqObj = {
        length: 10,
        premiseId: getStorageData().selectedPremiseID,
        start: 0,
        search: { value: e, regex: false }
    }

    Request.post(`${url.GET_ALLOW_ROOM_LIST_URL}`, reqObj).
        then((res) => {
            console.log("search room Response", res);
            var allRoomName = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                allRoomName.push({ label: `${ele.name}(${ele.guestStatus == 0 ? "Available" : "Occupied"})`, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setRoomNumberList(allRoomName);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const hardwareListApiCall = (setHardwareList) => {
    Request.get(`${url.GET_HARDWARE_LIST}`).
        then((res) => {
            console.log("search hardware Response", res);
            var allHardwareName = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                allHardwareName.push({ label: `${ele.name}(${ele.hardwareVersion})`, value: ele.id });
            }
            setHardwareList(allHardwareName);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const firmwareSearchApiCall = (hardwareId, e, setFirmwareList) => {
    Request.get(`${url.SEARCH_FIRMWARE_LIST}?hardwareId=${hardwareId}&keyword=${e}`).
        then((res) => {
            console.log("search room Response", res);
            var allFirmware = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                allFirmware.push({ label: `${ele.firmwareVersion}(${ele.isStable ? "Stable" : "Test"})`, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setFirmwareList(allFirmware);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const stateSearchApiCall = (e, setStateList) => {
    const value = e ? e : "all";
    Request.get(`${url.GET_SEARCH_STATE_URL}?keyword=${value}`).
        then((res) => {
            console.log("search state Response", res);
            var stateData = [];
            stateData.push({ label: "All", value: "all" })
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                stateData.push({ label: ele.name, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setStateList(stateData);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const cityFromStateSearch = (e, setCityList, searchStateValue) => {
    const value = e ? e : "a";
    Request.get(`${url.GET_SEARCH_CITY_FROM_STATE_URL}?keyword=${value}&stateId=${searchStateValue}`).
        then((res) => {
            console.log("search state Response", res);
            var cityData = [];
            cityData.push({ label: "All", value: "all" })
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                cityData.push({ label: ele.name, value: ele.id });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setCityList(cityData);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const guestNameSearchApiCall = (e, setGuestNameList) => {
    const value = e ? e : "a";
    Request.get(`${url.SEARCH_GUEST_NAME_URL}?keyword=${value}&premiseId=${getStorageData().selectedPremiseID}`).
        then((res) => {
            console.log("search state Response", res);
            var guestList = [];
            for (var i = 0; i < res.data?.data?.length; i++) {
                var ele = res.data.data[i]
                // console.log("ele", ele);
                guestList.push({
                    label: `
                    ${ele.name} | 
                    ${ele.email ? `${ele.email} ${ele?.mobile ? "|" : ""} ` : ""}  
                    ${ele?.mobile ? `${ele?.mobile}` : ""}`,
                    value: ele.id
                });
                // setCityData(data);
            }
            // console.log("datadatadata", user);
            setGuestNameList(guestList);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

const webUserSearchApiCall = (e, setWebUserList) => {
    const value = e ? e : "a";
    Request.get(`${url.SEARCH_WEB_USER_URL}?keyword=${value}`).
        then((res) => {
            console.log("search web user Response", res);
            var userList = [];
            userList.push({ label: "All", value: "all" })
            if (res.data?.data?.length > 0) {
                for (var i = 0; i < res.data?.data?.length; i++) {
                    var ele = res.data.data[i]
                    const combineKey = [
                        `${ele?.firstname} ${ele?.lastname}`,
                        ele.email,
                        ele?.mobile,
                        ele?.type === 0 ? "Admin" : ele?.type === 1 ? "Sub Admin" : ele?.type === 4 ? "Hotel User" : ""
                    ]
                    // console.log("ele", ele);
                    userList.push({
                        label: combineKey?.join(" | "),
                        value: ele.id
                    });
                }
            } else {
                userList.push({ label: "All", value: "all" })
            }
            // console.log("datadatadata", user);
            setWebUserList(userList);
        }).
        catch((err) => {
            console.error('Error fetching data:', err);
        })
}

export {
    areaSearchApiCall,
    userSearchApiCall,
    premiseSearchApiCall,
    citySearchApiCall,
    tagSearchApiCall,
    allPremisesSearchApiCall,
    allRoomSearchApiCall,
    hardwareListApiCall,
    firmwareSearchApiCall,
    stateSearchApiCall,
    cityFromStateSearch,
    guestNameSearchApiCall,
    allowRoomSearchApiCall,
    webUserSearchApiCall
}