import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { convertLocalTime } from '../../../Utils/configController';
import moment from 'moment';
import accessibility from 'highcharts/modules/accessibility';


const RealTimeChart = ({ data }: any) => {
  // console.log(
  //   // "data=========>", data
  // );
  accessibility(Highcharts);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'line',
    },
    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: '', // Set yAxis title to empty string
      },
    },
    xAxis: {
      type: 'category',
      categories: [], // Initialize with empty categories
    },
    series: [{
      name: '',
      data: [],
      color: '#14C9C9',
      showInLegend: false
    }],
    credits: {
      enabled: false, // Disable Highcharts.com credits
    },
    accessibility: {
      enabled: true
    }
  });
  // console.log("chartOptions", chartOptions);

  useEffect(() => {
    const interval = setInterval(() => {
      setChartOptions((options: any) => {

        const newTime = moment().format('HH:mm:ss');
        const updatedSeries = [newTime, data]; // Static y-value of 10
        // console.log("updatedSeries", updatedSeries);
        // const newSeriesData = [...options.series[0].data];
        // const newCategories = [...options.xAxis.categories, newTime];
        const newCategories = [...options.xAxis.categories, newTime]; // Add new time to categories
        const newSeriesData = [...options.series[0].data, Number(data)];
        // console.log("newSeriesData", newSeriesData);

        // if (newSeriesData.length > 6) {
        //   newSeriesData.shift()
        //   newCategories.shift();
        // } else {
        //   // Remove the first element
        //   newSeriesData.push([newTime, Number(data)]);
        //   newCategories.push(newTime)
        // }
        const categories = newCategories.slice(-6);
        const seriesData = newSeriesData.slice(-6);

        return {
          ...options,
          yAxis: {
            min: 0, // Optional, set the minimum value
            max: 100, // Optional, set the maximum value
            tickInterval: 10, // Set the interval between ticks
            // Define the specific tick positions
            tickPositions: [0,25, 50, 75, 100],
          },
          xAxis: {
            ...options.xAxis,
            categories: categories,
          },
          series: [{
            ...options.series[0],
            data: seriesData,
          }],
        };
      });
    }, 1000); // Update every 1 second

    return () => clearInterval(interval); // Cleanup interval
  }, [data]);



  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

export default RealTimeChart;
