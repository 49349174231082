import DataTable from '../../../components/Common/DataTable';
import React, { useEffect, useState } from 'react'
import { Card } from 'reactstrap'
// import { BASE_URL, GET_ROOM_LIST_URL } from '../../../helpers/url_helper';
import getStorageData from '../../../Utils/getStorageData';
import { convertLocalDate } from '../../../Utils/configController';
import AllocateRoom from '../Dashboard/AllocateRoom';
import $ from 'jquery'
import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import Request from '../../../helpers/request';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as url from "../../../helpers/url_helper";
import { getMenuPermissions } from '../../../services/APIServices';
import { useDispatch } from 'react-redux';

const Rooms = () => {
    const dispatch = useDispatch();
    const [isOpenAllocateRoomModal, setOpenAllocateRoomModal] = useState<boolean>(false);
    const [allocateRoomData, setAllocateRoomData] = useState<any>();
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [loading, setLoading] = useState(false);
    const data = [
        {
            room_no: "001",
            guestname: "Eleanor Pena",
            checkIn: "2020-05-05 10:21:13",
            checkOut: "2020-05-05 10:21:13",
            roomStatus: "Booked"
        },
        {
            room_no: "002",
            guestname: "Eleanor Pena",
            checkIn: "2020-05-05 10:21:13",
            checkOut: "2020-05-05 10:21:13",
            roomStatus: "Booked"
        },
        {
            room_no: "003",
            guestname: "Eleanor Pena",
            checkIn: "2020-05-05 10:21:13",
            checkOut: "2020-05-05 10:21:13",
            roomStatus: "Booked"
        },
        {
            room_no: "004",
            guestname: "Eleanor Pena",
            checkIn: "2020-05-05 10:21:13",
            checkOut: "2020-05-05 10:21:13",
            roomStatus: "Booked"
        },
    ];

    const toggleRoomModal = () => {
        setIsEditable(!isEditable);
        setOpenAllocateRoomModal(!isOpenAllocateRoomModal);
    }

    const columns = [
        { data: 'name', title: 'Room No.' },
        {
            title: 'Guest Name',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.guestName ? row.guestName : "-"} </div>
                <div>${row?.guestEmail ? row.guestEmail : ""} ${row?.guestMobile ? "|" : ""} ${row?.guestMobile ? row?.guestMobile : ""} </div>
                </div>`
            },
        },
        {
            title: 'Check-in',
            render: function (data: any, type: any, row: any) {
                const convertLocal: any = row?.guestCheckIn ? convertLocalDate(row?.guestCheckIn) : ""
                return `<span>${row?.guestCheckIn ? convertLocal : "-"} </span>`
            }
        },
        {
            title: 'Check-out',
            render: function (data: any, type: any, row: any) {
                const convertLocal: any = row?.guestCheckOut ? convertLocalDate(row?.guestCheckOut) : ""
                return `<span>${row?.guestCheckOut ? convertLocal : "-"} </span>`
            }
        },
        {
            title: 'Room Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.guestStatus === 0 ? (
                    '<span  style="height: 25px; width: 60px; font-size: 14px; padding: 6px; color: green;">Available</span>'
                ) : row?.guestStatus === 1 ? (
                    '<span  style="height: 25px; width: 60px; font-size: 14px; padding: 6px; color: red;">Booked</span>'
                ) : "";

                return statusBadge;
            }
            // render: function (data: any, type: any, row: any) {
            //     return `<span>${row?.guestStatus ? row.guestStatus : "-"} </span>`
            // }
        },
        {
            title: 'Actions',
            render: function (data: any, type: any, row: any) {
                const editable = row?.guestStatus === 1 ? (
                    `<div class="d-flex gap-2">
                    <button class="edit-btn" style="border: none; outline: none; background: none;">
                        <i class="bx bxs-edit" style="font-size: 20px;"></i>
                    </button>
                    <button class="delete-btn" style="border: none; outline: none; background: none;">
                    <span class="badge bg-danger" style="height: 25px;font-size: 12px; padding: 6px;">Remove Guest</span>
                    </button>
                </div>`
                ) : ""
                return editable;
            }
        }
    ];

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        setIsEditable(true);
        setAllocateRoomData(rowData);
        setOpenAllocateRoomModal(true);
        // setAreaData([{ id: rowData.id, name: rowData.name }])
        // setAreaData([{ id: rowData.areaId, name: rowData.areaId.name }])
        // setAreaDetail(rowData)
        // setIsEditable(true)
        // setSubAdminModelOpen(true);
        // validation.setValues({
        //     adminId: rowData.id,
        //     firstname: rowData.firstname,
        //     lastname: rowData.lastname,
        //     email: rowData.email,
        //     mobile: rowData.mobile,
        //     // areaId: rowData.areaId,
        //     addSubAdminError: ""
        // })
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDeleteClick = (rowData: any) => {
        console.log("rowData", rowData);

        setOpenDeleteModal(!openDeleteModal);
        setDeleteData(rowData);
    }

    const removeGuestApiCall = () => {
        setLoading(true);
        const reqObj = {
            roomId: deleteData.id
        }
        Request.post(url.REMOVE_GUEST_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                toast.success(res.data.msg)
                reloadDataTable();
                setOpenDeleteModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    useEffect(() => {
        if (getStorageData().userType === 4) {
            getMenuPermissions("", dispatch)
        }
    }, [])

    return (
        <div>
            <ToastContainer />
            {openDeleteModal &&
                <ConfirmationModal
                    loading={loading}
                    headerTitle="Remove Guest"
                    text={`Are You Sure you want to Remove Guest ${deleteData.guestName}?`}
                    buttonText="Remove"
                    onClick={() => { removeGuestApiCall() }}
                    cancleBtnClick={() => setOpenDeleteModal(false)}
                />
            }
            {
                isOpenAllocateRoomModal &&
                <AllocateRoom
                    isOpenAllocateRoomModal={isOpenAllocateRoomModal}
                    toggleRoomModal={() => toggleRoomModal()}
                    allocateRoomData={allocateRoomData}
                    isEditable={isEditable}
                    reloadData={reloadDataTable}
                />
            }
            <DataTable
                apiURL={`${url.BASE_URL}${url.GET_ROOM_LIST_URL}`}
                paramsId={{ premiseId: getStorageData().selectedPremiseID }}
                columns={columns}
                isBtnShow={false}
                btnName="Allocate Room"
                btnStyle="btn btn-dark"
                onClickBtn={() => { }}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Rooms"}
            />
        </div>
    )
}

export default Rooms