import axios from './api';

class Request {
    baseURL;
    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.delete = this.delete.bind(this);
        this.put = this.put.bind(this);
    }

    get(url, qParams = {}) {
        let urlToHit = `${url}`;
        return axios.get(urlToHit, {
            params: qParams,
        })
    }

    post(url, body = {}) {
       // console.log("body", body);
        let urlToHit = `${url}`;
        return axios.post(urlToHit, body);
    }
    delete(url) {
        let urlToHit = `${url}`;
        return axios.delete(urlToHit);
    }
    put(url, body = {}) {
        let urlToHit = `${url}`;
        return axios.put(urlToHit, body);
    }
}

export default (new Request())
