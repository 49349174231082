import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

// img 
import logo from "../../assets/images/logo.png"

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={7}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay">
            </div>
            <div className="h-100 d-flex align-items-center justify-content-center ">
              <div className="p-5">
                <div className="row justify-content-center">
                  <div className="text-center"  >
                    <img src={logo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
