import React, { useState } from 'react';
import FirmwareDataTable from './FirmwareDataTable';
import * as url from "../../../helpers/url_helper";
import { Images } from '../../../constants/images';

const FirmwareVersionList = ({ setDataTableId }: any) => {
    // const [dataTableId, setDataTableId] = useState(null);
    const columns = [
        {
            title: 'Hardware',
            render: function (data: any, type: any, row: any) {
                return row?.name ? `${row?.name}(${row?.hardwareVersion})` : "-";
            }
        },
        {
            title: 'Firmware Version',
            render: function (data: any, type: any, row: any) {
                return row?.firmwareVersion ? `${row?.firmwareVersion}` : "-";
            }
        },
        {
            title: 'Is Stable?',
            render: function (data: any, type: any, row: any) {
                return row?.isStable ? '1' : "0";
            }
        },
        {
            title: 'Download',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;">
                                <img src="${Images.DownloadIcon}" alt="Download" style="width: 20px; height: 20px;">
                                </button>
                            </div>`
                );
            }
        }
    ]
    const handleDownloadClick = (rowData: any) => {
        console.log("Download rowData", rowData);
        const url = rowData.binFilePath.includes("http:") ? rowData.binFilePath.replace("http:", "https:") : rowData.binFilePath;
        console.log("url~~~~~~~~~~>", url);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'filename.bin'; // Set the desired filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <FirmwareDataTable
            // data={areaList}
            apiURL={`${url.BASE_URL}${url.FIRMWARE_LIST}`}
            columns={columns}
            title={"Firmware Version List"}
            handleEditClick={handleDownloadClick}
            onDataTableRefresh={handleDataTableIdChange}
            idName={"FirmwareVesrionList"}
        />
    )
}

export default FirmwareVersionList