// import { io } from 'socket.io-client';
// import getStorageData from './getStorageData';
// import { MY_CHIP_DATA } from '../helpers/socketEvent';

// // "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? "" : `http://api.whitelion.in:3334?token=${getStorageData().token}`;

// export const socket = io(URL);

// socket.on('connect', () => {
//     console.log('Connected to the server');
// });

// SocketContext.tsx
// SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { io } from 'socket.io-client'; // Import io function
import getStorageData from './getStorageData';
import { MY_CHIP_DATA } from '../helpers/socketEvent';
import { useDispatch } from 'react-redux';
import { getChipData } from '../store/slices/APIResponseSlice';

interface SocketContextProps {
    children: React.ReactNode;
}

const SocketContext = createContext<Socket | null>(null);

export const useSocket = (): Socket | null => {
    return useContext(SocketContext);
};

export const SocketProvider: React.FC<SocketContextProps> = ({ children }) => {
    const dispatch = useDispatch();
    const [socket, setSocket] = useState<Socket | any>(null);

    useEffect(() => {
        const newSocket: any = getStorageData().token ? io(`https://data.whitelion.in/?token=${getStorageData().token}`, {
            reconnection: true, // Enable reconnection
            reconnectionDelay: 1000,
        }) as Socket : ""
        console.log("newSocket", newSocket);

        if (newSocket) {
            newSocket.on('connect', () => {
                console.log('Connected to the server', newSocket);



                // Listen for MY_CHIP_DATA event once connected
                newSocket.on(MY_CHIP_DATA, (data: any) => {
                    console.log('Received MY_CHIP_DATA:', data);
                    dispatch(getChipData(data));
                });
            });

            // newSocket.on(MY_CHIP_DATA, (data: any) => {
            //     console.log('Received MY_CHIP_DATA:', data);
            //     dispatch(getChipData(data));
            // });

            setSocket(newSocket);
        }

        return () => {
            if (newSocket) {
                newSocket.disconnect();
            }
        };
    }, []);

    console.log("socket provider", socket);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};


