import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import $ from 'jquery';
import { ToastContainer } from 'react-bootstrap';
import { Alert, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Form, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from '../../../components/Common/DataTable';
import * as url from '../../../helpers/url_helper'
import { Images } from '../../../constants/images';
import Select from "react-select";
import { firmwareSearchApiCall, hardwareListApiCall, userSearchApiCall } from '../../../Utils/searchApiCall';
import Request from '../../../helpers/request';
import { toast } from 'react-toastify';
import FirmwareDataTable from './FirmwareDataTable';
import classNames from 'classnames';
import FirmwareVersionList from './FirmwareVersionList';
import PushFirmwareList from './PushFirmwareList';
import { values } from 'lodash';
import { convertUTCDate } from '../../../Utils/configController';

const Firmware = () => {
    const [loading, setLoading] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [firmwareVersiondataTableId, setFirmwareVersionDataTableId] = useState(null);
    const [pushFirmwaredataTableId, setPushFirmwareDataTableId] = useState(null);
    const [openAddFirmwareModal, setOpenAddFirmwareModal] = useState(false);
    const [isOpenPushFirmwareModalOpen, seOpenPushFirmwareModalOpen] = useState<boolean>(false);
    const [hardwareList, setHardwareList] = useState([]);
    const [formwareList, setFirmwareList] = useState([]);
    const [userData, setUserData] = useState([]);
    const [customActiveTab, setcustomActiveTab] = useState<string>("1");
    console.log("pushFirmwaredataTableId", pushFirmwaredataTableId);

    const validation = useFormik({
        initialValues: {
            hardware: "",
            binFile: "",
            binFilePath: "",
            versionName: "",
            isStable: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            hardware: Yup.string().required("Please select hardware"),
            binFile: Yup.string().required("Please select bin file"),
            versionName: Yup.string()
                .required("Please provide version name"),
        }),
        onSubmit: (values) => {
            console.log("values", values);
            saveFirmwareApiCall(values);
            // addOTPApiCall(values);
            // addAreaApiCall(values)
        }
    })

    interface FormValues {
        userId: string;
        hardware: string;
        firmwareId: string;
        isScheduled: string;
        scheduledDateTime: string;
        formError: string;
    }

    const pushFirmwareValidation = useFormik<FormValues>({
        initialValues: {
            userId: "",
            hardware: "",
            firmwareId: "",
            isScheduled: "",
            scheduledDateTime: "",
            formError: ""
        },
        validate(values) {
            console.log("validationSchema", values);
            const errors: any = {};
            if (!values.userId) {
                errors.userId = 'Please select user';
            }
            if (!values.hardware) {
                errors.hardware = 'Please select hardware';
            }
            if (!values.firmwareId) {
                errors.firmwareId = 'Please select firmware';
            }
            if (values.isScheduled && !values.scheduledDateTime) {
                errors.scheduledDateTime = 'Please select scheduled date and time';
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log("values", values);
            pushFirmwareApiCall(values);
            // addOTPApiCall(values);
            // addAreaApiCall(values)
        }
    });

    // useEffect(() => {
    //     if (pushFirmwareValidation.values.scheduledDateTime) {
    //         const newDate = new Date(pushFirmwareValidation.values.scheduledDateTime)
    //         const date = convertUTCDate(newDate)
    //         console.log("converted date: ", date, pushFirmwareValidation.values.scheduledDateTime);

    //     }
    // }, [pushFirmwareValidation.values.scheduledDateTime])
    console.log("pushFirmwareValidation", pushFirmwareValidation.errors);

    const firmwareVesrionReloadDataTable = () => {
        if (firmwareVersiondataTableId) {
            const dataTableInstance: any = $(`#${firmwareVersiondataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };
    const pushFirmwareReloadDataTable = () => {

        if (pushFirmwaredataTableId) {
            const dataTableInstance: any = $(`#${pushFirmwaredataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    useEffect(() => {
        // if (pushFirmwaredataTableId) {
        const intervalId: any = setInterval(() => {
            pushFirmwareReloadDataTable();
        }, 3000)
        return () => clearInterval(intervalId);
        // }
    }, [pushFirmwaredataTableId])

    const toggleAddFirmware = () => {
        setOpenAddFirmwareModal(!openAddFirmwareModal);
        validation.resetForm({
            values: {
                hardware: "",
                binFile: "",
                binFilePath: "",
                versionName: "",
                isStable: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const togglePushFirmware = () => {
        seOpenPushFirmwareModalOpen(!isOpenPushFirmwareModalOpen);
        pushFirmwareValidation.resetForm({
            values: {
                userId: "",
                hardware: "",
                firmwareId: "",
                isScheduled: "",
                scheduledDateTime: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }
    const customStyles: CustomStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
        // control: (base: any) => ({
        //   ...base,
        //   borderColor: invalid ? 'red' : base.borderColor,
        // }),
    };

    useEffect(() => {
        hardwareListApiCall(setHardwareList)
    }, [])

    const userSearch = (e: any) => {
        userSearchApiCall(e, setUserData, 3)
    }

    const firmwareSearch = (e: any) => {
        firmwareSearchApiCall(pushFirmwareValidation.values.hardware, e, setFirmwareList)
    }

    const handleFileChange = async (event: any, setFieldValue: any) => {
        const file = event.currentTarget.files[0];
        console.log("file", file);

        setFieldValue("binFile", event.target.value)
        const data = new FormData();
        data.append("binFile", file)
        if (file) {
            try {
                Request.post(`${url.UPLOAD_BIN_FILE}`, data).then((response: any) => {
                    console.log("bin file upload success", response);
                    setFieldValue("binFilePath", response?.data?.binFile);
                }).catch((error) => {
                    console.error('Error upload bin file data:', error);
                })
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const saveFirmwareApiCall = (values: any) => {
        // console.log("assign premise values", values);
        setLoading(true);
        const reqObj = {
            hardwareId: values.hardware,
            firmwareVersion: values.versionName,
            binFile: values.binFilePath,
            isStable: values.isStable ? values.isStable : false,
        }
        Request.post(url.SAVE_FIRMWARE, reqObj).
            then((res) => {
                // console.log("Assign Premise Response", res);
                toggleAddFirmware();
                firmwareVesrionReloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("Assign Premise Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                setLoading(false)
            })
    }

    const pushFirmwareApiCall = (values: any) => {
        // console.log("assign premise values", values);
        setLoading(true);
        const newDate = values.scheduledDateTime ? new Date(values.scheduledDateTime) : ""
        const reqObj = {
            hardwareId: values.hardware,
            firmwareId: values.firmwareId,
            userId: values.userId,
            isScheduled: values.isScheduled ? values.isScheduled : false,
            scheduledDateTime: newDate
        }
        Request.post(url.PUSH_FIRMWARE, reqObj).
            then((res) => {
                // console.log("Assign Premise Response", res);
                toast.success(res.data.msg);
                togglePushFirmware();
                pushFirmwareReloadDataTable();
                setLoading(false)
            }).
            catch((err) => {
                // console.log("Assign Premise Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                setLoading(false)
            })
    }

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <div className="page-content" style={{ backgroundColor: '#fff' }}>
            <ToastContainer />
            <Modal
                isOpen={openAddFirmwareModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleAddFirmware}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Add Firmware"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Hardware</Label>
                                <Select
                                    name="recipientUserId"
                                    // onFocus={() => userSearch("a")}
                                    value={hardwareList.find((option: any) => option.value === validation.values.hardware)}
                                    options={hardwareList}
                                    onChange={(selectedOption: any) => validation.setFieldValue('hardware', selectedOption.value)}
                                    onBlur={() => validation.setFieldTouched('hardware', true)}
                                    isSearchable={false}
                                    // invalid={
                                    //   validation.touchedhardwared && validation.errors.hardware ? true : false
                                    // }
                                    // className={cx({ 'is-invalid': invalid })}
                                    // onInputChange={(e) => userSearch(e)}
                                    styles={customStyles}
                                />
                                {validation.touched.hardware && validation.errors.hardware ? (
                                    <span className="error">{validation.errors.hardware}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">bin File</Label>
                                <Input
                                    value={validation.values.binFile || ""}
                                    name="binFile"
                                    type={"file"}
                                    placeholder="Enter Static binFile"
                                    onBlur={validation.handleBlur}
                                    onChange={(event) => handleFileChange(event, validation.setFieldValue)}
                                    invalid={
                                        validation.touched.binFile && validation.errors.binFile ? true : false
                                    }
                                    maxLength={4}
                                    accept='.bin'
                                />
                                {validation.touched.binFile && validation.errors.binFile ? (
                                    <span className="error">{validation.errors.binFile}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Version Name</Label>
                                <Input
                                    value={validation.values.versionName || ""}
                                    name="versionName"
                                    type={"text"}
                                    placeholder="Enter Vesrion Name"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.versionName && validation.errors.versionName ? true : false
                                    }
                                />
                                {validation.touched.versionName && validation.errors.versionName ? (
                                    <span className="error">{validation.errors.versionName}</span>
                                ) : null}
                            </div>
                            <div className="form-check">
                                <Input
                                    value={validation.values.isStable || ""}
                                    name="isStable"
                                    type={"checkbox"}
                                    // placeholder="Enter Vesrion Name"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.isStable && validation.errors.isStable ? true : false
                                    }
                                    className='form-check-input'
                                    id="checkVesrionName"
                                />
                                <Label className="form-label" for='checkVesrionName'>Is Stable?</Label>
                            </div>
                            {validation.touched.isStable && validation.errors.isStable ? (
                                <span className="error">{validation.errors.isStable}</span>
                            ) : null}
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>

            <Modal
                isOpen={isOpenPushFirmwareModalOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={togglePushFirmware}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Push Firmware"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            pushFirmwareValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>User</Label>
                                <Select
                                    name="userId"
                                    onFocus={() => userSearch("")}
                                    value={userData.find((option: any) => option.value === pushFirmwareValidation.values.userId)}
                                    options={userData}
                                    onChange={(selectedOption: any) => pushFirmwareValidation.setFieldValue('userId', selectedOption.value)}
                                    onBlur={() => pushFirmwareValidation.setFieldTouched("userId", true)}
                                    onInputChange={(e) => userSearch(e)}
                                    styles={customStyles}
                                />
                                {pushFirmwareValidation.touched.userId && pushFirmwareValidation.errors.userId ? (
                                    <span className="error">{pushFirmwareValidation.errors.userId}</span>
                                ) : null}
                            </div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Hardware</Label>
                                <Select
                                    name="hardware"
                                    // onFocus={() => userSearch("a")}
                                    value={hardwareList.find((option: any) => option.value === pushFirmwareValidation.values.hardware)}
                                    options={hardwareList}
                                    onChange={(selectedOption: any) => pushFirmwareValidation.setFieldValue('hardware', selectedOption.value)}
                                    onBlur={() => pushFirmwareValidation.setFieldTouched('hardware', true)}
                                    isSearchable={false}
                                    styles={customStyles}
                                />
                                {pushFirmwareValidation.touched.hardware && pushFirmwareValidation.errors.hardware ? (
                                    <span className="error">{pushFirmwareValidation.errors.hardware}</span>
                                ) : null}
                            </div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Firmware</Label>
                                <Select
                                    name="firmwareId"
                                    onFocus={() => firmwareSearch("1")}
                                    value={formwareList.find((option: any) => option.value === pushFirmwareValidation.values.firmwareId)}
                                    options={formwareList}
                                    onChange={(selectedOption: any) => pushFirmwareValidation.setFieldValue('firmwareId', selectedOption.value)}
                                    onBlur={() => pushFirmwareValidation.setFieldTouched('firmwareId', true)}
                                    styles={customStyles}
                                />
                                {pushFirmwareValidation.touched.firmwareId && pushFirmwareValidation.errors.firmwareId ? (
                                    <span className="error">{pushFirmwareValidation.errors.firmwareId}</span>
                                ) : null}
                            </div>
                            <div className="form-check">
                                <Input
                                    value={pushFirmwareValidation.values.isScheduled || ""}
                                    name="isScheduled"
                                    type={"checkbox"}
                                    // placeholder="Enter Vesrion Name"
                                    onBlur={pushFirmwareValidation.handleBlur}
                                    onChange={pushFirmwareValidation.handleChange}
                                    invalid={
                                        pushFirmwareValidation.touched.isScheduled && pushFirmwareValidation.errors.isScheduled ? true : false
                                    }
                                    className='form-check-input'
                                    id="checkVesrionName"
                                />
                                <Label className="form-label" for='checkVesrionName'>Is Scheduled   ?</Label>
                            </div>
                            {pushFirmwareValidation.touched.isScheduled && pushFirmwareValidation.errors.isScheduled ? (
                                <span className="error">{pushFirmwareValidation.errors.isScheduled}</span>
                            ) : null}
                            {pushFirmwareValidation.values.isScheduled &&
                                <div className="mb-3">
                                    <Label className="form-label">Scheduled Date & Time</Label>
                                    <Input
                                        value={pushFirmwareValidation.values.scheduledDateTime || ""}
                                        name="scheduledDateTime"
                                        type={"datetime-local"}
                                        placeholder="Enter scheduledDateTime"
                                        onBlur={pushFirmwareValidation.handleBlur}
                                        onChange={pushFirmwareValidation.handleChange}
                                        invalid={
                                            pushFirmwareValidation.touched.scheduledDateTime && pushFirmwareValidation.errors.scheduledDateTime ? true : false
                                        }
                                    />
                                    {pushFirmwareValidation.touched.scheduledDateTime && pushFirmwareValidation.errors.scheduledDateTime ? (
                                        <span className="error">{pushFirmwareValidation.errors.scheduledDateTime}</span>
                                    ) : null}
                                </div>
                            }
                            {/* {pushFirmwareValidation.touched.isScheduled && pushFirmwareValidation.errors.isScheduled ? (
                                <span className="error">{pushFirmwareValidation.errors.isScheduled}</span>
                            ) : null} */}

                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal >
            <div className="mb-4 h4 card-title d-flex flex-column flex-md-row justify-content-between" >
                <Nav tabs className="nav-tabs-custom" style={{ marginTop: 20 }}>
                    <NavItem>
                        <NavLink
                            style={{
                                cursor: "pointer",
                                color: customActiveTab === "1" ? "#121212" : "#7D8398"
                            }}
                            className={classNames({
                                active: customActiveTab === "1",
                                'custom-active-border': customActiveTab === '1',
                            })}
                            onClick={() => {
                                toggleCustom("1");
                            }}
                        >
                            <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Firmware Version List</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer", color: customActiveTab === "2" ? "#121212" : "#7D8398" }}
                            className={classNames({
                                active: customActiveTab === "2",
                            })}
                            onClick={() => {
                                toggleCustom("2");
                            }}
                        >
                            <span className="d-block d-sm-none">
                                <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block" style={{ fontSize: 15 }}>Push Firmware</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <div className="justify-content-between flex-row d-flex" style={{ gap: 20, height: "10%", marginTop: 15 }}>
                    <button className="btn btn-dark"
                        onClick={() => toggleAddFirmware()}
                    >
                        Add Firmware
                    </button>
                    <button
                        className="btn btn-outline-secondary"
                        style={{ gap: 20 }}
                        onClick={() => togglePushFirmware()}
                    >
                        Push Firmware
                    </button>
                </div>
            </div>
            <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
            >
                <TabPane tabId="1">
                    <FirmwareVersionList setDataTableId={setFirmwareVersionDataTableId} />
                </TabPane>
                <TabPane tabId="2">
                    <PushFirmwareList setDataTableId={(value: any) => setPushFirmwareDataTableId(value)} />
                </TabPane>
            </TabContent>
        </div >
    )
}

export default Firmware