export const SEND_OTP = "SEND_OTP"
export const SEND_OTP_FAILED = "SEND_OTP_FAILED"
export const VERIFY_OTP = "VERIFY_OTP"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED"

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
