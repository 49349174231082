export const Images = {
    Premises: require("../assets/images/Premises.svg").default,
    ActivePremises: require("../assets/images/ActivePremises.svg").default,
    Settings: require("../assets/images/Setting.svg").default,
    ActiveSetting: require("../assets/images/ActiveSetting.svg").default,
    Logs: require("../assets/images/Logs.svg").default,
    ActiveLogs: require("../assets/images/ActiveLogs.svg").default,
    Dashboard: require("../assets/images/Dashboard.svg").default,
    ActiveDashboard: require("../assets/images/ActiveDashboard.svg").default,
    ControlPanel: require("../assets/images/ControlPanel.svg").default,
    ActiveControlPanel: require("../assets/images/ActiveControlPanel.svg").default,
    Users: require("../assets/images/Users.svg").default,
    ActiveUsers: require("../assets/images/ActiveUsers.svg").default,
    RoleManagement: require("../assets/images/RoleManagement.svg").default,
    ActiveRoleManagement: require("../assets/images/ActiveRoleManagement.svg").default,
    TagManagement: require("../assets/images/TagManagement.svg").default,
    ActiveTagManagement: require("../assets/images/ActiveTagManagement.svg").default,
    SubAdmin: require("../assets/images/SubAdmin.svg").default,
    ActiveSubAdmin: require("../assets/images/ActiveSubAdmin.svg").default,
    ServicePerson: require("../assets/images/ServicePerson.svg").default,
    ActiveServicePerson: require("../assets/images/ActiveServicePerson.svg").default,
    block: require("../assets/images/DNDIcon.svg").default,
    cleaner: require("../assets/images/Cleaner.svg").default,
    loundry: require("../assets/images/Laundry.svg").default,
    TunableLigntOn: require("../assets/images/TunableLightOn.png"),
    TunableLigntOff: require("../assets/images/TunableLightOff.png"),
    BulbOn: require("../assets/images/bulbOn.png"),
    BulbOff: require("../assets/images/bulbOff.png"),
    Fan: require("../assets/images/Fan.png"),
    Curtains: require("../assets/images/mdi_curtains.png"),
    SunRise: require("../assets/images/sunRise.png"),
    Movie: require("../assets/images/movie.png"),
    BedTime: require("../assets/images/badtime.png"),
    BedTime: require("../assets/images/badtime.png"),
    OpenIcon: require("../assets/images/openIcon.png"),
    CloseIcon: require("../assets/images/closeIcon.png"),
    PauseIcon: require("../assets/images/pause.png"),
    OnlineIcon: require("../assets/images/online.svg").default,
    OfflineIcon: require("../assets/images/offline.svg").default,
    TotalDeviceIcon: require("../assets/images/TotalDevices.svg").default,
    ActiveAdminDashboard: require("../assets/images/ActiveAdminDashboard.svg").default,
    AdminDashboard: require("../assets/images/AdminDashboard.svg").default,
    downArrow: require("../assets/images/downArrow.svg").default,
    staticOTP: require("../assets/images/StaticOTP.svg").default,
    ActiveStaticOTP: require("../assets/images/ActiveStaticOtp.svg").default,
    ActiveSendNotification: require("../assets/images/ActiveSendNotification.svg").default,
    SendNotification: require("../assets/images/SendNotification.svg").default,
    ActiveScheduleLog: require("../assets/images/ActiveScheduleLog.svg").default,
    ScheduleLog: require("../assets/images/ScheduleLog.svg").default,
    Firmware: require("../assets/images/Firmware.svg").default,
    ActiveFirmware: require("../assets/images/ActiveFirmware.svg").default,
    DownloadIcon: require("../assets/images/DownloadIcon.svg").default,
    TotalDashboardDevices: require("../assets/images/TotalDashboardDevices.svg").default,
    TotalModels: require("../assets/images/TotalModels.svg").default,
    TotalUsers: require("../assets/images/TotalUsers.svg").default,
    ActiveDebugDevice: require("../assets/images/ActiveDebugDevice.svg").default,
    DebugDevice: require("../assets/images/IconDebugDevice.svg").default,
}