import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import 'datatables.net';
import 'datatables.net-bs4';
import $ from 'jquery'
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import getStorageData from '../../../Utils/getStorageData';
import { PremisesListData, PremisesListResponse } from '../../../helpers/response/Admin/PremiseListResponse';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Select from "react-select";
import classNames from 'classnames';

export interface interfaceDataTable<T> {
    apiURL: string,
    columns: any[],
    title: string,
    handleEditClick?: (data: any) => void | undefined,
    handleDeleteClick?: (data: any) => void,
    onDataTableRefresh?: (dataTableId: any) => void,
    idName: string,
    handleColumnClicked?: (data: any) => void | undefined,
    btnName?: string,
    btnClick?: () => void | undefined,
    btnStyle?: string,
}
const FirmwareDataTable = <T,>({
    apiURL,
    columns,
    title,
    handleEditClick,
    handleDeleteClick,
    onDataTableRefresh,
    idName,
    handleColumnClicked,
    btnName,
    btnClick,
    btnStyle
}: interfaceDataTable<T>) => {
    // const [data, setData] = useState([]);
    const [tableData, setTableData] = useState<PremisesListData[]>([]);
    const [loading, setLoading] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectFilter, setSelectFilter] = useState<any>({
        label: "First Name / Last Name",
        value: 0
    });
    const [searchValue, setSeachValue] = useState("")

    console.log("selectFilter", selectFilter);
    const tableRef: any = useRef(null);
    const userType = getStorageData().userType;

    const token = localStorage.getItem("Authorization");
    var initializeTable = 0;
    // console.log("tableRef", tableRef);

    let dataTableInstance: any = null;
    const fetchData = (dataTablesData: any, callback: any) => {
        // setLoading(true)
        $('#customLoader').show()
        // console.log("dataTablesData", dataTablesData);
        var reqObj: any
        reqObj = {
            ...dataTablesData,
        }
        axios.post(apiURL && apiURL, reqObj, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            const res: PremisesListResponse = response.data
            console.log("res", res);
            $('#customLoader').hide()
            callback({
                draw: dataTablesData.draw,
                recordsTotal: res.recordsTotal,
                recordsFiltered: res.recordsFiltered, // You might adjust this based on filtered records
                data: res.data,
            });
            setTableData(res.data)
        })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // localStorage.clear();
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("selectedPremiseID");
                    localStorage.removeItem("selectedUserID");
                    localStorage.removeItem("selectedUserEmail");
                    localStorage.removeItem("Authorization");
                    localStorage.removeItem("permissions");
                    window.location.reload();
                } else {
                    // Handle other errors as needed
                    console.error("Error fetching data:", err);
                }
            }
            );
    }

    console.log("datadatadata", tableData);
    const initializeData = () => {
        // if (!loading && tableData.length > 0) {
        console.log("calll  data length > 0");
        // if ($.fn.DataTable.isDataTable(tableRef.current)) {
        //     $(tableRef.current).DataTable().destroy();
        // }

        try {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            dataTableInstance = $(tableRef.current).DataTable({
                processing: false,
                serverSide: true,
                ajax: fetchData,
                columns: columns,
                searching: true,
                ordering: false,

            });
        } catch (error) {
            console.error('Error initializing DataTable:', error);
        }

        // $(tableRef.current).on('click', '.column-click', function () {
        //     const data = dataTableInstance.row($(this).closest('tr')).data();
        //     // Perform actions on click, e.g., open a modal, navigate to a page, etc.
        //     if (!isClicked) {
        //         //  console.log('User type is not allowed to click Premise Name');
        //         return; // Abort further actions
        //     }
        //     handleColumnClicked && handleColumnClicked(data)
        //     //  console.log('Clicked Premise Name:', data);
        // });
        // {
        //     !isEntryShow &&
        //         $(tableRef.current)
        //             .closest('.dataTables_wrapper')
        //             .find('.dataTables_length')
        //             .hide();
        //     $(tableRef.current).closest('.dataTables_wrapper').find('.dataTables_filter').hide();
        // }
        $(tableRef.current).on('click', '.edit-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("edit rowData", rowData);
            handleEditClick && handleEditClick(rowData);
        });

        $(tableRef.current).on('click', '.delete-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("delete rowData", rowData);
            handleDeleteClick && handleDeleteClick(rowData);
        });
        $(tableRef.current).on('preXhr.dt', function () {
            $('#customLoader').show();
        });

        $(tableRef.current).on('xhr.dt', function () {
            $('#customLoader').hide();
        });
        $(tableRef.current).find('thead th').css('background-color', '#EFF2F7');
    }

    useEffect(() => {
        initializeData()
    }, [loading, selectFilter, searchValue]);

    useEffect(() => {
        const dataTableId = $(tableRef.current).attr('id');
        onDataTableRefresh && onDataTableRefresh(dataTableId);
    }, [])

    //  console.log("dataTableInstancedataTableInstance", dataTableInstance);
    const handleCustomSearch = (value: any) => {
        //  console.log("valuevalue", dataTableInstance);
        // console.log("valuevalue", value);
        setSeachValue(value)
        if (tableRef.current) {
            $(tableRef.current).DataTable().draw();
            // $(tableRef.current).DataTable().search(value).draw();
        }
    };

    function handleSelectFilter(selectedValue: any) {
        setSelectFilter(selectedValue);
        console.log("selectedValue", selectedValue);
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
            fontWeight: "normal", // Set font weight for the dropdown menu
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            color: '#6c757d', // Placeholder color
            fontWeight: 'normal', // Placeholder font-weight
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: '#333', // Change selected value color
            fontWeight: 'normal', // Change selected
            // Add any other custom styles for the selected value here if needed
        }),
    };


    return (
        <Card style={{}}>
            <CardBody>
                <div className='d-flex justify-content-between mb-3'>
                    <h3 style={{ fontWeight: 600, color: "#000", fontSize: 16, marginBottom: 20 }}>{title}</h3>
                    {btnName && <button className={btnStyle}
                        onClick={() => btnClick && btnClick()}
                    >
                        {btnName}
                    </button>}
                </div>
                <div>
                    {/* <Spinners /> */}
                    <div id="customLoader" style={{
                        display: 'none',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                    }}>
                        <p>Processing....</p>
                    </div>
                    {initializeTable === 0 && (
                        initializeTable = 1,
                        <div className='table-responsive'>
                            <table
                                id={`dataTable${idName}`}
                                ref={tableRef}
                                className="table table-hover"
                                style={{ width: '100%', fontSize: 14, color: "#858D9D" }}
                            >
                            </table>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card >
    )
}

export default FirmwareDataTable;