import PropTypes from 'prop-types';
import React, { useState } from "react";

import { connect } from "react-redux";
import { Row, Col, DropdownItem } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import images from '../../assets/images';
import moment from 'moment';
import { Images } from '../../constants/images';
import getStorageData from '../../Utils/getStorageData';

const Header = props => {
  // console.log("header props", props);
  const navigate = useNavigate()
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const date = new Date();
  console.log("date", date);
  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

  }

  console.log("activeRoute", props.activeRoute);
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={images.logo} alt="" height="40" style={{ borderRadius: 4 }} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={images.logo} alt="" height="40" style={{ borderRadius: 4 }} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <box-icon name='menu'></box-icon>
              {/* <i className="fa fa-bars" style={{ color: 'black' }} /> */}
            </button>
            <div
              style={{
                // width: '100%',
                marginTop: 7,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              {getStorageData().userType === 0 && props.activeRoute === "Dashboard" || getStorageData().userType === 0 && props.activeRoute === "User & Device Dashboard" ?
                <Dropdown
                  className="dropdown-mega ms-2"
                  isOpen={megaMenu}
                  toggle={() => {
                    setmegaMenu(!megaMenu);
                  }}
                >
                  <DropdownToggle
                    className="btn header-item "
                    caret
                    tag="button"
                  >
                    <h5 style={{ color: '#000', fontWeight: 'bold', fontSize: 17, display: "flex" }}><span className='d-none d-md-flex' >{props.activeRoute === "Dashboard" ? "Request Dashboard" : props.activeRoute} </span>
                      <span style={{ marginLeft: 10 }}>
                        <img src={Images.downArrow} />
                      </span></h5>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className="dropdown-megamenu" onClick={() => navigate("/dashboard/request-dashboard")}>Request Dashboard</DropdownItem>
                    <DropdownItem className="dropdown-megamenu" onClick={() => navigate("/dashboard/request-dashboard/device-dashboard")}>User & Device Dashboard</DropdownItem>
                  </DropdownMenu>
                </Dropdown> :
                < h5 style={{ color: '#000', fontWeight: 'bold', fontSize: 17 }}>{props.activeRoute}</h5>
              }
            </div>
          </div>

          <div
            style={{
              // width: '100%',
              marginTop: 7,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              color: "#000000",
              fontSize: 15,
              fontWeight: '500',
            }}
          >
            {moment(date).format('dddd, MMMM DD, YYYY')}
          </div>
          <div className="d-flex">

            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" style={{ color: '#000' }} />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {/* <LanguageDropdown /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}
            {/* <NotificationDropdown /> */}
            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment >
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

// const mapStatetoProps = state => {
//   const {
//     layoutType,
//     showRightSidebar,
//     leftMenu,
//     leftSideBarType,
//   } = state.Layout;
//   return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
// };

// export default connect(mapStatetoProps, {
//   // showRightSidebarAction,
//   // toggleLeftmenu,
//   // changeSidebarType,
// })(withTranslation()(Header));

export default Header
