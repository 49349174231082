
import React, { useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import withRouter from "../../../components/Common/withRouter";
import { isEmpty, iteratee } from "lodash";
import Select from "react-select";
import cx from "classnames"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { PremisesListData, PremisesListResponse } from "../../../helpers/response/Admin/PremiseListResponse";

import makeAnimated from "react-select/animated";
import { getAreaList, getPremiseName, loginUser } from "../../../store/actions";
import * as url from '../../../helpers/url_helper';
import Request from "../../../helpers/request";
import DataTable from "../../../components/Common/DataTable";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup';
import $ from 'jquery'
import { ToastContainer, toast } from "react-toastify";
import { areaSearchApiCall, cityFromStateSearch, premiseSearchApiCall, stateSearchApiCall, userSearchApiCall } from "../../../Utils/searchApiCall";
import getStorageData from "../../../Utils/getStorageData";
import { useNavigate } from "react-router-dom";

const Premises = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = getStorageData().userType

  const [addPrimiseModal, setAddPrimiseModal] = useState(false);
  const [assignPrimiseModal, setAssignPrimiseModal] = useState(false);
  const [countryMenu, setCountryMenu] = useState(false);
  const [stateMenu, setStateMenu] = useState(false);
  const [cityMenu, setCityMenu] = useState(false);
  const [areaMenu, setAreaMenu] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [PremiseNameMenu, setPremiseNameMenu] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [premisesList, setPremisesList] = useState([]);
  const [dataTableId, setDataTableId] = useState(null);
  const [userData, setUserData] = useState([]);
  const [premisesNameList, setPremisesNameList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [searchStateValue, setSearchStateValue] = useState("all");
  const [seachCityValue, setSearchCityValue] = useState("all");
  const [cityList, setCityList] = useState([]);
  const [data, setData] = useState<any>([]);
  console.log("data------------->", data);

  // console.log("userData", userData);
  console.log("searchStateValue", premisesNameList);

  const toggleViewModal = () => {
    setAddPrimiseModal(!addPrimiseModal)
    addPremiseFormreset()
  };
  const toggleAssignPrimise = () => {
    setAssignPrimiseModal(!assignPrimiseModal);
    assignPremisesFormReset()
  }

  const addPremiseValidation = useFormik({
    initialValues: {
      premiseName: "",
      email: "",
      areaId: "",
      addPremiseError: "",
    },
    validationSchema: Yup.object().shape({
      premiseName: Yup.string().trim().max(20).
        required("Please provide service person name "),
      areaId: Yup.string()
        .required("Please provide your area "),
      email: Yup.string().trim()
        .email("Invalid email address")
        .required("please provide your email address "),
    }),
    onSubmit: (values) => {
      //  console.log("add premise values", values);
      addPremiseApiCall(values)
      // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
    }
  })

  console.log("values", addPremiseValidation.values, searchStateValue);

  interface initialValueType {
    grantorUserId: any[] | string;
    recipientUserId: string;
    assignPremiseError: string;
  }

  const assignPremiseValidation = useFormik<initialValueType>({
    initialValues: {
      grantorUserId: [],
      recipientUserId: "",
      assignPremiseError: ""
    },
    validationSchema: Yup.object().shape({
      // grantorUserId: Yup.string()
      //   .required("please provide premise"),
      grantorUserId: Yup.array()
        .min(1, 'At least one premise must be selected')
        .required('This field is required'),
      recipientUserId: Yup.string()
        .required("Please provide sub-admin")
    }),
    onSubmit: (values) => {
      // console.log("assign premise values", values);
      assignPremiseApiCall(values)
      // addPremiseApiCall(values)
      // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
    }
  })
  const addPremiseFormreset = () => {
    addPremiseValidation.resetForm({
      values: {
        premiseName: "",
        email: "",
        areaId: "",
        addPremiseError: ""
      },
      touched: {} // Reset touched state for all fields
    });
  }

  console.log("assignPremiseValidation", assignPremiseValidation.values.grantorUserId);


  const assignPremisesFormReset = () => {
    assignPremiseValidation.resetForm({
      values: {
        grantorUserId: [],
        recipientUserId: "",
        assignPremiseError: ""
      },
      touched: {} // Reset touched state for all fields
    });
  }

  const addPremiseApiCall = (values: any) => {
    //  console.log("add premise values", values);
    setLoading(true);
    const reqObj = {
      name: values.premiseName,
      email: values.email,
      areaId: values.areaId
    }
    Request.post(url.ADD_PREMISE_URL, reqObj).
      then((res) => {
        //  console.log("Add Premise Response", res);
        toggleViewModal();
        reloadDataTable();
        toast.success(res.data.msg);
        setLoading(false)
      }).
      catch((err) => {
        // console.log("add Premise Error", err);
        addPremiseValidation.setErrors({ addPremiseError: err?.response?.data?.errors[0]?.msg })
        setLoading(false)
      })
  }

  const assignPremiseApiCall = (values: any) => {
    // console.log("assign premise values", values);
    setLoading(true);
    const reqObj = {
      grantorUserId: values.grantorUserId,
      recipientUserId: values.recipientUserId,
    }
    Request.post(url.ASSIGN_PREMISE_URL, reqObj).
      then((res) => {
        // console.log("Assign Premise Response", res);
        toggleAssignPrimise();
        reloadDataTable();
        toast.success(res.data.msg);
        setLoading(false)
      }).
      catch((err) => {
        // console.log("Assign Premise Error", err);
        assignPremiseValidation.setErrors({ assignPremiseError: err?.response?.data?.errors[0]?.msg })
        setLoading(false)
      })
  }

  const token = localStorage.getItem('Authorization');

  const areaSearch = (e: any) => {
    areaSearchApiCall(e, setAreaData);
  }
  // const userSearchApiCall = (e) => {
  //   console.log("search Api Call", e);
  //   const value = !e ? "a" : e
  //   $.ajax({
  //     url: `${url.GET_SEARCH_USER_URL}?keyword=${value}&type=1`,
  //     method: 'GET',
  //     headers: {
  //       "Authorization": `Bearer ${token}`
  //     },
  //     success: function (data) {
  //       // Handle the successful response here
  //       console.log('Received data:', data.data);
  //       var user = [];
  //       for (var i = 0; i < data?.data?.length; i++) {
  //         var ele = data.data[i]
  //         // console.log("ele", ele);
  //         user.push({ label: `${ele.firstname} ${ele.lastname} | ${ele.email} | ${ele.mobile}`, value: ele.id });
  //         // setCityData(data);
  //       }
  //       console.log("datadatadata", data);
  //       setUserData(user);
  //     },
  //     error: function (error) {
  //       // Handle any errors
  //       console.error('Error fetching data:', error);
  //     }
  //   });
  // }
  const userSearch = (e: any) => {
    userSearchApiCall(e, setUserData, 1)
  }

  const stateSeach = (e: any) => {
    console.log("selectedOptionselectedOption", e);

    stateSearchApiCall(e, setStateList)
    // setCityFilterValue({ value: "all", label: "All" })
  }

  useEffect(() => {
    stateSeach("");
    citySearch("")
  }, [])
  const citySearch = (e: any) => {
    console.log("selectedOptionselectedOption", e);

    cityFromStateSearch(e, setCityList, searchStateValue)
  }

  // const premiseSearchApiCall = (e) => {
  //   console.log("search Api Call", e);
  //   const value = !e ? "a" : e
  //   $.ajax({
  //     url: `${url.GET_SEARCH_PREMISE_URL}?keyword=${value}`,
  //     method: 'GET',
  //     headers: {
  //       "Authorization": `Bearer ${token}`
  //     },
  //     success: function (data) {
  //       // Handle the successful response here
  //       console.log('Received data:', data.data);
  //       var user = [];
  //       for (var i = 0; i < data?.data?.length; i++) {
  //         var ele = data.data[i]
  //         // console.log("ele", ele);
  //         user.push({ label: `${ele.firstname} ${ele.lastname}`, value: ele.id });
  //         // setCityData(data);
  //       }
  //       console.log("datadatadata", data);
  //       setPremisesNameList(user);
  //     },
  //     error: function (error) {
  //       // Handle any errors
  //       console.error('Error fetching data:', error);
  //     }
  //   });
  // }

  useEffect(() => {
    setData([...data, ...premisesNameList.filter((item: any) => !data.some((obj: any) => obj.value === item.value))])
  }, [premisesNameList])

  const premisesSearch = (e: any) => {
    premiseSearchApiCall(e, setPremisesNameList)
  }

  useEffect(() => {
    // premisesListApiCall();
    // areaSearch("a");
    // userSearch("a");
    // premiseSearchApiCall("a");
  }, [])

  const reloadDataTable = () => {
    if (dataTableId) {
      const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
      dataTableInstance.ajax.reload(null, false);
    }
  };

  const handleDataTableIdChange = (dataTableId: any) => {
    // Now you have access to the DataTable ID here
    // console.log('DataTable ID:', dataTableId);
    setDataTableId(dataTableId)
    // You can do whatever you need with the ID in this component
  };

  const handleEditClick = () => {
    // console.log('Edit button clicked for row data:', rowData);
    // setAreaDetail(rowData)
    // setIsEditable(true)
    // setOpenAreaModal(true);
    // validation.setValues({
    //   name: rowData.name,
    //   cityName: rowData.cityId.id,
    //   areaId: rowData.id
    // })
    // Add your logic here
  };

  const columns = [
    // { data: 'name', title: 'Countries' },
    {
      title: 'State',
      render: function (data: any, type: any, row: any) {
        return `<span>${row?.stateId?.name ? row.stateId.name : "-"} </span>`
      },
    },
    {
      title: 'City',
      render: function (data: any, type: any, row: any) {
        return `<span>${row?.cityId?.name ? row.cityId.name : "-"} </span>`
      },
    },
    {
      data: null,
      render: function (data: any, type: any, row: any) {
        const joinArray = [row.name, row.userId.email].filter(Boolean)
        const underlineStyle = getStorageData().userType === 1 ? 'text-decoration: underline; cursor: pointer;' : 'text-decoration: none;';
        return `<span style="${underlineStyle}">${joinArray.join(" | ")}</span>`
        // return `${row.name} | ${row.userId.email}`; // Combining firstname and lastname for the 'Name' column
      },
      createdCell: function (cell: any, cellData: any, rowData: any) {
        $(cell).addClass('column-click'); // Add a class to identify the column
      },
      title: 'Premise'
    },
  ]
  interface CustomStyles {
    menu?: (provider: any) => any
    control?: (base: any, state: any) => any;
    // ... other style properties as needed
  }
  const customStyles: CustomStyles = {
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'white', // Set background color for the dropdown menu
      border: '1px solid #ccc', // Optional: Add border for clarity
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
    }),
    // control: (base: any) => ({
    //   ...base,
    //   borderColor: invalid ? 'red' : base.borderColor,
    // }),
  };

  const selectPremiseAPICall = (premiseId: string, email: string) => {
    setLoading(true);
    Request.get(`${url.SELECT_PREMISE_URL}?premiseId=${premiseId}`).
      then((res) => {
        console.log("select Premise Response", res);
        localStorage.setItem("selectedPremiseID", premiseId)
        localStorage.setItem("selectedUserEmail", email)
        navigate('/dashboard', { replace: true });
        setLoading(false)
      }).
      catch((err) => {
        assignPremiseValidation.setErrors({ assignPremiseError: err?.response?.data?.errors[0]?.msg })
        setLoading(false)
      })
  }

  const handlePremiseNameClick = (data: any) => {
    console.log("selectedPremiseID", data)
    selectPremiseAPICall(data.id, data.userId.email)
    // localStorage.setItem("selectedPremiseID", data.id)
    // // localStorage.setItem("selectedUserID", data.userId.id)
    // localStorage.setItem("selectedUserEmail", data.userId.email)
    // dispatch(getPremiseName(data));

    // navigate('/dashboard', { replace: true });
    // // props.forceRerender();
    // window.location.reload();
    // localStorage.setItem("Authorization", res.data.token)
  }

  const setFilterValue = (e: any) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e.value);
    setSearchStateValue(e.value);
  }

  const setCityFilterValue = (e: any) => {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e.value);
    setSearchCityValue(e.value);
  }

  const invalid = assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
  return (
    <React.Fragment>
      <div>
        <ToastContainer />
        {/* ADD PREMISES */}
        <Modal
          isOpen={addPrimiseModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          // tabIndex="-1"
          toggle={toggleViewModal}
        >
          <div className="modal-content">
            <ModalHeader style={{ justifyContent: 'center' }}>Add Premise</ModalHeader>
            <Form className="form-horizontal"
              onSubmit={(e) => {
                //  console.log("add premise e", e);
                e.preventDefault();
                addPremiseValidation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                {addPremiseValidation.errors && addPremiseValidation.errors.addPremiseError ? (
                  <Alert color="danger">{addPremiseValidation.errors.addPremiseError}</Alert>
                ) : null}
                <div className="mb-3">
                  <Label className="form-label">Premise Name</Label>
                  <InputGroup>
                    <Input
                      name="premiseName"
                      type={"text"}
                      placeholder="Enter Premise"
                      onChange={addPremiseValidation.handleChange}
                      onBlur={addPremiseValidation.handleBlur}
                      value={addPremiseValidation.values.premiseName || ""}
                      invalid={
                        addPremiseValidation.touched.premiseName && addPremiseValidation.errors.premiseName ? true : false
                      }
                      maxLength={20}
                      prefix="1212"
                    />
                    <InputGroupText>
                      {addPremiseValidation.values.premiseName.length} / 20
                    </InputGroupText>
                  </InputGroup>
                  {addPremiseValidation.touched.premiseName && addPremiseValidation.errors.premiseName ? (
                    <span className="error">{addPremiseValidation.errors.premiseName}</span>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    type={"text"}
                    placeholder="Enter Your Email"
                    onChange={addPremiseValidation.handleChange}
                    onBlur={addPremiseValidation.handleBlur}
                    value={addPremiseValidation.values.email || ""}
                    invalid={
                      addPremiseValidation.touched.email && addPremiseValidation.errors.email ? true : false
                    }
                  />
                  {addPremiseValidation.touched.email && addPremiseValidation.errors.email ? (
                    <span className="error">{addPremiseValidation.errors.email}</span>
                  ) : null}
                </div>
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Label>Area</Label>
                  <Select
                    name="areaId"
                    value={areaData.find((option: any) => option.value === addPremiseValidation.values.areaId)}
                    options={areaData}
                    onChange={(selectedOption: any) => addPremiseValidation.setFieldValue('areaId', selectedOption.value)}
                    onBlur={() => addPremiseValidation.setFieldTouched('areaId', true)}
                    // invalid={
                    //   addPremiseValidation.touched.areaId && addPremiseValidation.errors.areaId ? true : false
                    // }
                    onFocus={() => areaSearch("a")}
                    onInputChange={(e) => areaSearch(e)}
                    styles={customStyles}
                  />
                  {addPremiseValidation.touched.areaId && addPremiseValidation.errors.areaId ? (
                    <span className="error">{addPremiseValidation.errors.areaId}</span>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter style={{ justifyContent: 'center' }}>
                <button
                  className="btn btn-dark"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        {/* ASSIGN PREMISES */}
        <Modal
          isOpen={assignPrimiseModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          // tabIndex="-1"
          toggle={toggleAssignPrimise}
        >
          <div className="modal-content">
            <ModalHeader style={{ justifyContent: 'center' }}>Assign Premise</ModalHeader>
            <Form className="form-horizontal"
              onSubmit={(e) => {
                // console.log("add premise e", e);
                e.preventDefault();
                assignPremiseValidation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                {assignPremiseValidation.errors && assignPremiseValidation.errors.assignPremiseError ? (
                  <Alert color="danger">{assignPremiseValidation.errors.assignPremiseError}</Alert>
                ) : null}
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Label>Premise</Label>
                  <Select
                    name="grantorUserId"
                    // value={premisesNameList.find((option: any) => option.value === assignPremiseValidation.values.grantorUserId)}
                    value={data.filter((option: any) => assignPremiseValidation.values.grantorUserId.includes(option.value))}
                    options={premisesNameList}
                    onChange={(selectedOptions: any) => {
                      console.log("selectedOptions", selectedOptions);

                      assignPremiseValidation.setFieldValue('grantorUserId', selectedOptions.map((option: any) => option.value));
                    }}
                    // onChange={(selectedOption: any) => assignPremiseValidation.setFieldValue('grantorUserId', selectedOption.value)}
                    onBlur={() => assignPremiseValidation.setFieldTouched('grantorUserId', true)}
                    // invalid={
                    //   assignPremiseValidation.touched.grantorUserId && assignPremiseValidation.errors.grantorUserId ? true : false
                    // }
                    onFocus={() => premisesSearch("")}
                    onInputChange={(e) => premisesSearch(e)}
                    styles={customStyles}
                    isMulti
                  />
                  {assignPremiseValidation.touched.grantorUserId && assignPremiseValidation.errors.grantorUserId ? (
                    <span className="error">{assignPremiseValidation.errors.grantorUserId}</span>
                  ) : null}
                </div>
                {/* <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    type={"text"}
                    placeholder="Enter Email"
                    onChange={assignPremiseValidation.handleChange}
                    onBlur={assignPremiseValidation.handleBlur}
                    value={assignPremiseValidation.values.email || ""}
                    invalid={
                      assignPremiseValidation.touched.email && assignPremiseValidation.errors.email ? true : false
                    }
                  />
                  {assignPremiseValidation.touched.email && assignPremiseValidation.errors.email ? (
                    <span className="error">{assignPremiseValidation.errors.email}</span>
                  ) : null}
                </div> */}
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Label>Sub-Admin</Label>
                  <Select
                    name="recipientUserId"
                    onFocus={() => userSearch("a")}
                    value={userData.find((option: any) => option.value === assignPremiseValidation.values.recipientUserId)}
                    options={userData}
                    onChange={(selectedOption: any) => assignPremiseValidation.setFieldValue('recipientUserId', selectedOption.value)}
                    onBlur={() => assignPremiseValidation.setFieldTouched('recipientUserId', true)}
                    // invalid={
                    //   assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
                    // }
                    // className={cx({ 'is-invalid': invalid })}
                    onInputChange={(e) => userSearch(e)}
                    styles={customStyles}
                  />
                  {assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? (
                    <span className="error">{assignPremiseValidation.errors.recipientUserId}</span>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter style={{ justifyContent: 'center' }}>
                <button
                  className="btn btn-dark"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Assigning..." : "Assign"}
                </button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        {/* TABLE */}
        {/* {
          loading &&
          <Spinners />
        } */}
        <DataTable<PremisesListData>
          apiURL={`${url.BASE_URL}${url.GET_PREMISE_LIST_URL}`}
          columns={columns}
          paramsId={{ stateId: searchStateValue, cityId: seachCityValue }}
          // responseType={{ recordsTotal: 0, recordsFiltered: 0, data: [] }}
          isAddBtnShow={userType === 0 ? true : false}
          isBtnShow={userType === 0 ? true : false}
          btnName={userType === 0 ? "Add Premise" : ""}
          btnStyle={userType === 0 ? "btn btn-dark" : ""}
          secondBtnText="Assign Premise"
          secondBtnStyle="btn btn-outline-secondary"
          onClickBtn={toggleViewModal}
          secondBtnOnClick={toggleAssignPrimise}
          handleEditClick={handleEditClick}
          handleDeleteClick={() => { }}
          // handleEditbtn={() => { }}
          isShowSearch={true}
          onDataTableRefresh={handleDataTableIdChange}
          idName={"Premises"}
          showSearchebleFilter={userType === 0 ? true : false}
          searchableFilterOption={stateList}
          filterValue={searchStateValue}
          setFilterValue={setFilterValue}
          searchableCityOption={cityList}
          cityFilterValue={seachCityValue}
          setCityFilterValue={setCityFilterValue}
          searchCityAPICall={(e: any) => citySearch(e)}
          searchAPICall={(e: any) => stateSeach(e)}
          isClicked={userType === 0 ? false : true}
          handleColumnClicked={handlePremiseNameClick}
        />
      </div>
    </React.Fragment >
  );
};

export default withRouter(Premises);