import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import React, { useEffect, useState } from 'react'
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    Form,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap';
import $ from 'jquery'
import * as url from '../../../helpers/url_helper';
import Request from '../../../helpers/request';
import DataTable from '../../../components/Common/DataTable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { allPremisesSearchApiCall, userSearchApiCall } from '../../../Utils/searchApiCall';

const Service = () => {
    const [loading, setLoading] = useState(false);
    const [openDeletModal, setDeleteModal] = useState(false);
    const [openServiceModal, setServiceModal] = useState(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [servicePersonList, setServicePersonList] = useState([]);
    const [dataTableId, setDataTableId] = useState(null);
    const [assignPrimiseModal, setAssignPrimiseModal] = useState(false);
    const [userData, setUserData] = useState([]);
    const [premisesNameList, setPremisesNameList] = useState([]);
    const [deleteData, setDeleteData] = useState<any>();
    const [isOpenAssignModal, setOpenAssignModal] = useState<boolean>(false);
    const [accessData, setAccessData] = useState<any>([]);
    const [isOpenRemoveAccess, setOpenRemoveAccess] = useState<boolean>(false);
    const [grantorUserId, setGrantorUserId] = useState<any>(null);
    const [removeAccessId, setRemoveAccessId] = useState<any>(null);

    console.log("premisesNameList", premisesNameList);
    console.log("isOpenAssignModal", isOpenAssignModal);
    console.log("accessData", accessData);

    const validation = useFormik({
        initialValues: {
            id: "",
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string()
                .required("Please provide firstname "),
            lastname: Yup.string()
                .required("Please provide lastname "),
            mobile: Yup.string()
                .required("Please provide your mobile number ").min(10, "Mobile number not valid"),
            email: Yup.string().trim()
                .email("Invalid email address")
                .required("Please provide email address ")
        }),
        onSubmit: (values) => {
            // console.log("values", values);
            // addServicePerson(values)
            // reloadDataTable();
            isEditable ? editServicePerson(values) : addServicePerson(values)
        }
    })

    const togglePersonal = () => {
        setIsEditable(false)
        setServiceModal(!openServiceModal)
        validation.resetForm({
            values: {
                id: "",
                firstname: "",
                lastname: "",
                mobile: "",
                email: "",
                formError: ""
            }
        })
    };

    const accessPremisesListApiCall = (id: string) => {
        setLoading(true);
        const reqObj = {
            userId: id
        }
        Request.post(url.ACCESS_PREMISES_LIST_URL, reqObj).
            then((res) => {
                setAccessData(res.data.data);
                // console.log("Service person List Response", res);
                // const modifiedData = res.data.data.map((row: any, index: number) => {
                //     return { index: index + 1, ...row }; // Adding an 'index' property to represent the row index
                // });
                // setServicePersonList(modifiedData);
                setLoading(false)
            }).
            catch((err) => {
                //   console.log("service person List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const premisesSearch = (e: any) => {
        allPremisesSearchApiCall(e, setPremisesNameList)
    }

    const userSearch = (e: any) => {
        userSearchApiCall(e, setUserData, 2)
    }


    useEffect(() => {
        // servicePersonalListApiCall();
    }, [])

    const assignPremiseValidation = useFormik({
        initialValues: {
            grantorUserId: "",
            recipientUserId: "",
            assignPremiseError: ""
        },
        validationSchema: Yup.object().shape({
            grantorUserId: Yup.string()
                .required("please provide premise"),
            recipientUserId: Yup.string()
                .required("Please provide sub-admin")
        }),
        onSubmit: (values) => {
            // console.log("assign premise values", values);
            assignPremiseApiCall(values)
            // addPremiseApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const toggleAssignPrimise = () => {
        setAssignPrimiseModal(!assignPrimiseModal);
        assignPremisesFormReset()
    }
    const toggleAssignPrimiseList = () => {
        setOpenAssignModal(!isOpenAssignModal);
        // assignPremisesFormReset()
    }

    const assignPremiseApiCall = (values: any) => {
        // console.log("assign premise values", values);
        setLoading(true);
        Request.post(url.ASSIGN_PREMISE_URL, values).
            then((res) => {
                // console.log("Assign Premise Response", res);
                toggleAssignPrimise();
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("Assign Premise Error", err);
                assignPremiseValidation.setErrors({ assignPremiseError: err?.response?.data?.errors[0]?.msg })
                setLoading(false)
            })
    }

    const assignPremisesFormReset = () => {
        assignPremiseValidation.resetForm({
            values: {
                grantorUserId: "",
                recipientUserId: "",
                assignPremiseError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const addServicePerson = (values: any) => {
        //   console.log("add service person values", values);
        // console.log("dataTable id", dataTableId);
        // reloadDataTable();
        setLoading(true);
        Request.post(url.ADD_SERVICE_PERSON_URL, values).
            then((res) => {

                //  console.log("Add Service person Response", res);
                // servicePersonalListApiCall();
                toast.success(res.data.msg);
                togglePersonal();
                reloadDataTable();
                setLoading(false);
            }).
            catch((err) => {
                // console.log("Add service person Error", err);
                setLoading(false);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg });
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false);
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }
    const editServicePerson = (values: any) => {
        //   console.log("add service person values", values);
        // console.log("dataTable id", dataTableId);
        // reloadDataTable();
        setLoading(true);
        const reqObj = {
            email: values.email,
            mobile: values.mobile,
            firstname: values.firstname,
            lastname: values.lastname,
            servicePersonId: values.id
        }
        Request.post(url.UPDATE_SERVICE_PERSON_URL, reqObj).
            then((res) => {

                //  console.log("Add Service person Response", res);
                // servicePersonalListApiCall();
                toast.success(res.data.msg);
                togglePersonal();
                reloadDataTable();
                setLoading(false);
            }).
            catch((err) => {
                // console.log("Add service person Error", err);
                setLoading(false);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg });
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false);
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const deleteAreaApiCall = () => {
        setLoading(true);
        const reqObj = {
            servicePersonId: deleteData.id
        }
        Request.post(url.DELETE_SERVICE_PERSON_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                validation.resetForm({
                    values: {
                        id: "",
                        firstname: "",
                        lastname: "",
                        mobile: "",
                        email: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const removeAssignApiCall = () => {
        setLoading(true);
        const reqObj = {
            grantorUserId: removeAccessId,
            recipientUserId: grantorUserId
        }
        Request.post(url.REMOVE_ACCESS_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                // validation.resetForm({
                //     values: {
                //         adminId: "",
                //         firstname: "",
                //         lastname: "",
                //         email: "",
                //         mobile: "",
                //         areaId: "",
                //         addSubAdminError: ""
                //     },
                //     touched: {} // Reset touched state for all fields
                // });
                toast.success(res.data.msg)
                reloadDataTable();
                accessPremisesListApiCall(grantorUserId)
                setOpenRemoveAccess(false)
                setRemoveAccessId(null)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }


    console.log("dataTable reload", dataTableId);
    const reloadDataTable = () => {
        if (dataTableId) {
            console.log("call reload Data table");
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('service DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    // const columns = [
    //     // { data: 'index', title: 'Id' },
    //     {
    //         title: 'Name',
    //         render: function (data: any, type: any, row: any) {
    //             return `${row.firstname} ${row.lastname}`; // Combining firstname and lastname for the 'Name' column
    //         }
    //     },
    //     {
    //         render: function (data: any, type: any, row: any) {
    //             return `+91 ${row.mobile}`;
    //         }, title: 'Mobile Number'
    //     },
    //     {
    //         data: null,
    //         title: 'No. Of Access',
    //         render: function (data: any, type: any, row: any) {
    //             const underlineStyle = 'text-decoration: underline; cursor: pointer;';
    //             return `<span style="${underlineStyle}">${row.noOfAccess}</span>`
    //         },
    //         createdCell: function (cell: any, cellData: any, rowData: any) {
    //             $(cell).addClass('column-click'); // Add a class to identify the column
    //         },
    //     },
    //     { data: 'email', title: 'Email' },
    //     {
    //         title: 'Actions',
    //         render: function () {
    //             return (
    //                 `<div class="d-flex gap-2">
    //                             <button class="edit-btn" style="border: none; outline: none; background: none;">
    //                                 <i class="bx bxs-edit" style="font-size: 20px; margin-right: 20px; color: "#858D9D";></i>
    //                             </button>
    //                             <button class="delete-btn" style="border: none; outline: none; background: none;">
    //                                 <i class="mdi mdi-delete" style="font-size: 20px;"></i>
    //                             </button>
    //                         </div>`
    //             );
    //         }
    //     }
    // ]

    const columns = [
        // { data: 'index', title: 'Id' },
        {
            title: 'Name',
            render: function (data: any, type: any, row: any) {
                return `${row?.firstname} ${row?.lastname}`; // Combining firstname and lastname for the 'Name' column
            }
        },
        { data: 'email', title: 'Email' },
        {
            title: 'Mobile Number',
            render: function (data: any, type: any, row: any) {
                return `+91 ${row?.mobile}`; // Combining firstname and lastname for the 'Name' column
            }
        },
        {
            data: null,
            title: 'No. Of Access',
            render: function (data: any, type: any, row: any) {
                const underlineStyle = 'text-decoration: underline; cursor: pointer;';
                return `<span style="${underlineStyle}">${row.noOfAccess}</span>`
            },
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isActive ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Active</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Inactive</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;">
                                     <box-icon type='solid' name='edit' size="20px"></box-icon>
                                </button>
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                    <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    const accessColumn = [{
        data: "",
        title: "Access name"
    }]

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        // setAreaDetail(rowData)
        setIsEditable(true);
        setServiceModal(true);
        validation.setValues({
            id: rowData.id,
            firstname: rowData.firstname,
            lastname: rowData.lastname,
            mobile: rowData.mobile,
            email: rowData.email,
            formError: ""
        })
        // Add your logic here
    };

    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModal(!openDeletModal);
        setDeleteData(rowData);
        // Add your logic here
    };

    const handleRemoveAccessClick = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData);
        setOpenRemoveAccess(!isOpenRemoveAccess);
        setRemoveAccessId(rowData.id);
        // Add your logic here
    };


    const handleAccessModal = (data: any) => {
        console.log("call access modal", data);
        accessPremisesListApiCall(data.id)
        setOpenAssignModal(true);
        setGrantorUserId(data?.id);
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }
    const customStyles: CustomStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
        // control: (base: any) => ({
        //   ...base,
        //   borderColor: invalid ? 'red' : base.borderColor,
        // }),
    };

    return (
        <React.Fragment>
            <div>
                <ToastContainer />
                {/* ADD / EDIT MODAL */}
                <Modal
                    isOpen={openServiceModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={togglePersonal}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Add Service Person</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                //  console.log("call on submit", e);
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.formError ? (
                                    <Alert color="danger">{validation.errors.formError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">Firstname</Label>
                                    <Input
                                        name="firstname"
                                        type={"text"}
                                        placeholder="Enter Firstname"
                                        value={validation.values.firstname || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                        }
                                    />
                                    {validation.touched.firstname && validation.errors.firstname ? (
                                        <span className="error">{validation.errors.firstname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Lastname</Label>
                                    <Input
                                        name="lastname"
                                        type={"text"}
                                        placeholder="Enter Lastname"
                                        value={validation.values.lastname || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.lastname && validation.errors.lastname ? true : false
                                        }
                                    />
                                    {validation.touched.lastname && validation.errors.lastname ? (
                                        <span className="error">{validation.errors.lastname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Mobile Number</Label>
                                    <InputGroup>
                                        <span className="input-group-text"
                                            style={{
                                                backgroundColor: '#fff',
                                                borderColor: validation.touched.mobile && validation.errors.mobile ? "red" : ""
                                            }}
                                        >
                                            +91
                                        </span>
                                        <Input
                                            name="mobile"
                                            type={"text"}
                                            placeholder="Enter Mobile Number"
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.touched.mobile && validation.errors.mobile ? true : false
                                            }
                                            maxLength={10}
                                            value={validation.values.mobile || ""}
                                        />
                                    </InputGroup>
                                    {validation.touched.mobile && validation.errors.mobile ? (
                                        <span className="error">{validation.errors.mobile}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        type={"text"}
                                        placeholder="Enter Your Email"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                        value={validation.values.email || ""}
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <span className="error">{validation.errors.email}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                    {/* Add */}
                                    {/* {loading ? "Adding..." : "Add"} */}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {/* ASSIGN PREMISES */}
                <Modal
                    isOpen={assignPrimiseModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleAssignPrimise}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Assign Premise</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                // console.log("add premise e", e);
                                e.preventDefault();
                                assignPremiseValidation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Premises</Label>
                                    <Select
                                        name="grantorUserId"
                                        value={premisesNameList.find((option: any) => option.value === assignPremiseValidation.values.grantorUserId)}
                                        options={premisesNameList}
                                        onChange={(selectedOption: any) => assignPremiseValidation.setFieldValue('grantorUserId', selectedOption.value)}
                                        onBlur={() => assignPremiseValidation.setFieldTouched('grantorUserId', true)}
                                        // invalid={
                                        //   assignPremiseValidation.touched.grantorUserId && assignPremiseValidation.errors.grantorUserId ? true : false
                                        // }
                                        onFocus={() => premisesSearch("a")}
                                        onInputChange={(e) => premisesSearch(e)}
                                        styles={customStyles}
                                    />
                                    {assignPremiseValidation.touched.grantorUserId && assignPremiseValidation.errors.grantorUserId ? (
                                        <span className="error">{assignPremiseValidation.errors.grantorUserId}</span>
                                    ) : null}
                                </div>
                                {/* <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    type={"text"}
                    placeholder="Enter Email"
                    onChange={assignPremiseValidation.handleChange}
                    onBlur={assignPremiseValidation.handleBlur}
                    value={assignPremiseValidation.values.email || ""}
                    invalid={
                      assignPremiseValidation.touched.email && assignPremiseValidation.errors.email ? true : false
                    }
                  />
                  {assignPremiseValidation.touched.email && assignPremiseValidation.errors.email ? (
                    <span className="error">{assignPremiseValidation.errors.email}</span>
                  ) : null}
                </div> */}
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Service Person</Label>
                                    <Select
                                        name="recipientUserId"
                                        onFocus={() => userSearch("a")}
                                        value={userData.find((option: any) => option.value === assignPremiseValidation.values.recipientUserId)}
                                        options={userData}
                                        onChange={(selectedOption: any) => assignPremiseValidation.setFieldValue('recipientUserId', selectedOption.value)}
                                        onBlur={() => assignPremiseValidation.setFieldTouched('recipientUserId', true)}
                                        // invalid={
                                        //   assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
                                        // }
                                        // className={cx({ 'is-invalid': invalid })}
                                        onInputChange={(e) => userSearch(e)}
                                        styles={customStyles}
                                    />
                                    {assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? (
                                        <span className="error">{assignPremiseValidation.errors.recipientUserId}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Adding..." : "Add"}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {/* ASSIGN PREMISES LIST */}
                <Modal
                    isOpen={isOpenAssignModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    size='lg'
                    // tabIndex="-1"
                    toggle={toggleAssignPrimiseList}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>Access Of Users</ModalHeader>
                        <ModalBody>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>
                                            First Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Mobile Number
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accessData.length === 0 && !loading ?
                                        <tr aria-colspan={3}>
                                            <td
                                                colSpan={3} style={{ textAlign: "center" }}>
                                                No data available in table
                                            </td>
                                        </tr>
                                        :
                                        accessData.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td scope="row">
                                                    {`${item.firstname} ${item.lastname}`}
                                                </td>
                                                <td scope="row">
                                                    {item.email}
                                                </td>
                                                <td scope="row">
                                                    {item.mobile}
                                                    {/* {`+ 91 ${item?.mobile}`} */}
                                                </td>
                                                <td scope="row">
                                                    <button
                                                        onClick={() => handleRemoveAccessClick(item)}
                                                        className="edit-btn" style={{ border: "none", outline: "none", background: "none" }}>
                                                        <span
                                                            className="badge bg-danger"
                                                            style={{
                                                                height: 25,
                                                                // width: 60,
                                                                fontSize: 12,
                                                                padding: 6
                                                            }}
                                                        >
                                                            Remove Access
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </ModalBody>
                    </div>
                </Modal>
                {/* DELETE MODAL */}
                {openDeletModal &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Delete Service Person?"
                        text="Are you sure you want to delete this service person? This action cannot be undone."
                        buttonText="Delete"
                        onClick={() => { deleteAreaApiCall() }}
                        cancleBtnClick={() => setDeleteModal(false)}
                    />
                }
                {isOpenRemoveAccess &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Remove Access"
                        text={`Are You Sure you want to remove access?`}
                        buttonText="Remove"
                        onClick={() => { removeAssignApiCall() }}
                        cancleBtnClick={() => setOpenRemoveAccess(false)}
                    />
                }
                {/* {loading &&
                    <Spinners />} */}
                <DataTable
                    // data={servicePersonList}
                    apiURL={`${url.BASE_URL}${url.GET_SERVICE_PERSON_URL}`}
                    columns={columns}
                    isAddBtnShow={true}
                    isBtnShow={true}
                    btnName="Add Service Person"
                    btnStyle="btn btn-dark"
                    secondBtnText="Assign User"
                    secondBtnStyle="btn btn-outline-secondary"
                    secondBtnOnClick={toggleAssignPrimise}
                    onClickBtn={togglePersonal}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                    isClicked={true}
                    handleColumnClicked={handleAccessModal}
                    // editApiCall={() => { }}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"Service"}
                />
            </div>
        </React.Fragment>
    )
}

export default Service