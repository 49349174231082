import React, { useEffect } from 'react'
import { Card, CardBody, CardText, Col, Input } from 'reactstrap';
import { customStyles } from './style';
import Lottie from 'lottie-react';
import { Images } from '../../../constants/images';
import { useSocket } from '../../../Utils/socket';
import { OPERATE_CHIP } from '../../../helpers/socketEvent';
import { jsonFileName } from '../../../constants/jsonFileName';

export interface SceneProps {
    switchData: any;
    getChipStatus: () => void;
}

const Scene = ({
    switchData,
    getChipStatus,
}: SceneProps) => {
    const socket: any = useSocket();
    const chipSwitchId = switchData
    console.log("switchData in switchData component", switchData);
    const switchType = chipSwitchId?.switchItem?.chipSwitchId?.type;
    const switchIcon = chipSwitchId?.switchItem?.chipSwitchId?.icon?.toLowerCase();

    // console.log("switchIcon", chipSwitchId);

    const switchName = chipSwitchId?.switchItem?.chipSwitchId?.displayName;
    const button = chipSwitchId?.switchItem?.chipSwitchId?.button;
    const modelType = chipSwitchId?.switchItem?.modelType;
    const isClickable = switchData?.isClickable;
    console.log("isClickable", `${chipSwitchId?.switchItem?.chipSwitchId?.displayName}${isClickable}`);

    const isOnOff = switchData?.isOnOff;

    var icon = isOnOff === "1" && isClickable ?
        switchIcon ?
            switchIcon :
            "surface_light" :
        isOnOff === "0" || isOnOff === "1" && !isClickable ?
            switchIcon ?
                `${switchIcon}_off` :
                "surface_light_off" :
            `surface_light_off`


    const operateChip = (isOnOrOff: any) => {
        console.log("chipSwitchId", chipSwitchId);
        const button = chipSwitchId?.chipSwitchId?.button;
        var executeVal: any = "XX"
        // if (switchType === 0) {
        if (chipSwitchId?.switchDetail?.val === "0" || chipSwitchId?.switchDetail?.val === "") {
            executeVal = "XX1"
        } else {
            executeVal = "XX0"
        }
        // }
        // else if (switchType === 1) {
        //     if (isOnOrOff) {
        //         if (chipSwitchId?.switchDetail?.val === "0") {
        //             executeVal = `${switchValue}0`
        //         } else {
        //             executeVal = `${switchValue}1`
        //         }
        //     }
        //     else if (chipSwitchId?.switchDetail?.val === "1") {
        //         executeVal = `${switchValue}0`
        //     } else {
        //         executeVal = `${switchValue}1`
        //     }
        // }

        const reqObj = {
            slave: chipSwitchId?.switchItem?.chipSwitchId?.slave,
            button: chipSwitchId?.switchItem?.chipSwitchId?.button,
            executeVal: executeVal,
            chipSwitchId: chipSwitchId?.switchItem?.id,
            chipId: chipSwitchId.switchDetail?.id,
            cmd: "ST"
        }
        console.log("reqObj: ", reqObj);
        socket.emit(OPERATE_CHIP, JSON.stringify(reqObj))
        // setTimeout(() => {
        //     getChipStatus()
        // }, 500)
        console.log("call oprate chip");
    }

    useEffect(() => {
        setTimeout(() => {
            if (chipSwitchId?.switchDetail?.val === "1") {
                operateChip("")
            }
        }, 1000)
    })

    const fileExist = jsonFileName.includes(`${icon}.json`)
    return (
        <Col xl="6">
            <Card style={{
                ...customStyles.sceneBox(isClickable, isOnOff
                ), position: 'relative', zIndex: 1
            }}
            // onClick={() => isClickable ? operateChip("") : ""}
            >
                {!isClickable &&
                    <div
                        style={{ ...customStyles.wifiStyle(), position: "absolute" }}
                    >
                        <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                    </div>}
                <CardBody>
                    <CardText className="float-end">
                        <div className="form-check form-switch form-switch-md mt-1" >
                            <Input
                                type="checkbox"
                                // className="form-check-input"
                                id="customSwitchsizemd"
                                checked={chipSwitchId?.switchDetail?.val === "1"}
                                // checked={changeTheme}
                                // color='#000'
                                onChange={() => {
                                    isClickable ? operateChip("") : "";
                                }}
                            />
                        </div>
                    </CardText>
                    <CardText style={{ width: "50%", alignItems: "center", display: "flex", flexDirection: "row" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 10 }}>
                            <Lottie
                                animationData={
                                    fileExist ?
                                        require(`../../../assets/Lottie_json/${icon}`) :
                                        !fileExist && isOnOff === "0" ?
                                            require(`../../../assets/Lottie_json/surface_light_off.json`) : !fileExist && isOnOff === "1" &&
                                            require(`../../../assets/Lottie_json/surface_light.json`)
                                }
                                loop={true}
                                size={5}
                                style={{ width: 40, height: 40 }}
                            />
                        </div>
                        <h6 style={{
                            ...customStyles.sceneName(isClickable, isOnOff),
                            lineBreak: "auto",
                            marginTop: 5,
                            fontSize: 15,
                            color: "#000000",
                            fontWeight: 400
                        }}>{switchName}</h6>

                    </CardText>
                </CardBody>
            </Card>
        </Col >
    )
}

export default Scene