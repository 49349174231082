import getStorageData from "../Utils/getStorageData";
import getHooks from "../hooks";
import { useEffect } from "react";
import { Images } from "./images";
import { getDashoboardPermissions } from "../Utils/searchApiCall";
import { useSelector } from "react-redux";

const useRoutes = () => {
    const permissionsChange = useSelector((state) => state.APIResponse.changePermission);
    console.log("permissionsChange", permissionsChange);
    const userType = getStorageData().userType;
    const menuPermissions = getStorageData().menuPermissions;
    const selectedPremiseID = getStorageData().selectedPremiseID;
    console.log("menuPermissions", menuPermissions);
    let route = [];
    let hotelRoute = [];
    // console.log("hotelRoute", hotelRoute);
    const hotelUserRoute = [
        {
            img: Images.Dashboard,
            activeImg: Images.ActiveDashboard,
            path: "/dashboard",
            name: "Dashboard",
            menuName: "DASHBOARD",
            isShow: 1,
            // handleClick: () => handleClick(),
        },
        {
            img: Images.ControlPanel,
            activeImg: Images.ActiveControlPanel,
            path: "/control-panel",
            name: "Control Panel",
            menuName: "CONTROL-PANEL",
            isShow: selectedPremiseID && menuPermissions?.includes("CONTROL-PANEL") ? 1 : 0,
            // handleClick: () => handleClick(),
        },
        {
            img: require("../assets/images/Icon-rooms.png"),
            activeImg: require("../assets/images/Icons_active_rooms.png"),
            path: "/rooms",
            name: "Rooms",
            menuName: "ROOMS",
            isShow: selectedPremiseID && menuPermissions?.includes("ROOMS") ? 1 : 0,
            // handleClick: () => handleClick(),
        },
        {
            img: Images.Settings,
            activeImg: Images.ActiveSetting,
            path: "/settings",
            name: "Settings",
            menuName: "SETTING",
            isShow: selectedPremiseID && menuPermissions?.includes("SETTING") ? 1 : 0
        },
    ]


    useEffect(() => {
        console.log("call useEffect");

        if (userType === 4 && !permissionsChange) {
            hotelRoute = hotelUserRoute.filter((item) => getStorageData()?.menuPermissions?.includes(item.menuName))
            console.log("hotelRoute", hotelRoute);
            window.location.reload();
        }
    }, [permissionsChange])

    const handleClick = (path) => {
        console.log("window", window);
        window.location.reload();
    }

    if (userType === 0) {
        route = [
            {
                img: Images.Premises,
                activeImg: Images.ActivePremises,
                path: "/premises",
                name: "Premises",
                isShow: 1
            },
            {
                img: Images.SubAdmin,
                activeImg: Images.ActiveSubAdmin,
                path: "/sub-admins",
                name: "Sub Admins",
                isShow: 1
            },
            {
                img: Images.SubAdmin,
                activeImg: Images.ActiveSubAdmin,
                path: "/sub-admins/permissions",
                name: "Sub Admin Permissions",
                isShow: 0
            },
            {
                img: Images.ServicePerson,
                activeImg: Images.ActiveServicePerson,
                path: "/service-person",
                name: "Service Person",
                isShow: 1
            },
            // {
            //     img: Images.Premises,
            //     activeImg: Images.ActivePremises,
            //     path: "/area",
            //     name: "Area",
            //     isShow: 1
            // },
            {
                img: Images.AdminDashboard,
                activeImg: Images.ActiveAdminDashboard,
                path: "/dashboard/request-dashboard",
                name: "Dashboard",
                isShow: 1
            },
            {
                img: Images.AdminDashboard,
                activeImg: Images.ActiveAdminDashboard,
                path: "/dashboard/request-dashboard/device-dashboard",
                name: "User & Device Dashboard",
                isShow: 0
            },
            {
                img: Images.SubAdmin,
                activeImg: Images.ActiveSubAdmin,
                path: "/users",
                name: "Users",
                isShow: 1
            },
            {
                img: Images.SubAdmin,
                activeImg: Images.ActiveSubAdmin,
                path: "/users/profile/:id",
                name: "Profile List",
                isShow: 0
            },
            {
                img: Images.SubAdmin,
                activeImg: Images.ActiveSubAdmin,
                path: "/users/profile/device/:slaveId",
                name: "Profile List",
                isShow: 0
            },
            {
                img: Images.ScheduleLog,
                activeImg: Images.ActiveScheduleLog,
                path: "/schedules-logs",
                name: "Schedule Logs",
                isShow: 1
            },
            {
                img: Images.ScheduleLog,
                activeImg: Images.ActiveScheduleLog,
                path: "/feedback",
                name: "Feedback",
                isShow: 1
            },
            {
                img: Images.staticOTP,
                activeImg: Images.ActiveStaticOTP,
                path: "/static-otp",
                name: "Static OTP",
                isShow: 1
            },
            {
                img: Images.SendNotification,
                activeImg: Images.ActiveSendNotification,
                path: "/send-notification",
                name: "Notification",
                isShow: 1
            },
            {
                img: Images.DebugDevice,
                activeImg: Images.ActiveDebugDevice,
                path: "/debug-device",
                name: "Debug Device",
                isShow: 1
            },
            {
                img: Images.DebugDevice,
                activeImg: Images.ActiveDebugDevice,
                path: "/debug-device-details/:slave",
                name: "Debug Device",
                isShow: 0
            },
            {
                img: Images.Firmware,
                activeImg: Images.ActiveFirmware,
                path: "/firmware",
                name: "Firmware",
                isShow: 1
            },
            {
                img: Images.Logs,
                activeImg: Images.ActiveLogs,
                path: "/logs",
                name: "Logs",
                isShow: 1
            },
            {
                img: Images.Logs,
                activeImg: Images.ActiveLogs,
                path: "/backup",
                name: "Backup",
                isShow: 1
            },
            {
                img: Images.Premises,
                activeImg: Images.ActivePremises,
                path: "/master",
                name: "Master",
                isShow: 1
            },
            {
                img: Images.Settings,
                activeImg: Images.ActiveSetting,
                path: "/settings",
                name: "Settings",
                isShow: 1
            },
            // {
            //     img: Images.DebugDevice,
            //     activeImg: Images.ActiveDebugDevice,
            //     path: "/testing",
            //     name: "Testing",
            //     isShow: 1
            // },
            // {
            //     img: Images.DebugDevice,
            //     activeImg: Images.ActiveDebugDevice,
            //     path: "/testing/testing-of-details/:id",
            //     name: "Testing",
            //     isShow: 0
            // },
            // {
            //     img: Images.DebugDevice,
            //     activeImg: Images.ActiveDebugDevice,
            //     path: "/testing/testing-details-of-details/:id",
            //     name: "Testing",
            //     isShow: 0
            // },
        ]
    }
    else if (userType === 1) {
        route = [
            {
                isShow: selectedPremiseID ? 3 : 0,
                name: getStorageData().selectedPremiseEmail,
                img: require("../assets/images/user.png"),
            },
            {
                img: Images.Dashboard,
                activeImg: Images.ActiveDashboard,
                path: "/dashboard",
                name: "Dashboard",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: Images.ControlPanel,
                activeImg: Images.ActiveControlPanel,
                path: "/control-panel",
                name: "Control Panel",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: require("../assets/images/Icon-rooms.png"),
                activeImg: require("../assets/images/Icons_active_rooms.png"),
                path: "/rooms",
                name: "Rooms",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: require("../assets/images/Icon-rooms.png"),
                activeImg: require("../assets/images/Icons_active_rooms.png"),
                path: "/guest-management",
                name: "Guest",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: require("../assets/images/Icon-rooms.png"),
                activeImg: require("../assets/images/Icons_active_rooms.png"),
                path: "/guest-history",
                name: "Guest History",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },

            // SERVICE
            {
                img: Images.Users,
                activeImg: Images.ActiveUsers,
                path: "/users",
                name: "Users",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: Images.RoleManagement,
                activeImg: Images.ActiveRoleManagement,
                path: "/role-management",
                name: "Role Management",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: Images.RoleManagement,
                activeImg: Images.ActiveRoleManagement,
                path: "/role-management/permissions/:name/:id",
                name: "Role Management",
                isShow: 0,
                // handleClick: () => handleClick(),
            },
            {
                img: Images.TagManagement,
                activeImg: Images.ActiveTagManagement,
                path: "/tag-management",
                name: "Tag Management",
                isShow: selectedPremiseID ? 1 : 0,
                // handleClick: () => handleClick(),
            },
            {
                img: Images.TagManagement,
                activeImg: Images.ActiveTagManagement,
                path: "/tag-management/rooms/:id",
                name: "Tag Management",
                isShow: 0,
                // handleClick: () => handleClick(),
            },
            {
                isShow: selectedPremiseID ? 2 : 0,
            },
            {
                img: Images.Premises,
                activeImg: Images.ActivePremises,
                path: "/premises",
                name: "Premises",
                // handleClick: () => handleClick(),
                // handleClick: () => {
                //     selectedPremiseID && localStorage.removeItem("selectedPremiseID")
                //     // window.location.reload();
                // },
                isShow: 1,
            },
            {
                img: Images.Settings,
                activeImg: Images.ActiveSetting,
                path: "/settings",
                name: "Settings",
                isShow: 1,
                // handleClick: () => handleClick("/setting"),
            },
        ]


    }
    else if (userType === 4) {
        route = getStorageData()?.menuPermissions?.length === 0 ?
            [{
                img: Images.Dashboard,
                activeImg: Images.ActiveDashboard,
                path: "/dashboard",
                name: "Dashboard",
                menuName: "DASHBOARD",
                isShow: 1,
                // handleClick: () => handleClick(),
            }] :
            // hotelUserRoute.filter((item) => getStorageData().menuPermissions.includes(item.menuName))
            //     .concat([{
            //         img: Images.Dashboard,
            //         activeImg: Images.ActiveDashboard,
            //         path: "/dashboard",
            //         name: "Dashboard",
            //         menuName: "DASHBOARD",
            //         isShow: 1,
            //         // handleClick: () => handleClick(),
            //     }])
            // (() => {
            //     const filteredRoutes = hotelUserRoute.filter((item) => menuPermissions.includes(item.menuName));
            //     console.log("filteredRoutes in routesData", filteredRoutes);
            //     const hasDashboard = filteredRoutes.some(route => route.menuName === "DASHBOARD");
            //     if (!hasDashboard) {
            //         filteredRoutes.push({
            //             img: Images.Dashboard,
            //             activeImg: Images.ActiveDashboard,
            //             path: "/dashboard",
            //             name: "Dashboard",
            //             menuName: "DASHBOARD",
            //             isShow: 1,
            //             // handleClick: () => handleClick(),
            //         });
            //     }
            //     return filteredRoutes;
            // })();
            (() => {
                const filteredRoutes = hotelUserRoute.filter((item) => menuPermissions.includes(item.menuName));
                console.log("filteredRoutes in routesData", filteredRoutes);
                const dashboardRoute = hotelUserRoute.find(route => route.menuName === "DASHBOARD");
                // const hasDashboard = filteredRoutes.some(route => route.menuName === "DASHBOARD");
                if (!filteredRoutes.some(route => route.menuName === "DASHBOARD")) {
                    filteredRoutes.unshift(dashboardRoute);
                }
                return filteredRoutes;
            })();
    }
    return route;
}

export default useRoutes;