// import { createStore } from "redux";

// import rootReducer from "./reducers";

// // const sagaMiddleware = createSagaMiddleware();
// // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//   rootReducer
// );
// // sagaMiddleware.run(rootSaga);

// export default store;

import { configureStore } from "@reduxjs/toolkit"
import APIResponseSlice from "./slices/APIResponseSlice"

export const store = configureStore({
  reducer: {
    // MSALAuth: APISlice,
    APIResponse: APIResponseSlice,
  },
})
