import React, { useEffect, useState } from 'react'
import { webUserSearchApiCall } from '../../Utils/searchApiCall';
import { convertLocalDate } from '../../Utils/configController';
import { ToastContainer } from 'react-bootstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from '../../components/Common/DataTable';
import * as url from '../../helpers/url_helper';

const Logs = () => {
    const [webUsersList, setWebUserList] = useState<any>([]);
    const [dataTableId, setDataTableId] = useState<any>(null);
    const [isOpneRequestModel, setOpenRequestModel] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<any>({});
    const [isOpenResponseModel, setOpenResponseModel] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<any>(null);
    const [expanded, setExpanded] = useState<any>({});
    const [searchWebValue, setSearchWebValue] = useState<string>("all")
    console.log("webUsersList", webUsersList);

    const columns = [
        {
            title: 'Created',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdAt ? convertLocalDate(row.createdAt) : "-"} </div>
             </div>`
            }
        },
        {
            title: 'User',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.userId?.email ? row?.userId?.email : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Type',
            render: function (data: any, type: any, row: any) {
                const userType =
                    row?.userId?.type === 0 ? "Admin" :
                        row?.userId?.type === 1 ? "Sub Admin" :
                            row?.userId?.type === 4 ? "Hotel User" : "-"
                return `
               <div>
                <div style="color: black; fontweight: bold">${userType} </div>
             </div>`
            }
        },
        {
            title: 'Method',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.method ? row.method : "-"} </div>
             </div>`
            }
        },
        {
            title: 'IP Address',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.ipAddress ? row.ipAddress : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Request',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
               <button class="delete-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">View</span></button>
               </div>
             </div>`
            }
        },
        {
            title: 'Response',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
               <button class="edit-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">View</span></button>
               </div>
             </div>`
            }
        },
        {
            title: 'OS Name',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdUserDeviceId?.osName ? row?.createdUserDeviceId?.osName : "-"} </div>
             </div>`
            }
        },
        {
            title: 'OS Version',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdUserDeviceId?.osVersion ? row?.createdUserDeviceId?.osVersion : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Device Info',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdUserDeviceId?.deviceInfo ? row?.createdUserDeviceId?.deviceInfo : "-"} </div>
             </div>`
            }
        },
    ]

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const handleViewRequest = (rowData: any) => {
        console.log("request view data", rowData);
        setOpenRequestModel(true);
        // const beautifiedJSON = JSON.stringify(rowData.request, null, 2);
        setRequestData(rowData?.request)
    }

    const handleViewResponse = (rowData: any) => {
        console.log("request view data", rowData);
        setOpenResponseModel(true);
        // const beautifiedJSON = JSON.stringify(rowData.response, null, 2);
        setResponseData(rowData?.response)
    }

    const toggleRequestModel = () => {
        setOpenRequestModel(!isOpneRequestModel)
    }

    const toggleResponsetModel = () => {
        setOpenResponseModel(!isOpenResponseModel)
    }

    const toggleExpand = (key: any) => {
        setExpanded((prevExpanded: any) => ({
            ...prevExpanded,
            [key]: !prevExpanded[key]
        }));
    };

    const renderValue = (value: any, key: any) => {
        const type = typeof value;

        if (type === 'object' && value !== null) {
            if (Array.isArray(value)) {
                return (
                    <div className="json__array">
                        {value.map((item, index) => (
                            <div key={index} className="json__item json__item--array">
                                {renderValue(item, index)}
                            </div>
                        ))}
                    </div>
                );
            } else {
                const isExpanded = expanded[key];
                const arrow = isExpanded ? '▼' : '▶';
                return (
                    <div className="json__object">
                        <div className="json__key" onClick={() => toggleExpand(key)}>{arrow} {key}:</div>
                        {isExpanded && (
                            <div className="json__value">
                                {Object.keys(value).map((nestedKey) => (
                                    <div key={nestedKey} className="json__nested">
                                        {renderValue(value[nestedKey], nestedKey)}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            }
        } else {
            return (
                <div className="json__primitive">
                    <span className="json__key">{key}:</span>
                    <span className={`json__value json__value--${type}`}>{JSON.stringify(value)}</span>
                </div>
            );
        }
    };

    const searchWebUsers = (e: any) => {
        webUserSearchApiCall(e, setWebUserList);
    }

    useEffect(() => {
        searchWebUsers("all")
    }, [])

    const setFilterValue = (e: any) => {
        console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", e.value);
        setSearchWebValue(e.value);
    }

    return (
        <div>
            <ToastContainer />

            <Modal
                isOpen={isOpneRequestModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleRequestModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Request"}</ModalHeader>
                    <ModalBody>
                        {/* {requestData} */}
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(requestData, null, 2)}
                        </pre>
                        {/* {Object.keys(requestData).map((key) => (
                            <div key={key} className="json__item">
                                {renderValue(requestData[key], key)}
                            </div>
                        ))} */}
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'center' }}>
                        <button
                            className="btn btn-dark"
                            type="submit"
                            onClick={() => setOpenRequestModel(false)}
                        >
                            Ok
                        </button>
                    </ModalFooter>
                </div>
            </Modal>

            {/* VIEW RESPONSE MODEL */}
            <Modal
                isOpen={isOpenResponseModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleResponsetModel}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Response"}</ModalHeader>
                    <ModalBody>
                        {/* {responseData} */}
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {JSON.stringify(responseData, null, 2)}
                        </pre>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: 'center' }}>
                        <button
                            className="btn btn-dark"
                            type="submit"
                            onClick={() => setOpenResponseModel(false)}
                        >
                            Ok
                        </button>
                    </ModalFooter>
                </div>
            </Modal>
            <DataTable
                apiURL={`${url.BASE_URL}${url.GET_USER_ACTION_LIST}`}
                columns={columns}
                paramsId={{ userId: searchWebValue }}
                // title="Logs"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                // handleEditClick={handleEditClick}
                handleDeleteClick={handleViewRequest}
                handleEditClick={handleViewResponse}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"LogsList"}
                // optionGroup={webUsersList}
                isShowSearch={false}
                showSearchebleFilter={true}
                searchableFilterOption={webUsersList}
                filterValue={searchWebValue}
                setFilterValue={setFilterValue}
                searchAPICall={(e: any) => searchWebUsers(e)}
            />
        </div>
    )
}

export default Logs;