import AdminDashboard from "../pages/Admin/AdminDashboard/AdminDashboard";
import UserDeviceDashboard from "../pages/Admin/UserDashboard/UserDeviceDashboard";
import Admin from "../pages/Admin/Area";
import ChipsLogs from "../pages/Admin/Users/ProfileList/ChipsDetail/ChipLogs";
import ProfileList from "../pages/Admin/Users/ProfileList/ProfileList";
import Users from "../pages/Admin/Users/Users";
import Dashboard from "../pages/Premises/Dashboard";
import Service from "../pages/Premises/Service";
import Settings from "../pages/Premises/Settings";
import SubAdmins from "../pages/Premises/SubAdmins";
import Permissions from "../pages/Premises/SubAdmins/Permissions";
import React from "react";
import StaticOtp from "../pages/Admin/StaticOtp";
import SendNotification from "../pages/Admin/SendNotification";
import SchedulesLogs from "../pages/Admin/SchedulesLogs";
import Master from "../pages/Admin/Master";
import Firmware from "../pages/Admin/Firmware/Firmware";
import DebugDevice from "../pages/Admin/DebugDevice";
import Logs from "../pages/Admin/Logs";
import Feedback from "../pages/Admin/Feedback";
import Backup from "../pages/Admin/Backup";
import DebugDetail from "../pages/Admin/DebugDetail";
import Testing from "../pages/Admin/Testing/Testing";
import TestingDetailsList from "../pages/Admin/Testing/TestingDetailsList";
import TestingDetailsOfDetails from "../pages/Admin/Testing/TestingDetailsOfDetails";

const adminRoutes = [
    { path: "/premises", component: <Dashboard /> },
    { path: "/sub-admins", component: <SubAdmins /> },
    { path: "/service-person", component: <Service /> },
    { path: "/settings", component: <Settings /> },
    { path: "/sub-admins/permissions", component: <Permissions /> },
    { path: "/users", component: <Users /> },
    { path: "/users/profile/:id", component: <ProfileList /> },
    { path: "/users/profile/device/:slaveId", component: <ChipsLogs /> },
    { path: "/area", component: <Admin /> },
    { path: "/dashboard/request-dashboard", component: <AdminDashboard /> },
    { path: "/dashboard/request-dashboard/device-dashboard", component: <UserDeviceDashboard /> },
    { path: "/static-otp", component: <StaticOtp /> },
    { path: "/send-notification", component: <SendNotification /> },
    { path: "/firmware", component: <Firmware /> },
    { path: "/schedules-logs", component: <SchedulesLogs /> },
    { path: "/master", component: <Master /> },
    { path: "/debug-device", component: <DebugDevice /> },
    { path: "/debug-device-details/:slave", component: <DebugDetail /> },
    { path: "/logs", component: <Logs /> },
    { path: "/feedback", component: <Feedback /> },
    { path: "/backup", component: <Backup /> },
    { path: "/testing", component: <Testing /> },
    { path: "/testing/details-of-testing/:id", component: <TestingDetailsList /> },
    { path: "/testing/testing-details-of-details/:id", component: <TestingDetailsOfDetails /> },
];

export { adminRoutes };