import { useDispatch } from "react-redux";
import getStorageData from "../Utils/getStorageData";
import axiosInstance from "../helpers/api";
import * as url from "../helpers/url_helper";
import { changePermission } from "../store/slices/APIResponseSlice";

export const getMenuPermissions = async (premiseId: string, dispatch: any) => {
    // console.log("requestPayload", requestPayload);
    const reqObj = {
        premiseId: premiseId ? premiseId : getStorageData().selectedPremiseID
    }
    try {
        const response = await axiosInstance.post(url.GET_HOTEL_USER_PERMISSIONS, reqObj)

        console.log("get hotel user permissions", response);
        const permission = JSON.stringify(response.data.menuPermission) === JSON.stringify(getStorageData().menuPermissions);
        console.log("check permissions", permission);
        dispatch(changePermission(permission))
        localStorage.setItem("permissions", JSON.stringify(response.data.menuPermission))

        return response.data
    } catch (error: any) {
        console.log('errorerrorerror', error)
        return error.response
    }
}

export const getRealTimeData = async () => {
    // console.log("requestPayload", requestPayload);
    try {
        const response = await axiosInstance.get(url.GET_REAL_TIME_DATA)
        console.log("get real time chart", response);
        return response.data
    } catch (error: any) {
        console.log('errorerrorerror', error)
        return error.response
    }
}

export const getOnlineChipsData = async () => {
    // console.log("requestPayload", requestPayload);
    try {
        const response = await axiosInstance.get(url.GET_ONLINE_CHIP_DATA)
        console.log("get online chips chart", response);
        return response.data
    } catch (error: any) {
        console.log('errorerrorerror', error)
        return error.response
    }
}

export const getOfflineChipsData = async () => {
    // console.log("requestPayload", requestPayload);
    try {
        const response = await axiosInstance.get(url.GET_OFFLINE_CHIP_DATA)
        console.log("get offline chips chart", response);
        return response.data
    } catch (error: any) {
        console.log('errorerrorerror', error)
        return error.response
    }
}

export const getMQTTData = async () => {
    // console.log("requestPayload", requestPayload);
    try {
        const response = await axiosInstance.get(url.GET_MQTT_DATA)
        console.log("get mqtt chart", response);
        return response.data
    } catch (error: any) {
        console.log('errorerrorerror', error)
        return error.response
    }
}