import axios from 'axios';
import { BASE_URL } from './url_helper';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("Authorization");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        // const token = localStorage.getItem("Authorization");
        // response.headers.Authorization = token ? `Bearer ${token}` : "";
        return Promise.resolve(response);
    },
    (error) => {
        if (error !== null && error?.response?.status === 401) {
            // window.localStorage.clear();
            localStorage.removeItem("userInfo");
            localStorage.removeItem("selectedPremiseID");
            localStorage.removeItem("selectedUserID");
            localStorage.removeItem("selectedUserEmail");
            localStorage.removeItem("Authorization");
            localStorage.removeItem("permissions");
            window.location.reload();
        }

        return Promise.reject((error) || 'Something went wrong!');
    }
);

export default axiosInstance;