import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'
import { customStyles } from './style'
import Lottie from 'lottie-react'
import Slider from 'react-rangeslider'
import { Images } from '../../../constants/images'
import { useSocket } from '../../../Utils/socket'
import { OPERATE_CHIP } from '../../../helpers/socketEvent'
import { jsonFileName } from '../../../constants/jsonFileName'

export interface SwitchDataProps {
    switchData: any;
    getChipStatus: () => void;
}


const SwitchData = ({
    switchData,
    getChipStatus }: SwitchDataProps) => {
    const socket: any = useSocket();
    const [value, setValue] = useState<number>(0)
    // console.log("value", value);

    const chipSwitchId = switchData
    // console.log("switchData in switchData component", switchData);
    const modelType = chipSwitchId?.switchItem?.modelType
    const switchId = chipSwitchId?.switchItem?.id
    const switchType = chipSwitchId?.switchItem?.chipSwitchId?.type;
    const switchIcon = chipSwitchId?.switchItem?.chipSwitchId?.icon?.toLowerCase();
    const isMenually = switchData?.isMenually
    console.log("isMenually", isMenually);

    const switchName = chipSwitchId?.switchItem?.chipSwitchId?.displayName;
    const isClickable = switchData?.isClickable;
    console.log("isClickable", switchData);

    const isOnOff = switchData?.isOnOff;
    const switchDValue = chipSwitchId?.switchDetail?.dval;
    const button = chipSwitchId?.switchItem?.chipSwitchId?.button;

    const [dValue, setDValue] = useState<string>("1");
    const [switchValue, setSwitchValue] = useState<string>(switchDValue);
    const [fanLevel, setFanLavel] = useState<string>("1");
    const [fileExists, setFileExists] = useState<boolean>(false);
    console.log("switchValue", switchDValue, switchValue);

    useEffect(() => {
        setSwitchValue(switchDValue)
    }, [switchDValue])

    useEffect(() => {
        if (switchType === 1) {
            if (switchDValue === "19" || switchValue === "19") {
                setFanLavel("1");
                setValue(0)
            } else if (switchDValue === "32" || switchValue === "32") {
                setFanLavel("2");
                setValue(33.33)
            } else if (switchDValue === "4b" || switchValue === "4b") {
                setFanLavel("3");
                setValue(66.66)
            } else if (switchDValue === "64" || switchValue === "64") {
                setFanLavel("4");
                setValue(99.99)
            }
        } else if (switchType === 2) {
            if (switchDValue === "0a" || switchValue == "0a") {
                setDValue("1");
                setValue(0)
            } else if (switchDValue === "14" || switchValue == "14") {
                setDValue("2");
                setValue(11.11)
            } else if (switchDValue === "1e" || switchValue == "1e") {
                setDValue("3");
                setValue(22.22)
            } else if (switchDValue === "28" || switchValue == "28") {
                setDValue("4");
                setValue(33.33)
            } else if (switchDValue === "32" || switchValue == "32") {
                setDValue("5");
                setValue(44.44)
            } else if (switchDValue === "3c" || switchValue == "3c") {
                setDValue("6");
                setValue(55.55)
            } else if (switchDValue === "46" || switchValue == "46") {
                setDValue("7");
                setValue(66.66)
            } else if (switchDValue === "50" || switchValue == "50") {
                setDValue("8");
                setValue(77.77)
            } else if (switchDValue === "5a" || switchValue == "5a") {
                setDValue("9");
                setValue(88.88)
            } else if (switchDValue === "64" || switchValue == "64") {
                setDValue("10");
                setValue(99.99)
            }
        }
    }, [switchValue, switchDValue])

    const handleChangeEnd = () => {
        operateChip("isOnOrOff")
    };

    const operateChip = (isOnOrOff: any) => {
        console.log("hnfghnfghjg");

        const button = chipSwitchId?.chipSwitchId?.button;
        var executeVal
        if (switchType === 0 || switchType === 6 || switchType === 7) {
            if (chipSwitchId?.switchDetail?.val === "0") {
                executeVal = "XX1"
            } else {
                executeVal = "XX0"
            }
        } else if (switchType === 1 || switchType === 2) {
            if (isOnOrOff) {
                if (chipSwitchId?.switchDetail?.val === "0") {
                    executeVal = `${switchValue}0`
                } else {
                    executeVal = `${switchValue}1`
                }
            }
            else if (chipSwitchId?.switchDetail?.val === "1") {
                executeVal = `${switchValue}0`
            } else {
                executeVal = `${switchValue}1`
            }
        }

        const reqObj = {
            slave: chipSwitchId?.switchItem?.chipSwitchId?.slave,
            button: chipSwitchId?.switchItem?.chipSwitchId?.button,
            executeVal: executeVal,
            chipSwitchId: chipSwitchId?.switchItem?.id,
            chipId: chipSwitchId.switchDetail?.id,
            cmd: "ST"
        }
        console.log("reqObj: ", reqObj);
        socket.emit(OPERATE_CHIP, JSON.stringify(reqObj))
        // setTimeout(() => {
        //     getChipStatus()
        // }, 500)
    }

    const switchUI = () => {
        const icon =
            isOnOff === "1" && isClickable ?
                switchIcon ?
                    switchIcon :
                    "surface_light" :
                isOnOff === "0" || isOnOff === "1" && !isClickable ?
                    switchIcon ?
                        `${switchIcon}_off` :
                        "surface_light_off" :
                    `surface_light_off`

        const fileExist = jsonFileName.includes(`${icon}.json`)
        console.log("check file exist", icon, jsonFileName.includes(`${icon}.json`))
        return (
            <Col xl="3" >
                <Card
                    style={{ ...customStyles.handleRoom(isClickable, isOnOff), position: 'relative', zIndex: 1 }}
                    onClick={() => isClickable ? operateChip("") : ""}
                >
                    {!isClickable &&
                        <div style={{ color: "white", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: 1 }}>
                            <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                        </div>}
                    <CardBody>
                        <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                            <Lottie
                                animationData={
                                    fileExist ?
                                        require(`../../../assets/Lottie_json/${icon}`) :
                                        !fileExist && isOnOff === "0" ?
                                            require(`../../../assets/Lottie_json/surface_light_off.json`) : !fileExist && isOnOff === "1" &&
                                            require(`../../../assets/Lottie_json/surface_light.json`)
                                }
                                loop={true}
                                size={5}
                                style={{ width: 50, height: 50 }}
                            />
                            <p style={{ ...customStyles.switchName(isClickable, isOnOff) }}>{switchName}</p>
                        </div>

                    </CardBody>
                </Card>
            </Col >
        )
    }

    const dimmerUI = () => {
        const icon =
            isOnOff === "1" && isClickable ?
                switchIcon ?
                    switchIcon :
                    "garden_light" :
                isOnOff === "0" || isOnOff === "1" && !isClickable ?
                    switchIcon ?
                        `${switchIcon}_off` :
                        "garden_light_off" :
                    `garden_light_off`

        const fileExist = jsonFileName.includes(`${icon}.json`)
        return (
            <Col xl="6">
                <Card
                    style={{
                        ...customStyles.handleRoom(isClickable, isOnOff),
                    }}
                    onClick={() => isClickable ? operateChip("") : ""}
                >
                    {!isClickable &&
                        <div style={{ color: "white", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: 1 }}>
                            <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                        </div>}
                    <CardBody>
                        <Row>
                            <Col md={5}>
                                <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                                    <Lottie
                                        animationData={
                                            fileExist ?
                                                require(`../../../assets/Lottie_json/${icon}`) :
                                                !fileExist && isOnOff === "0" ?
                                                    require(`../../../assets/Lottie_json/garden_light_off.json`) :
                                                    !fileExist && isOnOff === "1" &&
                                                    require(`../../../assets/Lottie_json/garden_light.json`)
                                        }
                                        loop={true}
                                        size={5}
                                        style={{ width: 50, height: 50 }}
                                    />
                                    <p style={{ ...customStyles.switchName(isClickable, isOnOff) }}>{switchName}</p>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: 10 }}>
                                        <p style={customStyles.dimmerValueStyle(isClickable, isOnOff)}>Temp</p>
                                        <p style={customStyles.dimmerValueStyle(isClickable, isOnOff)}>{dValue}</p>
                                    </div>
                                    <div className="slider-container"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Slider
                                            step={11.11}
                                            value={value}
                                            orientation="horizontal"
                                            min={0}
                                            max={100}
                                            tooltip={false}
                                            onChangeComplete={handleChangeEnd}
                                            onChange={(value: any) => {
                                                if (value === 0) {
                                                    setSwitchValue("0a")
                                                } else if (value === 11.11) {
                                                    setSwitchValue("14")
                                                } else if (value === 22.22) {
                                                    setSwitchValue("1e")
                                                } else if (value === 33.33) {
                                                    setSwitchValue("28")
                                                } else if (value === 44.44) {
                                                    setSwitchValue("32")
                                                } else if (value === 55.55) {
                                                    setSwitchValue("3c")
                                                } else if (value === 66.66) {
                                                    setSwitchValue("46")
                                                } else if (value === 77.77) {
                                                    setSwitchValue("50")
                                                } else if (value === 88.88) {
                                                    setSwitchValue("5a")
                                                } else if (value === 99.99) {
                                                    setSwitchValue("64")
                                                }
                                                setValue(value)
                                            }
                                            }
                                        // handleLabel=""
                                        // onChangeStart={handleChangeStart}
                                        // handle={(props: any) => <CustomHandle {...props} />}
                                        // ={<CustomHandle value={value} />}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col >
        )
    }

    const FanUI = () => {
        const icon = isOnOff === "1" && isClickable ? switchIcon ? switchIcon : "fan" : isOnOff === "0" || isOnOff === "1" && !isClickable ? switchIcon ? `${switchIcon}_off` : "fan_off" : `${switchIcon}_off`
        const fileExist = jsonFileName.includes(`${icon}.json`)
        return (
            <Col xl="6" >
                <Card
                    onClick={() => isClickable ? operateChip("") : ""}
                    style={{
                        ...customStyles.handleRoom(isClickable, isOnOff),
                        position: "relative"
                    }}
                >
                    {!isClickable &&
                        <div style={{ color: "white", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", zIndex: 1 }}>
                            <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                        </div>}
                    <CardBody>
                        <Row>
                            <Col md={5}>
                                <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                                    <Lottie
                                        animationData={
                                            fileExist ?
                                                require(`../../../assets/Lottie_json/${icon}`) :
                                                !fileExist && isOnOff === "0" ?
                                                    require(`../../../assets/Lottie_json/fan_off.json`) :
                                                    !fileExist && isOnOff === "1" &&
                                                    require(`../../../assets/Lottie_json/fan.json`)
                                        }
                                        loop={true}
                                        size={5}
                                        style={{ width: 50, height: 50 }}
                                    />
                                    <p style={customStyles.switchName(isClickable, isOnOff)}>{switchName}</p>
                                </div>
                            </Col>
                            <Col
                                md={7}
                                style={{ alignSelf: "center", display: "block" }}>
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: 10 }}>
                                        <p
                                            style={customStyles.dimmerValueStyle(isClickable, isOnOff)}
                                        >
                                            Speed
                                        </p>
                                        <p
                                            style={customStyles.dimmerValueStyle(isClickable, isOnOff)}
                                        >{fanLevel}</p>
                                    </div>
                                    <div className="slider-container" onClick={(e) => e.stopPropagation()} >
                                        <Slider
                                            step={33.33}
                                            value={value}
                                            orientation="horizontal"
                                            onChange={(value: any) => {
                                                if (value === 0) {
                                                    setSwitchValue("19")
                                                } else if (value === 33.33) {
                                                    setSwitchValue("32")
                                                } else if (value === 66.66) {
                                                    setSwitchValue("4b")
                                                } else {
                                                    setSwitchValue("64")
                                                }
                                                setValue(value)
                                            }
                                            }
                                            min={0}
                                            max={100}
                                            tooltip={false}
                                            onChangeComplete={handleChangeEnd}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col >
        )
    }

    const curtainUI = () => {
        return (
            <Col xl="6" onClick={() => !isClickable ? console.log("click on curtain UI") : ""}>
                <Card
                    onClick={() => !isClickable ? console.log("click on Fan UI") : ""}
                    style={{
                        ...customStyles.handleRoom(isClickable, isOnOff),
                    }}
                >
                    <CardBody>
                        <Row>
                            <Col md={5}>
                                <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                                    <Lottie
                                        animationData={require(`../../../assets/Lottie_json/curtain_opened`)}
                                        loop={true}
                                        size={5}
                                        style={{ width: 50, height: 50 }}
                                    />
                                    <p style={customStyles.switchName(isClickable, isOnOff)}>{switchName}</p>
                                </div>
                            </Col>
                            <Col md={5} style={{ justifyContent: "flex-end" }}>
                                <div style={{ alignItems: "center", display: "flex", height: "100%", color: "#AAAAAA", justifyContent: "flex-end" }}>
                                    <img src={Images.OpenIcon} />
                                </div>
                            </Col>
                            <Col md={1}>
                                <div style={{ alignItems: "center", display: "flex", height: "100%", color: "#AAAAAA", justifyContent: "center" }}>
                                    <img src={Images.PauseIcon} />
                                </div>
                            </Col>
                            <Col md={1}>
                                <div style={{ alignItems: "center", display: "flex", height: "100%", color: "#AAAAAA", justifyContent: "center" }}>
                                    <img src={Images.CloseIcon} />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col >
        )
    }
    return (
        <>
            {switchType === 0 && switchUI()}
            {/* {switchType === 7 && switchUI()} */}
            {switchType === 6 && modelType === "4HIN" && ["03", "04", "05"].indexOf(button) === -1 && switchId !== "" && switchUI()}
            {switchType === 6 && modelType === "4HISC" && ["04", "05", "06"].indexOf(button) === -1 && switchId !== "" && switchUI()}
            {switchType === 1 && FanUI()}
            {switchType === 2 && dimmerUI()}
            {switchType === 4 && curtainUI()}
        </>
    )
}

export default SwitchData;