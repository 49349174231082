const getStorageData = () => {
    const userInfo = localStorage.getItem("userInfo");
    const selectedPremiseID = localStorage.getItem("selectedPremiseID");
    const selectedPremiseEmail = localStorage.getItem("selectedUserEmail");
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const token = localStorage.getItem("Authorization");

    const user = userInfo ? JSON.parse(userInfo) : null;

    const userType = user?.type; // Assign a default value if type is not present
    const menuPermissions = permissions; // Assign a default value if type is not present


    return { user, userType, selectedPremiseID, selectedPremiseEmail, token, menuPermissions }
}

export default getStorageData;