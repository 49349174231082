// import React, { useState, useEffect } from 'react';
// import ReactEcharts from "echarts-for-react";
// import { getRealTimeData } from '../../../services/APIServices';

// const RealTimeChart = ({ data }: any) => {
//   console.log("data=========>", data);

//   const [CPUData, setCPUData] = useState();
//   const [memoryData, setMemoryData] = useState();
//   const [chartData, setChartData] = useState({
//     tooltip: {
//       trigger: "axis",
//     },
//     grid: {
//       zlevel: 0,
//       x: 50,
//       x2: 50,
//       y: 30,
//       y2: 30,
//       borderWidth: 0,
//     },
//     xAxis: {
//       type: "category",
//       data: [],
//       axisLine: {
//         lineStyle: {
//           color: "#8791af",
//         },
//       },
//     },
//     yAxis: {
//       type: "value",
//       axisLine: {
//         lineStyle: {
//           color: "#8791af"
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: "rgba(166, 176, 207, 0.1)"
//         }
//       }
//     },
//     series: [
//       {
//         data: [],
//         type: "line",
//       },
//     ],
//     color: "#14C9C9",
//     textStyle: {
//       color: ["#8791af"],
//     },
//   });



//   // const getChartData = () => {
//   //   getRealTimeData().then((res: any) => {
//   //     console.log("realtime chart", res);
//   //     setCPUData(res.cpu);
//   //     setMemoryData(res.memory);
//   //   }).catch((err: any) => {
//   //     console.log("realtime chart error", err);
//   //   })
//   // }

//   // useEffect(() => {
//   //   getChartData()
//   // }, [])

//   // useEffect(() => {
//   //   const intervalId = setInterval(() => {
//   //     getChartData();
//   //   }, 1000)

//   //   return () => clearInterval(intervalId);
//   // }, [])

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Generate random data for demonstration
//       const newDataPoint = Math.floor(Math.random() * 100);
//       const newTime = new Date().toLocaleTimeString();

//       // Update chart data
//       setChartData((prevData: any) => {
//         const newSeriesData = [...prevData.series[0].data];
//         console.log("newSeriesData", newSeriesData);
//         newSeriesData.length > 6 ?
//           newSeriesData.shift() : // Remove the first element
//           newSeriesData.push(data); // Add new data point
//         return {
//           ...prevData,
//           xAxis: {
//             ...prevData.xAxis,
//             data: prevData.xAxis.data.length > 6 ? [...prevData.xAxis.data.slice(1), newTime] : [...prevData.xAxis.data, newTime] // Add new time point
//           },
//           series: [
//             {
//               ...prevData.series[0],
//               data: newSeriesData,
//             },
//           ],
//         };
//       });
//     }, 1000); // Update every second

//     return () => clearInterval(interval);
//   }, [data]);

//   return <ReactEcharts style={{ height: "350px" }} option={chartData} />
// };

// export default RealTimeChart;

import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { convertLocalTime } from '../../../Utils/configController';
import moment from 'moment';
import accessibility from 'highcharts/modules/accessibility';

const MQTTChart = ({ MQTTSmartNodeData, MQTTWhitelionData, MQTTSmartNodeTitle, MQTTWhitelionTitle }: any) => {
    console.log(
        "MQTTSmartNodeData=========>", MQTTSmartNodeData
    );
    accessibility(Highcharts);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'line',
        },
        title: {
            text: '',
        },
        yAxis: {
            title: {
                text: '', // Set yAxis title to empty string
            },
        },
        xAxis: {
            type: 'category',
            categories: [], // Initialize with empty categories
        },
        series: [
            {
                name: 'SMARTNODE',
                data: [],
            },
            {
                name: 'WHITELION',
                data: [],
            },
            // {
            //     name: 'ALEXA',
            //     data: [],
            // },
            // {
            //     name: 'GOOGLE',
            //     data: [],
            // },
        ],
        credits: {
            enabled: false, // Disable Highcharts.com credits
        },
        accessibility: {
            enabled: true
        }
    });
    console.log("chartOptions", chartOptions);

    useEffect(() => {
        const categories = MQTTSmartNodeData.map((item: any) => moment(item.readingDate).format('YYYY-MM-DD')); // Extract readingDate for XAxis categories
        const seriesData = MQTTSmartNodeData.map((item: any) => item.commandCount); // Extract commandCount for series data
        const seriesData1 = MQTTWhitelionData.map((item: any) => item.commandCount); // Extract commandCount for series data
        // const seriesData2 = MQTTAlexaData.map((item: any) => item.commandCount); // Extract commandCount for series data
        // const seriesData3 = MQTTGoogleData.map((item: any) => item.commandCount); // Extract commandCount for series data

        setChartOptions(options => ({
            ...options,
            xAxis: {
                ...options.xAxis,
                categories: categories,
            },
            series: [
                {
                    ...options.series[0],
                    name: MQTTSmartNodeTitle,
                    data: seriesData,
                },
                {
                    ...options.series[1],
                    name: MQTTWhitelionTitle,
                    data: seriesData1,
                },
                // {
                //     ...options.series[2],
                //     data: seriesData2,
                // },
                // {
                //     ...options.series[3],
                //     data: seriesData3,
                // },
            ],
        }));
    }, [MQTTSmartNodeData]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
};

export default MQTTChart;
