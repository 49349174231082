import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import 'datatables.net';
import 'datatables.net-bs4';
import $ from 'jquery';
import { Card, CardBody } from 'reactstrap';
import getStorageData from '../../Utils/getStorageData';
import { PremisesListData, PremisesListResponse } from '../../helpers/response/Admin/PremiseListResponse';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Select from 'react-select';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import { useSelector } from 'react-redux';

export interface interfaceDataTable<T> {
    responseType?: T;
    apiURL: string;
    columns: any[];
    btnName?: string;
    isAddBtnShow?: boolean;
    btnStyle?: any;
    onClickBtn?: () => void;
    handleDeleteClick?: (data: any) => void;
    onDataTableRefresh?: (dataTableId: any) => void;
    idName: string;
    handleColumnClicked?: (data: any) => void | undefined;
    paramsId?: any;
    isEntryShow?: boolean;
    isClicked?: boolean;
    showFilterOpetions?: boolean;
    optionGroup?: any[];
    isReload?: boolean;
}

const UsersDataTable = <T,>({
    apiURL,
    columns,
    btnName,
    isAddBtnShow,
    btnStyle,
    onClickBtn,
    handleDeleteClick,
    onDataTableRefresh,
    idName,
    handleColumnClicked,
    paramsId,
    isEntryShow,
    isClicked,
    optionGroup,
}: interfaceDataTable<T>) => {
    const selectedList = useSelector((state: any) => state.APIResponse.selectedAdvancedFilter);
    const { width } = useWindowDimensions();
    const [tableData, setTableData] = useState<PremisesListData[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectFilter, setSelectFilter] = useState<any>({
        label: "First Name / Last Name",
        value: 0
    });
    const [searchValue, setSearchValue] = useState("");

    const tableRef: any = useRef<HTMLTableElement>(null);
    const userType = getStorageData().userType;
    const token = localStorage.getItem("Authorization");

    const fetchData = (dataTablesData: any, callback: any) => {
        $('#customLoader').show();
        const reqObj = {
            ...dataTablesData,
            ...paramsId,
            filterType: selectFilter.value,
            search: {
                value: searchValue,
                regex: false
            }
        };
        axios.post(apiURL, reqObj, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            const res: PremisesListResponse = response.data;
            $('#customLoader').hide();
            callback({
                draw: dataTablesData.draw,
                recordsTotal: res.recordsTotal,
                recordsFiltered: res.recordsFiltered,
                data: res.data,
            });
            setTableData(res.data);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            } else {
                console.error("Error fetching data:", err);
            }
        });
    };

    const initializeData = () => {
        try {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            const dataTableInstance = $(tableRef.current).DataTable({
                processing: false,
                serverSide: true,
                ajax: fetchData,
                columns: columns,
                searching: true,
                ordering: false,
                // dom: 'ltip',
            });
            $(tableRef.current).closest('.dataTables_wrapper').find('.dataTables_filter').hide();
            // $(tableRef.current).off('click', '.column-click'); // Remove existing event listeners
            $(tableRef.current).on('click', '.column-click', function () {
                const data = dataTableInstance.row($(this).closest('tr')).data();
                console.log("data--------->", data);

                if (!isClicked) return;
                handleColumnClicked && handleColumnClicked(data);
            });

            $(tableRef.current).closest('.dt-container').find('.dt-length').hide();
            $(tableRef.current).closest('.dt-container').find('.dt-search').hide();

            // if (!isEntryShow) {
            //     $(tableRef.current).closest('.dt-container').find('.dataTables_length').hide();
            //     $(tableRef.current).closest('.dataTables_wrapper').find('.dataTables_filter').hide();
            // }

            $(tableRef.current).off('click', '.delete-btn'); // Remove existing event listeners
            $(tableRef.current).on('click', '.delete-btn', function () {
                const rowData = dataTableInstance.row($(this).parents('tr')).data();
                handleDeleteClick && handleDeleteClick(rowData);
            });

            $(tableRef.current).on('preXhr.dt', function () {
                $('#customLoader').show();
            });

            $(tableRef.current).on('xhr.dt', function () {
                $('#customLoader').hide();
            });

            $(tableRef.current).find('thead th').css('background-color', '#EFF2F7');
        } catch (error) {
            console.error('Error initializing DataTable:', error);
        }
    };

    useEffect(() => {
        initializeData();
    }, [selectFilter, searchValue]);

    useEffect(() => {
        if (paramsId?.filter !== null) {
            initializeData();
        }
    }, [paramsId]);

    useEffect(() => {
        const dataTableId = $(tableRef.current).attr('id');
        onDataTableRefresh && onDataTableRefresh(dataTableId);
    }, []);

    const handleCustomSearch = (value: any) => {
        setSearchValue(value);
    };

    const handleSelectFilter = (selectedValue: any) => {
        setSelectFilter(selectedValue);
    }

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            fontWeight: "normal",
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#6c757d',
            fontWeight: 'normal',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#333',
            fontWeight: 'normal',
        }),
    };

    return (
        <Card style={{ marginTop: 100, marginLeft: 30, marginRight: 30, marginBottom: 0 }}>
            <CardBody>
                <div className="mb-4 h4 card-title d-flex flex-column flex-md-row justify-content-between">
                    <div className="d-flex flex-row" style={{ gap: 10 }}>
                        <div style={{ width: 500 }}>
                            <Select
                                value={selectFilter}
                                onChange={handleSelectFilter}
                                options={optionGroup}
                                className="select2-selection"
                                styles={customStyles}
                                placeholder="Select..."
                            />
                        </div>
                        <div className="app-search d-flex flex-row align-items-center" style={{ width: "100%" }}>
                            <div className="position-relative" style={{ width: "100%" }}>
                                {/* <span className="bx bx-search-alt" /> */}
                                <span><box-icon name='search-alt' color="#74788d" size="16px"></box-icon></span>
                                <input
                                    type="search"
                                    className="form-control"
                                    style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                    placeholder="Search..."
                                    onChange={(e) => handleCustomSearch(e.target.value)}
                                    value={searchValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row" style={{ gap: 20 }}>
                        {isAddBtnShow &&
                            <button className={btnStyle} onClick={onClickBtn}>
                                {btnName}
                            </button>}
                    </div>
                </div>
                <div>
                    <div id="customLoader" style={{
                        display: 'none',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                    }}>
                        <p>Processing....</p>
                    </div>
                    <div className="table-responsive">
                        <table
                            id={`dataTable${idName}`}
                            ref={tableRef}
                            className="table table-hover"
                            style={{ width: '100%', fontSize: 14, color: "#858D9D" }}
                        >
                        </table>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default UsersDataTable;
