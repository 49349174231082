import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataTable from '../../../components/Common/DataTable';
import Spinners from '../../../components/Common/Spinner';
import * as url from '../../../helpers/url_helper'
import Request from '../../../helpers/request';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import getStorageData from '../../../Utils/getStorageData';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';

const Roles = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [deleteData, setDeleteData] = useState<any>();
    const [roleList, setRoleList] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [isRoleModelOpen, setRoleModelOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    console.log("roleList", roleList);
    const validation = useFormik({
        initialValues: {
            name: "",
            formError: "",
            roleId: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Please provide your role name "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            // addRoleApiCall(values)
            isEditable ? editRoleApiCall(values) : addRoleApiCall(values)
        }
    })

    const addRoleApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            name: values.name
        }
        Request.post(url.ADD_ROLE_URL, reqObj).
            then((res) => {
                console.log("Add Role Response", res);
                toggleRole()
                // roleListApiCall()
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }
    const editRoleApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            roleId: values.roleId,
            name: values.name
        }
        Request.post(url.EDIT_ROLE_URL, reqObj).
            then((res) => {
                console.log("Edit Role Response", res);
                toggleRole()
                // roleListApiCall()
                reloadDataTable();
                toast.success(res.data.msg);
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const deleteRoleApiCall = () => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            roleId: deleteData.id,
        }
        Request.post(url.DELETE_ROLE_URL, reqObj).
            then((res) => {
                console.log("delete Role Response", res);
                validation.resetForm({
                    values: {
                        name: "",
                        roleId: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                // roleListApiCall()
                toast.success(res.data.msg);
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }
    const toggleRole = () => {
        setRoleModelOpen(!isRoleModelOpen);
        setIsEditable(false);
        roleFormReset();
    }

    const roleFormReset = () => {
        validation.resetForm({
            values: {
                name: "",
                formError: "",
                roleId: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const roleListApiCall = () => {
        setLoading(true);
        const reqObj = {
            "draw": 1,
            "search": {
                "value": "",
            },
            premiseId: getStorageData().selectedPremiseID
        }
        Request.post(url.GET_ROLE_LIST_URL, reqObj).
            then((res) => {
                console.log("Area List Response", res);
                setRoleList(res.data.data);
                setLoading(false)
            }).
            catch((err) => {
                //  console.log("area List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }


    useEffect(() => {
        // roleListApiCall();
    }, [])

    const handleRoleNameClick = (data: any) => {
        console.log("role name click data", data)
        navigate(`/role-management/permissions/${data.name}/${data.id}`)
    }


    const columns = [
        {
            data: null,
            render: function (data: any, type: any, row: any) {
                return `<span style=" text-decoration: underline; cursor: pointer;">${row.name}</span>`; // Combining firstname and lastname for the 'Name' column
            },
            createdCell: function (cell: any, cellData: any, rowData: any) {
                $(cell).addClass('column-click'); // Add a class to identify the column
            },
            title: 'Roles'
        },
        { data: 'noOfUsers', title: 'Members' },

        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="edit-btn" style="border: none; outline: none; background: none;">
                                    <box-icon type='solid' name='edit' size="20px"></box-icon>
                                </button>
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                    <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                            </div>`
                );
            }
        }
    ]

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        // console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        setIsEditable(true);
        setRoleModelOpen(true);
        validation.setValues({
            name: rowData.name,
            roleId: rowData.id,
            formError: ""
        })
    }

    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
    };
    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={isRoleModelOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleRole}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Role" : "Add Role"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Role Name</Label>
                                <Input
                                    value={validation.values.name || ""}
                                    name="name"
                                    type={"text"}
                                    placeholder="Enter Role Name"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.name && validation.errors.name ? true : false
                                    }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <span className="error">{validation.errors.name}</span>
                                ) : null}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Delete Role?"
                    text="There are no users currently assigned to this role. Deleting this role will permanently remove it from the system. Are you sure you want to proceed?"
                    buttonText="Delete"
                    onClick={() => { deleteRoleApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={loading}
                />
            }
            {/* {loading &&
                <Spinners />} */}
            <DataTable
                // data={roleList}
                apiURL={`${url.BASE_URL}${url.GET_ROLE_LIST_URL}`}
                paramsId={{ premiseId: getStorageData().selectedPremiseID }}
                columns={columns}
                isAddBtnShow={true}
                isBtnShow={false}
                btnName="Add Role"
                btnStyle="btn btn-dark"
                onClickBtn={toggleRole}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                // editApiCall={() => { }}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Roles"}
                isClicked={true}
                handleColumnClicked={handleRoleNameClick}
            />
        </div>
    )
}

export default Roles