import React, { useEffect, useState } from 'react'
import Request from '../../../../helpers/request';
import * as url from '../../../../helpers/url_helper';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'
import ProfileListDataTable from './ProfileDataTable';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../../../../components/Common/ConfirmationModal';
import ChipDataTable from './ChipDataTable';

export interface ChipsControlProps {
    userId: any;
}

const BlasterList = ({ userId }: ChipsControlProps) => {
    const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [isReload, setReload] = useState(false);
    const [expandedRows, setExpandedRows] = useState<any>([]);

    const columns = [
        {
            title: "",
            render: function (data: any, type: any, row: any) {
                console.log("rowrowrowrow", row, expandedRows);
                const isExpanded = expandedRows.includes(row.id);
                console.log("isExpanded", isExpanded);

                const iconClass = isExpanded ? 'fas fa-caret-down' : 'fas fa-caret-right';
                console.log("iconClass", iconClass);

                // return `<div class="details-row-toggle" style="cursor: pointer";><i class="fas fa-caret-right"></i></div>`;
                return `<div class="details-row-toggle" style="cursor: pointer";><i class='bx bx-caret-right'></i></div>`;
            }
        },
        {
            title: `<input type="checkbox" />`,
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <input type="checkbox" />
                            </div>`
                );
            }
        },
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.slave ? row.slave : "-"} </div>
                </div>`
            }
        },
        {
            title: 'Device ID',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                    <div style="color: black; fontweight: bold">${row?.deviceId ? row.deviceId : "-"} </div>
                </div>`
            }
        },
        {
            title: 'Device IP',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                    <div style="color: black; fontweight: bold">${row?.deviceIp ? row.deviceIp : "-"} </div>
                </div>`
            }
        },
        {
            title: 'HW Version',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                    <div style="color: black; fontweight: bold">${row?.hwVersion ? row.hwVersion : "-"} </div>
                </div>`
            }
        },
        {
            title: 'WiFi Version',
            render: function (data: any, type: any, row: any) {
                return `
            <div>
                <div style="color: black; fontweight: bold">${row?.wifiVersion ? row.wifiVersion : "-"} </div>
            </div>`
            }
        },
        {
            title: 'Name',
            render: function (data: any, type: any, row: any) {
                return `
            <div>
                <div style="color: black; fontweight: bold">${row?.name ? row.name : "-"} </div>
            </div>`
            }
        },
        {
            title: 'Status',
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isOnline ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                       <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                                <button class="column-click" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px;width: 60px;  font-size: 12px; padding: 6px;">MQTT</span></button>
                            </div>`
                );
            }
        }
    ]

    useEffect(() => {
        if (isReload) {
            const interval = setInterval(() => {
                reloadDataTable()
            }, 3000)
            return () => clearInterval(interval);
        }
    }, [dataTableId, isReload])
    // useEffect(() => {
    //     selectedRooms.length > 0 ?
    //         reloadDataTable() : ""
    // }, [selectedRooms])

    const handleRowToggle = (rowId: number) => {
        // var updatedRows = [];
        // if (expandedRows?.includes(rowId)) {
        //     updatedRows = expandedRows.filter((id: any) => id !== rowId);
        // } else {
        //     updatedRows.push(rowId);
        // }
        // console.log("updatedRows", updatedRows);
        // setExpandedRows(updatedRows)
        // if (expandedRows.includes(rowId)) {
        //     setExpandedRows(expandedRows.filter((id: any) => id !== rowId))
        // } else {
        //     setExpandedRows([...expandedRows, rowId]);
        // }
        setExpandedRows((prev: any) => {
            const updatedRows = prev;
            console.log("updatedRowsupdatedRows", updatedRows.includes(rowId));

            if (updatedRows.includes(rowId)) {
                console.log("fgdfgghg", typeof rowId);

                updatedRows.filter((row: any) => row !== rowId)
                console.log("updatedRowsupdatedRows", updatedRows)
                // Collapse row
            } else {
                updatedRows.push(rowId); // Expand row
            }
            return updatedRows;
        });
    };

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const deleteChipApiCall = () => {
        setLoading(true);
        const reqObj = {
            blasterId: deleteData.id
        }
        console.log("delete blaster", reqObj);

        Request.post(url.DELETE_USER_BLASTER, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }


    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    const handleMQTTData = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData);
        rowData?.slave ?
            window.open(`/debug-device-details/${rowData?.slave}`, "_blank") : "";
        // rowData?.slave ?
        //     navigate(`/debug-device-details/${rowData?.slave}`) : ""
        // Add your logic here
    };

    return (
        <div>
            <ToastContainer />
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Delete Blaster?"
                    text="Are you sure you want to delete this Blaster ? This action cannot be undone."
                    buttonText="Delete"
                    onClick={() => { deleteChipApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={loading}
                />
            }
            <ChipDataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.GET_BLASTER_LIST}`}
                columns={columns}
                paramsId={{ userId: userId }}
                title="Blaster List"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                // handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                selectedRoomID={selectedRooms}
                // editApiCall={editAreaApiCall}
                handleColumnClicked={handleMQTTData}
                isClicked={true}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Blasterlist"}
                setReload={setReload}
            />
        </div>
    )
}

export default BlasterList