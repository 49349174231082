import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route, Navigate, RouteProps } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// import { authProtectedRoutes, publicRoutes } from './routes';
// Import Routes all
// import RoutesData from './routes';


// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import 'react-toastify/dist/ReactToastify.css';
import { removeConsoleLogs } from './Utils/removeConsole';
// import { socket } from './Utils/socket';
import getStorageData from './Utils/getStorageData';
import { adminRoutes } from './routes/Admin';
import { subAdminRoutes } from './routes/SubAdmin';
import { hotelUserRoutes } from './routes/HotelUser';
import SendOtp from './pages/Authentication/SendOtp';
import VerifyOTP from './pages/Authentication/VerifyOtp';
import { SocketProvider } from './Utils/socket';
import Request from './helpers/request';
import * as url from './helpers/url_helper';
import { getMenuPermissions } from './services/APIServices';
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";


// Activating fake backend


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);
// const { publicRoutes, authProtectedRoutes } = RoutesData();
// console.log("publicRoutes", publicRoutes);


// const getLayout = ({ layoutType }: any) => {
//   let Layout = VerticalLayout;
//   switch (layoutType) {
//     case layoutTypes.VERTICAL:
//       Layout = VerticalLayout;
//       break;
//     // case layoutTypes.HORIZONTAL:
//     //   Layout = HorizontalLayout;
//     //   break;
//     default:
//       break;
//   }

//   return Layout;
// };




const App = () => {




  // const selectLayoutState = (state: any) => state.Layout;
  // const LayoutProperties = createSelector(
  //   selectLayoutState,
  //   (layout) => ({
  //     layoutType: layout.layoutType,
  //   })
  // );

  // const {
  //   layoutType
  // } = useSelector(LayoutProperties);

  const Layout = VerticalLayout;

  const userInfo = localStorage.getItem("Authorization");
  const restoreConsole = removeConsoleLogs();
  const userType = getStorageData().userType

  useEffect(() => {
    // getMenuPermissions();
    // const socketConnection = socket
    // console.log("socketConnection", socketConnection);
  }, [])

  //console.log("userInfo", userInfo);

  // // Parse the retrieved data
  // const user = userInfo ? JSON.parse(userInfo) : null;
  // console.log("user", user);

  // Access the userType property
  // const userType = user?.type || 0; // Assign a default value if type is not present
  // console.log("userType", userType);
  const isAuthorized = localStorage.getItem("Authorization");
  const authProtectedRoutes =
    userType === 0 ?
      adminRoutes :
      userType === 1 ?
        subAdminRoutes :
        userType === 4 ?
          hotelUserRoutes :
          []

  const publicRoutes = [
    { path: "/", component: <SendOtp /> },
    { path: "/verify-otp", component: <VerifyOTP /> },
  ];
  console.log("hotelUserRoutes", hotelUserRoutes);


  // const getDashboardApiCall = () => {
  //   const reqObj = {
  //     premiseId: getStorageData().selectedPremiseID,
  //   }
  //   console.log("reqObj", reqObj);

  //   Request.post(url.GET_HOTEL_USER_PERMISSIONS, reqObj).
  //     then((res) => {
  //       console.log("get dashboard Response", res);
  //       localStorage.setItem("permissions", JSON.stringify(res.data.menuPermission));
  //       // window.location.reload();
  //     }).
  //     catch((err) => {
  //       // setLoading(false)
  //     })
  // }

  // useEffect(() => {
  //   if (userType === 4) {
  //     getDashboardApiCall()
  //   }
  // }, [])

  return (
    <SocketProvider>
      <React.Fragment>
        <Routes>
          {!isAuthorized && publicRoutes.map((route: any, idx: any) => (
            // console.log("route", route),
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
            />
          ))}
          {isAuthorized && authProtectedRoutes.map((route: any, idx: any) => (
            // console.log("authProtectedRoutes route", route),
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>}
              key={idx}
            />
          ))}
          <Route
            path="*"
            element={
              isAuthorized ? (
                <Navigate to={(userType === 4 || userType === 1) ? "/dashboard" : "/premises"} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </React.Fragment >
    </SocketProvider>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;