// import React, { useState, useEffect } from 'react';
// import ReactEcharts from "echarts-for-react";
// import { getRealTimeData } from '../../../services/APIServices';

// const RealTimeChart = ({ data }: any) => {
//   console.log("data=========>", data);

//   const [CPUData, setCPUData] = useState();
//   const [memoryData, setMemoryData] = useState();
//   const [chartData, setChartData] = useState({
//     tooltip: {
//       trigger: "axis",
//     },
//     grid: {
//       zlevel: 0,
//       x: 50,
//       x2: 50,
//       y: 30,
//       y2: 30,
//       borderWidth: 0,
//     },
//     xAxis: {
//       type: "category",
//       data: [],
//       axisLine: {
//         lineStyle: {
//           color: "#8791af",
//         },
//       },
//     },
//     yAxis: {
//       type: "value",
//       axisLine: {
//         lineStyle: {
//           color: "#8791af"
//         },
//       },
//       splitLine: {
//         lineStyle: {
//           color: "rgba(166, 176, 207, 0.1)"
//         }
//       }
//     },
//     series: [
//       {
//         data: [],
//         type: "line",
//       },
//     ],
//     color: "#14C9C9",
//     textStyle: {
//       color: ["#8791af"],
//     },
//   });



//   // const getChartData = () => {
//   //   getRealTimeData().then((res: any) => {
//   //     console.log("realtime chart", res);
//   //     setCPUData(res.cpu);
//   //     setMemoryData(res.memory);
//   //   }).catch((err: any) => {
//   //     console.log("realtime chart error", err);
//   //   })
//   // }

//   // useEffect(() => {
//   //   getChartData()
//   // }, [])

//   // useEffect(() => {
//   //   const intervalId = setInterval(() => {
//   //     getChartData();
//   //   }, 1000)

//   //   return () => clearInterval(intervalId);
//   // }, [])

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Generate random data for demonstration
//       const newDataPoint = Math.floor(Math.random() * 100);
//       const newTime = new Date().toLocaleTimeString();

//       // Update chart data
//       setChartData((prevData: any) => {
//         const newSeriesData = [...prevData.series[0].data];
//         console.log("newSeriesData", newSeriesData);
//         newSeriesData.length > 6 ?
//           newSeriesData.shift() : // Remove the first element
//           newSeriesData.push(data); // Add new data point
//         return {
//           ...prevData,
//           xAxis: {
//             ...prevData.xAxis,
//             data: prevData.xAxis.data.length > 6 ? [...prevData.xAxis.data.slice(1), newTime] : [...prevData.xAxis.data, newTime] // Add new time point
//           },
//           series: [
//             {
//               ...prevData.series[0],
//               data: newSeriesData,
//             },
//           ],
//         };
//       });
//     }, 1000); // Update every second

//     return () => clearInterval(interval);
//   }, [data]);

//   return <ReactEcharts style={{ height: "350px" }} option={chartData} />
// };

// export default RealTimeChart;

import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { convertLocalTime } from '../../../Utils/configController';
import moment from 'moment';
import accessibility from 'highcharts/modules/accessibility';

const OnlineOfflineChart = ({ data }: any) => {
    // console.log(
    //     "data=========>", data
    // );
    accessibility(Highcharts);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'line',
        },
        title: {
            text: '',
        },
        yAxis: {
            title: {
                text: '', // Set yAxis title to empty string
            },
        },
        xAxis: {
            type: 'category',
            categories: [], // Initialize with empty categories
        },
        series: [
            {
                name: 'All',
                data: [],
            },
            {
                name: 'WL1.1',
                data: [],
            },
            {
                name: 'WL1.2',
                data: [],
            },
            {
                name: 'WL1.3',
                data: [],
            },
            {
                name: '1.0.1',
                data: [],
            },
            {
                name: '2.0.1',
                data: [],
            },
            {
                name: '2.0.2',
                data: [],
            },
            {
                name: 'Other',
                data: [],
            }],
        credits: {
            enabled: false, // Disable Highcharts.com credits
        },
        accessibility: {
            enabled: true
        }
    });
    // console.log("chartOptions", chartOptions);

    useEffect(() => {
        const interval = setInterval(() => {
            const newTime = moment().format('HH:mm:ss'); // Get current time in format 'HH:mm:ss'
            const newDataPoint1 = Math.random() * 100; // Generate random data for series 1
            const newDataPoint2 = Math.random() * 100; // Generate random data for series 2

            setChartOptions((options: any) => {
                const newCategories = [...options.xAxis.categories, newTime]; // Add new time to categories

                // Add new data points for series 1 and series 2
                const newSeriesData1 = [...options.series[0]?.data, Number(data?.All)];
                const newSeriesData2 = [...options.series[1]?.data, Number(data?.WL11)];
                const newSeriesData3 = [...options.series[2]?.data, Number(data?.WL12)];
                const newSeriesData4 = [...options.series[3]?.data, Number(data?.WL13)];
                const newSeriesData5 = [...options.series[4]?.data, Number(data?.data101)];
                const newSeriesData6 = [...options.series[5]?.data, Number(data?.data201)];
                const newSeriesData7 = [...options.series[6]?.data, Number(data?.data202)];
                const newSeriesData8 = [...options.series[7]?.data, Number(data?.Other)];

                // Limit the number of categories and data points to 6 for better visualization
                const categories = newCategories.slice(-6);
                const seriesData1 = newSeriesData1.slice(-6);
                const seriesData2 = newSeriesData2.slice(-6);
                const seriesData3 = newSeriesData3.slice(-6);
                const seriesData4 = newSeriesData4.slice(-6);
                const seriesData5 = newSeriesData5.slice(-6);
                const seriesData6 = newSeriesData6.slice(-6);
                const seriesData7 = newSeriesData7.slice(-6);
                const seriesData8 = newSeriesData8.slice(-6);
                // console.log("seriesData1", seriesData6);

                return {
                    ...options,
                    xAxis: {
                        ...options.xAxis,
                        categories: categories,
                    },
                    series: [
                        {
                            name: 'All',
                            data: seriesData1,
                        },
                        {
                            name: 'WL1.1',
                            data: seriesData2,
                        },
                        {
                            name: 'WL1.2',
                            data: seriesData3,
                        },
                        {
                            name: 'WL1.3',
                            data: seriesData4,
                        },
                        {
                            name: '1.0.1',
                            data: seriesData5,
                        },
                        {
                            name: '2.0.1',
                            data: seriesData6,
                        },
                        {
                            name: '2.0.2',
                            data: seriesData7,
                        },
                        {
                            name: 'Other',
                            data: seriesData8,
                        },

                    ],
                };
            });
        }, 1000); // Update every 1 second

        return () => clearInterval(interval); // Cleanup interval
    }, [data]);




    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
};

export default OnlineOfflineChart;
