import getStorageData from '../../../Utils/getStorageData';
import Spinners from '../../../components/Common/Spinner';
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper';
import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap'
import RoomControl from './RoomControl';
import axios from 'axios';
import 'datatables.net';
import 'datatables.net-bs4';
import ReactPaginate from "react-paginate";
import $ from 'jquery'
import Paginations from '../../../components/Common/Pagination';
import moment from 'moment';
import "../../../assets/scss/custom/pages/_roomcontrol.scss"
// import { getDashoboardPermissions } from '../../../Utils/searchApiCall';
import { getMenuPermissions } from '../../../services/APIServices';
import { useDispatch } from 'react-redux';
import { permission } from '../../../store/slices/APIResponseSlice';
import { useSelector } from 'react-redux';
// import "style.css"

const ControlPanel = ({ chipData }: any) => {
    const dispatch = useDispatch();
    const menuPermissions = useSelector((state: any) => state.APIResponse.menuPermissions)
    console.log("menuPermissions", menuPermissions);

    const [loading, setLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [showRoomControl, setShowRoomControl] = useState(false);
    const [roomSwitchDetail, setRoomSwitchDetail] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState<string>("");

    const data = [
        {
            roomNo: "1",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "2",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "3",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "4",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "5",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "6",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "7",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "8",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "9",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "10",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "11",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
        {
            roomNo: "12",
            sharing: "Single Sharing",
            date: "25/08 to 27/08",
            guestName: "Guest Name"
        },
    ];
    const token = localStorage.getItem("Authorization");
    const tableRef: any = useRef(null);
    let dataTableInstance: any = null;

    // const roomListApiCall = (dataTablesData: any, callback: any) => {
    //     console.log("call room list api", dataTablesData);

    //     // setLoading(true);
    //     $('#customLoader').show()
    //     const reqObj: any = {
    //         ...dataTablesData,
    //         premiseId: getStorageData().selectedPremiseID
    //     }
    //     Request.post(url.GET_ROOM_LIST_URL, reqObj).
    //         then((res) => {
    //             console.log("room List Response", res);
    //             callback({
    //                 draw: dataTablesData.draw,
    //                 recordsTotal: res.data.recordsTotal,
    //                 recordsFiltered: res.data.recordsFiltered, // You might adjust this based on filtered records
    //                 data: res.data.data,
    //             });
    //             setRoomList(res.data.data)
    //         }).
    //         catch((err) => {
    //             setLoading(false)
    //         })

    //     // axios.post(`${url.BASE_URL}${url.GET_ROOM_LIST_URL}`, reqObj, {
    //     //     headers: {
    //     //         "Authorization": `Bearer ${token}`
    //     //     }
    //     // }).then((response) => {
    //     //     const res: any = response.data
    //     //     console.log("res", res);
    //     //     $('#customLoader').hide()
    //     //     callback({
    //     //         draw: dataTablesData.draw,
    //     //         recordsTotal: res.recordsTotal,
    //     //         recordsFiltered: res.recordsFiltered, // You might adjust this based on filtered records
    //     //         data: res.data,
    //     //     });
    //     //     setRoomList(res.data)
    //     // });
    // }
    const roomListApiCall = () => {
        // console.log("page", page);

        setLoading(true);
        const reqObj = {
            length: 10,
            premiseId: getStorageData().selectedPremiseID,
            start: currentPage === 1 ? 0 : (currentPage - 1) * 10,
            search: { value: search, regex: false }
        }
        Request.post(url.GET_CONTROL_ROOM_LIST_URL, reqObj).
            then((res) => {
                // console.log("room List Response", res);
                setRoomList(res.data.data);
                setTotalPages(res.data.recordsTotal);
                setLoading(false)
            }).
            catch((err) => {
                //  console.log("area List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    useEffect(() => {
        roomListApiCall()
    }, [currentPage, search])

    useEffect(() => {
        if (getStorageData().userType === 4) {
            getMenuPermissions("", dispatch)
        }
    }, [])

    const columns = [
        { data: '', title: '' }
    ]

    const toggleRoomControl = () => {
        setShowRoomControl(!showRoomControl)

    }
    // const handlePageChange = (selectedPage: any) => {
    //     console.log("selectedPage", selectedPage);

    //     setCurrentPage(selectedPage.selected);
    // };

    var initializeTable = 0;
    return (
        // <div className="page-content" style={{ backgroundColor: '#fff' }}>
        <Card style={{ marginTop: 100, marginLeft: 30, marginRight: 30, marginBottom: 0 }}>
            {/* {showRoomControl && <ControlPanel />} */}
            <CardBody>
                <div className="mb-4 h4 card-title d-flex justify-content-between flex-row" >
                    <div className="app-search d-lg-block" >
                        <div className="position-relative">
                            <span className="bx bx-search-alt" />
                            <input
                                type="search"
                                className="form-control"
                                style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                placeholder={"Search..."}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                {/* {initializeTable === 0 && (
                    initializeTable = 1,
                    <table
                        // id={`dataTable${idName}`}
                        ref={tableRef}
                        className="table table-striped"
                        style={{ width: '100%', fontSize: 14, color: "#858D9D" }}
                    > */}

                {/* <table ref={tableRef} /> */}
                {loading && <div style={{
                    // display: 'none',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 9999,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                }}>
                    <p>Processing....</p>
                </div>}
                {/* {loading ? <div style={{ height: "60vh" }}><Spinners /></div>
                    : */}
                {roomList.length === 0 && !loading ?
                    <div style={{ height: "60vh", textAlign: "center" }}>
                        <h4 style={{ color: "#667085" }}>No Rooms Available</h4>
                    </div> :
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, flexWrap: "wrap" }}>
                            {
                                roomList.map((item: any, index: number) => {
                                    const checkOutDate =
                                        item?.guestCheckOut && item?.guestCheckIn ?
                                            `${moment(item?.guestCheckIn).local().format('DD/MM')} to ${moment(item?.guestCheckOut).local().format('DD/MM')}` :
                                            "";
                                    return (
                                        <Row key={index}>
                                            <Col md={3} style={{ marginRight: 16 }}
                                                onClick={() => {
                                                    toggleRoomControl();
                                                    setRoomSwitchDetail(item)
                                                }
                                                }
                                            >
                                                <Card body
                                                    className='room-list'
                                                    style={{
                                                        backgroundColor: item.guestStatus === 0 ? "#E7F8F0" : "#FEECEB",
                                                        // width: '158px',
                                                        // height: 158,
                                                        // borderRadius: 10,
                                                        // alignItems: "center",
                                                        // justifyContent: "center",
                                                        // display: "flex",
                                                        // cursor: "pointer",
                                                        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                        // overflow: 'hidden',
                                                        // whiteSpace: 'pre-wrap',
                                                        // wordWrap: 'break-word',
                                                    }}
                                                >
                                                    <h1
                                                        className='room-name-font'
                                                    // style={{
                                                    //     color: "#5D6679",
                                                    //     fontWeight: 500,
                                                    //     fontSize: 24,
                                                    // }}
                                                    >{item?.name.length > 8 ? `${item?.name.slice(0, 8)}...` : item?.name}</h1>
                                                    <h6
                                                        className='guest-name-font'
                                                    // style={{
                                                    //     fontSize: 16,
                                                    //     color: "#858D9D"
                                                    // }}
                                                    >
                                                        {item?.guestName ? item.guestName : ""}
                                                    </h6>
                                                    <h6 className="guest-name-font" style={{ fontSize: 16 }}>{checkOutDate}</h6>
                                                    <h6
                                                        style={{
                                                            fontSize: !item.guestName && !item.checkOutDate ? 16 : 12,
                                                            color: "#858D9D",
                                                            fontWeight: "400"
                                                        }}
                                                    >
                                                        {item.guestStatus === 0 ? "Available" : "Booked"}
                                                    </h6>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                        {roomList.length === 0 ? "" : <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Paginations
                                perPageData={10}
                                data={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isShowingPageLength={false}
                                paginationDiv="col-12"
                                paginationClass="pagination pagination justify-content-center mt-2 mb-5"
                            />
                        </div>}

                    </div>
                }
                {/* </table>)} */}
                {showRoomControl &&
                    <RoomControl
                        roomSwitchDetail={roomSwitchDetail}
                        showRoomControl={showRoomControl}
                        toggleRoomControl={() => toggleRoomControl()}
                    />
                }
            </CardBody >
        </Card >
    )
}

export default ControlPanel;    