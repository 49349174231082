import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from "yup";
import $ from 'jquery';
import * as url from '../../helpers/url_helper'
import DataTable from '../../components/Common/DataTable';
import Request from '../../helpers/request';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import { convertLocalDate } from '../../Utils/configController';
import Select from "react-select";
import { userSearchApiCall } from '../../Utils/searchApiCall';

const SendNotification = () => {
    const [loading, setLoading] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [dataTableId, setDataTableId] = useState(null);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [userData, setUserData] = useState([]);

    const validation = useFormik({
        initialValues: {
            user: "",
            title: "",
            description: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            user: Yup.string()
                .required("Please select user "),
            title: Yup.string()
                .required("Please provide your title "),
            description: Yup.string()
                .required("please provide your description "),
        }),
        onSubmit: (values) => {
            console.log("values", values);
            sendNotificationApiCall(values);
        }
    })

    const columns = [
        {
            title: 'User Detail',
            render: function (data: any, type: any, row: any) {
                return row?.userLable ?
                    row?.userLable : "-"
            }
        },
        {
            title: 'Title',
            render: function (data: any, type: any, row: any) {
                return row?.title
                    ? `${row?.title}` : "-"
            }
        },
        {
            title: 'Description',
            render: function (data: any, type: any, row: any) {
                return row?.description ? `${row?.description}` : "-";
            }
        },
        {
            title: 'Date & Time',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? convertLocalDate(row?.createdAt) : "-"
            }
        },
    ]

    const userSearch = (e: any) => {
        userSearchApiCall(e, setUserData, 3)
    }

    const sendNotificationApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            userId: values.user,
            title: values.title,
            description: values.description
        }
        Request.post(url.SENT_NOTIFICATION, reqObj).
            then((res) => {
                console.log("send notification response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        user: "",
                        title: "",
                        description: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenNotificationModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const toggleNotification = () => {
        setOpenNotificationModal(!openNotificationModal);
        validation.resetForm({
            values: {
                user: "",
                title: "",
                description: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }
    const customStyles: CustomStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
        // control: (base: any) => ({
        //   ...base,
        //   borderColor: invalid ? 'red' : base.borderColor,
        // }),
    };

    return (
        <div>
            <ToastContainer />
            <Modal
                isOpen={openNotificationModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleNotification}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{"Send Notification"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {validation.errors && validation.errors.formError ? (
                                <Alert color="danger">{validation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>User</Label>
                                <Select
                                    name="user"
                                    onFocus={() => userSearch("")}
                                    value={userData.find((option: any) => option.value === validation.values.user)}
                                    options={userData}
                                    onChange={(selectedOption: any) => validation.setFieldValue('user', selectedOption.value)}
                                    onBlur={() => validation.setFieldTouched('user', true)}
                                    // invalid={
                                    //   assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
                                    // }
                                    // className={cx({ 'is-invalid': invalid })}
                                    onInputChange={(e) => userSearch(e)}
                                    styles={customStyles}
                                />
                                {validation.touched.user && validation.errors.user ? (
                                    <span className="error">{validation.errors.user}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Title</Label>
                                <Input
                                    value={validation.values.title || ""}
                                    name="title"
                                    type={"text"}
                                    placeholder="Enter Title"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.title && validation.errors.title ? true : false
                                    }
                                />
                                {validation.touched.title && validation.errors.title ? (
                                    <span className="error">{validation.errors.title}</span>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    value={validation.values.description || ""}
                                    name="description"
                                    type={"textarea"}
                                    placeholder="Enter description"
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <span className="error">{validation.errors.description}</span>
                                ) : null}
                            </div>


                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading ? "Sanding..." : "Send"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
            <DataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.SENT_NOTIFICATION_LIST}`}
                columns={columns}
                isAddBtnShow={true}
                isBtnShow={false}
                btnName="Send Notification"
                btnStyle="btn btn-dark"
                onClickBtn={toggleNotification}
                // handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"SendNotification"}
            />
        </div>
    )
}

export default SendNotification