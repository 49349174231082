import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { color } from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';

const BarChart = ({ applicationCount }: any) => {
    accessibility(Highcharts);
    console.log("applicationCount", applicationCount);

    const [chartOptions, setChartOptions] = useState<any>({
        colors: ["#FF9F0ACC", "#32D74B"],
        chart: {
            type: 'bar',
            // colors: ["#FF9F0ACC", "#32D74B"],
            // height: 400
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            bar: {
                borderRadius: '50%',

                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1,
                pointWidth: 10,
            }
        },
        series: [{
            name: '',
            data: [5, 7, 3] // Sample data
        }],
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: true
        }
    });

    useEffect(() => {
        const categories = applicationCount?.map((item: any, index: number) => {
            return item.title
        });
        const data = applicationCount?.map((item: any, index: number) => {
            return {
                y: item.total,
                color: item.color
            }
        })

        setChartOptions((options: any) => {
            return {
                ...options,
                xAxis: {
                    ...options.xAxis,
                    categories: categories
                },
                series: [
                    {
                        showInLegend: false,
                        data: data
                    }
                ]
            }
        })
    }, [applicationCount])

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
};

export default BarChart;
