import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Input, Table } from 'reactstrap';
import * as url from '../../../helpers/url_helper';
import Request from '../../../helpers/request';
import getStorageData from '../../../Utils/getStorageData';
import { useNavigate, useParams } from 'react-router-dom';
import Spinners from '../../../components/Common/Spinner';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';
// import Breadcrumb from 'components/Common/Breadcrumb';

const TagAreaRooms = () => {
    const params = useParams();
    const navigate = useNavigate();
    const tagId = params.id
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [selectedRoomList, setSelectedRoomList] = useState([]);
    const [filterRoomList, setFilterRoomList] = useState(roomList)
    const [search, setSearch] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    console.log("roomList", roomList);
    console.log("filterRoomList", filterRoomList);
    console.log("selectedRoomList", selectedRoomList);
    const roomsListApiCall = () => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            tagId: tagId
        }
        Request.post(url.GET_AREA_ROOMS_URL, reqObj).
            then((res) => {
                //  console.log("premises List Response", res);
                // setRoomList(res.data.data);
                var list = [];
                var selectedList = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    const element = res.data.data[i];
                    list.push({ ...element, isChecked: true });
                    // setSelectedRoomList([...selectedRoomList, element.id])
                    selectedList.push(element.id)
                    // if (res.data.selectedRooms.includes(element.id)) {
                    //     list.push({ ...element, isChecked: true })
                    // } else {
                    //     list.push({ ...element, isChecked: false })
                    // }

                }
                setRoomList(list)
                setFilterRoomList(list)
                setSelectedRoomList(selectedList)
                setLoading(false)
            }).
            catch((err) => {
                //  console.log("premises List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    useEffect(() => {
        roomsListApiCall();
    }, [])

    const assignTagRoomApiCall = () => {
        setSaveLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            tagId: tagId,
            roomIds: selectedRoomList,
            isAdd: 0
        }
        Request.post(url.ASSIGN_AREA_ROOMS_URL, reqObj).
            then((res) => {
                //  console.log("premises List Response", res);
                // setTagList(res.data.data);
                toast.success(res.data.msg)
                roomsListApiCall();
                setDeleteModalOpen(false)
                setSaveLoading(false)
            }).
            catch((err) => {
                //  console.log("premises List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setSaveLoading(false)
                toast.error(err.response.data.errors[0]?.msg)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const groupedRooms = filterRoomList.reduce((acc, room, index) => {
        const groupIndex = Math.floor(index / 10);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(room);
        return acc;
    }, []);

    console.log(
        "groupedRooms", groupedRooms
    );

    const unSelectArea = (item) => {
        console.log("itemitem", item);
        var list = [];
        var selectedList = selectedRoomList;
        if (selectedRoomList.includes(item.id)) {
            const index = selectedRoomList.indexOf(item.id);
            selectedList.splice(index, 1)
        }
        setSelectedRoomList(selectedList)
        // setSelectedRoomList(list);
        for (var i = 0; i < roomList.length; i++) {
            const element = roomList[i];
            if (element.id === item.id) {
                list.push({ ...element, isChecked: !item.isChecked });
            } else {
                list.push(element);
            }
        }
        setRoomList(list);
        setFilterRoomList(list);
    }

    useEffect(() => {
        const filterTagRoomData = roomList.filter((e) => e.name.includes(search));
        setFilterRoomList(filterTagRoomData)
    }, [search])

    return (
        <div className="page-content" style={{ backgroundColor: '#fff' }}>
            <ToastContainer />
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Remove Areas?"
                    text="Are you sure you want to remove the selecred areas from this Area tag? These areas will be unassigned and moved to the Unassigned Area Listing. Preceed with the removal?"
                    buttonText="Remove"
                    onClick={() => { assignTagRoomApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={saveLoading}
                />
            }
            <Card>
                <CardBody>
                    <div className="mb-4 h4 card-title d-flex justify-content-between flex-row" >
                        <div className="app-search d-lg-block" style={{
                            borderStyle: "solid"
                        }}>
                            <div className="position-relative">
                                <span className="bx bx-search-alt" />
                                <Input
                                    type="search"
                                    placeholder="Search"
                                    style={{ background: '#F7F9FC', color: "#858D9D" }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <Breadcrumb title="Tag Management" breadcrumbItem="Rooms" titleClick={"/tag-management"} />
                    {loading ? <div style={{ height: "60vh" }}><Spinners /></div>
                        :
                        groupedRooms.length === 0 ?

                            <div style={{ height: "60vh", textAlign: "center" }}>
                                <h4 style={{ color: "#667085" }}>No Rooms Available</h4>
                            </div> :

                            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                                {groupedRooms.map((group, groupIndex) => (
                                    <Table key={groupIndex} style={{ width: '300px', display: 'inline-block', marginRight: '20px' }} className="table-bordered ">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '300px', textAlign: "center" }}>Rooms</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {group.map((row, rowIndex) => (
                                                < tr key={rowIndex} >
                                                    <td onClick={() => unSelectArea(row)} style={{ fontSize: 14,cursor: "pointer" }}>{
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={row.isChecked}
                                                            onChange={() => { }}
                                                            style={{ fontSize: 15 }}
                                                        // onClick={() => unSelectArea(row)}
                                                        />}     {row.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ))}
                            </div>}
                </CardBody>
                {!loading && groupedRooms.length > 0 && <CardFooter style={{ justifyContent: "center", display: "flex" }}>
                    <Button
                        className='btn btn-dark'
                        // onClick={() => assignTagRoomApiCall()}
                        onClick={() => setDeleteModalOpen(true)}
                        disabled={saveLoading}
                    >
                        {saveLoading ? "Saving..." : "Save"}
                    </Button>
                </CardFooter>}
            </Card>
        </div >
    )
}

export default TagAreaRooms