import React, { useEffect, useState } from 'react'
import {
    Alert,
    Card,
    CardBody,
    CardText,
    Col,
    Form,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table
} from 'reactstrap'
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import DataTable from '../../../components/Common/DataTable';
import * as url from '../../../helpers/url_helper';
import $ from 'jquery'
import { useFormik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import Request from '../../../helpers/request';
import getStorageData from '../../../Utils/getStorageData';
// import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import Select from 'react-select';
import ConfirmationModal from '../../../components/Common/ConfirmationModal';
import { convertLocalDate } from '../../../Utils/configController';

function Users() {
    const [loading, setLoading] = useState(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isUserModal, setUserModel] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenRemoveAccess, setOpenRemoveAccess] = useState<boolean>(false);
    const [removeAccessId, setRemoveAccessId] = useState<any>(null);

    console.log("roleList", roleList);
    const accessList = [
        {
            label: "1 Hour", value: 1
        },
        {
            label: "6 Hours", value: 6
        },
        {
            label: "12 Hours", value: 12
        },
        {
            label: "24 Hours", value: 24
        },
        {
            label: "48 Hours", value: 48
        },
        {
            label: "Full Time Access", value: 0
        },
    ]

    interface FormValues {
        hotelUserId?: string;
        firstname: string;
        lastname: string;
        mobile: string;
        email: string;
        formError: string;
        rolesData?: any[];
        roles: { id: number, role: string; access: string }[];
        // ... other fields
    }

    const validation = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            formError: "",
            rolesData: [],
            roles: [{
                id: 0,
                role: "",
                access: ""
            }]
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string()
                .required("Please provide firstname "),
            lastname: Yup.string()
                .required("Please provide lastname "),
            mobile: Yup.string()
                .required("Please provide your mobile number ").min(10, "Mobile number not valid"),
            email: Yup.string().trim()
                .email("Invalid email address")
                .required("Please provide email address "),
            roles: Yup.array().when('isEditable', (isEditable: any, schema: any) => {
                return isEditable ? schema : schema.of(
                    Yup.object().shape({
                        role: Yup.string().required("Please select role"),
                        access: Yup.string().required("Please select duration"),
                    })
                );
            })
            // roles: Yup.array().of(
            //     Yup.object().shape({
            //         role: Yup.string().required("Please select role"),
            //         access: Yup.string().required("Please select duration"),
            //     })
            // ),
        }),
        onSubmit: (values: FormValues) => {
            // console.log("values", values);
            // addHotelUser(values)
            // reloadDataTable();
            isEditable && !isOpenRemoveAccess ? editHotelUser(values) : !isEditable && !isOpenRemoveAccess && addHotelUser(values)
        }
    })

    console.log("rolesData", validation.values);

    useEffect(() => {
        roleListApiCall()
    }, [])

    const toggleUserModal = () => {
        setUserModel(!isUserModal)
        setIsEditable(false)
        validation.resetForm({
            values: {
                firstname: "",
                lastname: "",
                mobile: "",
                email: "",
                formError: "",
                roles: [{
                    id: 0,
                    role: "",
                    access: ""
                }]
            }
        })
    };

    const resetForm = () => {
        validation.resetForm({
            values: {
                firstname: "",
                lastname: "",
                mobile: "",
                email: "",
                formError: "",
                roles: [{
                    id: 0,
                    role: "",
                    access: ""
                }]
            }
        })
    }

    const roleListApiCall = () => {
        setLoading(true);
        const reqObj = {
            "draw": 1,
            "search": {
                "value": "",
            },
            premiseId: getStorageData().selectedPremiseID
        }
        Request.post(url.GET_ROLE_LIST_URL, reqObj).
            then((res) => {
                console.log("Area List Response", res);
                const role = res.data["data"].map((item: any, index: Number) => (
                    { label: item.name, value: item.id }
                ))
                setRoleList(role);
                setLoading(false)
            }).
            catch((err) => {
                //  console.log("area List Error", err);
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const addHotelUser = (values: any) => {
        const reqObj = {
            email: values.email,
            mobile: values.mobile,
            firstname: values.firstname,
            lastname: values.lastname,
            premiseId: getStorageData().selectedPremiseID,
            roleInfo: values.roles.map((item: any, index: number) => {
                return {
                    id: index + 1,
                    roleId: item.role,
                    duration: item.access
                }
            })
        }
        console.log("add service person values", reqObj);
        // console.log("dataTable id", dataTableId);
        // reloadDataTable();
        setLoading(true);
        Request.post(url.ADD_HOTEL_USER_URL, reqObj).
            then((res) => {
                //  console.log("Add Service person Response", res);
                // servicePersonalListApiCall();
                toast.success(res.data.msg);
                toggleUserModal();
                reloadDataTable();
                setLoading(false);
            }).
            catch((err) => {
                // console.log("Add service person Error", err);
                setLoading(false);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg });
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false);
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const editHotelUser = (values: any) => {
        const roleInfo = values.roles.map((item: any, index: number) => {
            return {
                roleId: item.role,
                duration: item.access
            };
        });
        console.log("roleInfo", roleInfo);

        // Check if roleInfo has at least one entry with non-blank roleId and duration
        const hasNonBlankRoleInfo = roleInfo.some((info: any) => info.roleId.trim() !== '' && !isNaN(info.duration));
        const reqObj = {
            email: values.email,
            mobile: values.mobile,
            firstname: values.firstname,
            lastname: values.lastname,
            premiseId: getStorageData().selectedPremiseID,
            hotelUserId: values.hotelUserId,
            ...(hasNonBlankRoleInfo ? { roleInfo } : {})
        }
        console.log("add service person values", reqObj);
        // console.log("dataTable id", dataTableId);
        // reloadDataTable();
        setLoading(true);
        Request.post(url.UPDATE_HOTEL_USER_URL, reqObj).
            then((res) => {
                //  console.log("Add Service person Response", res);
                // servicePersonalListApiCall();
                toast.success(res.data.msg);
                toggleUserModal();
                reloadDataTable();
                setLoading(false);
            }).
            catch((err) => {
                // console.log("Add service person Error", err);
                setLoading(false);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg });
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false);
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const deleteHotelUserApiCall = () => {
        setLoading(true);
        const reqObj = {
            hotelUserId: deleteData.id
        }
        Request.post(url.DELETE_HOTEL_USER_URL, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                resetForm();
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        //   console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }
    const customStyles: CustomStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
        // control: (base: any) => ({
        //   ...base,
        //   borderColor: invalid ? 'red' : base.borderColor,
        // }),
    };

    const columns = [
        {
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                  <div>${row.firstname} ${row.lastname}</div>
                </div>`;
            }, title: 'Name'
        },
        {
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                  <div>+91 ${row.mobile}</div>
                </div>`;
            }, title: 'Mobile Number'
        },
        { data: "email", title: "email" },
        {
            render: function (data: any, type: any, row: any) {
                console.log("rowwww", row.role);
                let rolesHTML = ''; // Initialize an empty string to store role HTML
                row.role.forEach((roleItem: any, index: number) => { // Loop through each role in the row
                    rolesHTML += `<span class="font-size-11 badge-soft-success badge bg-secondary m-1">${roleItem.roleId.name}</span>`; // Add role name to the HTML
                });
                return rolesHTML;

            },
            title: 'Role'
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                    <button class="edit-btn" style="border: none; outline: none; background: none;">
                         <box-icon type='solid' name='edit' size="20px"></box-icon>
                    </button>
                    <button class="delete-btn" style="border: none; outline: none; background: none;">
                        <box-icon name='trash-alt' size="20px"></box-icon>
                    </button>
                </div>`
                );
            }
        }
    ]

    const handleDeleteClick = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    const handleEditClick = (rowData: any) => {
        console.log('Edit button clicked for row data:', rowData);
        // setCityData([{ id: rowData.cityId.id, name: rowData.cityId.name }])
        // setAreaDetail(rowData)
        setIsEditable(true)
        setUserModel(true);
        validation.setValues({
            hotelUserId: rowData.id,
            firstname: rowData.firstname,
            lastname: rowData.lastname,
            mobile: rowData.mobile,
            email: rowData.email,
            formError: "",
            rolesData: rowData.role,
            // roles: rowData.role.map((item: any, index: number) => {
            //     return ({
            //         role: item.roleId.name,
            //         access: ""
            //     })
            // })
            roles: [{
                id: 1,
                role: "",
                access: ""
            }]
        })
        // Add your logic here
    };

    const handleRemoveAccessClick = (e: React.MouseEvent<HTMLButtonElement>, rowData: any) => {
        console.log("sjhfgdjhgdfg");

        // if (e.target instanceof HTMLButtonElement) {
        console.log('Delete button clicked for row data:', rowData);
        setOpenRemoveAccess(!isOpenRemoveAccess);
        setRemoveAccessId(rowData.id);
        // Add your logic here
        // }
    };

    const handleRemoveRoleClick = (e: any, id: any) => {
        console.log("sjhfgdjhgdfg");
        const index = validation.values.roles.filter(r => r.id !== id);
        validation.setFieldValue("roles", index)
        console.log("validation.values.roles.length === 0", validation.values.roles.length);

        if (validation.values.roles.length === 1) {
            console.log("dfhgdfhg");

            validation.setFieldValue("roles", [{
                id: 0,
                role: "",
                access: ""
            }])
        }
        // else {
        //     const index = validation.values.roles.filter(r => r.id !== id);
        //     validation.setFieldValue("roles", index)
        // }
        // Add your logic here
        // }
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLButtonElement;
        if (target && target.classList.contains('remove-btn')) {
            const accessId = target.getAttribute('data-id');
            if (accessId) {
                setOpenRemoveAccess(true); // Open the remove access modal
                setRemoveAccessId(accessId); // Set the access ID to be removed
            }
        } else {
            console.log("call else part");

            // Handle other button clicks here
        }
    };

    const removeAssignApiCall = () => {
        setLoading(true);
        const reqObj = {
            accessId: removeAccessId
        }
        Request.post(url.REMOVE_HOTEL_USER_ROLE_PERMISSION, reqObj).
            then((res) => {
                console.log("removeAccessId", removeAccessId);

                validation.setFieldValue("rolesData", validation.values.rolesData?.filter((role) => role.id !== removeAccessId))
                // console.log("delete Area Response", res);
                // areaListApiCall()
                toast.success(res.data.msg)
                reloadDataTable();
                // accessPremisesListApiCall(grantorUserId)
                setOpenRemoveAccess(false)
                setRemoveAccessId(null)
                setLoading(false)

            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                toast.error(err.response.data.errors[0]?.msg)
            })
    }

    return (
        <React.Fragment>
            <div>
                <ToastContainer />
                <Modal
                    isOpen={isUserModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    toggle={toggleUserModal}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit User" : "Add User"}</ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                //  console.log("call on submit", e);
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.formError ? (
                                    <Alert color="danger">{validation.errors.formError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">Firstname</Label>
                                    <Input
                                        name="firstname"
                                        type={"text"}
                                        placeholder="Enter Firstname"
                                        value={validation.values.firstname || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.firstname && validation.errors.firstname ? true : false
                                        }
                                    />
                                    {validation.touched.firstname && validation.errors.firstname ? (
                                        <span className="error">{validation.errors.firstname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Lastname</Label>
                                    <Input
                                        name="lastname"
                                        type={"text"}
                                        placeholder="Enter Lastname"
                                        value={validation.values.lastname || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.lastname && validation.errors.lastname ? true : false
                                        }
                                    />
                                    {validation.touched.lastname && validation.errors.lastname ? (
                                        <span className="error">{validation.errors.lastname}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        type={"text"}
                                        placeholder="Enter Email"
                                        value={validation.values.email || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <span className="error">{validation.errors.email}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">Mobile Number</Label>
                                    <InputGroup>
                                        <span className="input-group-text"
                                            style={{
                                                backgroundColor: '#fff',
                                                borderColor: validation.touched.mobile && validation.errors.mobile ? "red" : ""
                                            }}
                                        >
                                            +91
                                        </span>
                                        <Input
                                            name="mobile"
                                            type={"text"}
                                            placeholder="Enter Mobile Number"
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={
                                                validation.touched.mobile && validation.errors.mobile ? true : false
                                            }
                                            maxLength={10}
                                            value={validation.values.mobile || ""}
                                        />
                                    </InputGroup>
                                    {validation.touched.mobile && validation.errors.mobile ? (
                                        <span className="error">{validation.errors.mobile}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    {validation?.values?.rolesData?.map((item: any, index: number) => {
                                        return (
                                            <div className='form-control d-flex justify-content-between align-items-center mb-3' key={index}>
                                                <span>
                                                    {item.roleId.name}
                                                </span>
                                                <span>
                                                    {item.hasExpired ? convertLocalDate(item.expiredDate) : "Full Time"}
                                                </span>
                                                <button
                                                    data-id={item.id} // Add a data attribute to identify the button
                                                    className="remove-btn" // Add a class name for better selection
                                                    style={{ border: "none", outline: "none", background: "none" }}
                                                    onClick={(e) => handleRemoveAccessClick(e, item)}
                                                // style={{ border: "none", outline: "none", background: "none" }}
                                                >
                                                    <span
                                                        className="badge bg-danger"
                                                        style={{
                                                            height: 25,
                                                            // width: 60,
                                                            fontSize: 12,
                                                            padding: 6
                                                        }}
                                                    >
                                                        Remove
                                                    </span>
                                                </button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Label className="form-label">Role & Duration</Label>
                                {validation.values.roles.map((item: any, index: number) => {
                                    return (
                                        <div key={index}>
                                            <div className='d-flex justify-content-end mb-2'>
                                                <Label className="form-label"
                                                    disabled={isEditable}
                                                    onClick={(e) => handleRemoveRoleClick(e, item.id)}
                                                >
                                                    <span
                                                        className="badge bg-dark"
                                                        style={{
                                                            height: 20,
                                                            // width: 60,
                                                            fontSize: 12,
                                                            padding: 4
                                                        }}
                                                    >
                                                        X
                                                    </span>
                                                </Label>
                                            </div>
                                            <div className="mb-3" >
                                                <Select
                                                    name={`roles.${index}.role`}
                                                    value={roleList.find((option: any) => option.value === item.role) || ""}
                                                    options={roleList}
                                                    onChange={(selectedOption: any) => validation.setFieldValue(`roles.${index}.role`, selectedOption.value)}
                                                    onBlur={() => validation.setFieldTouched(`roles.${index}.role`, true)}
                                                    styles={customStyles}
                                                    placeholder="Select Role"
                                                // isDisabled={isEditable}
                                                />
                                                {validation.touched.roles && // Check if the roles array is touched
                                                    validation.touched.roles[index] && // Check if the specific field in the array is touched
                                                    (validation.touched.roles[index] as { role?: string }).role && // Check if the 'role' field is touched
                                                    validation.errors.roles && // Check if there are validation errors for the roles array
                                                    validation.errors.roles[index] &&
                                                    (validation.errors.roles[index] as { role?: string }).role
                                                    ? (
                                                        <span className="error">{(validation.errors.roles[index] as { role?: string }).role}</span>
                                                    ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Select
                                                    name={`roles.${index}.access`}
                                                    value={accessList.find((option: any) => option.value === item.access) || ""}
                                                    options={accessList}
                                                    onChange={(selectedOption: any) => validation.setFieldValue(`roles.${index}.access`, selectedOption.value)}
                                                    onBlur={() => validation.setFieldTouched(`roles.${index}.access`, true)}
                                                    styles={customStyles}
                                                    placeholder="Select Duration"
                                                // isDisabled={isEditable}
                                                />
                                                {validation.touched.roles && // Check if the roles array is touched
                                                    validation.touched.roles[index] && // Check if the specific field in the array is touched
                                                    (validation.touched.roles[index] as { access?: string }).access && // Check if the 'role' field is touched
                                                    validation.errors.roles && // Check if there are validation errors for the roles array
                                                    validation.errors.roles[index] &&
                                                    (validation.errors.roles[index] as { access?: string }).access
                                                    ? (
                                                        <span className="error">{(validation.errors.roles[index] as { access?: string }).access}</span>
                                                    ) : null}
                                            </div>
                                        </div>
                                    )
                                })}

                                <div>
                                    <Label className="form-label"
                                        disabled={isEditable}
                                        onClick={() => {
                                            validation.setValues({
                                                ...validation.values,
                                                roles: [...validation.values.roles, { id: validation.values.roles?.length, role: "", access: "" }],
                                            });
                                        }}
                                    >+ Add more</Label>
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>

                {isOpenRemoveAccess &&
                    <ConfirmationModal
                        loading={loading}
                        headerTitle="Remove Access"
                        text={`Are You Sure you want to remove access?`}
                        buttonText="Remove"
                        // onClick={() => { }}
                        onClick={() => { removeAssignApiCall() }}
                        cancleBtnClick={() => setOpenRemoveAccess(false)}
                    />
                }

                {isDeleteModalOpen &&
                    <ConfirmationModal
                        headerTitle="Delete Area?"
                        text="Are you sure you want to delete this Area ? This action cannot be undone."
                        buttonText="Delete"
                        onClick={() => { deleteHotelUserApiCall() }}
                        cancleBtnClick={() => setDeleteModalOpen(false)}
                        loading={loading}
                    />
                }
                <DataTable
                    // data={servicePersonList}
                    apiURL={`${url.BASE_URL}${url.GET_HOTEL_USERS_LIST_URL}`}
                    columns={columns}
                    isAddBtnShow={true}
                    paramsId={{ premiseId: getStorageData().selectedPremiseID }}
                    // isBtnShow={false}
                    btnName="Add User"
                    btnStyle="btn btn-dark"
                    onClickBtn={toggleUserModal}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                    // editApiCall={() => { }}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"Users"}
                />
            </div>
        </React.Fragment>
    )
}

export default Users