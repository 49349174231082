// import React, { useEffect, useState } from 'react'
// import Request from '../../../../../helpers/request';
// import * as url from '../../../../../helpers/url_helper';
// import { ToastContainer, toast } from 'react-toastify';
// import $ from 'jquery'
// import ProfileListDataTable from '../ProfileDataTable';
// import { useNavigate } from 'react-router-dom';
// import Breadcrumb from '../../../../../components/Common/Breadcrumb';
// import ConfirmationModal from '../../../../../components/Common/ConfirmationModal';
// import { useSelector } from 'react-redux';
// import ChipDataTable from '../ChipDataTable';

// export interface ChipsControlProps {
//     userId: any;
// }

// const ChipsList = ({ userId }: ChipsControlProps) => {
//     const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState<boolean>(false);
//     const [dataTableId, setDataTableId] = useState(null);
//     const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
//     const [deleteData, setDeleteData] = useState<any>();
//     const [roomId, setRoomId] = useState<any>();
//     const [isDataTableInitialized, setIsDataTableInitialized] = useState(false);
//     const [isReload, setReload] = useState(false);
//     const [expandedRows, setExpandedRows] = useState<any>([]);
//     console.log("expandedRows", expandedRows);

//     const columns = [
//         {
//             title: "",
//             render: function (data: any, type: any, row: any) {
//                 console.log("rowrowrowrow", row, expandedRows);
//                 const isExpanded = expandedRows.includes(row.id);
//                 console.log("isExpanded", isExpanded);

//                 const iconClass = isExpanded ? 'fas fa-caret-down' : 'fas fa-caret-right';
//                 console.log("iconClass", iconClass);

//                 return `<div class="details-row-toggle" style="cursor: pointer";><i class="${iconClass}"></i></div>`;
//             }
//         },
//         {
//             title: `<input type="checkbox" />`,
//             render: function () {
//                 return (
//                     `<div class="d-flex gap-2">
//                                 <input type="checkbox" />
//                             </div>`
//                 );
//             }
//         },
//         {
//             title: 'Slave',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.slave ? row.slave : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: 'HW-V',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.hwVersion ? row.hwVersion : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: 'WI-V',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.wifiVersion ? row.wifiVersion : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: "Status",
//             render: function (data: any, type: any, row: any) {
//                 const statusBadge = row?.isOnline ? (
//                     '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
//                 ) : (
//                     '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
//                 );

//                 return statusBadge;
//             }
//         },
//         {
//             title: 'Model',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                <div>
//                 <div style="color: black; fontweight: bold">${row?.modelType
//                         ? row.modelType
//                         : "-"} </div>
//              </div>`
//             }
//         },
//         {
//             title: 'Name',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.name ? row.name : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: 'Signal',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.signalStrength ? row.signalStrength : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: 'MQTT',
//             render: function (data: any, type: any, row: any) {
//                 return `
//                 <div>
//                 <div style="color: black; fontweight: bold">${row?.readingCount ? row.readingCount : "-"} </div>
//                 </div>`
//             }
//         },
//         {
//             title: 'Actions',
//             render: function () {
//                 return (
//                     `<div class="d-flex gap-2">
//                                 <button class="delete-btn" style="border: none; outline: none; background: none;">
//                                     <i class="bx bx-trash-alt" style="font-size: 20px;"></i>
//                                 </button>
//                                 <button class="log-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Log</span></button>
//                                 <button class="column-click" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px;width: 60px;  font-size: 12px; padding: 6px;">MQTT</span></button>
//                             </div>`
//                 );
//             }
//         }
//     ]

//     const handleRowToggle = (rowId: number) => {
//         // var updatedRows = [];
//         // if (expandedRows?.includes(rowId)) {
//         //     updatedRows = expandedRows.filter((id: any) => id !== rowId);
//         // } else {
//         //     updatedRows.push(rowId);
//         // }
//         // console.log("updatedRows", updatedRows);
//         // setExpandedRows(updatedRows)
//         // if (expandedRows.includes(rowId)) {
//         //     setExpandedRows(expandedRows.filter((id: any) => id !== rowId))
//         // } else {
//         //     setExpandedRows([...expandedRows, rowId]);
//         // }
//         setExpandedRows((prev: any) => {
//             const updatedRows = prev;
//             console.log("updatedRowsupdatedRows", updatedRows.includes(rowId));

//             if (updatedRows.includes(rowId)) {
//                 console.log("fgdfgghg", typeof rowId);

//                 updatedRows.filter((row: any) => row !== rowId)
//                 console.log("updatedRowsupdatedRows", updatedRows)
//                 // Collapse row
//             } else {
//                 updatedRows.push(rowId); // Expand row
//             }
//             return updatedRows;
//         });
//     };

//     useEffect(() => {
//         if (isReload) {
//             const interval = setInterval(() => {
//                 reloadDataTable()
//             }, 3000)
//             return () => clearInterval(interval);
//         }
//     }, [dataTableId, isReload])

//     const handleDeleteClick = (rowData: any) => {
//         //  console.log('Delete button clicked for row data:', rowData);
//         setDeleteModalOpen(!isDeleteModalOpen);
//         setDeleteData(rowData);
//         // Add your logic here
//     };
//     const handleMQTTData = (rowData: any) => {
//         //  console.log('Delete button clicked for row data:', rowData);
//         rowData?.slave ?
//             window.open(`/debug-device-details/${rowData?.slave}`, "_blank") : "";
//         // rowData?.slave ?
//         //     navigate(`/debug-device-details/${rowData?.slave}`) : ""
//         // Add your logic here
//     };

//     const handleLogClick = (rowData: any) => {
//         console.log("rowData: ", rowData);
//         rowData?.slave ?
//             navigate(`/users/profile/device/${rowData?.slave}`) :
//             ""
//     }

//     // useEffect(() => {
//     //     setTimeout(() => {
//     //         reloadDataTable();
//     //     }, 5000);
//     // }, [])

//     const reloadDataTable = () => {
//         if (isDataTableInitialized) {
//             console.log("DataTable is initialized:", isDataTableInitialized);
//             console.log("dataTableId", dataTableId);
//             const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
//             dataTableInstance.ajax.reload(null, false);
//         }
//     };

//     const handleDataTableIdChange = (dataTableId: any) => {
//         // Now you have access to the DataTable ID here
//         console.log('Area DataTable ID:', dataTableId);
//         setIsDataTableInitialized(true);
//         setDataTableId(dataTableId)
//         // You can do whatever you need with the ID in this component
//     };

//     const deleteChipApiCall = () => {
//         setLoading(true);
//         const reqObj = {
//             chipId: deleteData.id
//         }
//         Request.post(url.DELETE_CHIP, reqObj).
//             then((res) => {
//                 // console.log("delete Area Response", res);
//                 // areaListApiCall()
//                 toast.success(res.data.msg)
//                 reloadDataTable();
//                 setDeleteModalOpen(false)
//                 setLoading(false)
//             }).
//             catch((err) => {
//                 // console.log("edit area Error", err);
//                 setLoading(false)
//                 // showToast("error", err.response.data.errors[0]?.msg)
//             })
//     }

//     return (
//         <div>
//             <ToastContainer />
//             {isDeleteModalOpen &&
//                 <ConfirmationModal
//                     headerTitle="Delete Area?"
//                     text="Are you sure you want to delete this Chip ? This action cannot be undone."
//                     buttonText="Delete"
//                     onClick={() => { deleteChipApiCall() }}
//                     cancleBtnClick={() => setDeleteModalOpen(false)}
//                     loading={loading}
//                 />
//             }
//             <ChipDataTable
//                 // data={areaList}
//                 apiURL={`${url.BASE_URL}${url.GET_CHIPS_LIST}`}
//                 columns={columns}
//                 paramsId={{
//                     userId: userId,
//                     // roomId: roomId
//                 }}
//                 title="Chip List"
//                 isAddBtnShow={false}
//                 isBtnShow={false}
//                 btnName="OTA"
//                 btnStyle="btn btn-primary"
//                 secondBtnText="Delete"
//                 secondBtnStyle="btn btn-dark"
//                 // onClickBtn={toggleArea}
//                 handleEditClick={handleLogClick}
//                 handleDeleteClick={handleDeleteClick}
//                 handleColumnClicked={handleMQTTData}
//                 isClicked={true}
//                 selectedRoomID={selectedRooms}
//                 // editApiCall={editAreaApiCall}
//                 onDataTableRefresh={handleDataTableIdChange}
//                 idName={"Chipslist"}
//                 setReload={setReload}
//                 onRowToggle={handleRowToggle} // Pass row toggle handler
//                 expandedRows={expandedRows} // Pass expanded rows state
//             />
//         </div>
//     )
// }

// export default ChipsList


import React, { useEffect, useState } from 'react'
import Request from '../../../../../helpers/request';
import * as url from '../../../../../helpers/url_helper';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'
import ProfileListDataTable from '../ProfileDataTable';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../../components/Common/Breadcrumb';
import ConfirmationModal from '../../../../../components/Common/ConfirmationModal';
import { useSelector } from 'react-redux';
import ChipDataTable from '../ChipDataTable';

export interface ChipsControlProps {
    userId: any;
}

const ChipsList = ({ userId }: ChipsControlProps) => {
    const selectedRooms = useSelector((state: any) => state.APIResponse.selectedRooms);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [roomId, setRoomId] = useState<any>();
    const [isDataTableInitialized, setIsDataTableInitialized] = useState(false);
    const [isReload, setReload] = useState(false);

    const columns = [
        {
            title: "",
            render: function (data: any, type: any, row: any) {
                console.log("rowrowrowrow", row);
                // return `<div class="details-row-toggle" style="cursor: pointer";><i class="fas fa-caret-right"></i></div>`;
                return `<div class="details-row-toggle" style="cursor: pointer";><i class='bx bx-caret-right'></i></div>`;
            }
        },
        {
            title: `<input type="checkbox" />`,
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <input type="checkbox" />
                            </div>`
                );
            }
        },
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.slave ? row.slave : "-"} </div>
                </div>`
            }
        },
        {
            title: 'HW-V',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.hwVersion ? row.hwVersion : "-"} </div>
                </div>`
            }
        },
        {
            title: 'WI-V',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.wifiVersion ? row.wifiVersion : "-"} </div>
                </div>`
            }
        },
        {
            title: "Status",
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isOnline ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'Model',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.modelType
                        ? row.modelType
                        : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Name',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.name ? row.name : "-"} </div>
                </div>`
            }
        },
        {
            title: 'Signal',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.signalStrength !== "" ? row.signalStrength : "-"} </div>
                </div>`
            }
        },
        {
            title: 'MQTT',
            render: function (data: any, type: any, row: any) {
                return `
                <div>
                <div style="color: black; fontweight: bold">${row?.readingCount !== "" ? row.readingCount : "-"} </div>
                </div>`
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                     <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                                <button class="log-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Log</span></button>
                                <button class="column-click" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px;width: 60px;  font-size: 12px; padding: 6px;">MQTT</span></button>
                            </div>`
                );
            }
        }
    ]

    useEffect(() => {
        if (isReload) {
            const interval = setInterval(() => {
                reloadDataTable()
            }, 3000)
            return () => clearInterval(interval);
        }
    }, [dataTableId, isReload])

    const handleDeleteClick = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };
    const handleMQTTData = (rowData: any) => {
        //  console.log('Delete button clicked for row data:', rowData);
        rowData?.slave ?
            window.open(`/debug-device-details/${rowData?.slave}`, "_blank") : "";
        // rowData?.slave ?
        //     navigate(`/debug-device-details/${rowData?.slave}`) : ""
        // Add your logic here
    };

    const handleLogClick = (rowData: any) => {
        console.log("rowData: ", rowData);
        rowData?.slave ?
            navigate(`/users/profile/device/${rowData?.slave}`) :
            ""
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         reloadDataTable();
    //     }, 5000);
    // }, [])

    const reloadDataTable = () => {
        if (isDataTableInitialized) {
            console.log("DataTable is initialized:", isDataTableInitialized);
            console.log("dataTableId", dataTableId);
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setIsDataTableInitialized(true);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const deleteChipApiCall = () => {
        setLoading(true);
        const reqObj = {
            chipId: deleteData.id
        }
        Request.post(url.DELETE_CHIP, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()
                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    return (
        <div>
            <ToastContainer />
            {isDeleteModalOpen &&
                <ConfirmationModal
                    headerTitle="Delete Area?"
                    text="Are you sure you want to delete this Chip ? This action cannot be undone."
                    buttonText="Delete"
                    onClick={() => { deleteChipApiCall() }}
                    cancleBtnClick={() => setDeleteModalOpen(false)}
                    loading={loading}
                />
            }
            <ChipDataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.GET_CHIPS_LIST}`}
                columns={columns}
                paramsId={{
                    userId: userId,
                    // roomId: roomId
                }}
                title="Chip List"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                handleEditClick={handleLogClick}
                handleDeleteClick={handleDeleteClick}
                handleColumnClicked={handleMQTTData}
                isClicked={true}
                selectedRoomID={selectedRooms}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Chipslist"}
                setReload={setReload}
            />
        </div>
    )
}

export default ChipsList