import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, InputGroup } from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import CarouselPage from "./CarouselPage";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as url from '../../helpers/url_helper';
import Request from '../../helpers/request';
import { BaseResponse, DataResponse } from '../../helpers/common/BaseResponse';
import useWindowDimensions from '../../Utils/useWindowDimensions';

const SendOtp = (props: any) => {
  // console.log("props", props);
  // const [emailOrMobile, setEmailOrMobile] = useState("")
  // const [passwordShow, setPasswordShow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const validation = useFormik({
    initialValues: {
      emailOrMobile: "",
      form: ""
    },
    validationSchema: Yup.object().shape({
      emailOrMobile: Yup.string()
        .test(
          'emailOrMobile',
          'Invalid email or mobile',
          (value: any) => {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            const mobileRegex = /^[0-9]{10}$/;
            // Trim whitespace before validation
            const trimmedValue = value.trim();
            return (
              !trimmedValue ||  // allow empty value
              trimmedValue === '' ||  // allow whitespace
              emailRegex.test(trimmedValue) ||  // check if it's an email
              (mobileRegex.test(trimmedValue) && trimmedValue.length === 10)  // check if it's a mobile number with length 10
            );
          }
        )
        .required('Email or mobile is required')
    }),
    onSubmit: (values) => {
      sendOtpApiCall(values)
      // dispatch(
      //   loginUser(values, navigate)
      // );
    }
  })

  const sendOtpApiCall = (values: any) => {
    setLoading(true);
    const reqData = {
      email_or_mobile: values.emailOrMobile
    }
    Request.post(url.SEND_OTP, reqData).
      then((result) => {
        console.log("res", result);
        const res: DataResponse = result.data
        setMsg(res.msg);
        setTimeout(() => {
          navigate("verify-otp", { state: { email: values.emailOrMobile } })
        }, 1000)
        //  console.log("send otp Response", res);
        validation.resetForm({
          values: {
            emailOrMobile: "",
            form: ""
          },
          touched: {} // Reset touched state for all fields
        });
        setLoading(false)
      }).
      catch((err) => {
        // console.log("edit area Error", err);
        validation.setErrors({ "form": err?.response?.data?.errors[0]?.msg })
        // setErrors({ form: "Failed to add area. Please try again." });
        setLoading(false)
        // showToast("error", err.response.data.errors[0]?.msg)
      })
  }
  // console.log("initialValues", validation.initialValues);
  // const selectLoginState = (state) => state.Login;
  // const LoginProperties = createSelector(
  //   selectLoginState,
  //   (login) => ({
  //     error: login.error,
  //     sendOtpMsg: login.sendOtpMsg,
  //     loading: login.loading
  //   })
  // );

  // const {
  //   error, sendOtpMsg, loading
  // } = useSelector(LoginProperties);
  // console.log("error", error);

  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#fff' }}>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={5}>
              <div className="auth-full-page-content justify-content-center p-md-5 p-4">
                <div className={width < 1000 ? "w-100" : "w-50"}>
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">

                    </div>
                    <div className="my-auto">
                      <div>
                        <h4 className='font-size-26 color-#000'>Hello!</h4>
                        <p className="text-muted font-size-18 mb-20" >
                          Welcome aboard!
                        </p>
                      </div>
                      {validation.errors && validation.errors.form ? (
                        <Alert color="danger">{validation.errors.form}</Alert>
                      ) : null}
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                      {msg ? <Alert color="success" role="alert">
                        {msg}
                      </Alert> : null}  
                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <InputGroup>
                              <span className="input-group-text"
                                style={{
                                  backgroundColor: '#fff',
                                  height: 55,
                                  borderColor: validation.touched.emailOrMobile && validation.errors.emailOrMobile ? "red" : "#EEEEEE"
                                }}
                              >
                                <i className="mdi mdi-email-outline" style={{ fontSize: '24px', color: "#A0A0A0" }}></i>
                              </span>
                              <Input
                                name="emailOrMobile"
                                className="form-control"
                                style={{
                                  backgroundColor: '#fff',
                                  height: 55,
                                  borderLeft: 0,
                                  fontSize: '16px',
                                  padding: 0,
                                  boxShadow: 'none',
                                  borderColor: validation.touched.emailOrMobile && validation.errors.emailOrMobile ? "red" : "#EEEEEE"
                                }}
                                placeholder="Email Address"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.emailOrMobile || ""}
                                maxLength={(validation.values.emailOrMobile && validation.values.emailOrMobile.match(/^\d+$/) && validation.values.emailOrMobile.length === 10) ? 10 : undefined}
                                invalid={
                                  validation.touched.emailOrMobile && validation.errors.emailOrMobile ? true : false
                                }
                              />
                            </InputGroup>
                            {validation.touched.emailOrMobile && validation.errors.emailOrMobile ? (
                              <span className="error">{validation.errors.emailOrMobile}</span>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-dark"
                              type="submit"
                              style={{ height: 53, fontSize: '14px' }}
                              disabled={loading}
                            >
                              {loading ? "Sending..." : "Send"}
                            </button>
                          </div>

                        </Form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

SendOtp.propTypes = {}

export default SendOtp