import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import 'datatables.net';
import 'datatables.net-bs4';
import $ from 'jquery'
import { Card, CardBody, Col, Row } from 'reactstrap';
import getStorageData from '../../Utils/getStorageData';
import { PremisesListData, PremisesListResponse } from '../../helpers/response/Admin/PremiseListResponse';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Select from "react-select";
import useWindowDimensions from '../../Utils/useWindowDimensions';
import { CHANGE_STATUS_URL } from '../../helpers/url_helper';
import { useSelector } from 'react-redux';

export interface interfaceDataTable<T> {
    responseType?: T;
    apiURL: string,
    columns: any[],
    btnName?: string,
    isAddBtnShow?: boolean,
    btnStyle?: any,
    isBtnShow?: boolean,
    secondBtnText?: string,
    secondBtnStyle?: any,
    onClickBtn?: () => void,
    secondBtnOnClick?: () => void,
    handleEditClick?: (data: any) => void | undefined,
    handleDeleteClick?: (data: any) => void,
    onDataTableRefresh?: (dataTableId: any) => void,
    idName: string,
    handleColumnClicked?: (data: any) => void | undefined,
    paramsId?: any,
    isEntryShow?: boolean,
    isClicked?: boolean,
    showFilterOpetions?: boolean,
    optionGroup?: any[],
    showSearchebleFilter?: boolean,
    searchableFilterOption?: any,
    setFilterValue?: any,
    searchAPICall?: any,
    filterValue?: string,
    searchableCityOption?: any,
    cityFilterValue?: string,
    setCityFilterValue?: any,
    searchCityAPICall?: any,
    isShowSearch?: boolean,
    handleDropdownClick?: any
    isReload?: boolean,
    setReload?: any
}
const DataTable = <T,>({
    responseType,
    apiURL,
    columns,
    btnName,
    isAddBtnShow,
    btnStyle,
    isBtnShow,
    secondBtnText,
    secondBtnStyle,
    onClickBtn,
    secondBtnOnClick,
    handleEditClick,
    handleDeleteClick,
    onDataTableRefresh,
    idName,
    handleColumnClicked,
    paramsId,
    isEntryShow,
    isClicked,
    showFilterOpetions,
    optionGroup,
    showSearchebleFilter,
    searchableFilterOption,
    searchableCityOption,
    setFilterValue,
    searchCityAPICall,
    searchAPICall,
    cityFilterValue,
    filterValue,
    setCityFilterValue,
    isShowSearch,
    handleDropdownClick,
    setReload
}: interfaceDataTable<T>) => {
    console.log("searchableFilterOption", searchableFilterOption);
    const selectedList = useSelector((state: any) => state.APIResponse.selectedAdvancedFilter);
    // const [data, setData] = useState([]);
    const { width } = useWindowDimensions();
    const [tableData, setTableData] = useState<PremisesListData[]>([]);
    const [loading, setLoading] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectFilter, setSelectFilter] = useState<any>({
        label: "First Name / Last Name",
        value: 0
    });
    const [searchValue, setSeachValue] = useState("")

    console.log("selectFilter", selectFilter);
    const tableRef: any = useRef(null);
    const userType = getStorageData().userType;

    const token = localStorage.getItem("Authorization");
    var initializeTable = 0;
    // console.log("tableRef", tableRef);

    let dataTableInstance: any = null;
    const fetchData = (dataTablesData: any, callback: any) => {
        console.log("paramsId", paramsId);
        if (idName === "DebugDeviceDetails") {
            setReload(false);
        }
        // setLoading(true)
        $('#customLoader').show()
        // console.log("dataTablesData", dataTablesData);
        var reqObj: any
        showFilterOpetions ?
            reqObj = {
                ...dataTablesData,
                ...paramsId,
                filterType: selectFilter.value,
                search: {
                    value: searchValue, // Include the search value
                    regex: false // You can adjust this based on your requirements
                }
            } : showSearchebleFilter ?
                reqObj = {
                    ...dataTablesData,
                    ...paramsId,
                    filterType: selectFilter.value,
                    search: {
                        value: searchValue, // Include the search value
                        regex: false // You can adjust this based on your requirements
                    },
                    // stateId: filterValue,
                    // cityId: filterValue === "all" ? "" : cityFilterValue
                }
                :
                reqObj = {
                    ...dataTablesData,
                    ...paramsId,
                    filterType: selectFilter.value,
                    search: {
                        value: searchValue, // Include the search value
                        regex: false // You can adjust this based on your requirements
                    }
                }
        axios.post(apiURL && apiURL, reqObj, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then((response) => {
            const res: PremisesListResponse = response.data
            console.log("res", res);
            $('#customLoader').hide()
            callback({
                draw: dataTablesData.draw,
                recordsTotal: res.recordsTotal,
                recordsFiltered: res.recordsFiltered, // You might adjust this based on filtered records
                data: res.data,
            });
            if (idName === "DebugDeviceDetails") {
                if (dataTablesData?.start >= 10) {
                    setReload(false);
                } else {
                    setReload(true);
                }
            }
            setTableData(res.data)
        })
           .catch((err) => {
                if (err.response && err.response.status === 401) {
                    // localStorage.clear();
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("selectedPremiseID");
                    localStorage.removeItem("selectedUserID");
                    localStorage.removeItem("selectedUserEmail");
                    localStorage.removeItem("Authorization");
                    localStorage.removeItem("permissions");
                    window.location.reload();
                } else {
                    // Handle other errors as needed
                    console.error("Error fetching data:", err);
                }
            }
            );
    }

    console.log("datadatadata", tableData);
    const initializeData = () => {
        // if (!loading && tableData.length > 0) {
        console.log("calll  data length > 0");
        // if ($.fn.DataTable.isDataTable(tableRef.current)) {
        //     $(tableRef.current).DataTable().destroy();
        // }

        try {
            if ($.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }

            dataTableInstance = $(tableRef.current).DataTable({
                processing: false,
                serverSide: true,
                ajax: fetchData,
                columns: columns,
                searching: true,
                ordering: false,
            });
        } catch (error) {
            console.error('Error initializing DataTable:', error);
        }

        $(tableRef.current).on('change', '.dropdown-btn', function () {
            const selectedValue = $(this).val();
            const rowIndex = dataTableInstance.row($(this).closest('tr')).index();
            const rowData = dataTableInstance.row(rowIndex).data();
            const feedbackId = rowData.id; // Assuming there's a column 'id' in your data
            console.log("selectedValue", selectedValue);

            // Now you have feedbackId and selectedValue, make your API call here
            // Example Axios call:
            handleDropdownClick && handleDropdownClick(rowData, selectedValue)
            // axios.post(CHANGE_STATUS_URL, {
            //     feedbackId: feedbackId,
            //     status: selectedValue,
            // })
            //     .then((res) => {
            //         // Handle success response here if needed
            //         console.log('Success:', res.data);
            //     })
            //     .catch((error) => {
            //         // Handle error here if needed
            //         console.error('Error:', error);
            //     });
        });

        $(tableRef.current).on('click', '.column-click', function () {
            const data = dataTableInstance.row($(this).closest('tr')).data();
            // Perform actions on click, e.g., open a modal, navigate to a page, etc.
            if (!isClicked) {
                //  console.log('User type is not allowed to click Premise Name');
                return; // Abort further actions
            }
            handleColumnClicked && handleColumnClicked(data)
            //  console.log('Clicked Premise Name:', data);
        });
        {
            !isEntryShow &&
                $(tableRef.current)
                    .closest('.dataTables_wrapper')
                    .find('.dataTables_length')
                    .hide();
            //     $(tableRef.current).closest('.dt-container').find('.dt-length').hide();
            // $(tableRef.current).closest('.dt-container').find('.dt-search').hide();
            $(tableRef.current).closest('.dataTables_wrapper').find('.dataTables_filter').hide();
        }
        $(tableRef.current).on('click', '.edit-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("edit rowData", rowData);
            handleEditClick && handleEditClick(rowData);
        });

        $(tableRef.current).on('click', '.delete-btn', function () {
            const rowData = dataTableInstance.row($(this).parents('tr')).data();
            console.log("delete rowData", rowData);
            handleDeleteClick && handleDeleteClick(rowData);
        });
        $(tableRef.current).on('preXhr.dt', function () {
            $('#customLoader').show();
        });

        $(tableRef.current).on('xhr.dt', function () {
            $('#customLoader').hide();
        });
        $(tableRef.current).find('thead th').css('background-color', '#EFF2F7');
    }

    useEffect(() => {
        initializeData()
    }, [loading, selectFilter, searchValue, filterValue, cityFilterValue]);

    useEffect(() => {
        if (paramsId?.filter && paramsId?.filter !== null) {
            initializeData()
        }
    }, [paramsId])

    useEffect(() => {
        const dataTableId = $(tableRef.current).attr('id');
        onDataTableRefresh && onDataTableRefresh(dataTableId);
    }, [])

    //  console.log("dataTableInstancedataTableInstance", dataTableInstance);
    const handleCustomSearch = (value: any) => {
        //  console.log("valuevalue", dataTableInstance);
        // console.log("valuevalue", value);
        setSeachValue(value)
        // if (tableRef.current) {
        //     $(tableRef.current).DataTable().draw();
        //     // $(tableRef.current).DataTable().search(value).draw();
        // }
    };

    function handleSelectFilter(selectedValue: any) {
        setSelectFilter(selectedValue);
        console.log("selectedValue", selectedValue);
    }

    interface CustomStyles {
        menu?: (provider: any) => any
        control?: (base: any, state: any) => any;
        // ... other style properties as needed
    }

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
            fontWeight: "normal", // Set font weight for the dropdown menu
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            color: '#6c757d', // Placeholder color
            fontWeight: 'normal', // Placeholder font-weight
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: '#333', // Change selected value color
            fontWeight: 'normal', // Change selected
            // Add any other custom styles for the selected value here if needed
        }),
    };

    return (
        <Card style={{ marginTop: 100, marginLeft: 30, marginRight: 30, marginBottom: 0 }}>
            <CardBody>
                <div className="mb-4 h4 card-title d-flex flex-column flex-md-row justify-content-between" >

                    {showFilterOpetions ?
                        <div className="justify-content-between flex-row d-flex" style={{ gap: 10 }}>
                            <div className="" style={{ width: 500 }}>
                                <Select
                                    value={selectFilter}
                                    onChange={handleSelectFilter}
                                    options={optionGroup}
                                    className="select2-selection"
                                    styles={customStyles}
                                    placeholder="Select..."

                                />
                            </div>
                            <div className="app-search d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0" style={{ width: "100%" }}>
                                <div className="position-relative" style={{ width: "100%" }}>
                                    {/* <span className="bx bx-search-alt" /> */}
                                    <span><box-icon name='search-alt' color="#74788d" size="16px"></box-icon></span>
                                    <input
                                        type="search"
                                        className="form-control"
                                        style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                        placeholder={"Search..."}
                                        onChange={(e) => handleCustomSearch(e.target.value)}
                                        value={searchValue}
                                    />
                                </div>
                            </div>
                        </div>
                        : showSearchebleFilter ?
                            <div className="d-flex flex-column flex-md-row" style={{ gap: 10 }} >
                                <div className="d-block mb-3 mb-md-0" style={{ width: isShowSearch ? width < 800 ? 350 : width < 1050 ? 200 : 350 : 550 }}>
                                    <Select
                                        name="stete"
                                        onFocus={() => searchAPICall("a")}
                                        value={searchableFilterOption?.find((option: any) => option?.value === filterValue)}
                                        options={searchableFilterOption}
                                        onChange={(selectedOption: any) => {
                                            setFilterValue(selectedOption)
                                            setCityFilterValue && setCityFilterValue({ label: "All", value: "all" })
                                        }}
                                        // invalid={
                                        //   assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
                                        // }
                                        // className={cx({ 'is-invalid': invalid })}
                                        onInputChange={(e) => {
                                            searchAPICall(e)
                                            setCityFilterValue && setCityFilterValue({ label: "All", value: "all" })
                                        }}
                                        styles={customStyles}
                                    />
                                </div>
                                {setCityFilterValue && <div className="d-block mb-3 mb-md-0" style={{ width: width < 800 ? 350 : width < 1050 ? 200 : 350 }}>
                                    <Select
                                        name="city"
                                        onFocus={() => searchCityAPICall("a")}
                                        value={searchableCityOption.find((option: any) => option.value === cityFilterValue)}
                                        options={searchableCityOption}
                                        onChange={setCityFilterValue} // Ensure selectedOption.value contains the desired value
                                        onInputChange={(e) => { searchCityAPICall(e) }} // Make sure this function works as expected
                                        styles={customStyles}
                                    />
                                </div>}
                                {isShowSearch && <div className="app-search d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0" style={{ width: "60%" }}>
                                    <div className="position-relative" style={{ width: '95%' }}>
                                        {/* <span className="bx bx-search-alt" /> */}
                                        <span><box-icon name='search-alt' color="#74788d" size="16px"></box-icon></span>
                                        <input
                                            type="search"
                                            className="form-control"
                                            style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                            placeholder={"Search..."}
                                            onChange={(e) => handleCustomSearch(e.target.value)}
                                            value={searchValue}
                                        />
                                    </div>
                                </div>}
                            </div> :
                            <div className="app-search d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0" style={{}}>
                                {!isShowSearch && < div className="position-relative" style={{ width: "100%" }}>
                                    {/* <span className="bx bx-search-alt" /> */}
                                    <span><box-icon name='search-alt' color="#74788d" size="16px"></box-icon></span>
                                    <input
                                        type="search"
                                        className="form-control"
                                        style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                        placeholder={idName === "BackupList" ? "Search by Email and Mobile No." : "Search..."}
                                        onChange={(e) => handleCustomSearch(e.target.value)}
                                        value={searchValue}
                                    />
                                </div>}
                            </div>
                    }
                    {/* </div> */}

                    {/* showSearchebleFilter ? <div className="justify-content-between flex-row d-flex" style={{ gap: 10 }}>
                            <Select
                                name="stete"
                                onFocus={() => searchAPICall("a")}
                                value={searchableFilterOption.find((option: any) => option.value === searchValue)}
                                options={searchableFilterOption}
                                onChange={(selectedOption: any) => setFilterValue(selectedOption)}
                                // invalid={
                                //   assignPremiseValidation.touched.recipientUserId && assignPremiseValidation.errors.recipientUserId ? true : false
                                // }
                                // className={cx({ 'is-invalid': invalid })}
                                onInputChange={(e) => searchAPICall(e)}
                                styles={customStyles}
                            />
                            <div className="app-search d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0" style={{}}>
                                <div className="position-relative" style={{ width: "100%" }}>
                                    <span className="bx bx-search-alt" />
                                    <input
                                        type="search"
                                        className="form-control"
                                        style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                        placeholder={"Search..."}
                                        onChange={(e) => handleCustomSearch(e.target.value)}
                                        value={searchValue}
                                    />
                                </div>
                            </div>
                        </div> :
                            <div className="app-search d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0" style={{}}>
                                <div className="position-relative" style={{ width: "100%" }}>
                                    <span className="bx bx-search-alt" />
                                    <input
                                        type="search"
                                        className="form-control"
                                        style={{ border: '1px solid #ccc', background: '#F7F9FC', color: "#858D9D" }}
                                        placeholder={"Search..."}
                                        onChange={(e) => handleCustomSearch(e.target.value)}
                                        value={searchValue}
                                    />
                                </div>
                            </div>} */}
                    <div className="justify-content-between flex-row d-flex" style={{ gap: 20 }}>
                        {isAddBtnShow &&
                            <button className={btnStyle}
                                onClick={() => onClickBtn && onClickBtn()}
                            >
                                {btnName}
                            </button>}
                        {isBtnShow &&
                            <button
                                className={secondBtnStyle}
                                style={{ gap: 20 }}
                                onClick={() => secondBtnOnClick && secondBtnOnClick()}
                            >
                                {secondBtnText}
                            </button>}
                    </div>
                </div>
                <div>
                    {/* <Spinners /> */}
                    <div id="customLoader" style={{
                        display: 'none',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '20px',
                    }}>
                        <p>Processing....</p>
                    </div>
                    {initializeTable === 0 && (
                        initializeTable = 1,
                        <div className='table-responsive'>
                            <table
                                id={`dataTable${idName}`}
                                ref={tableRef}
                                className="table table-hover"
                                style={{ width: '100%', fontSize: 14, color: "#858D9D" }}

                            >
                            </table>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card >
    )
}

export default DataTable