import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, InputGroup } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, useFormik } from "formik";
import OtpInput from 'react-otp-input'

import CarouselPage from "./CarouselPage";
import * as url from '../../helpers/url_helper';
import Request from '../../helpers/request';
import { VERIFY_OTP_SUCCESS } from '../../store/auth/login/actionTypes';
import { VerifyOtpResponse } from '../../helpers/response/Auth/VerifyOtpResponse';
import useWindowDimensions from '../../Utils/useWindowDimensions';
import getStorageData from '../../Utils/getStorageData';
import { getMenuPermissions } from '../../services/APIServices';
import { permission } from '../../store/slices/APIResponseSlice';
import { v4 as uuidv4 } from 'uuid';
// import macaddress from "macaddress"

const VerifyOTP = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    let os = 'Unknown OS';
    let osVersion = 'Unknown OS Version';
    let browserName = 'Unknown Browser';
    let uniqId = ""

    useEffect(() => {
        // Check if the unique ID exists in local storage
        const storedUniqueId = localStorage.getItem('uniqueId');
        console.log("storedUniqueId", storedUniqueId);

        // If unique ID exists, set it in state
        if (storedUniqueId) {
            setUniqueId(storedUniqueId);
        } else {
            // If unique ID doesn't exist, generate a new one
            const newUniqueId = uuidv4();
            setUniqueId(newUniqueId);
            console.log("newUniqueId", newUniqueId);

            // Store the new unique ID in local storage
            localStorage.setItem('uniqueId', newUniqueId);
        }
    }, []);

    const getOSName = () => {
        const platform = navigator.platform;
        const userAgent = window.navigator.userAgent;
        console.log("userAgent", navigator.appCodeName);
        const storedUniqueId = localStorage.getItem('uniqueId');

        // osVersion
        if (userAgent.includes('Windows NT')) {
            osVersion = userAgent.split('Windows NT')[1].split(';')[0];
        } else if (userAgent.includes('Mac OS X')) {
            osVersion = userAgent.split('Mac OS X')[1].split(')')[0];
        } else if (userAgent.includes('Linux')) {
            osVersion = 'Linux';
        } else if (userAgent.toLowerCase().includes('iPhone') || userAgent.toLowerCase().includes('iPad')) {
            osVersion = 'iOS';
        }

        if (storedUniqueId) {
            uniqId = storedUniqueId
        } else {
            const newUniqueId = uuidv4();
            localStorage.setItem('uniqueId', newUniqueId);
        }

        // deviceInfo
        if (userAgent.indexOf('Firefox') > -1) {
            const firefoxMatch: any = userAgent.match(/Firefox\/(\S+)/);
            browserName = `Mozilla Firefox | ${firefoxMatch[1]}`;
        } else if (userAgent.indexOf('Chrome') > -1) {
            const chromeMatch: any = userAgent.match(/Chrome\/(\S+)/);
            browserName = `Google Chrome | ${chromeMatch[1]}`;
        } else if (userAgent.indexOf('Safari') > -1) {
            const safariMatch: any = userAgent.match(/Safari\/(\S+)/);
            browserName = `Apple Safari | ${safariMatch[1]}`;
        } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
            const explorerMatch: any = userAgent.match(/MSIE\/(\S+)/);
            browserName = `Internet Explorer | ${explorerMatch[1]}`;
        }

        const chromeMatch = userAgent.match(/Chrome\/(\S+)/);

        // macOs
        if (platform.toLowerCase().includes('win')) {
            os = 'Windows';
        } else if (platform.toLowerCase().includes('mac')) {
            os = 'MacOS';
        } else if (platform.toLowerCase().includes('linux')) {
            os = 'Linux';
        } else if (userAgent.toLowerCase().includes('android')) {
            os = 'Android';
        } else if (userAgent.toLowerCase().includes('iPhone') || userAgent.toLowerCase().includes('iPad')) {
            os = 'iOS';
        }
        // console.log("os", osVersion);
        return {
            os,
            osVersion,
            browserName,
            uniqId
        };
    };

    //  console.log('location: ' + location.state.email);
    //  console.log("React.version", navigator.appVersion);
    const validation = useFormik({
        initialValues: {
            email_or_mobile: location.state.email,
            otp: '',
            appVersion: React.version,
            osName: getOSName().os,
            osVersion: getOSName().osVersion ? getOSName().osVersion : "osVersion",
            macAddress: getOSName().uniqId,
            deviceInfo: getOSName().browserName,
            form: ""
        },
        validationSchema: Yup.object().shape({
            email_or_mobile: Yup.string()
                .test(
                    'email_or_mobile',
                    'Invalid email or mobile',
                    (value: any) => {
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        const mobileRegex = /^[0-9]{10}$/;
                        // Trim whitespace before validation
                        const trimmedValue = value.trim();
                        return (
                            !trimmedValue ||  // allow empty value
                            trimmedValue === '' ||  // allow whitespace
                            emailRegex.test(trimmedValue) ||  // check if it's an email
                            (mobileRegex.test(trimmedValue) && trimmedValue.length === 10)  // check if it's a mobile number with length 10
                        );
                    }
                )
                .required('Email or mobile is required'),
            otp: Yup.string()
                .matches(/^\d{4}$/, 'Please enter a valid 4-digit OTP')
                .required('Please enter a valid OTP')
            // otp: Yup.string()
            //     .required("Please enter a valid 4-digit OTP ")
        },
        ),
        onSubmit: (values) => {
            // macaddress.one().then(function (mac) {
            //     console.log("Mac address for this host: %s", mac);
            // });
            // dispatch(
            verifyOtpApiCall(values)
            // );
        }
    })

    const getUserInfo = (data: any) => {
        // console.log("datadatadatadata", data);
        return {
            type: VERIFY_OTP_SUCCESS,
            data,
        };
    };

    const verifyOtpApiCall = (values: any) => {
        const reqObj = {
            email_or_mobile: values.email_or_mobile,
            otp: values.otp,
            appVersion: values.appVersion,
            osName: values.osName,
            osVersion: values.osVersion,
            macAddress: values.macAddress,
            deviceInfo: values.deviceInfo,
        }
        setLoading(true);
        console.log("reqObj", reqObj);

        Request.post(url.VERIFY_OTP, reqObj).
            then((result) => {
                const res: VerifyOtpResponse = result.data
                //  console.log("verify otp Response", res);
                localStorage.setItem("userInfo", JSON.stringify(res?.data));
                localStorage.setItem("Authorization", res.token)
                // dispatch(getUserInfo(res.data))
                res.data.type !== 4 && window.location.reload();
                // console.log("localStorage.setItem", localStorage.getItem("userInfo"));
                const userType = res.data.type

                if (userType === 0) {
                    navigate("/premises", { replace: true })
                } else if (userType === 1) {
                    localStorage.setItem("selectedPremiseID", res?.premiseId)
                    // localStorage.setItem("selectedUserID".userId.id)
                    localStorage.setItem("selectedUserEmail", res?.premiseEmail)
                    navigate("/dashboard", { replace: true })
                } else if (userType === 4 && res?.data?.premiseId) {
                    getDashboardApiCall(res.data.premiseId);
                    navigate("/dashboard", { replace: true })
                    localStorage.setItem("selectedPremiseID", res.data.premiseId)
                } else if (userType === 4 && !res?.data?.premiseId) {
                    navigate("/send-otp")
                }
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                validation.setErrors({ form: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });

                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const getDashboardApiCall = (premiseId: string) => {
        getMenuPermissions(premiseId, dispatch).then((res) => {
            window.location.reload();
        })
        // .then((res: any) => {
        //     console.log("dataaaaaaa", res.menuPermission);

        //     if (res) {
        //         dispatch(permission(res.menuPermission))
        //         localStorage.setItem("permissions", JSON.stringify(res.menuPermission))
        //         window.location.reload();
        //     } else {

        //     }
        //     // console.log("JSON.stringify(menuPermissions) !== JSON.stringify(res.menuPermission", menuPermissions, res.menuPermission);
        // }
        // )
    }

    // console.log("validation", validation.errors);
    // const selectLoginState = (state) => state.Login;
    // const LoginProperties = createSelector(
    //     selectLoginState,
    //     (login) => ({
    //         verifyError: login.verifyError,
    //         verifyOtpMsg: login.verifyOtpMsg,
    //         loading: login.verifyLoading
    //     })
    // );

    // const {
    //     verifyError, verifyOtpMsg, loading
    // } = useSelector(LoginProperties);
    const [containerWidth, setContainerWidth] = useState(0);
    const inputPercentage = 50;
    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            const width: any = (containerRef?.current as HTMLElement)?.offsetWidth * (inputPercentage / 700);
            setContainerWidth(width);
        }
    }, [containerRef, inputPercentage]);
    // console.log("loading", loading, verifyError, verifyOtpMsg);
    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#fff' }}>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage />

                        <Col xl={5}>
                            <div className="auth-full-page-content justify-content-center p-md-5 p-4" ref={containerRef}>
                                <div className={width < 1000 ? "w-100" : "w-50"}>
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5">

                                        </div>
                                        <div className="my-auto">
                                            <div>
                                                <h4 className='font-size-26'>Hello!</h4>
                                                <p className="text-muted font-size-18">
                                                    Welcome aboard!
                                                </p>
                                            </div>
                                            <div className="mt-4">
                                                <Form className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="mb-3">
                                                        {validation.errors && validation.errors.form ? (
                                                            <Alert color="danger">{validation.errors.form}</Alert>
                                                        ) : null}
                                                        <InputGroup>
                                                            <span className="input-group-text"
                                                                style={{
                                                                    backgroundColor: '#fff',
                                                                    height: 55,
                                                                    borderColor: validation.touched.email_or_mobile && validation.errors.email_or_mobile ? "red" : "#EEEEEE"
                                                                }}
                                                            >
                                                                <i className="mdi mdi-email-outline" style={{ fontSize: '24px', color: "#A0A0A0" }}></i>
                                                            </span>
                                                            <Input
                                                                name="email_or_mobile"
                                                                className="form-control"
                                                                style={{
                                                                    backgroundColor: '#fff',
                                                                    height: 55,
                                                                    borderLeft: 0,
                                                                    fontSize: '16px',
                                                                    padding: 0,
                                                                    boxShadow: 'none',
                                                                    borderColor: "#EEEEEE"
                                                                }}
                                                                placeholder="Email Address"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email_or_mobile || ""}
                                                                invalid={
                                                                    validation.touched.email_or_mobile && validation.errors.email_or_mobile ? true : false
                                                                }
                                                                maxLength={(validation.values.email_or_mobile && validation.values.email_or_mobile.match(/^\d+$/) && validation.values.email_or_mobile.length === 10) ? 10 : undefined}
                                                                disabled
                                                            />
                                                        </InputGroup>
                                                        {/* {validation.touched.email_or_mobile && validation.errors.email_or_mobile ? (
                                                            <span className="error">{validation.errors.email_or_mobile}</span>
                                                        ) : null} */}
                                                    </div>

                                                    <div className="mb-3">
                                                        {/* <Label className="form-label">Email Address</Label> */}
                                                        <InputGroup>
                                                            {/* <span className="input-group-text" style={{ backgroundColor: '#fff' }}>
                                                                <i className="mdi mdi-email-outline"></i>
                                                            </span> */}
                                                            {/* <Input
                                                                name="otp"
                                                                className="form-control"
                                                                style={{ backgroundColor: '#fff', height: 60 }}
                                                                placeholder="OTP"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.otp || ""}
                                                                invalid={
                                                                    validation.touched.otp && validation.errors.otp ? true : false
                                                                }
                                                                maxLength={4}
                                                            /> */}
                                                        </InputGroup>
                                                        {/* <div className="otp-input">
                                                                {Array.from({ length: 4 }, (_, index) => (
                                                                    <Field
                                                                        key={index}
                                                                        type="text"
                                                                        name={`otp[${index}]`}
                                                                        maxLength={1}
                                                                        className="otp-digit"
                                                                    />
                                                                ))}
                                                            </div> */}
                                                        <OtpInput
                                                            value={validation.values.otp || ""}
                                                            // className="form-control"
                                                            shouldAutoFocus={true}
                                                            // containerStyle={{ border: "1px solid #858D9D" }}
                                                            onChange={(otp) => validation.handleChange('otp')(otp)}
                                                            numInputs={4}
                                                            inputStyle={{
                                                                height: 55,
                                                                width: width < 1000 ? width / 7 : `${containerWidth}px`,
                                                                marginRight: "12%",
                                                                border: "1px solid #EEEEEE",
                                                                borderRadius: "5px",
                                                                fontSize: '16px',
                                                            }}
                                                            // renderSeparator={<span>-</span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                        {validation.touched.otp && validation.errors.otp ? (
                                                            <span className="error">{validation.errors.otp}</span>
                                                        ) : null}
                                                    </div>

                                                    <div className="mt-3 d-grid">
                                                        <button
                                                            className="btn btn-dark"
                                                            type="submit"
                                                            style={{ height: 53 }}
                                                            disabled={loading}
                                                        // onClick={() => navigate("/premises")}
                                                        >
                                                            {loading ? "Verifying..." : "Verify"}
                                                        </button>
                                                    </div>

                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </React.Fragment >
    )
}

VerifyOTP.propTypes = {}

export default VerifyOTP