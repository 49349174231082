import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    chipData: [],
    menuPermissions: [],
    changePermission: true,
    chipsLogs: {},
    changeChipStatus: false,
    selectedRooms: [],
    selectedAdvancedFilter: [],
    advancedFilterCount: {
        appOpenValue: "1",
        appOperateValue: "1",
        appNotOpenValue: "1",
        appNotOperateValue: "1",
        sceneCount: "1",
        sceneRunCount: "1",
        automationCount: "1",
        automationRunCount: "1"
    }
};

export const APIResponseSlice = createSlice({
    name: "GetMenuPermission",
    initialState,
    reducers: {
        getChipData: (state, action) => {
            state.chipData = action.payload
        },
        permission: (state, action) => {
            state.menuPermissions = action.payload
            // state.userProfile = { ...state.userProfile, ...action.payload }
        },
        changePermission: (state, action) => {
            state.changePermission = action.payload
        },
        chipLogs: (state, action) => {
            state.chipsLogs = action.payload
        },
        selectedPremiseRooms: (state, action) => {
            state.selectedRooms = action.payload
        },
        getSelectedAdvancedFilter: (state, action) => {
            state.selectedAdvancedFilter = action.payload
        },
        getAdvancedFilterCount: (state, action) => {
            state.advancedFilterCount = { ...state.advancedFilterCount, ...action.payload }
        }
    },
})

export const {
    getChipData,
    permission,
    changePermission,
    chipLogs,
    selectedPremiseRooms,
    getSelectedAdvancedFilter,
    getAdvancedFilterCount
} = APIResponseSlice.actions;

export default APIResponseSlice.reducer;