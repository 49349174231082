import moment from "moment";
const seconds = 3660;

export const convertUTCDate = (date: any) => {
    const convertUTCDate = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
    // const formatMill = moment(date).millisecond
    // console.log("date=========", date);
    return convertUTCDate;
};

export const convertLocalDate = (date: any) => {
    console.log("new Date", new Date());

    const convertUTCDate = moment(date).local().format('YYYY-MM-DD HH:mm:ss');
    // const formatMill = moment(date).millisecond
    // console.log("date=========", date);
    return convertUTCDate;
};

export const convertLocalTime = (time: any) => {
    const convertUTCDate = moment.utc(time, 'HH:mm:ss').local().format('HH:mm:ss');
    // const formatMill = moment(date).millisecond
    // console.log("date=========", date);
    return convertUTCDate;
};

export const convertsecondsToHours = (seconds: any) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.asHours();
    const formattedTime = moment.utc(hours * 3600 * 1000).format('HH:mm:ss');
    return formattedTime;
}