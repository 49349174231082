import React from 'react'
import { customStyles } from './style'
import { Card } from 'reactstrap'
import { Images } from '../../../constants/images'
import Lottie from 'lottie-react';
import { useSocket } from '../../../Utils/socket';
import { OPERATE_CHIP } from '../../../helpers/socketEvent';

export interface ServiceSwitchProps {
    switchData: any;
    getChipStatus: () => void;
}

const ServiceSwitch = ({
    switchData,
    getChipStatus,
}: ServiceSwitchProps) => {
    console.log("switchData in scene", switchData);
    const socket: any = useSocket();

    const chipSwitchId = switchData
    console.log("switchData in switchData component", switchData);
    const switchType = chipSwitchId?.switchItem?.chipSwitchId?.type;
    const switchIcon = chipSwitchId?.switchItem?.chipSwitchId?.icon?.toLowerCase();

    // console.log("switchIcon", chipSwitchId);

    const switchName = chipSwitchId?.switchItem?.chipSwitchId?.displayName;
    const button = chipSwitchId?.switchItem?.chipSwitchId?.button;
    const modelType = chipSwitchId?.switchItem?.modelType;
    const isClickable = switchData?.isClickable;
    console.log("isClickable", `${chipSwitchId?.switchItem?.chipSwitchId?.displayName}${isClickable}`);

    const isOnOff = switchData?.isOnOff;
    var icon = ""
    if (isOnOff === "1" && isClickable) {
        if (switchIcon) {
            if (switchIcon === "laundry") {
                icon = "washing_machine"
            } else {
                icon = switchIcon
            }
        } else {
            if (modelType === "4HINSC" && button === "04" || modelType === "4HIN" && button === "03") {
                icon = "dnd"
            } else if (button === "05") {
                icon = "washing_machine"
            } else {
                icon = "cleaning"
            }
        }
    } else {
        if (switchIcon) {
            if (switchIcon === "laundry") {
                icon = "washing_machine_off"
            } else {
                icon = `${switchIcon}_off`
            }
        } else {
            if (modelType === "4HINSC" && button === "04" || modelType === "4HIN" && button === "03") {
                icon = "dnd_off"
            } else if (modelType === "4HINSC" && button === "05" || modelType === "4HIN" && button === "04") {
                icon = "washing_machine_off"
            } else {
                icon = "cleaning_off"
            }
        }
    }

    const operateChip = (isOnOrOff: any) => {
        console.log("chipSwitchId", chipSwitchId);
        const button = chipSwitchId?.chipSwitchId?.button;
        var executeVal: any = "XX"
        // if (switchType === 0) {
        if (chipSwitchId?.switchDetail?.val === "0" || chipSwitchId?.switchDetail?.val === "") {
            executeVal = "XX1"
        } else {
            executeVal = "XX0"
        }
        // }
        // else if (switchType === 1) {
        //     if (isOnOrOff) {
        //         if (chipSwitchId?.switchDetail?.val === "0") {
        //             executeVal = `${switchValue}0`
        //         } else {
        //             executeVal = `${switchValue}1`
        //         }
        //     }
        //     else if (chipSwitchId?.switchDetail?.val === "1") {
        //         executeVal = `${switchValue}0`
        //     } else {
        //         executeVal = `${switchValue}1`
        //     }
        // }

        const reqObj = {
            slave: chipSwitchId?.switchItem?.chipSwitchId?.slave,
            button: chipSwitchId?.switchItem?.chipSwitchId?.button,
            executeVal: executeVal,
            chipSwitchId: chipSwitchId?.switchItem?.id,
            chipId: chipSwitchId.switchDetail?.id,
            cmd: "ST"
        }
        // console.log("reqObj: ", reqObj);
        socket.emit(OPERATE_CHIP, JSON.stringify(reqObj))
        // setTimeout(() => {
        //     getChipStatus()
        // }, 500)
        console.log("call oprate chip");
    }

    const dndView: any = () => {
        return (
            <Card
                style={{
                    ...customStyles.box(isClickable, isOnOff
                    ), position: 'relative', zIndex: 1
                }}
                onClick={() => isClickable ? operateChip("") : ""}
            >
                {!isClickable &&
                    <div
                        style={{ ...customStyles.wifiStyle(), position: "absolute" }}
                    >
                        <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                    </div>}
                <Lottie
                    animationData={require(`../../../assets/Lottie_json/${icon}`)}
                    loop={true}
                    size={5}
                    style={{ width: 40, height: 40 }}
                />
                {/* <img src={Images.block} style={{ width: 20, height: 20, fill: "red" }} /> */}
                <h1 style={{
                    ...customStyles.sceneName(isClickable, isOnOff),
                    lineBreak: "auto",
                }}>
                    DND
                </h1>
            </Card>
        )
    }

    const laundryView = () => {
        return (
            <Card
                style={{
                    ...customStyles.box(isClickable, isOnOff
                    ), position: 'relative', zIndex: 1
                }}
                onClick={() => isClickable ? operateChip("") : ""}
            >
                {!isClickable && <div
                    style={{ ...customStyles.wifiStyle(), position: "absolute" }}
                >
                    <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                </div>}
                <Lottie
                    animationData={require(`../../../assets/Lottie_json/${icon}`)}
                    loop={true}
                    size={5}
                    style={{ width: 40, height: 40 }}
                />
                {/* <img src={Images.loundry} style={{ width: 20, height: 20 }} /> */}
                <h1 style={{
                    ...customStyles.sceneName(isClickable, isOnOff),
                    lineBreak: "auto",
                }}>
                    Laundry
                </h1>
            </Card>
        )
    }

    const cleanerView = () => {
        return (<Card
            style={{
                ...customStyles.box(isClickable, isOnOff
                ), position: 'relative', zIndex: 1
            }}
            onClick={() => isClickable ? operateChip("") : ""}
        >
            {!isClickable &&
                <div
                    style={{ ...customStyles.wifiStyle(), position: "absolute" }}
                >
                    <i className="bx bx-wifi-off" style={{ fontSize: 25, color: "#fff" }} />
                </div>}
            <Lottie
                animationData={require(`../../../assets/Lottie_json/${icon}`)}
                loop={true}
                size={5}
                style={{ width: 40, height: 40 }}
            />
            {/* <img src={Images.cleaner} style={{ width: 20, height: 20 }} /> */}
            <h1 style={{
                ...customStyles.sceneName(isClickable, isOnOff),
                lineBreak: "auto",
            }}>
                Clean Room
            </h1>
        </Card>
        )
    }

    const offLineView = () => {
        return (
            <div style={customStyles.offCanvasHeader()}>
                {dndView()}
                {laundryView()}
                {cleanerView()}
            </div>
        )
    }
    if (modelType === "4HIN") {
        if (button === "03") {
            return dndView()
        }
        if (button === "04") {
            return laundryView()
        }
        if (button === "05") {
            return cleanerView();
        }
    } else if (modelType === "4HISC") {
        if (button === "04") {
            return dndView()
        }
        if (button === "05") {
            return laundryView()
        }
        if (button === "06") {
            return cleanerView();
        }
    }
}

export default ServiceSwitch