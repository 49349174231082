import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import * as Yup from "yup";
import Request from '../../../helpers/request';
import * as url from '../../../helpers/url_helper'
import { ToastContainer, toast } from 'react-toastify';
import { allRoomSearchApiCall, allowRoomSearchApiCall, guestNameSearchApiCall } from '../../../Utils/searchApiCall';
import Select from "react-select";
import { convertLocalDate, convertUTCDate } from '../../../Utils/configController';
import moment from 'moment';
import useWindowDimensions from '../../../Utils/useWindowDimensions';
import getStorageData from '../../../Utils/getStorageData';

export interface AllocateRoomProps {
    toggleRoomModal: () => void;
    isOpenAllocateRoomModal?: boolean;
    allocateRoomData?: any;
    isEditable?: boolean;
    reloadData?: any;
}
const AllocateRoom = ({
    toggleRoomModal,
    isOpenAllocateRoomModal,
    allocateRoomData,
    isEditable,
    reloadData }:
    AllocateRoomProps) => {
    const [loading, setLoading] = useState(false);
    const [roomNumberList, setRoomNumberList] = useState<any[]>([]);
    const [guestNameList, setGuestNameList] = useState<any[]>([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isOpenGuestModel, setOpenGuestModel] = useState(false);
    const { height, width } = useWindowDimensions();

    console.log("useWindowDimensions", "height", height, "width", width);
    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Set background color for the dropdown menu
            border: '1px solid #ccc', // Optional: Add border for clarity
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: Add shadow
        }),
    };


    const validation = useFormik({
        initialValues: {
            id: "",
            roomNo: "",
            guestName: "",
            guestNumberDays: "",
            guestEmail: "",
            guestMobile: "",
            guestCheckInDate: "",
            guestCheckInTime: "",
            guestCheckOutDate: "",
            guestCheckOutTime: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            roomNo: Yup.string()
                .required("Please select room number"),
            guestName: Yup.string()
                .required("Please provide guest name "),
            guestNumberDays: Yup.string()
                .required("Please provide number of days"),
            // guestEmail: Yup.string().trim()
            //     .email("Invalid email address")
            //     .required("Please provide guest email address "),
            // guestMobile: Yup.string()
            //     .required("Please provide guest mobile number ").min(10, "Mobile number not valid"),
            guestCheckInDate: Yup.string()
                .required("Please provide guest check-in date "),
            guestCheckInTime: Yup.string()
                .required("Please provide guest check-in time "),
            guestCheckOutDate: Yup.string()
                .required("Please provide guest check-out date ")
                .test(
                    'is-check-out-later',
                    'Check-in date must be earlier than check-out date',
                    function (value) {
                        const { guestCheckInDate } = this.parent;
                        const isValid = new Date(guestCheckInDate) <= new Date(value);
                        if (!isValid) {
                            this.parent.formError = 'Check-in date must be earlier than check-out date';
                        }
                        return isValid;
                    }
                ),
            guestCheckOutTime: Yup.string()
                .required("Please provide guest check-out time "),
        }),
        onSubmit: (values) => {
            allocateRoomApiCall(values);

            // setConfirmModal(true);
            //  console.log("values", values);
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const addGuestValidation = useFormik({
        initialValues: {
            guestName: "",
            email_or_mobile: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            guestName: Yup.string()
                .required("Please provide your guest name "),
            email_or_mobile: Yup.string()
                .test(
                    'emailOrMobile',
                    'Invalid email or mobile',
                    (value: any) => {
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        const mobileRegex = /^[0-9]{10}$/;
                        // Trim whitespace before validation
                        const trimmedValue = value.trim();
                        return (
                            !trimmedValue ||  // allow empty value
                            trimmedValue === '' ||  // allow whitespace
                            emailRegex.test(trimmedValue) ||  // check if it's an email
                            (mobileRegex.test(trimmedValue) && trimmedValue.length === 10)  // check if it's a mobile number with length 10
                        );
                    }
                )
                .required('Email or mobile is required')
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            addGuestApiCall(values);
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    console.log("guestCheckInDate", validation.values.guestCheckInDate);

    useEffect(() => {
        if (isEditable) {
            console.log("allocateRoomData", allocateRoomData);
            const convertCheckInDate = convertLocalDate(allocateRoomData.guestCheckIn)
            const convertCheckOutDate = convertLocalDate(allocateRoomData.guestCheckOut)
            const getConvertedCheckInDate = moment(convertCheckInDate).format('YYYY-MM-DD')
            const getConvertedCheckInTime = moment(convertCheckInDate).format('HH:mm:ss')
            const getConvertedCheckOutDate = moment(convertCheckOutDate).format('YYYY-MM-DD')
            const getConvertedCheckOutTime = moment(convertCheckOutDate).format('HH:mm:ss')
            console.log("getConvertedCheckInDate", getConvertedCheckInDate);

            searchRoomNumber("");
            searchGuestName(allocateRoomData.guestId.name);
            validation.setValues({
                id: allocateRoomData.id,
                roomNo: allocateRoomData.id,
                guestName: allocateRoomData.guestId.id,
                guestNumberDays: allocateRoomData.guestNumberDays,
                guestEmail: allocateRoomData.guestEmail,
                guestMobile: allocateRoomData.guestMobile,
                guestCheckInDate: getConvertedCheckInDate,
                guestCheckInTime: getConvertedCheckInTime,
                guestCheckOutDate: getConvertedCheckOutDate,
                guestCheckOutTime: getConvertedCheckOutTime,
                formError: ""
            })

        }
    }, [])

    const searchRoomNumber = (e: any) => {
        console.log("eeeee", e);

        allowRoomSearchApiCall(e, setRoomNumberList)
    }

    const searchGuestName = (e: any) => {
        console.log("eeeee", e);

        guestNameSearchApiCall(e, setGuestNameList)
    }

    const allocateRoomApiCall = (values: any) => {
        console.log("values", values);
        const checkInDate = moment(`${values.guestCheckInDate} ${values.guestCheckInTime}`).format();
        const checkOutDate = moment(`${values.guestCheckOutDate} ${values.guestCheckOutTime}`).format();
        const convertheckInDate = convertUTCDate(checkInDate);
        const convertheckOutDate = convertUTCDate(checkOutDate);
        console.log("convertheckInDate", convertheckInDate, checkInDate);

        setLoading(true);
        const reqObj = {
            roomId: values.roomNo,
            guestId: values.guestName,
            guestNumberDays: values.guestNumberDays,
            // guestEmail: values.guestEmail,
            // guestMobile: values.guestMobile,
            guestCheckIn: convertheckInDate,
            guestCheckOut: convertheckOutDate
        }
        console.log("reqObj", reqObj);

        Request.post(isEditable ? url.UPDATE_ROOM : url.ALLOW_ROOM, reqObj).
            then((res) => {
                //    console.log("Add Area Response", res);
                // areaListApiCall()
                setConfirmModal(true);
                validation.resetForm({
                    values: {
                        id: "",
                        roomNo: "",
                        guestName: "",
                        guestNumberDays: "",
                        guestEmail: "",
                        guestMobile: "",
                        guestCheckInDate: "",
                        guestCheckInTime: "",
                        guestCheckOutDate: "",
                        guestCheckOutTime: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setTimeout(() => {
                    toggleRoomModal();
                    reloadData && reloadData();
                }, 1000)

                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const handleGuestModel = () => {
        setOpenGuestModel(true);
    }

    const addGuestApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            premiseId: getStorageData().selectedPremiseID,
            guestName: values.guestName,
            email_or_mobile: values.email_or_mobile
        }
        Request.post(url.ADD_GUEST_URL, reqObj).
            then((res) => {
                //    console.log("Add Area Response", res);
                // areaListApiCall()
                addGuestValidation.resetForm({
                    values: {
                        guestName: "",
                        email_or_mobile: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenGuestModel(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                addGuestValidation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const toggleGuest = () => {
        setOpenGuestModel(!isOpenGuestModel);
        addGuestValidation.resetForm({
            values: {
                guestName: "",
                email_or_mobile: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    return (
        <>
            <ToastContainer />
            <Modal
                isOpen={isOpenGuestModel}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                // tabIndex="-1"
                toggle={toggleGuest}
            >
                <div className="modal-content">
                    <ModalHeader style={{ justifyContent: 'center' }}>{isEditable ? "Edit Area" : "Add Guest"}</ModalHeader>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            addGuestValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <ModalBody>
                            {addGuestValidation.errors && addGuestValidation.errors.formError ? (
                                <Alert color="danger">{addGuestValidation.errors.formError}</Alert>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Guest Name</Label>
                                <Input
                                    value={addGuestValidation.values.guestName || ""}
                                    name="guestName"
                                    type={"text"}
                                    placeholder="Enter Guest Name"
                                    onBlur={addGuestValidation.handleBlur}
                                    onChange={addGuestValidation.handleChange}
                                    invalid={
                                        addGuestValidation.touched.guestName && addGuestValidation.errors.guestName ? true : false
                                    }
                                />
                                {addGuestValidation.touched.guestName && addGuestValidation.errors.guestName ? (
                                    <span className="error">{addGuestValidation.errors.guestName}</span>
                                ) : null}
                            </div>
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Email or Mobile</Label>
                                <Input
                                    value={addGuestValidation.values.email_or_mobile || ""}
                                    name="email_or_mobile"
                                    type={"text"}
                                    placeholder="Enter Email or Mobile Number"
                                    onBlur={addGuestValidation.handleBlur}
                                    onChange={addGuestValidation.handleChange}
                                    invalid={
                                        addGuestValidation.touched.email_or_mobile && addGuestValidation.errors.email_or_mobile ? true : false
                                    }
                                    maxLength={(addGuestValidation.values.email_or_mobile && addGuestValidation.values.email_or_mobile.match(/^\d+$/) && addGuestValidation.values.email_or_mobile.length === 10) ? 10 : undefined}
                                />
                                {addGuestValidation.touched.email_or_mobile && addGuestValidation.errors.email_or_mobile ? (
                                    <span className="error">{addGuestValidation.errors.email_or_mobile}</span>
                                ) : null}
                            </div>

                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                                disabled={loading}
                            >
                                {/* Add */}
                                {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>

            <Offcanvas
                isOpen={isOpenAllocateRoomModal}
                direction="end"
                toggle={() => toggleRoomModal()}
                style={{ width: width < 1000 ? "90%" : "30%" }}
            // backdrop={false}
            >
                <OffcanvasHeader></OffcanvasHeader>
                <OffcanvasBody style={{ marginRight: 20, marginLeft: 20 }}>
                    <Form className="form-horizontal"
                        onSubmit={(e) => {
                            // console.log("call on submit", e);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        {validation.errors && validation.errors.formError ? (
                            <Alert color="danger">{validation.errors.formError}</Alert>
                        ) : null}
                        <div className="mb-3">
                            <Label className="form-label">Room Number</Label>
                            <Select
                                name="roomNo"
                                value={roomNumberList.find((option: any) => option.value === validation.values.roomNo)}
                                options={roomNumberList}
                                onChange={(selectedOption: any) => validation.setFieldValue('roomNo', selectedOption.value)}
                                onBlur={() => validation.setFieldTouched('roomNo', true)}
                                // invalid={
                                //     validation.touched.cityName && validation.errors.cityName ? true : false
                                // }
                                onFocus={() => searchRoomNumber("")}
                                onInputChange={(e) => searchRoomNumber(e)}
                                styles={customStyles}
                            />
                            {validation.touched.roomNo && validation.errors.roomNo ? (
                                <span className="error">{validation.errors.roomNo}</span>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <div className='d-flex justify-content-between align-items-center'>
                                <Label className="form-label">Guest Name</Label>
                                <Button
                                    className='btn btn-dark mb-3'
                                    onClick={() => handleGuestModel()}
                                >
                                    Add Guest
                                </Button></div>
                            <Select
                                name="guestName"
                                value={guestNameList.find((option: any) => option.value === validation.values.guestName)}
                                options={guestNameList}
                                onChange={(selectedOption: any) => validation.setFieldValue('guestName', selectedOption.value)}
                                onBlur={() => validation.setFieldTouched('guestName', true)}
                                // invalid={
                                //     validation.touched.cityName && validation.errors.cityName ? true : false
                                // }
                                onFocus={() => searchGuestName("")}
                                onInputChange={(e) => searchGuestName(e)}
                                styles={customStyles}
                            />
                            {validation.touched.guestName && validation.errors.guestName ? (
                                <span className="error">{validation.errors.guestName}</span>
                            ) : null}
                            {/* <Input
                            value={validation.values.guestName || ""}
                            name="guestName"
                            type={"text"}
                            placeholder="Enter Guest Name"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                                validation.touched.guestName && validation.errors.guestName ? true : false
                            }
                        /> */}
                            {validation.touched.guestName && validation.errors.guestName ? (
                                <span className="error">{validation.errors.guestName}</span>
                            ) : null}
                        </div>
                        {/* <div className="mb-3">
                            <Label className="form-label">Email Address</Label>
                            <Input
                                value={validation.values.guestEmail || ""}
                                name="guestEmail"
                                type={"text"}
                                placeholder="Enter Guest Email Address"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.guestEmail && validation.errors.guestEmail ? true : false
                                }
                            // disabled={isEditable}
                            />
                            {validation.touched.guestEmail && validation.errors.guestEmail ? (
                                <span className="error">{validation.errors.guestEmail}</span>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Mobile Number</Label>
                            <Input
                                value={validation.values.guestMobile || ""}
                                name="guestMobile"
                                type={"text"}
                                placeholder="Enter Guest Mobile Number"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.guestMobile && validation.errors.guestMobile ? true : false
                                }
                                // disabled={isEditable}
                                maxLength={10}
                            />
                            {validation.touched.guestMobile && validation.errors.guestMobile ? (
                                <span className="error">{validation.errors.guestMobile}</span>
                            ) : null}
                        </div> */}
                        <div className="mb-3">
                            <Label className="form-label">Number of Days</Label>
                            <Input
                                value={validation.values.guestNumberDays || ""}
                                name="guestNumberDays"
                                type={"text"}
                                placeholder="Enter Number of Days"
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                                invalid={
                                    validation.touched.guestNumberDays && validation.errors.guestNumberDays ? true : false
                                }
                            // disabled={isEditable}
                            />
                            {validation.touched.guestNumberDays && validation.errors.guestNumberDays ? (
                                <span className="error">{validation.errors.guestNumberDays}</span>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Check-in Date</Label>
                            <div className="mb-3 d-flex justify-content-betweem gap-2">
                                <div className="mb-3" style={{ width: "50%" }}>
                                    <Input
                                        value={validation.values.guestCheckInDate || ""}
                                        name="guestCheckInDate"
                                        type={"date"}
                                        placeholder="Enter Number of Days"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.guestCheckInDate && validation.errors.guestCheckInDate ? true : false
                                        }
                                    // disabled={isEditable}
                                    />
                                    {validation.touched.guestCheckInDate && validation.errors.guestCheckInDate ? (
                                        <span className="error">{validation.errors.guestCheckInDate}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3" style={{ width: "50%" }}>
                                    <Input
                                        value={validation.values.guestCheckInTime || ""}
                                        name="guestCheckInTime"
                                        type={"time"}
                                        placeholder="Enter Number of Days"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.guestCheckInTime && validation.errors.guestCheckInTime ? true : false
                                        }
                                    // disabled={isEditable}
                                    />
                                    {validation.touched.guestCheckInTime && validation.errors.guestCheckInTime ? (
                                        <span className="error">{validation.errors.guestCheckInTime}</span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Check-out Date</Label>
                            <div className="mb-3 d-flex justify-content-betweem gap-2">
                                <div className="mb-3" style={{ width: "50%" }}>
                                    <Input
                                        value={validation.values.guestCheckOutDate || ""}
                                        name="guestCheckOutDate"
                                        type={"date"}
                                        placeholder="Enter Number of Days"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.guestCheckOutDate && validation.errors.guestCheckOutDate ? true : false
                                        }
                                    // disabled={isEditable}
                                    />
                                    {validation.touched.guestCheckOutDate && validation.errors.guestCheckOutDate ? (
                                        <span className="error">{validation.errors.guestCheckOutDate}</span>
                                    ) : null}
                                </div>
                                <div className="mb-3" style={{ width: "50%" }}>
                                    <Input
                                        value={validation.values.guestCheckOutTime || ""}
                                        name="guestCheckOutTime"
                                        type={"time"}
                                        placeholder="Enter Number of Days"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.guestCheckOutTime && validation.errors.guestCheckOutTime ? true : false
                                        }
                                    // disabled={isEditable}
                                    />
                                    {validation.touched.guestCheckOutTime && validation.errors.guestCheckOutTime ? (
                                        <span className="error">{validation.errors.guestCheckOutTime}</span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        {/* {!isEditable ? */}
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            <button
                                className="btn btn-dark"
                                type="submit"
                            // disabled={loading}
                            >
                                {isEditable ? "Update Room" : "Allocate Room"}
                                {/* {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"} */}
                                {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                            </button>
                        </div>
                        {/* :
                            <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
                                <button
                                    className="btn btn-outline-secondary"
                                    type="submit"
                                    style={{ width: "100%" }}
                                // disabled={loading}
                                >
                                    Remove Access
                                </button>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    style={{ width: "100%" }}
                                // disabled={loading}
                                >
                                    {isEditable ? "Update Room" : "Allocate Room"}
                                </button>
                            </div> 
                        } */}
                    </Form>
                </OffcanvasBody>
            </Offcanvas >
        </>
    )
}

export default AllocateRoom