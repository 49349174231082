import React, { useEffect, useState } from 'react'
import Request from '../../../../helpers/request';
import * as url from '../../../../helpers/url_helper';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery'
import ProfileListDataTable from './ProfileDataTable';
import { convertLocalDate, convertLocalTime } from '../../../../Utils/configController';

export interface ChipsControlProps {
    userId: any;
}

const ScheduleList = ({ userId }: ChipsControlProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);

    const columns = [
        {
            title: 'Slave',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.slave ? row.slave : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Button',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.button ? row.button : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Button Value',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.executeValue ? row.executeValue : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Day Numbers',
            render: function (data: any, type: any, row: any) {
                const day = row.dayNumbers.map((item: any, index: any) => {
                    return (
                        item === 0 ? "Sunday" :
                            item === 1 ? "Monday" :
                                item === 2 ? "Tuesday" :
                                    item === 3 ? "Wednesday" :
                                        item === 4 ? "Thursday" :
                                            item === 5 ? "Friday" :
                                                item === 6 ? "Saturday" : ""
                    )
                })
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.dayNumbers ? day.join("|") : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Time',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.time ? convertLocalTime(row.time) : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Is Repeat',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.isRepeat ? "True" : "False"} </div>
             </div>`
            }
        },
        {
            title: 'Next Run',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.nextRunDateTime
                        ? convertLocalDate(row.nextRunDateTime)
                        : "-"} </div>
             </div>`
            }
        },
    ]

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <div>
            <ToastContainer />
            <ProfileListDataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.GET_SCHEDULE_LIST}`}
                columns={columns}
                paramsId={{ userId: userId }}
                title="Schedule List"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                // handleEditClick={handleEditClick}
                // handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Schedulelist"}
            />
        </div>
    )
}

export default ScheduleList