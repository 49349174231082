import React, { useEffect, useState } from 'react'
import { convertLocalDate } from '../../Utils/configController';
import DataTable from '../../components/Common/DataTable';
import * as url from '../../helpers/url_helper';
import $ from 'jquery'
import { useParams } from 'react-router-dom';

const DebugDetail = () => {
    const { slave } = useParams()
    const [loading, setLoading] = useState(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isReload, setReload] = useState(false);
    console.log("isReload", isReload);

    const reloadDataTable = () => {
        console.log("dataTableId", dataTableId);

        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    useEffect(() => {
        // reloadDataTable();
        console.log("sdfdjbfdhfbghdfg", isReload);
        if (isReload) {
            console.log("sdfdjbfdhfbghdfg", isReload);

            const interval = setInterval(() => {
                reloadDataTable()
            }, 3000)
            return () => clearInterval(interval);
        }
    }, [dataTableId, isReload])

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        //   console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const columns = [
        {
            title: 'Device',
            render: function (data: any, type: any, row: any) {
                return (row?.topic && row?.topic?.includes("/TX") || row?.topic && row?.topic?.includes("/g2m")) ? `<div style="width:400px"> 
                           <div>${row?.command}</div>
                         </div>` : "-"
                // return row?.topic && row?.topic?.includes("/TX")
                //     ? `${row?.command}` : "-"
            }
        },
        {
            title: 'Mobile',
            render: function (data: any, type: any, row: any) {
                return (row?.topic && row?.topic?.includes("/RX") || row?.topic && row?.topic?.includes("/m2g")) ? `<div style="width:500px"> 
                <div>${row?.command}</div>
              </div>` : "-"
            }
        },
        {
            title: 'Created At',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt ? `<div> 
                <div>${convertLocalDate(row.createdAt)}</div>
              </div>` : "-"
                // return row?.createdAt
                //     ? convertLocalDate(row.createdAt) : "-"
            }
        },
        // {
        //     title: 'Actions',
        //     render: function () {
        //         return (
        //             `<div class="d-flex gap-2">
        //                         <button class="delete-btn" style="border: none; outline: none; background: none;">
        //                             <i class="bx bx-trash-alt" style="font-size: 20px;"></i>
        //                         </button>
        //                         <button class="log-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">Details</span></button>
        //                     </div>`
        //         );
        //     }
        // }
    ]

    return (
        <>
            <DataTable
                // data={servicePersonList}
                apiURL={`${url.BASE_URL}${url.DEBUG_DETAIL_LIST}`}
                paramsId={{ slave: slave }}
                columns={columns}
                isAddBtnShow={false}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"DebugDeviceDetails"}
                isEntryShow={true}
                isClicked={true}
                setReload={setReload}
            />
        </>
    )
}

export default DebugDetail