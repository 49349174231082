import React, { useState } from 'react'
import * as url from '../../helpers/url_helper';
import $ from 'jquery'
import { convertLocalDate } from '../../Utils/configController';
import { ToastContainer, toast } from 'react-toastify';
import Request from '../../helpers/request';
import ConfirmationModal from '../../components/Common/ConfirmationModal';
import DataTable from '../../components/Common/DataTable';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Alert, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Common/Breadcrumb';

const DebugDevice = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataTableId, setDataTableId] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteData, setDeleteData] = useState<any>();
    const [openSlaveModal, setOpenSlaveModal] = useState(false);

    const validation = useFormik({
        initialValues: {
            slave: "",
            formError: ""
        },
        validationSchema: Yup.object().shape({
            slave: Yup.string()
                .required("Please provide your slave "),
        }),
        onSubmit: (values) => {
            //  console.log("values", values);
            addSlaveApiCall(values)
            // isEditable ? editAreaApiCall(values) : addAreaApiCall(values)
        }
    })

    const addSlaveApiCall = (values: any) => {
        setLoading(true);
        const reqObj = {
            slave: values.slave,
        }
        Request.post(url.ADD_DEBUG_CHIP, reqObj).
            then((res) => {
                //    console.log("Add Area Response", res);
                // areaListApiCall()
                reloadDataTable()
                validation.resetForm({
                    values: {
                        slave: "",
                        formError: ""
                    },
                    touched: {} // Reset touched state for all fields
                });
                toast.success(res.data.msg);
                setOpenSlaveModal(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("add area Error", err);
                validation.setErrors({ formError: err?.response?.data?.errors[0]?.msg })
                // setErrors({ form: "Failed to add area. Please try again." });
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        //   console.log('DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    const handleDeleteClick = (rowData: any) => {
        console.log('Delete button clicked for row data:', rowData);
        setDeleteModalOpen(!isDeleteModalOpen);
        setDeleteData(rowData);
        // Add your logic here
    };

    const columns = [
        {
            title: 'slave',
            render: function (data: any, type: any, row: any) {
                return row?.slave
                    ? `${row?.slave}` : "-"
            }
        },
        {
            title: 'Created At',
            render: function (data: any, type: any, row: any) {
                return row?.createdAt
                    ? convertLocalDate(row.createdAt) : "-"
            }
        },
        {
            title: 'Actions',
            render: function () {
                return (
                    `<div class="d-flex gap-2">
                                <button class="delete-btn" style="border: none; outline: none; background: none;">
                                    <box-icon name='trash-alt' size="20px"></box-icon>
                                </button>
                                <button class="edit-btn" style="border: none; outline: none; background: none;"><span class="badge bg-primary" style="height: 25px; width: 60px; font-size: 12px; padding: 6px;">View</span></button>
                            </div>`
                );
            }
        }
    ]

    const deleteUserApiCall = () => {
        setLoading(true);
        const reqObj = {
            slave: deleteData.slave
        }
        Request.post(url.DELETE_DEBUG_CHIP, reqObj).
            then((res) => {
                // console.log("delete Area Response", res);
                // areaListApiCall()

                toast.success(res.data.msg)
                reloadDataTable();
                setDeleteModalOpen(false)
                setLoading(false)
            }).
            catch((err) => {
                // console.log("edit area Error", err);
                setLoading(false)
                // showToast("error", err.response.data.errors[0]?.msg)
            })
    }

    const handleDetailsClick = (rowData: any) => {
        console.log("details data", rowData);
        // rowData?.id ?
        //     navigate(`/users/profile/${rowData.id}`) : ""
    }

    const toggleSlave = () => {
        setOpenSlaveModal(!openSlaveModal);
        validation.resetForm({
            values: {
                slave: "",
                formError: ""
            },
            touched: {} // Reset touched state for all fields
        });
    }

    const handleLogClick = (rowData: any) => {
        console.log("rowData: ", rowData);
        rowData?.slave ?
            navigate(`/debug-device-details/${rowData?.slave}`) :
            ""
    }

    return (
        <React.Fragment>
            <div>
                <Modal
                    isOpen={openSlaveModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    // tabIndex="-1"
                    toggle={toggleSlave}
                >
                    <div className="modal-content">
                        <ModalHeader style={{ justifyContent: 'center' }}>
                            Add Device
                            {/* {isEditable ? "Edit Area" : "Add Area"} */}
                        </ModalHeader>
                        <Form className="form-horizontal"
                            onSubmit={(e) => {
                                // console.log("call on submit", e);
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <ModalBody>
                                {validation.errors && validation.errors.formError ? (
                                    <Alert color="danger">{validation.errors.formError}</Alert>
                                ) : null}
                                <div className="mb-3">
                                    <Label className="form-label">Slave</Label>
                                    <Input
                                        value={validation.values.slave || ""}
                                        name="slave"
                                        type={"text"}
                                        placeholder="Slave"
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={
                                            validation.touched.slave && validation.errors.slave ? true : false
                                        }
                                    />
                                    {validation.touched.slave && validation.errors.slave ? (
                                        <span className="error">{validation.errors.slave}</span>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ justifyContent: 'center' }}>
                                <button
                                    className="btn btn-dark"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {/* Add */}
                                    {loading ? "Saving..." : "Save"}
                                    {/* {loading && isEditable ? "Saving..." : isEditable ? "Save" : loading ? "Saving..." : "Save"} */}
                                    {/* {loading ? "Loading..." : isEditable ? "Edit" : "Add"} */}
                                </button>
                            </ModalFooter>
                        </Form>
                    </div>
                </Modal>
                {isDeleteModalOpen &&
                    <ConfirmationModal
                        headerTitle="Delete Slave?"
                        text="Are you sure you want to delete this slave ? This action cannot be undone."
                        buttonText="Delete"
                        onClick={() => { deleteUserApiCall() }}
                        cancleBtnClick={() => setDeleteModalOpen(false)}
                        loading={loading}
                    />
                }
                <ToastContainer />
                <DataTable
                    // data={servicePersonList}
                    apiURL={`${url.BASE_URL}${url.GET_DEBUG_LIST}`}
                    columns={columns}
                    isAddBtnShow={true}
                    // isBtnShow={false}
                    btnName="Add Device"
                    btnStyle="btn btn-dark"
                    onClickBtn={toggleSlave}
                    handleColumnClicked={handleDetailsClick}
                    handleDeleteClick={handleDeleteClick}
                    // editApiCall={() => { }}
                    onDataTableRefresh={handleDataTableIdChange}
                    idName={"DebugDevice"}
                    isEntryShow={true}
                    isClicked={true}
                    handleEditClick={handleLogClick}
                />
            </div>
        </React.Fragment>
    )
}

export default DebugDevice