import React, { useEffect, useState } from 'react'
import Request from '../../../../helpers/request';
import * as url from '../../../../helpers/url_helper';
import { ToastContainer } from 'react-toastify';
import $ from 'jquery'
import ProfileListDataTable from './ProfileDataTable';
import { convertLocalDate } from '../../../../Utils/configController';

export interface ChipsControlProps {
    userId: any;
}

const DeviceList = ({ userId }: ChipsControlProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTableId, setDataTableId] = useState(null);

    const columns = [
        {
            title: 'Login Date & Time',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.createdAt ? convertLocalDate(row.createdAt) : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Logout Date & Time',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.logoutDateTime ? convertLocalDate(row.logoutDateTime) : "-"} </div>
             </div>`
            }
        },
        {
            title: 'OS',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.osName ? row.osName : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Version',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.osVersion ? row.osVersion : "-"} </div>
             </div>`
            }
        },
        {
            title: 'App Version',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.appVersion ? row.appVersion : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Mac Address',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.macAddress ? row.macAddress : "-"} </div>
             </div>`
            }
        },
        {
            title: 'Device Information',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.deviceInfo ? row.deviceInfo : "-"} </div>
             </div>`
            }
        },
        {
            title: "Status",
            render: function (data: any, type: any, row: any) {
                const statusBadge = row?.isOnline ? (
                    '<span class="font-size-11 badge-soft-success badge bg-secondary">Online</span>'
                ) : (
                    '<span class="font-size-11 badge-soft-danger badge bg-secondary">Offline</span>'
                );

                return statusBadge;
            }
        },
        {
            title: 'User Token',
            render: function (data: any, type: any, row: any) {
                return `
               <div>
                <div style="color: black; fontweight: bold">${row?.id ? row.id : "-"} </div>
             </div>`
            }
        }
    ]

    useEffect(() => {
        reloadDataTable();
    }, [])

    const reloadDataTable = () => {
        if (dataTableId) {
            const dataTableInstance: any = $(`#${dataTableId}`).DataTable();
            dataTableInstance.ajax.reload(null, false);
        }
    };

    const handleDataTableIdChange = (dataTableId: any) => {
        // Now you have access to the DataTable ID here
        console.log('Area DataTable ID:', dataTableId);
        setDataTableId(dataTableId)
        // You can do whatever you need with the ID in this component
    };

    return (
        <div>
            <ToastContainer />
            <ProfileListDataTable
                // data={areaList}
                apiURL={`${url.BASE_URL}${url.GET_DEVICE_LIST}`}
                columns={columns}
                paramsId={{ userId: userId }}
                title="Device List"
                isAddBtnShow={false}
                isBtnShow={false}
                btnName="OTA"
                btnStyle="btn btn-primary"
                secondBtnText="Delete"
                secondBtnStyle="btn btn-dark"
                // onClickBtn={toggleArea}
                // handleEditClick={handleEditClick}
                // handleDeleteClick={handleDeleteClick}
                // editApiCall={editAreaApiCall}
                onDataTableRefresh={handleDataTableIdChange}
                idName={"Devicelist"}
            />
        </div>
    )
}

export default DeviceList;