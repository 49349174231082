export const customStyles = {
    offCanvasHeader: () => ({
        // alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    }),


    wifiStyle: () => ({
        color: "white",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        zIndex: 2
    }),

    box: (isClickable, isOnOff) => ({
        height: 80,
        width: 80,
        borderRadius: 10,
        boxShadow: '0 0 30px #0000001F',
        border: 1,
        opacity: isClickable && isOnOff === "0" || !isClickable ? 0.7 : 1,
        backgroundColor: isClickable && isOnOff === "0" || !isClickable ? "#747474" : "#fff",
        // backgroundColor: "yellow",
        alignItems: "center",
        // padding: 25,
        justifyContent: "center",
        display: "flex",
        marginRight: 20,
        cursor: "pointer",
    }),

    sceneName: (isClickable, isOnOff) => ({
        fontSize: 12,
        lineHeight: 2,
        color: isClickable && isOnOff === "0" || !isClickable ? "#fff" : "#000"
    }),

    handleRoom: (isClickable, isOnOff) => (
        {
            // height: 120,
            // width: 80,
            borderRadius: 10,
            boxShadow: '0 0 20px #0000001F',
            border: 1,
            opacity: isClickable && isOnOff === "0" || !isClickable ? 0.7 : 1,
            backgroundColor: isClickable && isOnOff === "0" || !isClickable ? "#747474" : "#fff",
            zIndex: 3,
            cursor: "pointer"
            // position: "relative",
        }),

    sceneBox: (isClickable, isOnOff) => (
        {
            // height: 120,
            // width: 80,
            borderRadius: 10,
            boxShadow: '0 0 20px #0000001F',
            border: 1,
            opacity: !isClickable ? 0.7 : 1,
            backgroundColor: !isClickable ? "#747474" : "#fff",
            zIndex: 3,
            cursor: "pointer"
            // position: "relative",
        }),

    switchName: (isClickable, isOnOff) => (
        // console.log("isClicked", isClickable, isOnOff),
        {
            color: isClickable && isOnOff === "0" || !isClickable ? "#fff" : "#000"
        }),

    dimmerValueStyle: (isClickable, isOnOff) => (
        {
            color: isClickable && isOnOff === "0" || !isClickable ? "#fff" : "#000"
        }),

};