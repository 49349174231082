import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { BASE_URL, IMAGE_URL } from "../../../helpers/url_helper";
import Request from "../../../helpers/request";
import * as url from '../../../helpers/url_helper'

const ProfileMenu = props => {
  const navigate = useNavigate();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const userName = `${user?.firstname} ${user?.lastname}`;
  const [username, setusername] = useState(userName);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  const logoutApiCall = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("selectedPremiseID");
    localStorage.removeItem("selectedUserID");
    localStorage.removeItem("selectedUserEmail");
    localStorage.removeItem("Authorization");
    localStorage.removeItem("permissions");
    Request.get(url.LOGOUT).
      then((res) => {
        console.log("get dashboard Response", res);
        res.data.statusCode === 200 &&
          navigate("/")
        // localStorage.clear();
        localStorage.removeItem("userInfo");
        localStorage.removeItem("selectedPremiseID");
        localStorage.removeItem("selectedUserID");
        localStorage.removeItem("selectedUserEmail");
        localStorage.removeItem("Authorization");
        localStorage.removeItem("permissions");
      }).
      catch((err) => {
        console.log("err", err);
      })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={`${IMAGE_URL}${user?.avatar}`}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1" style={{ color: '#000' }}>{username}</span>
          <i className='bx bx-chevron-down' size={100}></i>
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/" onClick={() => {
            // localStorage.removeItem("selectedUserID");
            // localStorage.removeItem("selectedPremiseID");
            // localStorage.removeItem("selectedUserEmail");
            logoutApiCall()
          }}>
            {/* <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> */}
            <div style={{ display: "flex", alignItems: "center", }}>
              <span className="me-1"><box-icon name='power-off' size="16px" color="red"></box-icon></span>
              <span>{props.t("Logout")}</span>
            </div>
          </DropdownItem>
          {/* <div className="dropdown-divider" />
          <Link onClick={() => (
            window.localStorage.clear(),
            navigate('/')
          )
          } className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment >
  );
};

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any
// };

// const mapStatetoProps = state => {
//   console.log("state", state);
//   const { error, success } = state.login;
//   return { error, success };
// };

export default withRouter(
  connect()(withTranslation()(ProfileMenu))
);
